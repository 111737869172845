import { gql } from "@apollo/client";

export const FETCH_SP_PICKUPS_MODES = gql`
    {
        getSpPickupsModes{
            _id,
            name,
            info,    
            createdAt,
            updatedAt  
        }
    }
`;

export const FETCH_SP_PICKUP_MODE = gql`
    query getSpPickupMode($id: ID) {
        getSpPickupMode(_id: $id) {
            _id,
            name,
            info,
            createdAt,
            updatedAt
        }
    }
`;

export const ADD_SP_PICKUP_MODE = gql`
    mutation addSpPickupMode($name: String, $info: String) {
        addSpPickupMode(name: $name, info: $info) {
                _id
        }      
    }
`;

export const DELETE_SP_PICKUP_MODE = gql`
    mutation deleteSpPickupMode($id: ID) {
        deleteSpPickupMode(_id: $id) {
            _id
        }
    }
`;

export const UPDATE_SP_PICKUP_MODE = gql`
    mutation updateSpPickupMode($id: ID, $name: String, $info: String) {
        updateSpPickupMode(_id: $id, name: $name, info: $info) {
           _id
        }
    }
`;

export const FETCH_SP_PICKUPS_MODES_FOR_SPPICKUPMODEID = gql`
    {
        getSpPickupsModes{
            _id
            name 
        }
    }
`;