import React, { useContext, useEffect, useState } from 'react';
import { useHttp } from '../../hooks/http.hook';
import { useMessage } from '../../hooks/message.hook';
import { AuthContext } from '../../context/AuthContext';
import { Box, Grid, TextField } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Copyright from '../../components/main/Copyright';
import { makeStyles } from '@material-ui/core/styles';
import Logo from '../../assets/LogoGSL510.svg'
import Back from '../../assets/MainPageBackground.svg';
import Guitars from '../../assets/MainPage_GTRDemo.svg';
import { green } from '@material-ui/core/colors';

const useStyles = makeStyles(() => ({
  paper: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginRight: '10%',
    flexWrap: 'wrap',
    alignContent: 'flex-end',
  },
  rootNotAuth: {
    backgroundImage: `url(` + Back + `)`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "cover",
  },
  imgGuitars: {
    backgroundImage: `url(` + Guitars + `)`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "contain"
  },
  imgLogo: {
    backgroundImage: `url(` + Logo + `)`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "contain"
  },
  form: {
    flexWrap: 'wrap',
    alignContent: 'flex-end',
  },
  title: {
    flexGrow: 1,
    minWidth: "500px"
  },
}));

export const AuthPage = ({ width }) => {
  const classes = useStyles();

  const auth = useContext(AuthContext)
  const message = useMessage()
  const { request, error, clearError } = useHttp()
  const [form, setForm] = useState({
    email: '', password: ''
  })

  useEffect(() => {
    message(error)
    clearError()
  }, [error, message, clearError])

  const changeHandler = event => {
    setForm({ ...form, [event.target.name]: event.target.value })
  }

  const registerHandler = async () => {
    try {
      const data = await request('/api/auth/register', 'POST', { ...form })
      alert(data.message)
    } catch (e) { alert(e) }
  }

  const loginHandler = async () => {
    try {
      const data = await request('/api/auth/login', 'POST', { ...form })
      auth.login(data.token, data.userId)
    } catch (e) { alert(e); }
  }

  var pixLogo = "";
  var pixGuitars = "";
  var pad = "";
  var padGuitars = "";
  switch (true) {
    case width === "xl":
      pixLogo = 510;
      pad = 150;
      pixGuitars= 500;
      padGuitars = 200;
      break;
    case width === "lg":
      pixLogo = 1200;
      pad = 150;
      pixGuitars= 500;
      padGuitars = 250;
      break;
    case width === "md":
      pixLogo = 1200;
      pad = 0;
      pixGuitars= 300;
      padGuitars = 25;
      break;
    case width === "sm":
      pixLogo = 300;
      pad = 0;
      pixGuitars= 200;
      padGuitars = 10;
      break;
    case width === "xs":
      pixLogo = 200;
      pad = 0;
      pixGuitars= 100;
      padGuitars = 10;
      break;
    default:
      pixLogo = 5000;
      pad = 500;
      pixGuitars= 500;
      padGuitars = 100;
  }

  return (
    <>
      <Grid container spacing={2}
        className={classes.rootNotAuth}>

        <Grid item xl={5} lg={5} md={12} sm={12} xs={12}>
          <Box display="flex" justifyContent="center" >
            <img src={Guitars} style={{ maxWidth: pixGuitars, paddingTop: padGuitars }} />
          </Box>
        </Grid>

        <Grid item xl={7} lg={7} md={12} sm={12} xs={12}>
          <Box display="flex" justifyContent="center" >
            
            <img src={Logo} style={{ maxWidth: pixLogo, paddingTop: pad }} />
          </Box>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <Box display="flex" justifyContent="center">
            <Box >
            {width}
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                type="text"
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
                value={form.email}
                onChange={changeHandler}
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                value={form.password}
                onChange={changeHandler}
              />
              <Box display="flex" >
                <Box p={0} flexGrow={1} >
                  <Button style={{ backgroundColor: green [500] }}
                    fullWidth
                    variant="contained"
                    color="primary"
                    onClick={loginHandler}> Sign In </Button>
                </Box>
                <Box p={0} flexGrow={1}>
                  <Button style={{ backgroundColor: green [500] }}
                    fullWidth
                    variant="contained"
                    color="primary"
                    onClick={registerHandler}> Register </Button>
                </Box>
              </Box>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
              <Copyright />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </>
  )
}