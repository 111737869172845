import { gql } from "@apollo/client";

export const FETCH_AREAS = gql`
{
    getAreas{
        _id
        name
        info
        userRole_id {
            _id
        }
        createdAt
        updatedAt  
        
    }
}
`;

export const FETCH_AREA = gql`
query getArea($id: ID) {
    getArea(_id: $id) {
    _id
    name
    info
    userRole_id {
        _id
        name
    }
    createdAt
    updatedAt
  }
}
`;

export const ADD_AREA = gql`
mutation addArea(
    $name: String, 
    $info: String,
    $userRole_id: ID)
{
 addArea(
     name: $name, 
     info: $info,
     userRole_id: $userRole_id) {
         _id
        
     }      
 }
`;

export const DELETE_AREA = gql`
 mutation deleteArea($id: ID) 
 {
  deleteArea(_id: $id) {
      _id
    }
 }
`;

export const UPDATE_AREA = gql`
 mutation updateArea(
     $id: ID, 
     $name: String, 
     $info: String,
     $userRole_id: ID) 
    {
    updateArea(
        _id: $id, 
        name: $name, 
        info: $info,
        userRole_id: $userRole_id) {
       _id
       
    }
 }
`;