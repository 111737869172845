import React from 'react';
import { useQuery } from '@apollo/client';
import Loader from '../../../../components/main/Loader';
import { Box, Container } from '@material-ui/core';
import { FETCH_ST_APPEALS } from './gqlStAppeal';
import GetStAppeal from './getStAppeal';
import { DeleteStAppeal } from './deleteStAppeal';
import { UpdateStAppeal } from './updateStAppeal';


const GetStAppeals = () => {

    const { loading, error, data } = useQuery(FETCH_ST_APPEALS);

    if (loading) return <Loader />;
    if (error) return `Error ${error}`;

    return (
        <>
            <Container>
                <Box display="flex" justifyContent="center">
                    <table>
                        <thead>
                            <tr>
                                <th>№</th>
                                <th>Текст обращения</th>
                                <th>Статус</th>
                                <th>Инициатор</th>
                                <th>Дата регистрации</th>
                                <th>Действия</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.getStAppeals.map((StAppeal, index) => {
                                return (
                                    <tr key={StAppeal._id}>
                                        <td>{StAppeal.numberAppeal}</td>
                                        <td>{StAppeal.textAppeal}</td>
                                        <td>{StAppeal.spStatusAppeal_id.name}</td>
                                        <td>{StAppeal.user_id.email}</td>
                                        <td>{StAppeal.createdAt}</td>
                                        <td>
                                            <GetStAppeal id={StAppeal._id} />
                                            <UpdateStAppeal
                                                id={StAppeal._id}
                                                textAppeal={StAppeal.textAppeal}
                                                location={StAppeal.location} 
                                                spStatusAppeal_id={StAppeal.spStatusAppeal_id._id}
                                                />
                                            <DeleteStAppeal id={StAppeal._id} />
                                        </td>
                                    </tr>)
                            })}
                        </tbody>
                    </table>
                </Box>
            </Container>
        </>
    )
}

export default GetStAppeals;