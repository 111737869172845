import React from 'react';
import { Box, Typography } from '@material-ui/core';

const OpenDataId = ({ label, data, color }) => {

  return ( 
    <Box display="flex" alignItems="center">
      <Box p={1}>
        <Typography >{label}:  </Typography>
      </Box>
      <Box p={1}>
        <Typography style={{ background: `${color}` }}>{data}
        </Typography>
      </Box>
    </Box>

  )
}

export default OpenDataId;