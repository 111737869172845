import React, { useContext, useState } from 'react';
import { useMutation } from '@apollo/client';
import Add from '../../../../components/actions/add';
import OptionalMultiRowsField from '../../../../components/InputElements/optionalMultiRowsField';
import { ADD_ST_APPEAL, FETCH_ST_APPEALS } from './gqlStAppeal';
import { AuthContext } from '../../../../context/AuthContext';

const AddStAppeal = () => {

    const { userId } = useContext(AuthContext)

    const [values, setValues] = useState({ });

    const [addStAppeal] = useMutation(ADD_ST_APPEAL, {

        onError(e) {
            console.log(e.graphQLErrors[0].message)
        },
        variables: {input: values},
        refetchQueries: [{ query: FETCH_ST_APPEALS }],
    });

    const handlerChangeName = (e) => {
        setValues({
            ...values,
            [e.target.name]: e.target.value,
        });
    };

    const formSubmit = (e) => {
        e.preventDefault();
        addStAppeal();
        setValues({  })
    }

    return (
        <Add
            title={"Добавление обращения"}
            ButtonText={"Добавить обращение"}
            dialogTitle={"Добавление обращения"}
            formSubmit={formSubmit}
            Fields={<>
                <OptionalMultiRowsField
                    label={"Текст обращения"}
                    value={values.textAppeal}
                    onChange={handlerChangeName}
                    name={"textAppeal"}
                /> <br></br>
                <OptionalMultiRowsField
                    label={"Расположение"}
                    value={values.location}
                    onChange={handlerChangeName}
                    name={"location"}
                /> <br></br>
                <OptionalMultiRowsField
                    label={"Переменные"}
                    value={values.valueVariables}
                    onChange={handlerChangeName}
                    name={"valueVariables"}
                />  <br></br>
                <OptionalMultiRowsField
                    label={"Инициатор"}
                    value={values.user_id = userId}
                    onChange={handlerChangeName}
                    name={"user_id"}
                />
            </>} />
    )
}

export default AddStAppeal;