import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { ADD_USER_POST, FETCH_USERS_POSTS } from './gqlUserPost';
import TextField from '@material-ui/core/TextField';
import { Box, Button } from '@material-ui/core';

const AddUserPost = (props) => {
  const [values, setValues] = useState({
    postText: "", user_id: props.userId
  });

  const [addUserPost] = useMutation(ADD_USER_POST, {

    onError(e) {
      console.log(e.graphQLErrors[0])
    },
    variables: values,
    refetchQueries: [{ query: FETCH_USERS_POSTS }]
  })

  const handleChange = (e) => {
    setValues({
      ...values,
      [e.target.name]: e.target.value
    })
  }

  const formSubmit = (e) => {
    e.preventDefault();
    addUserPost();
    setValues({ postText: "", user_id: props.userId })
  }
  return (
    <>
      <Box display="flex" justifyContent="center">
        <form onSubmit={formSubmit} >
          <TextField
            id="outlined-textarea"
            label="Multiline Placeholder"
            placeholder="Placeholder"
            multiline
            variant="outlined"
            label="Расскажите о чём либо"
            type="text"
            name="postText"
            fullWidth
            value={values.postText}
            onChange={handleChange}
          />
          <Button
            type="submit"
            variant="contained"
          >
            Add POST
        </Button>
        </form>
      </Box>
    </>
  )
}

export default AddUserPost;