import React from 'react';
import { useQuery } from '@apollo/client';
import { FETCH_AREA } from './gqlArea';
import Loader from '../../../components/main/Loader';
import Open from '../../../components/actions/open';
import OpenDataId from '../../../components/view/openDataId';
import { UpdateArea } from './updateArea';

const GetArea = ({ id }) => {

  const { loading, error, data } = useQuery(FETCH_AREA, {
    variables: { id },
    // refetchQueries: [{ query: FETCH_USERS_ROLES_FOR_USERROLEID }]
  });

  if (loading) return <Loader />;
  if (error) return `Error ${error}`;

  return (
    <Open
      title={"Открыть"}
      dialogTitle={data.getArea.name}
      edit={<UpdateArea
        id={data.getArea._id}
        name={data.getArea.name}
        info={data.getArea.info}
        userRole_id={data.getArea.userRole_id._id}
      />}
      Fields={<>
        <OpenDataId
          label={"Описание"}
          data={data.getArea.info}
        />
        <OpenDataId
          label={"Роли"}
          data={data.getArea.userRole_id.map((userRole) => {
            return (
              <tr key={userRole._id}>
                {[userRole.name]}
              </tr>)
          })}
        />
      </>} />
  )
}

export default GetArea;