import { gql } from "@apollo/client";

export const FETCH_SP_INSTRUMENTS = gql`
{
    getSpInstruments{
        _id,
        name,
        info,
        instrumentIcon,
        createdAt,
        updatedAt
    }
}
`;

export const FETCH_SP_INSTRUMENT = gql`
    query getSpInstrument($id: ID) {
        getSpInstrument(_id: $id) {
            _id,
            name,
            info,
            instrumentIcon,
            createdAt,
            updatedAt
        }
    }
`;

export const ADD_SP_INSTRUMENT = gql`
    mutation addSpInstrument(
        $name: String, 
        $info: String,
        $instrumentIcon: String){
            addSpInstrument(
                name: $name, 
                info: $info,
                instrumentIcon: $instrumentIcon) {
                    _id   
                }      
            }
`;

export const DELETE_SP_INSTRUMENT = gql`
    mutation deleteSpInstrument($id: ID) {
        deleteSpInstrument(_id: $id) {
            _id
        }
    }
`;

export const UPDATE_SP_INSTRUMENT = gql`
    mutation updateSpInstrument(
        $id: ID, 
        $name: String, 
        $info: String,
        $instrumentIcon: String) {
            updateSpInstrument(
                _id: $id, 
                name: $name, 
                info: $info,
                instrumentIcon: $instrumentIcon) {
                    _id
                }
        }
`;

export const FETCH_SP_INSTRUMENTS_FOR_SPINSTRUMENTID = gql`
{
    getSpInstruments{
        _id,
        name,
        instrumentIcon
    }
}
`;