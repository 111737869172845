import React from 'react';
import { useQuery } from '@apollo/client';
import { FETCH_SP_INSTRUMENT } from './gqlSpInstrument';
import Loader from '../../../../components/main/Loader';
import Open from '../../../../components/actions/open';
import OpenDataId from '../../../../components/view/openDataId';
import { UpdateSpInstrument } from './updateSpInstrument';

const GetSpInstrument = ({ id }) => {

  const { loading, error, data } = useQuery(FETCH_SP_INSTRUMENT, {
    variables: { id },
  });

  if (loading) return <Loader />;
  if (error) return `Error ${error}`;

  return (
    <Open
      title={"Открыть"}
      dialogTitle={data.getSpInstrument.name}
      edit={<UpdateSpInstrument
        id={data.getSpInstrument._id}
        name={data.getSpInstrument.name}
        info={data.getSpInstrument.info}
        instrumentIcon={data.getSpInstrument.instrumentIcon} />}
      Fields={<>
        <OpenDataId
          label={"ID"}
          data={data.getSpInstrument._id}
        />
        <OpenDataId
          label={"Наименование"}
          data={data.getSpInstrument.name}
        />
        <OpenDataId
          label={"Icon"}
          data={data.getSpInstrument.instrumentIcon}
        />
        <OpenDataId
          label={"Информация"}
          data={data.getSpInstrument.info}
        />
        <OpenDataId
          label={"Дата создания"}
          data={data.getSpInstrument.createdAt}
        />
        <OpenDataId
          label={"Дата изменения"}
          data={data.getSpInstrument.updatedAt}
        />
      </>
      } />
  )
}

export default GetSpInstrument;