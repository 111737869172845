import React from 'react';
import { useQuery } from '@apollo/client';
import { FETCH_SP_BRIDGE_FAMILY } from './gqlSpBridgeFamily';
import Loader from '../../../../components/main/Loader';
import Open from '../../../../components/actions/open';
import OpenDataId from '../../../../components/view/openDataId';
import { UpdateSpBridgeFamily } from './updateSpBridgeFamily';

const GetSpBridgeFamily = ({ id }) => {

  const { loading, error, data } = useQuery(FETCH_SP_BRIDGE_FAMILY, {
    variables: { id },
  });

  if (loading) return <Loader />;
  if (error) return `Error ${error}`;

  return (
    <Open
      title={"Открыть"}
      dialogTitle={data.getSpBridgeFamily.name}
      edit={<UpdateSpBridgeFamily
        id={data.getSpBridgeFamily._id}
        name={data.getSpBridgeFamily.name}
        info={data.getSpBridgeFamily.info} />}
      Fields={<>
        <OpenDataId
          label={"Наименование"}
          data={data.getSpBridgeFamily._id}
        />
        <OpenDataId
          label={"Информация"}
          data={data.getSpBridgeFamily.info}
        />
        <OpenDataId
          label={"Дата создания"}
          data={data.getSpBridgeFamily.createdAt}
        />
        <OpenDataId
          label={"Дата изменения"}
          data={data.getSpBridgeFamily.updatedAt}
        />
      </>} />
  )
}

export default GetSpBridgeFamily;