import React from 'react';

import GetUsers from '../../requests/users/getUsers';


export const PeoplePage = () => {
  return (
    <>
    <GetUsers/>
    </>
  )
}
