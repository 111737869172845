import React from 'react';
import { useMutation } from '@apollo/client';
import { DELETE_PT_PICKUP, FETCH_PT_PICKUPS } from './gqlPtPickup';
import { Delete } from '../../../../components/actions/delete';


export const DeletePtPickup = ({ id }) => {
  const [deletePtPickup] = useMutation(DELETE_PT_PICKUP, {

    onError(e) {
      console.log(e.graphQLErrors[0].message)
    },
    variables: { id },
    refetchQueries: [{ query: FETCH_PT_PICKUPS }]
  });

  const onDelete = () => {
    deletePtPickup()
  }
  return (
    <Delete
      onDelete={onDelete} />
  )
}