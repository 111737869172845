import React, { useState } from 'react';
import { Button, Dialog, DialogActions, DialogTitle, Tooltip, IconButton } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';

export const Delete = ({ onDelete }) => {
  const [open, setOpen] = useState(false)
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>

      <Tooltip
        title="Delete"
        arrow>
        <IconButton aria-label="delete" type="submit" onClick={() => setOpen(true)}><DeleteIcon /></IconButton>
      </Tooltip>

      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">

        <DialogTitle id="form-dialog-title">Вы действительно хотите удалить запись?</DialogTitle>

        <DialogActions>

          <Button type="submit" onClick={onDelete} color="primary">
            Delete
            </Button>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>

        </DialogActions>
      </Dialog>
    </>
  )
}
