import React from 'react';
import { useMutation } from '@apollo/client';
import { DELETE_AREA, FETCH_AREAS } from './gqlArea';
import { Delete } from '../../../components/actions/delete';


export const DeleteArea = ({ id }) => {
  const [deleteSpBrand] = useMutation(DELETE_AREA, {

    onError(e) {
      console.log(e.graphQLErrors[0].message)
    },
    variables: { id },
    refetchQueries: [{ query: FETCH_AREAS }]
  });

  const onDelete = () => {
    deleteSpBrand()
  }
  return (
    <Delete
      onDelete={onDelete} />
  )
}