import React from 'react';
import { useQuery } from '@apollo/client';
import { FETCH_PR_GSL_CARDS } from './gqlPrGslCard';
import Loader from '../../../components/main/Loader';
import CardGslMini from '../../../components/view/cards/cardGslMini';
import { Container, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  cardGrid: {
    flexGrow: 1,
    paddingTop: 5,
  },
  rootGrid: {
    flexGrow: 1,
  },
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
  },
  card: {
    width: "100%",
    position: 'relative',
    height: 200
  },
  image: {
    width: "100%",
    position: 'absolute'
  },
}));

const GslCardMini = () => {
  const classes = useStyles();

  const { loading, error, data } = useQuery(FETCH_PR_GSL_CARDS);

  if (loading) return <Loader />;
  if (error) return `Error ${error}`;

  return (
    <>
      <Container className={classes.cardGrid} maxWidth="xl">
        <Grid
          container
          direction="row"
          justify="space-evenly"
          alignItems="stretch"
          spacing={5}
        >
          {!data.getPrGslCards ? null : data.getPrGslCards.map((prGslCard, index) => {
            return (
              <Grid item
                key={`GridItem-${prGslCard._id}`} xs={4} sm={6} md={4} lg={4} xl={4}
              >
                <CardGslMini
                  name={prGslCard.spBrand_id.name}
                  ptFormFactor_id={prGslCard.ptFormFactor_id.formfactorImage}
                  dirName_ptFormFactor_id={"images/formfactor"}
                  ptNeck_id={prGslCard.ptNeck_id.neckImage}
                  dirName_ptNeck_id={"images/neck"}
                  ptHeadstock_id={prGslCard.ptHeadstock_id.headstockImage}
                  dirName_ptHeadstock_id={"images/headstock"}
                  ptBridge_id={prGslCard.ptBridge_id.bridgeImage}
                  dirName_ptBridge_id={"images/bridge"}
                  ptPickguard_Id={!prGslCard.ptPickguard_id ? null :prGslCard.ptPickguard_id.pickguardImage}
                  dirName_ptPickguard_Id={"images/pickguard"}

                  ptPickupBridge_id={prGslCard.ptPickupBridge_id.pickupImage}
                  ptPickupMiddle_id={!prGslCard.ptPickupMiddle_id ? null : prGslCard.ptPickupMiddle_id.pickupImage}
                  ptPickupNeck_id={!prGslCard.ptPickupNeck_id ? null : prGslCard.ptPickupNeck_id.pickupImage}
                  dirName_ptPickup={"images/pickup"}
                  info={prGslCard.model}
                />
              </Grid>
            )
          })}
        </Grid>
      </Container>
    </>
  )
}

export default GslCardMini;