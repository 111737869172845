import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { ADD_USER_ROLE, FETCH_USERS_ROLES } from './gqlUserRole';
import { Button, Container, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Tooltip } from '@material-ui/core';


const AddUserRole = () => {


    const [open, setOpen] = useState(false)
    const [values, setValues] = useState({
        name: ""
    });

    const [addUserRole] = useMutation(ADD_USER_ROLE, {

        onError(e) {
            console.log(e.graphQLErrors[0].message)
        },
        variables: values,
        refetchQueries: [{ query: FETCH_USERS_ROLES }]
    });
console.log(values)
    const handleClose = () => {
        setOpen(false);
    };

    const handlerChangeName = (e) => {
        setValues({
            ...values,
            [e.target.name]: e.target.value,
        });
    };


    const formSubmit = (e) => {
        e.preventDefault();
        addUserRole();
        setValues({ name: "" })
    }

    return (
       
            <Container>
                <Tooltip
                    title="Add Role"
                    arrow>
                    <Button onClick={() => setOpen(true)}>Add Role</Button>
                </Tooltip>

                <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                    <form onSubmit={formSubmit}>
                        <DialogTitle id="form-dialog-title">Update</DialogTitle>
                        <DialogContent>

                            <TextField
                                id="standard-basic"
                                label="Наименование"
                                type="text"
                                name="name"
                                value={values.name}
                                onChange={handlerChangeName}
                            >
                            </TextField>
                            <TextField
                                id="standard-basic"
                                label="info"
                                type="text"
                                name="info"
                                value={values.info}
                                onChange={handlerChangeName}
                            >
                            </TextField>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleClose} color="primary">
                                Cancel
          </Button>
                            <Button type="Submit" onClick={handleClose} color="secondary">
                                Save
          </Button>
                        </DialogActions>
                    </form>
                </Dialog>
            </Container>
      
    )

}

export default AddUserRole;