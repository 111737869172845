import { gql } from "@apollo/client";

export const FETCH_SP_BRANDS = gql`
    query getSpBrands($sheet: Int, $limit: Int){
        getSpBrands(sheet: $sheet, limit: $limit){
            _id,
            name,
            info,   
            dateOfFoundation,
            web,
            spCountry_id {
                _id,
                name
            },
            spCountryProduction_id {
                _id,
                name
            },
            createdAt,
            updatedAt
        }
    }
`;

export const FETCH_SP_BRAND = gql`
    query getSpBrand($id: ID) {
        getSpBrand(_id: $id) {
            _id,
            name,
            info,
            dateOfFoundation,
            spCountry_id {
                _id,
                name
            },
            spCountryProduction_id {
                _id,
                name
            },
            web,
            createdAt,
            updatedAt
    }
}
`;

export const ADD_SP_BRAND = gql`
    mutation addSpBrand(
        $name: String, 
        $info: String, 
        $dateOfFoundation: String,
        $spCountry_id: ID,
        $spCountryProduction_id: ID,
        $web: String) {
            addSpBrand(
                name: $name, 
                info: $info, 
                dateOfFoundation: $dateOfFoundation,
                spCountry_id: $spCountry_id,
                spCountryProduction_id: $spCountryProduction_id,
                web: $web) {
                    _id
                }      
        }
`;

export const DELETE_SP_BRAND = gql`
    mutation deleteSpBrand($id: ID) {
        deleteSpBrand(_id: $id) {
            _id
        }
    }
`;

export const UPDATE_SP_BRAND = gql`
    mutation updateSpBrand(
        $id: ID, 
        $name: String, 
        $info: String,  
        $dateOfFoundation: String,
        $spCountry_id: ID,
        $spCountryProduction_id: ID,
        $web: String) {
            updateSpBrand(
                _id: $id, 
                name: $name, 
                info: $info,
                dateOfFoundation: $dateOfFoundation,
                spCountry_id: $spCountry_id,
                spCountryProduction_id: $spCountryProduction_id,
                web: $web) {
                    _id
                }
    }
`;

export const FETCH_SP_BRANDS_FOR_SPBRANDSID = gql`
{
    getSpBrands{
        _id,
        name
    }
}
`;