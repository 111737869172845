import { gql } from "@apollo/client";

export const FETCH_SP_MENSURAS = gql`
query getSpMensuras($sheet: Int, $limit: Int){
        getSpMensuras(sheet: $sheet, limit: $limit){
            _id,
            name,
            nameInch,
            info,
            spInstrument_id {
                _id
            },
            spInstrumentType_id {
                _id
            },
            spNumberOfStrings_id {
                _id
            },
            spNumberOfFrets_id {
                _id
            },
            source
            createdAt,
            updatedAt  
        }
    }
`;

export const FETCH_SP_MENSURA = gql`
    query getSpMensura($id: ID) {
        getSpMensura(_id: $id) {
            _id,
            name,
            nameInch,
            info,
            spInstrument_id {
                _id,
                name
            },
            spInstrumentType_id {
                _id,
                name
            },
            spNumberOfStrings_id {
                _id,
                name,
                code
            },
            spNumberOfFrets_id {
                _id,
                name
            },
            source
            createdAt,
            updatedAt
        }
    }
`;

export const ADD_SP_MENSURA = gql`
    mutation addSpMensura(
        $name: String,
        $nameInch: String,
        $info: String,
        $spInstrument_id: ID,
        $spInstrumentType_id: ID,
        $spNumberOfStrings_id: ID,
        $spNumberOfFrets_id: ID,
        $source: String) {
            addSpMensura(
                name: $name,
                nameInch: $nameInch, 
                info: $info,
                spInstrument_id:  $spInstrument_id,
                spInstrumentType_id: $spInstrumentType_id,
                spNumberOfStrings_id: $spNumberOfStrings_id,
                spNumberOfFrets_id: $spNumberOfFrets_id,
                source: $source) {
                    _id 
                }      
    }
`;

export const DELETE_SP_MENSURA = gql`
    mutation deleteSpMensura($id: ID) {
        deleteSpMensura(_id: $id) {
            _id
        }
    }
`;

export const UPDATE_SP_MENSURA = gql`
    mutation updateSpMensura(
        $id: ID, 
        $name: String,
        $nameInch: String,
        $info: String,
        $spInstrument_id: ID,
        $spInstrumentType_id: ID,
        $spNumberOfStrings_id: ID,
        $spNumberOfFrets_id: ID,
        $source: String) {
            updateSpMensura(
                _id: $id, 
                name: $name,
                nameInch: $nameInch,
                info: $info,
                spInstrument_id: $spInstrument_id,
                spInstrumentType_id: $spInstrumentType_id,
                spNumberOfStrings_id: $spNumberOfStrings_id,
                spNumberOfFrets_id: $spNumberOfFrets_id,
                source: $source) {
                    _id
                }
    }
`;

export const FETCH_SP_MENSURAS_FORSPMENSURASID = gql`
    {
        getSpMensuras{
            _id,
            name
        }
    }
`;

export const FETCH_SP_MENSURA_FILTER = gql`
    query getSpMensuraFilter(
        $spInstrument_id: String, 
        $spInstrumentType_id: String,
        $spNumberOfStrings_id: String,
        $spNumberOfFrets_id: String) {
            getSpMensuraFilter(
                spInstrument_id: $spInstrument_id,
                spInstrumentType_id: $spInstrumentType_id,
                spNumberOfStrings_id: $spNumberOfStrings_id,
                spNumberOfFrets_id: $spNumberOfFrets_id) {
                    _id,
                    name
                }
        }
`;