import React from 'react';
import { useMutation } from '@apollo/client';
import { DELETE_USER_ROLE, FETCH_USERS_ROLES } from './gqlUserRole';
import { Tooltip } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';

export const DeleteUserRole = ({ id }) => {
  const [deleteUserRole] = useMutation(DELETE_USER_ROLE, {

    onError(e) {
      console.log(e.graphQLErrors[0].message)
    },
    variables: { id },
    refetchQueries: [{ query: FETCH_USERS_ROLES }]
  });

  const onDelete = () => {
    deleteUserRole()
  }
  return (
    <>
      <Tooltip
        title="Delete"
        arrow>
        <IconButton aria-label="delete" type="submit" onClick={onDelete}>
          <DeleteIcon />
        </IconButton>
      </Tooltip>
    </>
  )
}
