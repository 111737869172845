import React, { useContext } from 'react';
import { NavLink } from 'react-router-dom';
import { AuthContext } from '../../context/AuthContext';
import { useQuery } from '@apollo/client';
import { FETCH_CURRENT_USER } from '../../requests/users/gqlCurrentUser';
import { UpdateUser } from '../../requests/users/updateUser';
import Loader from './Loader';
import HomeIcon from '@material-ui/icons/Home';
import RssFeedIcon from '@material-ui/icons/RssFeed';
import PeopleIcon from '@material-ui/icons/People';
import StorageIcon from '@material-ui/icons/Storage';
import { Box, CardMedia, Grid, List, ListItem, ListItemIcon, ListItemText, MenuItem, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import userAvatar from '../../assets/images/userAvatar.png'
import { grey } from '@material-ui/core/colors';

const useStyles = makeStyles((theme) => ({
  large: {
    width: 200,
    height: 200,
    maxWidth: 400,
  },
}));

export const SideBar = () => {
  const classes = useStyles();

  const { userId } = useContext(AuthContext)

  const { loading, error, data } = useQuery(FETCH_CURRENT_USER, {
    variables: { id: userId },
  });

  if (loading) return <Loader />;
  if (error) return `Error ${error}`;

  const imgOnServer = `http://localhost:5000/public/dataUser/${data.getUser.email}/avatar/${data.getUser.avatar}`

  return (
    <>
      <Grid container spacing={2} style={{ backgroundColor: grey[800] }}>

        <Grid item xl={12} lg={12} md={12} sm={12} xs={12} justifyContent="center">
          <Box display="flex" justifyContent="center">
            {data.getUser.avatar !== "Информация не заполнена" || ""
              ? <CardMedia className={classes.large}
                component="img"
                image={imgOnServer} />
              : <CardMedia className={classes.large}
                component="img"
                image={userAvatar} />
            }
          </Box>
        </Grid>

        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Box textAlign='center'>
            <Typography style={{ color: grey[50] }}>{data.getUser.firstName}{" "}{data.getUser.lastName}</Typography>
          </Box>  <br></br>
        </Grid>

        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Box textAlign='center'>
            <Typography component={'span'} style={{ color: grey[50] }}>
              {data.getUser.userRole_id.map(userRole_id => {
                return (
                  <div key={userRole_id._id}>
                    {[userRole_id.name]}
                  </div>)
              })}
            </Typography>
          </Box> <br></br>
        </Grid>

        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Box textAlign='center'>
            <Typography style={{ color: grey[50] }}>{data.getUser.email}</Typography>
          </Box><br></br>
        </Grid>

        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <List>
            <ListItem>
              <ListItemIcon> <HomeIcon /></ListItemIcon>
              <ListItemText>
                <NavLink to="/profile" style={{ textDecoration: 'none' }}>
                  <Typography subtitle1="h2" style={{ color: grey[500] }}>Профиль</Typography>
                </NavLink>
              </ListItemText>
            </ListItem>

            <ListItem>
              <ListItemIcon>  <RssFeedIcon /></ListItemIcon>
              <ListItemText>
                <NavLink to="/feed" style={{ textDecoration: 'none' }}>
                  <Typography subtitle1="h2" style={{ color: grey[500] }}>Лента</Typography>
                </NavLink>
              </ListItemText>
            </ListItem>

            <ListItem>
              <ListItemIcon>  <PeopleIcon /></ListItemIcon>
              <ListItemText>
                <NavLink to="/people" style={{ textDecoration: 'none' }}>
                  <Typography subtitle1="h2" style={{ color: grey[500] }}>Пользователи</Typography>
                </NavLink>
              </ListItemText>
            </ListItem>

            <ListItem>
              <ListItemIcon>  <PeopleIcon /></ListItemIcon>
              <ListItemText>
                <NavLink to="/instruments" style={{ textDecoration: 'none' }}>
                  <Typography subtitle1="h2" style={{ color: grey[500] }}>Инструменты</Typography>
                </NavLink>
              </ListItemText>
            </ListItem>

          </List>

        </Grid>

        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
        <Box justifyContent="center" style={{ color: grey[500] }}>

          <UpdateUser
            id={data.getUser._id}
            email={data.getUser.email}
            password={data.getUser.password}
            firstName={data.getUser.firstName}
            lastName={data.getUser.lastName}
            userRole_id={data.getUser.userRole_id._id}
            avatar={data.getUser.avatar}
            canvas={data.getUser.canvas}
          /> <br></br> <br></br> <br></br> <br></br>

          Панель администратора
            <MenuItem>
              <StorageIcon />
              <NavLink to="/administrativePage" style={{ textDecoration: 'none' }}>
                <Typography subtitle1="h2" style={{ color: grey[500] }}>Администрирование</Typography>
              </NavLink>
            </MenuItem>

            <MenuItem>
              <StorageIcon />
              <NavLink to="/supportPage" style={{ textDecoration: 'none' }}>
                <Typography subtitle1="h2" style={{ color: grey[500] }}>Поддержка</Typography>
              </NavLink>
            </MenuItem>

            <MenuItem>
              <StorageIcon />
              <NavLink to="/spPage" style={{ textDecoration: 'none' }}>
                <Typography subtitle1="h2" style={{ color: grey[500] }}>Справочники</Typography>
              </NavLink>
            </MenuItem>

            <MenuItem>
              <StorageIcon />
              <NavLink to="/ptPage" style={{ textDecoration: 'none' }}>
                <Typography subtitle1="h2" style={{ color: grey[500] }}>Части</Typography>
              </NavLink>
            </MenuItem>

            <MenuItem>
              <StorageIcon />
              <NavLink to="/productPage" style={{ textDecoration: 'none' }}>
                <Typography subtitle1="h2" style={{ color: grey[500] }}>Продукты</Typography>
              </NavLink>
            </MenuItem>

            <MenuItem>
              <StorageIcon />
              <NavLink to="/materials" style={{ textDecoration: 'none' }}>
                <Typography subtitle1="h2" style={{ color: grey[500] }}>Материалы</Typography>
              </NavLink>
            </MenuItem>

            <MenuItem>
              <StorageIcon />
              <NavLink to="/instruments" style={{ textDecoration: 'none' }}>
                <Typography subtitle1="h2" style={{ color: grey[500] }}>Инструменты</Typography>
              </NavLink>
            </MenuItem>

            <MenuItem>
              <StorageIcon />
              <NavLink to="/test" style={{ textDecoration: 'none' }}>
                <Typography subtitle1="h2" style={{ color: grey[500] }}>Тест</Typography>
              </NavLink>
            </MenuItem>
          
        </Box>
        </Grid>
      </Grid>
    </>
  )
}