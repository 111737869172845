import React from 'react';
import { useQuery } from '@apollo/client';
import { FETCH_SP_COUNTRY } from './gqlSpCountry';
import Loader from '../../../../components/main/Loader';
import Open from '../../../../components/actions/open';
import OpenDataId from '../../../../components/view/openDataId';
import { UpdateSpCountry } from './updateSpCountry';

const GetSpCountry = ({ id }) => {

  const { loading, error, data } = useQuery(FETCH_SP_COUNTRY, {
    variables: { id },
  });

  if (loading) return <Loader />;
  if (error) return `Error ${error}`;

  return (
    <Open
      title={"Открыть"}
      dialogTitle={data.getSpCountry.name}
      edit={<UpdateSpCountry id={data.getSpCountry._id} name={data.getSpCountry.name} />}
      Fields={<>
        <OpenDataId
          label={"ID"}
          data={data.getSpCountry._id}
        />
        <OpenDataId
          label={"Информация"}
          data={data.getSpCountry.info}
        />
        <OpenDataId
          label={"Дата создания"}
          data={data.getSpCountry.createdAt}
        />
        <OpenDataId
          label={"Дата изменения"}
          data={data.getSpCountry.updatedAt}
        />
      </>} />
  )
}

export default GetSpCountry;