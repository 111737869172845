import React, { useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { FETCH_SP_BRANDS, UPDATE_SP_BRAND } from './gqlSpBrand';
import { FETCH_SP_COUNTRYS_FOR_SPCOUNTRYID } from '../spCountry/gqlSpCountry';
import Loader from '../../../../components/main/Loader';
import Update from '../../../../components/actions/update';
import RequiredField from '../../../../components/InputElements/requiredField';
import OptionalMultiRowsField from '../../../../components/InputElements/optionalMultiRowsField';
import RequiredSelect from '../../../../components/InputElements/requiredSelect';


export const UpdateSpBrand = ({
    id, name, info, dateOfFoundation, spCountry_id, spCountryProduction_id, web }) => {

    const [values, setValues] = useState({
        name, info, dateOfFoundation, spCountry_id, spCountryProduction_id, web
    });

    const [updateSpBrand] = useMutation(UPDATE_SP_BRAND, {

        onError(e) {
            console.log(e.graphQLErrors[0].message)
        },
        variables: {
            id, name: values.name, info: values.info, dateOfFoundation: values.dateOfFoundation, spCountry_id: values.spCountry_id,
            spCountryProduction_id: values.spCountryProduction_id, web: values.web
        },
        refetchQueries: [{ query: FETCH_SP_BRANDS }]
    })

    const { loading, error, data: data_FETCH_SP_COUNTRYS_FOR_SPCOUNTRYID } = useQuery(FETCH_SP_COUNTRYS_FOR_SPCOUNTRYID);

    if (loading) return <Loader />;
    if (error) return `Error ${error}`;

    const handlerChangeName = (e) => {
        setValues({
            ...values,
            [e.target.name]: e.target.value
        })
    }

    const formSubmit = (e) => {
        e.preventDefault();
        updateSpBrand();
    }

    return (
        <Update
            title={"Изменение Бренда"}
            ButtonText={"Изменить Бренд"}
            dialogTitle={"Изменение Бренда"}
            formSubmit={formSubmit}
            Fields={<>
                <RequiredField
                    label={"Наименование"}
                    value={values.name}
                    onChange={handlerChangeName}
                    name={"name"}
                />
                <br></br>
                <OptionalMultiRowsField
                    label={"Информация"}
                    value={values.info}
                    onChange={handlerChangeName}
                    name={"info"}
                />
                <br></br>
                <RequiredField
                    label={"dateOfFoundation"}
                    value={values.dateOfFoundation}
                    onChange={handlerChangeName}
                    name={"dateOfFoundation"}
                />
                <br></br>
                <RequiredField
                    label={"web"}
                    value={values.web}
                    onChange={handlerChangeName}
                    name={"web"}
                />
                <br></br>
                <RequiredSelect
                    label={"spCountry_id"}
                    name={"spCountry_id"}
                    value={values.spCountry_id}
                    onChange={handlerChangeName}
                    fetch={data_FETCH_SP_COUNTRYS_FOR_SPCOUNTRYID.getSpCountrys}
                />
                <br></br>
                <RequiredSelect
                    label={"spCountryProduction_id"}
                    name={"spCountryProduction_id"}
                    value={values.spCountryProduction_id}
                    onChange={handlerChangeName}
                    fetch={data_FETCH_SP_COUNTRYS_FOR_SPCOUNTRYID.getSpCountrys}
                />
                <br></br>
            </>} />

    )
}