import React from 'react';
import { useQuery } from '@apollo/client';
import { FETCH_SP_PICKUPS_TYPES } from './gqlSpPickupType';
import GetSpPickupType from './getSpPickupType';
import { DeleteSpPickupType } from './deleteSpPickupType';
import { UpdateSpPickupType } from './updateSpPickupType';
import Loader from '../../../../components/main/Loader';
import { Box, Container } from '@material-ui/core';

const GetSpPickupsTypes = () => {

    const { loading, error, data } = useQuery(FETCH_SP_PICKUPS_TYPES);

    if (loading) return <Loader />;
    if (error) return `Error ${error}`;

    return (
        <>
            <Container>
                <Box display="flex" justifyContent="center">
                    <table>
                        <thead>
                            <tr>
                                <th>№</th>
                                <th>Наименование</th>
                                <th>Информация</th>
                                <th>Дата создания</th>
                                <th>Дата изменения</th>
                                <th>Действия</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.getSpPickupsTypes.map((SpPickupType, index) => {
                                return (
                                    <tr key={SpPickupType._id}>
                                        <td>{index + 1}</td>
                                        <td>{SpPickupType.name}</td>
                                        <td>{SpPickupType.info}</td>
                                        <td>{SpPickupType.createdAt}</td>
                                        <td>{SpPickupType.updatedAt}</td>
                                        <td>
                                            <GetSpPickupType id={SpPickupType._id} />
                                            <UpdateSpPickupType id={SpPickupType._id} name={SpPickupType.name} info={SpPickupType.info}/>
                                            <DeleteSpPickupType id={SpPickupType._id} />
                                        </td>
                                    </tr>)
                            })}
                        </tbody>
                    </table>
                </Box>
            </Container>
        </>
    )
}

export default GetSpPickupsTypes;