import React from 'react';
import { useQuery } from '@apollo/client';
import { FETCH_SP_INSTRUMENT_TYPE } from './gqlSpInstrumentType';
import Loader from '../../../../components/main/Loader';
import Open from '../../../../components/actions/open';
import { UpdateSpInstrument } from '../spInstrument/updateSpInstrument';
import OpenDataId from '../../../../components/view/openDataId';

const GetSpInstrumentType = ({ id }) => {

  const { loading, error, data } = useQuery(FETCH_SP_INSTRUMENT_TYPE, {
    variables: { id }
  });

  if (loading) return <Loader />;
  if (error) return `Error ${error}`;

  return (
    <Open
      title={"Открыть"}
      dialogTitle={data.getSpInstrumentType.name}
      edit={<UpdateSpInstrument
        id={data.getSpInstrumentType._id}
        name={data.getSpInstrumentType.name}
        info={data.getSpInstrumentType.info}
        instrumentIcon={data.getSpInstrumentType.instrumentTypeIcon} />}
      Fields={<>
        <OpenDataId
          label={"ID"}
          data={data.getSpInstrumentType._id}
        />
        <OpenDataId
          label={"Наименование"}
          data={data.getSpInstrumentType.name}
        />
        <OpenDataId
          label={"Icon"}
          data={data.getSpInstrumentType.instrumentTypeIcon}
        />
        <OpenDataId
          label={"Информация"}
          data={data.getSpInstrumentType.info}
        />
        <OpenDataId
          label={"Дата создания"}
          data={data.getSpInstrumentType.createdAt}
        />
        <OpenDataId
          label={"Дата изменения"}
          data={data.getSpInstrumentType.updatedAt}
        />
      </>
      } />
  )
}

export default GetSpInstrumentType;