import { useMutation } from '@apollo/client';
import React, { useState } from 'react';
import Update from '../../../../components/actions/update';
import UploadImage from '../../../../components/actions/uploadImage';
import OptionalMultiRowsField from '../../../../components/InputElements/optionalMultiRowsField';
import RequiredField from '../../../../components/InputElements/requiredField';
import { FETCH_SP_MATERIALS, UPDATE_SP_MATERIAL } from './gqlSpMaterial';


export const UpdateSpMaterial = ({ id, name, info, nameEng, materialImage }) => {
    const [values, setValues] = useState({
        name, info, nameEng, materialImage
    });

    const [image, setImage] = useState({
        fileName: materialImage
    });

    const [updateUserRole] = useMutation(UPDATE_SP_MATERIAL, {

        onError(e) {
            console.log(e.graphQLErrors[0].message)
        },
        variables: { id, name: values.name, info: values.info, nameEng: values.nameEng, materialImage: values.materialImage },
        refetchQueries: [{ query: FETCH_SP_MATERIALS }]
    })

    const handlerChangeName = (e) => {
        setValues({
            ...values,
            [e.target.name]: e.target.value
        })
    }

    const formSubmit = (e) => {
        e.preventDefault();
        updateUserRole();
    }

    const updateDataImage = (value) => {
        setImage({ fileName: value })
    }

    return (
        <Update
            title={"Изменение Material"}
            ButtonText={"Изменить Material"}
            dialogTitle={"Изменение Material"}
            formSubmit={formSubmit}
            Fields={<>
                <RequiredField
                    label={"Наименование"}
                    value={values.name}
                    onChange={handlerChangeName}
                    name={"name"}
                /><br></br>
                <RequiredField
                    label={"Наименование (ENG)"}
                    value={values.nameEng}
                    onChange={handlerChangeName}
                    name={"nameEng"}
                /><br></br>
                <OptionalMultiRowsField
                    label={"Информация"}
                    value={values.info}
                    onChange={handlerChangeName}
                    name={"info"}
                /><br></br>
                <UploadImage
                    label="Изображение"
                    value={values.materialImage}
                    nameData="sp_material_img"
                    method={"sp/material"}
                    updateDataImage={updateDataImage}
                /><br></br>
                <RequiredField
                    label={"Изображение"}
                    value={values.materialImage = image.fileName.toString()}
                    onChange={handlerChangeName}
                    name={"materialImage"}
                />
            </>} />
    )
}