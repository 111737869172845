import React from 'react';
import { useQuery } from '@apollo/client';
import { FETCH_PT_BRIDGE } from './gqlPtBridge';
import Loader from '../../../../components/main/Loader';
import Open from '../../../../components/actions/open';
import { UpdatePtBridge } from './updatePtBridge';
import OpenDataId from '../../../../components/view/openDataId';
import ViewImage from '../../../../components/view/displayingData/viewImage';
import ViewIcon from '../../../../components/view/displayingData/viewIcon';

const GetPtBridge = ({ id }) => {

  const { loading, error, data } = useQuery(FETCH_PT_BRIDGE, {
    variables: { id },
  });

  if (loading) return <Loader />;
  if (error) return `Error ${error}`;

  return (
    <Open
      title={"Открыть"}
      dialogTitle={data.getPtBridge.name}
      edit={<UpdatePtBridge
        id={data.getPtBridge._id}
        name={data.getPtBridge.name}
        info={data.getPtBridge.info}
        spInstrument_id={data.getPtBridge.spInstrument_id._id}
        spInstrumentType_id={data.getPtBridge.spInstrumentType_id._id}
        spBridgeClass_id={data.getPtBridge.spBridgeClass_id._id}
        spBridgeFamily_id={data.getPtBridge.spBridgeFamily_id._id}
        spNumberOfStrings_id={data.getPtBridge.spNumberOfStrings_id._id}
        sourse={data.getPtBridge.sourse}
        bridgeIcon={data.getPtBridge.bridgeIcon}
        bridgeImage={data.getPtBridge.bridgeImage}
      />}
      Fields={<>
        <OpenDataId
          label={"ID"}
          data={data.getPtBridge._id}
        />
        <OpenDataId
          label={"Инструмент"}
          data={data.getPtBridge.spInstrument_id.name}
        />
        <OpenDataId
          label={"Тип инструмента"}
          data={data.getPtBridge.spInstrumentType_id.name}
        />
        <OpenDataId
          label={"Класс Bridge"}
          data={data.getPtBridge.spBridgeClass_id.name}
        />
        <OpenDataId
          label={"Семейство Bridge"}
          data={data.getPtBridge.spBridgeFamily_id.name}
        />
        <OpenDataId
          label={"Количество струн"}
          data={data.getPtBridge.spNumberOfStrings_id.name}
        />
        <OpenDataId
          label={"Источник"}
          data={data.getPtBridge.sourse}
        />
        <OpenDataId
          label={"bridgeIcon"}
          data={data.getPtBridge.bridgeIcon}
        />
        <ViewIcon
          image={data.getPtBridge.bridgeIcon}
          dir={"icons/bridge"}
        />
        <OpenDataId
          label={"bridgeImage"}
          data={data.getPtBridge.bridgeImage}
        />
        <ViewImage
          image={data.getPtBridge.bridgeImage}
          dir={"images/bridge"}
        />
        <OpenDataId
          label={"Дата изменения"}
          data={data.getPtBridge.createdAt}
        />
        <OpenDataId
          label={"Дата изменения"}
          data={data.getPtBridge.updatedAt}
        />
      </>
      } />
  )
}

export default GetPtBridge;