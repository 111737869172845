import React from 'react';
import { Grid } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';


export const AreaBar = () => {

  return (
    <>
      <Grid container spacing={3} style={{ backgroundColor: grey[800] }}></Grid>
    </>
  )
}