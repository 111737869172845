import React from 'react';
import { useQuery } from '@apollo/client';
import { FETCH_SP_NUMBER_OF_STRINGS } from './gqlSpNumberOfStrings';
import Loader from '../../../../components/main/Loader';
import Open from '../../../../components/actions/open';
import OpenDataId from '../../../../components/view/openDataId';
import { UpdateSpNumberOfStrings } from './updateSpNumberOfStrings';

const GetSpNumberOfStrings = ({ id }) => {

  const { loading, error, data } = useQuery(FETCH_SP_NUMBER_OF_STRINGS, {
    variables: { id }
  });

  if (loading) return <Loader />;
  if (error) return `Error ${error}`;

  return (
    <Open
      title={"Открыть"}
      dialogTitle={data.getSpNumberOfStrings.name}
      edit={<UpdateSpNumberOfStrings
        id={data.getSpNumberOfStrings._id}
        name={data.getSpNumberOfStrings.name}
        info={data.getSpNumberOfStrings.info}
        spInstrument_id={data.getSpNumberOfStrings.spInstrument_id._id}
        spInstrumentType_id={data.getSpNumberOfStrings.spInstrumentType_id._id}
        code={data.getSpNumberOfStrings.code}
        spInstrumentName={data.getSpNumberOfStrings.spInstrument_id._id} />}
      Fields={<>
        <OpenDataId
          label={"Наименование"}
          data={data.getSpNumberOfStrings._id}
        />
        <OpenDataId
          label={"Информация"}
          data={data.getSpNumberOfStrings.info}
        />
        <OpenDataId
          label={"spInstrument_id"}
          data={data.getSpNumberOfStrings.spInstrument_id.name}
        />
        <OpenDataId
          label={"spInstrumentType_id"}
          data={data.getSpNumberOfStrings.spInstrumentType_id.name}
        />
        <OpenDataId
          label={"Дата создания"}
          data={data.getSpNumberOfStrings.createdAt}
        />
        <OpenDataId
          label={"Дата изменения"}
          data={data.getSpNumberOfStrings.updatedAt}
        />
      </>} />
  )
}

export default GetSpNumberOfStrings;