import React from 'react';
import { useMutation } from '@apollo/client';
import { DELETE_SP_PICKUP_COIL, FETCH_SP_PICKUP_COILS } from './gqlSpPickupCoil';
import { Delete } from '../../../../components/actions/delete';


export const DeleteSpPickupCoil = ({id}) => {
  const [deleteSpPickupCoil] = useMutation(DELETE_SP_PICKUP_COIL, {
    
    onError(e) {
        console.log(e.graphQLErrors[0].message)
    },
     variables: {id},
     refetchQueries: [{ query: FETCH_SP_PICKUP_COILS }]
});

const onDelete= () => {
  deleteSpPickupCoil()
}
  return (
    <Delete onDelete={onDelete} />
  )
}


