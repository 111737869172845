import { gql } from "@apollo/client";

export const FETCH_PT_NECKS = gql`
query getPtNecks($sheet: Int, $limit: Int){
        getPtNecks(sheet: $sheet, limit: $limit){
            _id,
            name,
            info,
            spInstrument_id {
                _id
            },
            spInstrumentType_id {
                _id
            },
            spNumberOfStrings_id {
                _id
            },
            spNumberOfFrets_id {
                _id
            },
            neckImage,
            neckIcon,
            createdAt,
            updatedAt  
        }
    }
`;

export const FETCH_PT_NECK = gql`
    query getPtNeck($id: ID) {
        getPtNeck(_id: $id) {
            _id,
            name,
            info,
            spInstrument_id {
                _id,
                name
            },
            spInstrumentType_id {
                _id,
                name
            },
            spNumberOfStrings_id {
                _id,
                name
            },
            spNumberOfFrets_id {
                _id,
                name
            },
            neckImage,
            neckIcon,
            createdAt,
            updatedAt
        }
    }
`;

export const ADD_PT_NECK = gql`
    mutation addPtNeck(
        $name: String, 
        $info: String,
        $spInstrument_id: ID,
        $spInstrumentType_id: ID,
        $spNumberOfStrings_id: ID,
        $spNumberOfFrets_id: ID,
        $neckImage: String,
        $neckIcon: String) {
            addPtNeck(
                name: $name, 
                info: $info,
                spInstrument_id: $spInstrument_id,
                spInstrumentType_id: $spInstrumentType_id,
                spNumberOfStrings_id: $spNumberOfStrings_id,
                spNumberOfFrets_id: $spNumberOfFrets_id,
                neckImage: $neckImage,
                neckIcon: $neckIcon) {
                    _id
                }      
}
`;

export const DELETE_PT_NECK = gql`
    mutation deletePtNeck($id: ID) {
        deletePtNeck(_id: $id) {
            _id
        }
    }
`;

export const UPDATE_PT_NECK = gql`
    mutation updatePtNeck(
        $id: ID, 
        $name: String, 
        $info: String,
        $spInstrument_id: ID,
        $spInstrumentType_id: ID,
        $spNumberOfStrings_id: ID,
        $spNumberOfFrets_id: ID,
        $neckImage: String,
        $neckIcon: String) {
            updatePtNeck(
                _id: $id, 
                name: $name, 
                info: $info,
                spInstrument_id: $spInstrument_id,
                spInstrumentType_id: $spInstrumentType_id,
                spNumberOfStrings_id: $spNumberOfStrings_id,
                spNumberOfFrets_id: $spNumberOfFrets_id,
                neckImage: $neckImage,
                neckIcon: $neckIcon) {
                    _id
                }
    }
`;

export const FETCH_PT_NECKS_FOR_PTNECKSID = gql`
    {
        getPtNecks{
            _id,
            name
        }
    }
`;

export const FETCH_PT_NECK_FILTER = gql`
    query getPtNeckFilter(
        $spInstrument_id: String, 
        $spInstrumentType_id: String,
        $spNumberOfStrings_id: String,
        $spNumberOfFrets_id: String) {
            getPtNeckFilter(
                spInstrument_id: $spInstrument_id,
                spInstrumentType_id: $spInstrumentType_id,
                spNumberOfStrings_id: $spNumberOfStrings_id,
                spNumberOfFrets_id: $spNumberOfFrets_id) {
                    _id,
                    name
                }
        }
`;