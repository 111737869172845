import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { useRoutes } from './routes';
import { useAuth } from './hooks/auth.hook';
import { AuthContext } from './context/AuthContext';
import { Navbar } from './components/main/Navbar';
import Loader from './components/main/Loader';
import ApolloClient, { InMemoryCache } from 'apollo-boost';
import { ApolloProvider } from '@apollo/react-hooks';
import { SideBar } from './components/main/SideBar';
import { Box, Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import withWidth from '@material-ui/core/withWidth';
import { makeStyles } from '@material-ui/core/styles';
import { AreaBar } from './components/main/AreaBar';
import Back from './assets/MainPageDemo.svg';
import { AuthPage } from './pages/public/AuthPage';
import { grey } from '@material-ui/core/colors';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: grey [800]
  },
  rootNotAuth: {
    backgroundImage: `url(` + Back + `)`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "cover",
    // backgroundAttachment: "fixed",
    overflow: 'hidden',
  },
  gridSidebar: {
    marginTop: 57,
  },
  avaSidebar: {
  }

}));

///
const client = new ApolloClient({
  // uri: 'http://localhost:4000',
  uri: 'http://192.168.1.33:4000',
  cache: new InMemoryCache(),
  request: (operation) => {
    const token = localStorage.getItem('token');
    operation.setContext({
      headers: {
        autorization: token ? token : ''
      }
    })
  }
})

function App(props) {
  const classes = useStyles();
  const { width } = props;
  const { token, login, logout, userId, ready } = useAuth()
  const isAuthenticated = !!token
  const routes = useRoutes(isAuthenticated)

  if (!ready) {
    return <Loader />
  }
  console.log(isAuthenticated)
  return (
    <AuthContext.Provider value={{
      token, login, logout, userId, isAuthenticated
    }}>
      <Router >
        {!isAuthenticated
          ? (<Grid 
            // className={classes.rootNotAuth}
          >
            <Grid >
              <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                <ApolloProvider client={client}>
                  <AuthPage width={width}/>
                </ApolloProvider>
              </Grid>
            </Grid>
          </Grid>)

          : (
           
            <Grid container style={{backgroundColor: grey[800]}}>
          
                <Grid container >
                  <Grid item xl={12} lg={12} md={12} sm={12} xs={12}> {isAuthenticated && <Navbar width={width} />}</Grid>
                </Grid>

                <Grid container >
                  <Grid item xl={2} lg={2} md={2} sm={4} xs={12} style={{ backgroundColor: grey[800] }}>
                    <ApolloProvider client={client}>
                      {isAuthenticated && <SideBar />}
                    </ApolloProvider>
                  </Grid>

                  <Grid item xl={8} lg={8} md={8} sm={8} xs={12} style={{paddingTop: "50px"}}>
                    <ApolloProvider client={client}>
                      {routes}
                    </ApolloProvider>
                  </Grid>

                  <Grid item xl={2} lg={2} md={2} sm={12} xs={12}>
                    {isAuthenticated && <AreaBar />}
                  </Grid>
                </Grid>
             

          </Grid>
         
          )}

      </Router>
    </AuthContext.Provider>
  )
}
App.propTypes = {
  width: PropTypes.oneOf(['lg', 'md', 'sm', 'xl', 'xs']).isRequired,
};
export default withWidth()(App)