import React from 'react';
import { useQuery } from '@apollo/client';
import Loader from '../../../components/main/Loader';
import { Box, Container } from '@material-ui/core';
import { FETCH_PR_GSL_CARDS } from './gqlPrGslCard';
import GetGslCard from './getPrGslCard';
import { UpdateGslCard } from './updatePrGslCard';
import { DeleteGslCard } from './deletePrGslCard';

const GetPrGslCards = () => {

    const { loading, error, data } = useQuery(FETCH_PR_GSL_CARDS);

    if (loading) return <Loader />;
    if (error) return `Error ${error}`;

    return (

        <Container>
            <Box display="flex" justifyContent="center">
                <table>
                    <thead>
                        <tr>
                            <th>№</th>
                            <th>Наименование</th>
                            <th>Дата создания</th>
                            <th>Дата изменения</th>
                            <th>Действия</th>
                        </tr>
                    </thead>
                    <tbody>
                        {!data.getPrGslCards ? null : data.getPrGslCards.map((prGslCard, index) => {
                            return (
                                <tr key={prGslCard._id}>
                                
                                    <td>{index + 1}</td>
                                    <td>{prGslCard.name}</td>
                                    <td>{prGslCard.info}</td>
                                    <td>{prGslCard.updatedAt}</td>
                                    <td>
                                    
                                        <GetGslCard id={prGslCard._id} />
                                      
                                        <UpdateGslCard
                                            id={prGslCard._id}
                                            name={prGslCard.name}
                                            original={prGslCard.original}
                                            foto={prGslCard.foto}
                                            userSeller_id={!prGslCard.userSeller_id ? null : prGslCard.userSeller_id._id}
                                            sellerContact={prGslCard.sellerContact}
                                            price={prGslCard.price}
                                            dateOfPurchase={prGslCard.dateOfPurchase}
                                            // # generalInfo: String
                                            spInstrument_id={prGslCard.spInstrument_id._id}
                                            spInstrumentType_id={prGslCard.spInstrumentType_id._id}
                                            spBrand_id={prGslCard.spBrand_id._id}
                                            model={prGslCard.model}
                                            spCountry_id={prGslCard.spCountry_id._id}
                                            spNeckType_id={prGslCard.spNeckType_id._id}
                                            spNumberOfStrings_id={prGslCard.spNumberOfStrings_id._id}
                                            spNumberOfFrets_id={prGslCard.spNumberOfFrets_id._id}
                                            spMensura_id={prGslCard.spMensura_id._id}
                                            // # bridgeInfo: String
                                            ptBridge_id={prGslCard.ptBridge_id._id}
                                            spBrandBridge_id={prGslCard.spBrandBridge_id._id}
                                            bridgeModel={prGslCard.bridgeModel}
                                            spCountryBridge_id={prGslCard.spCountryBridge_id._id}
                                            // # formFactorInfo: String
                                            ptFormFactor_id={prGslCard.ptFormFactor_id._id}
                                            spMaterialFormFactor_id={prGslCard.spMaterialFormFactor_id._id}
                                            overlay={prGslCard.overlay}
                                            spMaterialFormFactorOverlay_id={!prGslCard.spMaterialFormFactorOverlay_id ? null : prGslCard.spMaterialFormFactorOverlay_id._id}
                                            pickguard={prGslCard.pickguard}
                                            ptPickguard_id={!prGslCard.ptPickguard_id ? null :prGslCard.ptPickguard_id._id}
                                            // # headstockInfo: String
                                            ptHeadstock_id={prGslCard.ptHeadstock_id._id}
                                            ptNeck_id={prGslCard.ptNeck_id._id}
                                            spMaterialNeck_id={prGslCard.spMaterialNeck_id._id}
                                            spMaterialNeckOverlay_id={prGslCard.spMaterialNeckOverlay_id._id}
                                            // # electronicInfo: String
                                            switchPositions={prGslCard.switchPositions}

                                            piezo={prGslCard.piezo}
                                            spBrandPiezo_id={!prGslCard.spBrandPiezo_id ? null : prGslCard.spBrandPiezo_id._id}
                                            modelPiezo={prGslCard.modelPiezo}

                                            positionBridge={prGslCard.positionBridge}
                                            spPickupPositions_id_bridge={prGslCard.spPickupPositions_id_bridge._id}
                                            ptPickupBridge_id={prGslCard.ptPickupBridge_id._id}
                                            spBrandBridgePickup_id={prGslCard.spBrandBridgePickup_id._id}
                                            modelBridgePickup={prGslCard.modelBridgePickup}

                                            positionMiddle={prGslCard.positionMiddle}
                                            spPickupPositions_id_middle={!prGslCard.spPickupPositions_id_middle ? null : prGslCard.spPickupPositions_id_middle._id}
                                            ptPickupMiddle_id={!prGslCard.ptPickupMiddle_id ? null : prGslCard.ptPickupMiddle_id._id}
                                            spBrandMiddlePickup_id={!prGslCard.spBrandMiddlePickup_id ? null : prGslCard.spBrandMiddlePickup_id._id}
                                            modelMiddlePickup={prGslCard.modelMiddlePickup}

                                            positionNeck={prGslCard.positionNeck}
                                            spPickupPositions_id_neck={!prGslCard.spPickupPositions_id_neck ? null : prGslCard.spPickupPositions_id_neck._id}
                                            ptPickupNeck_id={!prGslCard.ptPickupNeck_id ? null : prGslCard.ptPickupNeck_id._id}
                                            spBrandNeckPickup_id={!prGslCard.spBrandNeckPickup_id ? null : prGslCard.spBrandNeckPickup_id._id}
                                            modelNeckPickup={prGslCard.modelNeckPickup}
                                        />
                                        <DeleteGslCard id={prGslCard._id} />
                                    </td>
                                </tr>)
                        })}
                    </tbody>
                </table>
            </Box>
        </Container>

    )
}

export default GetPrGslCards;