import React from 'react';
import { useQuery } from '@apollo/client';
import { FETCH_SP_PICKUP_COILS } from './gqlSpPickupCoil';
import { UpdateSpPickupCoil } from './updateSpPickupCoil';
import Loader from '../../../../components/main/Loader';
import { Box, Container } from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';
import GetSpPickupCoil from './getSpPickupCoil';
import { DeleteSpPickupCoil } from './deleteSpPickupCoil';


const GetSpPickupCoils = () => {

    const [page, setPage] = React.useState(1);

    const { loading, error, data } = useQuery(FETCH_SP_PICKUP_COILS);

    if (loading) return <Loader />;
    if (error) return `Error ${error}`;

    const handleChange = (event, value) => {
        setPage(value);
    };

    return (
        <>
            <Pagination count={100} page={page} onChange={handleChange} />
            <Container>
                <Box display="flex" justifyContent="center">
                    <table>
                        <thead>
                            <tr>
                                <th>№</th>
                                <th>Наименование</th>
                                <th>Информация</th>
                                <th>Дата создания</th>
                                <th>Дата изменения</th>
                                <th>Действия</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.getSpPickupCoils.map((spPickupCoil, index) => {
                                return (
                                    <tr key={spPickupCoil._id}>
                                        <td>{index + 1}</td>
                                        <td>{spPickupCoil.name}</td>
                                        <td>{spPickupCoil.info}</td>
                                        <td>{spPickupCoil.createdAt}</td>
                                        <td>{spPickupCoil.updatedAt}</td>
                                        <td>
                                            <GetSpPickupCoil id={spPickupCoil._id} />
                                            <UpdateSpPickupCoil
                                                id={spPickupCoil._id}
                                                name={spPickupCoil.name}
                                                info={spPickupCoil.info}
                                                spPickupType_id={spPickupCoil.spPickupType_id._id} />
                                            <DeleteSpPickupCoil id={spPickupCoil._id} />
                                        </td>
                                    </tr>)
                            })}
                        </tbody>
                    </table>
                </Box>
            </Container>
        </>
    )
}

export default GetSpPickupCoils;