import { gql } from "@apollo/client";

export const FETCH_SP_FF_FAMILYS = gql`
    {
        getSpFFFamilys{
            _id,
            name,
            info,   
            spBrand_id {
                _id
            },
            dateOfCreation,
            createdAt,
            updatedAt  
        }
    }
`;

export const FETCH_SP_FF_FAMILY = gql`
    query getSpFFFamily($id: ID) {
        getSpFFFamily(_id: $id) {
            _id,
            name,
            info,
            spBrand_id {
                _id,
                name
            },
            dateOfCreation,
            createdAt,
            updatedAt
        }
    }
`;

export const ADD_SP_FF_FAMILY = gql`
    mutation addSpFFFamily(
        $name: String, 
        $info: String,
        $spBrand_id: ID,
        $dateOfCreation: String) {
            addSpFFFamily(
                name: $name, 
                info: $info,
                spBrand_id: $spBrand_id,
                dateOfCreation: $dateOfCreation) {
                    _id
                }      
    }
`;

export const DELETE_SP_FF_FAMILY = gql`
    mutation deleteSpFFFamily($id: ID) {
        deleteSpFFFamily(_id: $id) {
            _id
        }
    }
`;

export const UPDATE_SP_FF_FAMILY = gql`
    mutation updateSpFFFamily(
        $id: ID, 
        $name: String, 
        $info: String,
        $spBrand_id: ID,
        $dateOfCreation: String) {
        updateSpFFFamily(
            _id: $id, 
            name: $name, 
            info: $info,
            spBrand_id: $spBrand_id,
            dateOfCreation: $dateOfCreation) {
           _id
        }
    }
`;

export const FETCH_SP_FF_FAMILYS_FOR_SPFFFAMILYSID = gql`
    {
        getSpFFFamilys{
            _id
            name 
        }
    }
`;