import React from 'react';
import { useQuery } from '@apollo/client';
import { FETCH_SP_PICKUP_POSITION } from './gqlSpPickupPositions';
import Loader from '../../../../components/main/Loader';
import Open from '../../../../components/actions/open';
import OpenDataId from '../../../../components/view/openDataId';
import { UpdateSpPickupPositions } from './updateSpPickupPositions';

const GetSpPickupPositions = ({ id }) => {

  const { loading, error, data } = useQuery(FETCH_SP_PICKUP_POSITION, {
    variables: { id },
  });

  if (loading) return <Loader />;
  if (error) return `Error ${error}`;

  return (
    <Open
      title={"Открыть"}
      dialogTitle={data.getSpPickupPositions.name}
      edit={<UpdateSpPickupPositions
        id={data.getSpPickupPositions._id}
        name={data.getSpPickupPositions.name}
        info={data.getSpPickupPositions.info}
        spInstrument_id={data.getSpPickupPositions.spInstrument_id._id}
        spInstrumentType_id={data.getSpPickupPositions.spInstrumentType_id._id}
        code={data.getSpPickupPositions.code} />}
      Fields={<>
        <OpenDataId
          label={"Наименование"}
          data={data.getSpPickupPositions._id}
        />
        <OpenDataId
          label={"Информация"}
          data={data.getSpPickupPositions.info}
        />
        <OpenDataId
          label={"spInstrument_id"}
          data={data.getSpPickupPositions.spInstrument_id.name}
        />
        <OpenDataId
          label={"spInstrumentType_id"}
          data={data.getSpPickupPositions.spInstrumentType_id.name}
        />
        <OpenDataId
          label={"code"}
          data={data.getSpPickupPositions.code}
        />
        <OpenDataId
          label={"Дата создания"}
          data={data.getSpPickupPositions.createdAt}
        />
        <OpenDataId
          label={"Дата изменения"}
          data={data.getSpPickupPositions.updatedAt}
        />
      </>} />
  )
}

export default GetSpPickupPositions;