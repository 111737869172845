import { gql } from "@apollo/client";

export const FETCH_ST_APPEALS = gql`
    {
        getStAppeals{
            _id,
            user_id {_id email},
            numberAppeal,
            textAppeal,
            location,
            valueVariables,
            spStatusAppeal_id {_id name}
            createdAt,
            updatedAt
        }
    }
`;

export const FETCH_ST_APPEAL = gql`
    query getStAppeal($id: ID) {
        getStAppeal(_id: $id) {
            _id,
            user_id {_id email},
            numberAppeal,
            textAppeal,
            location,
            valueVariables,
            spStatusAppeal_id {_id name},
            createdAt,
            updatedAt
    }
}
`;

export const ADD_ST_APPEAL = gql`
    mutation addStAppeal( $input: inputStAppeal ) {
            addStAppeal( input: $input ) {
                    _id
            }      
    }
`;

export const DELETE_ST_APPEAL = gql`
    mutation deleteStAppeal($id: ID) {
        deleteStAppeal(_id: $id) {
            _id
        }
    }
`;

export const UPDATE_ST_APPEAL = gql`
    mutation updateStAppeal( $id: ID, $input: inputStAppeal ) {
            updateStAppeal( _id: $id, input: $input ) {
                    _id
            }
    }
`;