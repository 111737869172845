import React from 'react';
import GetUsersPosts from '../../requests/users/userPost/getUsersPosts';
import { Container } from '@material-ui/core';
import HorizontalLabelPositionBelowStepper from '../../components/view/steppers/stepperAddGslCard';


export const FeedPage = () => {

  return (
    <>
      <Container>
        <GetUsersPosts />
        <HorizontalLabelPositionBelowStepper />
      </Container>
    </>
  )
}