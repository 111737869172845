import { gql } from "@apollo/client";

export const FETCH_PT_PICKUPS = gql`
query getPtPickups($sheet: Int, $limit: Int){
        getPtPickups(sheet: $sheet, limit: $limit){
            _id,
            name,
            info,
            spInstrument_id {
                _id
            },
            spInstrumentType_id {
                _id
            },
            spNumberOfStrings_id {
                _id
            },
            spPickupPositions_id {
                _id
            },
            spPickupType_id {
                _id
            },
            spPickupMode_id {
                _id
            },
            sourse,
            pickupImage, 
            pickupIcon,
            createdAt,
            updatedAt  
        }
    }
`;

export const FETCH_PT_PICKUP = gql`
    query getPtPickup($id: ID) {
        getPtPickup(_id: $id) {
            _id,
            name,
            info,
            spInstrument_id {
                _id,
                name
            },
            spInstrumentType_id {
                _id,
                name
            },
            spNumberOfStrings_id {
                _id,
                name
            },
            spPickupPositions_id {
                _id,
                name
            },
            spPickupType_id {
                _id,
                name
            },
            spPickupMode_id {
                _id,
                name
            },
            sourse,
            pickupImage, 
            pickupIcon,
            createdAt,
            updatedAt
        }
}
`;

export const ADD_PT_PICKUP = gql`
    mutation addPtPickup(
        $name: String, 
        $info: String,
        $spInstrument_id: ID,
        $spInstrumentType_id: ID,
        $spNumberOfStrings_id: ID,
        $spPickupPositions_id: ID,
        $spPickupType_id: ID,
        $spPickupMode_id: ID,
        $sourse: String,
        $pickupImage: String, 
        $pickupIcon: String) {
            addPtPickup(
                name: $name, 
                info: $info,
                spInstrument_id: $spInstrument_id,
                spInstrumentType_id: $spInstrumentType_id,
                spNumberOfStrings_id: $spNumberOfStrings_id,
                spPickupPositions_id: $spPickupPositions_id,
                spPickupType_id: $spPickupType_id,
                spPickupMode_id: $spPickupMode_id,
                sourse: $sourse,
                pickupImage: $pickupImage, 
                pickupIcon: $pickupIcon) {
                _id
                }      
        }
`;

export const DELETE_PT_PICKUP = gql`
    mutation deletePtPickup($id: ID) {
        deletePtPickup(_id: $id) {
            _id
        }
}
`;

export const UPDATE_PT_PICKUP = gql`
    mutation updatePtPickup(
        $id: ID, 
        $name: String, 
        $info: String,
        $spInstrument_id: ID,
        $spInstrumentType_id: ID,
        $spNumberOfStrings_id: ID,
        $spPickupPositions_id: ID,
        $spPickupType_id: ID,
        $spPickupMode_id: ID,
        $sourse: String,
        $pickupImage: String, 
        $pickupIcon: String) {
            updatePtPickup(
                _id: $id, 
                name: $name, 
                info: $info,
                spInstrument_id: $spInstrument_id,
                spInstrumentType_id: $spInstrumentType_id,
                spNumberOfStrings_id: $spNumberOfStrings_id,
                spPickupPositions_id: $spPickupPositions_id,
                spPickupType_id: $spPickupType_id,
                spPickupMode_id: $spPickupMode_id,
                sourse: $sourse,
                pickupImage: $pickupImage, 
                pickupIcon: $pickupIcon) {
                    _id
                }
        }
`;

export const FETCH_PT_PICKUPS_FOR_PTPICKUPSID = gql`
    {
        getPtPickups{
            _id,
            name
        }
    }
`;

export const FETCH_PT_PICKUP_FILTER = gql`
    query getPtPickupFilter(
        $spInstrument_id: String, 
        $spInstrumentType_id: String,
        $spNumberOfStrings_id: String,
        $spPickupPositions_id: String,
        $spPickupType_id: String,
        $spPickupMode_id: String) {
            getPtPickupFilter(
                spInstrument_id: $spInstrument_id,
                spInstrumentType_id: $spInstrumentType_id,
                spNumberOfStrings_id: $spNumberOfStrings_id,
                spPickupPositions_id: $spPickupPositions_id,
                spPickupType_id: $spPickupType_id,
                spPickupMode_id: $spPickupMode_id) {
                    _id,
                    name
                }
        }
`;