import { gql } from "@apollo/client";

export const FETCH_SP_BRIDGES_CLASSES = gql`
    {
        getSpBridgesClasses{
            _id,
            name,
            info,
            createdAt,
            updatedAt  
        }
    }
`;

export const FETCH_SP_BRIDGE_CLASS = gql`
    query getSpBridgeClass($id: ID) {
        getSpBridgeClass(_id: $id) {
            _id,
            name,
            info,
            createdAt,
            updatedAt
        }
    }
`;

export const ADD_SP_BRIDGE_CLASS = gql`
    mutation addSpBridgeClass($name: String, $info: String) {
        addSpBridgeClass(name: $name, info: $info) {
            _id 
        }      
    }
`;

export const DELETE_SP_BRIDGE_CLASS = gql`
    mutation deleteSpBridgeClass($id: ID) {
        deleteSpBridgeClass(_id: $id) {
            _id
        }
    }
`;

export const UPDATE_SP_BRIDGE_CLASS = gql`
    mutation updateSpBridgeClass($id: ID, $name: String, $info: String) {
        updateSpBridgeClass(_id: $id, name: $name, info: $info) {
            _id
        }
    }
`;

export const FETCH_SP_BRIDGES_CLASSES_FOR_SPBRIDGECLASSID = gql`
    {
        getSpBridgesClasses{
            _id,
            name
        }
    }
`;