import React from 'react';
import { useMutation } from '@apollo/client';
import { DELETE_SP_MATERIAL, FETCH_SP_MATERIALS } from './gqlSpMaterial';
import { Delete } from '../../../../components/actions/delete';


export const DeleteSpMaterial = ({id}) => {
  const [deleteSpMaterial] = useMutation(DELETE_SP_MATERIAL, {
    update(cache, data) {
        console.log(data)
    },
    onError(e) {
        console.log(e.graphQLErrors[0].message)
    },
     variables: {id},
     refetchQueries: [{ query: FETCH_SP_MATERIALS }]
});

const onDelete= () => {
  deleteSpMaterial()
}
  return (
    <Delete onDelete={onDelete} />
  )
}