import { gql } from "@apollo/client";

export const FETCH_USERS = gql`
{ 
  getUsers{
    email,
    _id,
    password,
    createdAt,
    firstName,
    lastName,
    userRole_id{
      _id,
    }
    avatar,
    canvas
  }
}
`;

export const FETCH_CURRENT_USER = gql`
  query getUser($id: ID) {
    getUser(_id: $id) {
      _id,
      email,
      password,
      firstName,
      lastName,
      createdAt,
      userRole_id{
        _id,
        name,
      }
      avatar,
      canvas
    }
  }
`;

export const FETCH_USER = gql`
  query getUser($id: ID) {
    getUser(_id: $id) {
      _id,
      email,
      firstName,
      userRole_id{
        _id,
        name,
      }
      lastName,
      createdAt
    }
  }
`;

export const UPDATE_USER = gql`
  mutation updateUser(
    $id: ID, 
    $email: String, 
    $password: String, 
    $firstName: String,
    $lastName: String,
    $userRole_id: ID,
    $avatar: String,
    $canvas: String) {
      updateUser(
        _id: $id, 
        email: $email, 
        password: $password, 
        firstName: $firstName, 
        lastName: $lastName,
        userRole_id: $userRole_id,
        avatar: $avatar,
        canvas: $canvas) {
          _id
        }
  }
`;

export const FETCH_USERS_FOR_USERSID = gql`
{
    getUsers{
        email,
        _id,
    }
}
`;

export const ADD_USER_ROLE_FOR_USER = gql`
  mutation addUserRoleForUser(
    $id: ID, $userRole_id: ID) {
      addUserRoleForUser(
        _id: $id, userRole_id: $userRole_id) {
          _id
        }
  }
`;

export const DELETE_USER_ROLE_IN_USER = gql`
  mutation deleteUserRoleInUser(
    $id: ID, $userRole_id: ID) {
      deleteUserRoleInUser(
      _id: $id, userRole_id: $userRole_id) {
        _id
      }
  }
`;