import React from 'react';
import axios from 'axios';
import Ava from '../../assets/images/yuna.jpg';
import { makeStyles } from '@material-ui/core/styles';
import FormData from 'form-data';
import { TextField, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 300,
    },
    large: {
        width: 200,
        height: 200,
        maxWidth: 400,

    },
}));


export default function UploadForm({ email, value, name, onChange, label, method }) {
    const classes = useStyles();

    const [img, setImg] = React.useState([`${value}`])
    const [avatar, setAvatar] = React.useState(value)

    const sendFile = React.useCallback(async () => {
        try {
            const data = new FormData()
            data.append(`${name}`, img)

            await axios.post(`/api/upload/${method}`, data, {
                headers: {
                    'content-type': 'mulpipart/form-data'
                },
                params: { email }
            })
                .then(res => setAvatar(res.data.path))
        } catch (error) { }
    }, [img])


    const imgOnServer = `http://localhost:5000/public/dataUser/${email}/${name}/`

    const file = [img].map((file) => (
        file.name
    ))

    return (
        <>
            {avatar
                ? <img className={classes.large} src={`${imgOnServer}${value}`} alt={name} />
                : <img className={classes.large} src={`${Ava}`} alt={name} />}

            <input type="file" onChange={e => setImg(e.target.files[0])}></input>
            <br></br>
            <button onClick={sendFile}>Изменить аватар</button>
            <br></br>
            <br></br>
            <Typography> {file}</Typography>
            <br></br>
            <TextField
                className={classes.formControl}
                id="standard-basic"
                label={label}
                type="text"
                name={name}
                value={value}
                multiline
                onChange={onChange}
            />
        </>
    )
}