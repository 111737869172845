import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import Add from '../../../../components/actions/add';
import RequiredField from '../../../../components/InputElements/requiredField';
import OptionalMultiRowsField from '../../../../components/InputElements/optionalMultiRowsField';
import { ADD_SP_STATUS_APPEAL, FETCH_SP_STATUSES_APPEALS } from './gqlSpStatusAppeal';

const AddSpStatusAppeal = () => {

    const [values, setValues] = useState({
        name: ""
    });

    const [addSpStatusAppeal] = useMutation(ADD_SP_STATUS_APPEAL, {

        onError(e) {
            alert(e)
        },
        variables: {input: values},
        refetchQueries: [{ query: FETCH_SP_STATUSES_APPEALS }],
    });

    const handlerChangeName = (e) => {
        setValues({
            ...values,
            [e.target.name]: e.target.value,
        });
    };

    const formSubmit = (e) => {
        e.preventDefault();
        addSpStatusAppeal();
        setValues({ name: "" })
    }

    return (
        <Add
            title={"Добавление статуса обращения"}
            ButtonText={"Добавить статус обращения"}
            dialogTitle={"Добавление статуса обращения"}
            formSubmit={formSubmit}
            Fields={<>
                <RequiredField
                    label={"Наименование"}
                    value={values.name}
                    onChange={handlerChangeName}
                    name={"name"}
                />
                <br></br>
                <OptionalMultiRowsField
                    label={"Информация"}
                    value={values.info}
                    onChange={handlerChangeName}
                    name={"info"}
                />
            </>} />
    )
}

export default AddSpStatusAppeal;