import React from 'react';
import { useQuery } from '@apollo/client';
import { FETCH_SP_FF_FAMILY } from './gqlSpFFFamily';
import Loader from '../../../../components/main/Loader';
import Open from '../../../../components/actions/open';
import OpenDataId from '../../../../components/view/openDataId';
import { UpdateSpFFFamily } from './updateSpFFFamily';

const GetSpFFFamily = ({ id }) => {

  const { loading, error, data } = useQuery(FETCH_SP_FF_FAMILY, {
    variables: { id }

  });

  if (loading) return <Loader />;
  if (error) return `Error ${error}`;

  return (
    <Open
      title={"Открыть"}
      dialogTitle={data.getSpFFFamily.name}
      edit={<UpdateSpFFFamily
        id={data.getSpFFFamily._id}
        name={data.getSpFFFamily.name}
        info={data.getSpFFFamily.info}
        spBrand_id={data.getSpFFFamily.spBrand_id._id}
        dateOfCreation={data.getSpFFFamily.dateOfCreation} />}
      Fields={<>
        <OpenDataId
          label={"Наименование"}
          data={data.getSpFFFamily._id}
        />
        <OpenDataId
          label={"Информация"}
          data={data.getSpFFFamily.info}
        />
        <OpenDataId
          label={"spBrand_id"}
          data={data.getSpFFFamily.spBrand_id.name}
        />
        <OpenDataId
          label={"Дата создания"}
          data={data.getSpFFFamily.createdAt}
        />
        <OpenDataId
          label={"Дата изменения"}
          data={data.getSpFFFamily.updatedAt}
        />
      </>} />
  )
}

export default GetSpFFFamily;