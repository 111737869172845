import React from 'react';
import { useMutation } from '@apollo/client';
import { Delete } from '../../../../components/actions/delete';
import { DELETE_ST_APPEAL, FETCH_ST_APPEALS } from './gqlStAppeal';


export const DeleteStAppeal = ({ id }) => {
  const [deleteStAppeal] = useMutation(DELETE_ST_APPEAL, {

    onError(e) {
      console.log(e.graphQLErrors[0].message)
    },
    variables: { id },
    refetchQueries: [{ query: FETCH_ST_APPEALS }]
  });

  const onDelete = () => {
    deleteStAppeal()
  }

  return (
    <Delete onDelete={onDelete} />
  )
}