import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { ADD_SP_FF_CLASS, FETCH_SP_FF_CLASSES } from './gqlSpFFClass';
import Add from '../../../../components/actions/add';
import RequiredField from '../../../../components/InputElements/requiredField';
import OptionalMultiRowsField from '../../../../components/InputElements/optionalMultiRowsField';

const AddSpFFClass = () => {
    const [values, setValues] = useState({
        name: ""
    });

    const [addSpFFClass] = useMutation(ADD_SP_FF_CLASS, {

        onError(e) {
            console.log(e.graphQLErrors[0].message)
        },
        variables: values,
        refetchQueries: [{ query: FETCH_SP_FF_CLASSES }]
    });

    const handlerChangeName = (e) => {
        setValues({
            ...values,
            [e.target.name]: e.target.value,
        });
    };


    const formSubmit = (e) => {
        e.preventDefault();
        addSpFFClass();
        setValues({ name: "" })
    }

    return (
        <Add
            title={"Добавление FFClass"}
            ButtonText={"Добавить FFClass"}
            dialogTitle={"Добавление нового FFClass"}
            formSubmit={formSubmit}
            Fields={<>
                <RequiredField
                    label={"Наименование"}
                    value={values.name}
                    onChange={handlerChangeName}
                    name={"name"}
                />
                <br></br>
                <OptionalMultiRowsField
                    label={"Информация"}
                    value={values.info}
                    onChange={handlerChangeName}
                    name={"info"}
                />
            </>} />
    )

}

export default AddSpFFClass;