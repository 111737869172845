import React from 'react';
import { useQuery } from '@apollo/client';
import { FETCH_SP_COUNTRYS } from './gqlSpCountry';
import GetSpCountry from './getSpCountry';
import { DeleteSpCountry } from './deleteSpCountry';
import { UpdateSpCountry } from './updateSpCountry';
import Loader from '../../../../components/main/Loader';
import { Box, Container } from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';

const GetSpCountrys = () => {
    const [page, setPage] = React.useState(1);

    const { loading, error, data } = useQuery(FETCH_SP_COUNTRYS, {
        variables: {
            sheet: page,
            limit: 10
        },
    });

    if (loading) return <Loader />;
    if (error) return `Error ${error}`;

    const handleChange = (event, value) => {
        setPage(value);
    };

    return (
        <>
        <Pagination count={100} page={page} onChange={handleChange} />
            <Container>
                <Box display="flex" justifyContent="center">
                    <table>
                        <thead>
                            <tr>
                                <th>№</th>
                                <th>Наименование</th>
                                <th>Дата создания</th>
                                <th>Дата изменения</th>
                                <th>Действия</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.getSpCountrys.map((SpCountry, index) => {
                                return (
                                    <tr key={SpCountry._id}>
                                        <td>{index + 1}</td>
                                        <td>{SpCountry.name}</td>
                                        <td>{SpCountry.createdAt}</td>
                                        <td>{SpCountry.updatedAt}</td>
                                        <td>
                                            <GetSpCountry id={SpCountry._id} />
                                            <UpdateSpCountry id={SpCountry._id} name={SpCountry.name} />
                                            <DeleteSpCountry id={SpCountry._id} />
                                        </td>
                                    </tr>)
                            })}
                        </tbody>
                    </table>
                </Box>
            </Container>
        </>
    )
}

export default GetSpCountrys;