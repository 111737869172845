import { gql } from "@apollo/client";

export const FETCH_SP_PICKUPS_POSITIONS = gql`
    {
        getSpPickupsPositions{
            _id,
            name,
            spInstrument_id {
                _id
            },
            spInstrumentType_id {
                _id
            },
            code,    
            createdAt,
            updatedAt  
        }
    }
`;

export const FETCH_SP_PICKUP_POSITION = gql`
    query getSpPickupPositions($id: ID) {
        getSpPickupPositions(_id: $id) {
            _id,
            name,
            spInstrument_id {
                _id,
                name
            },
            spInstrumentType_id {
                _id,
                name
            },
            code,
            createdAt,
            updatedAt
        }
    }
`;

export const ADD_SP_PICKUP_POSITION = gql`
    mutation addSpPickupPositions(
        $name: String, 
        $info: String,
        $spInstrument_id: ID,
        $spInstrumentType_id: ID,
        $code: String) {
            addSpPickupPositions(
                name: $name, 
                info: $info,
                spInstrument_id: $spInstrument_id,
                spInstrumentType_id: $spInstrumentType_id,
                code: $code) {
                    _id
                }      
        }
`;

export const DELETE_SP_PICKUP_POSITION = gql`
    mutation deleteSpPickupPositions($id: ID) {
        deleteSpPickupPositions(_id: $id) {
            _id
        }
    }
`;

export const UPDATE_SP_PICKUP_POSITION = gql`
    mutation updateSpPickupPositions(
        $id: ID, 
        $name: String, 
        $info: String,
        $spInstrument_id: ID,
        $spInstrumentType_id: ID,
        $code: String) {
            updateSpPickupPositions(
                _id: $id, 
                name: $name, 
                info: $info,
                spInstrument_id: $spInstrument_id,
                spInstrumentType_id: $spInstrumentType_id,
                code: $code) {
               _id
            }
        }
`;

export const FETCH_SP_PICKUPS_POSITIONS_FOR_SPPICKUPPOSITIONCODE = gql`
    {
        getSpPickupsPositions{
            _id,
            code 
        }
    }
`;

export const FETCH_SP_PICKUP_POSITIONS_FILTER = gql`
    query getSpPickupPositionsFilter(
        $spInstrument_id: String, 
        $spInstrumentType_id: String) {
            getSpPickupPositionsFilter(
                spInstrument_id: $spInstrument_id,
                spInstrumentType_id: $spInstrumentType_id) {
                    _id,
                    name
                }
        }
`;