import React from 'react';
import { useQuery } from '@apollo/client';
import { FETCH_SP_BRAND } from './gqlSpBrand';
import { FETCH_SP_COUNTRYS_FOR_SPCOUNTRYID } from '../spCountry/gqlSpCountry';
import Loader from '../../../../components/main/Loader';
import Open from '../../../../components/actions/open';
import OpenDataId from '../../../../components/view/openDataId';
import { UpdateSpBrand } from './updateSpBrand';


const GetSpBrand = ({ id }) => {

  const { loading, error, data } = useQuery(FETCH_SP_BRAND, {
    variables: { id },
    refetchQueries: [{ query: FETCH_SP_COUNTRYS_FOR_SPCOUNTRYID }]
  });

  if (loading) return <Loader />;
  if (error) return `Error ${error}`;

  return (
    <>
      <Open
        title={"Открыть"}
        dialogTitle={data.getSpBrand.name}
        edit={<UpdateSpBrand
          id={data.getSpBrand._id}
          name={data.getSpBrand.name}
          info={data.getSpBrand.info}
          dateOfFoundation={data.getSpBrand.dateOfFoundation}
          spCountry_id={data.getSpBrand.spCountry_id._id}
          spCountryProduction_id={data.getSpBrand.spCountryProduction_id._id}
          web={data.getSpBrand.web} />}
        Fields={<>
          <OpenDataId
            label={"Наименование"}
            data={data.getSpBrand._id}
          />
          <OpenDataId
            label={"Информация"}
            data={data.getSpBrand.info}
          />
          <OpenDataId
            label={"Дата создания компании"}
            data={data.getSpBrand.dateOfFoundation}
          />
          <OpenDataId
            label={"Страна производителя"}
            data={data.getSpBrand.spCountry_id.name}
          />
          <OpenDataId
            label={"Страна производства"}
            data={data.getSpBrand.spCountryProduction_id.map((spCountryProduction) => {

              return (

                <tr key={spCountryProduction._id}>
                  {[spCountryProduction.name]}
                </tr>)

            })} />
          <OpenDataId
            label={"web"}
            data={data.getSpBrand.web}
          />
          <OpenDataId
            label={"Дата создания"}
            data={data.getSpBrand.createdAt}
          />
          <OpenDataId
            label={"Дата изменения"}
            data={data.getSpBrand.updatedAt}
          />
        </>} />
    </>
  )
}

export default GetSpBrand;