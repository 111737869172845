import React from 'react';
import { useQuery } from '@apollo/client';
import { FETCH_SP_MATERIAL } from './gqlSpMaterial';
import Loader from '../../../../components/main/Loader';
import { UpdateSpMaterial } from './updateSpMaterial';
import Open from '../../../../components/actions/open';
import OpenDataId from '../../../../components/view/openDataId';

const GetSpMaterial = ({ id }) => {

  const { loading, error, data } = useQuery(FETCH_SP_MATERIAL, {
    variables: { id },
  });

  if (loading) return <Loader />;
  if (error) return `Error ${error}`;

  return (
    <Open
      title={"Открыть"}
      dialogTitle={data.getSpMaterial.name}
      edit={<UpdateSpMaterial
        id={data.getSpMaterial._id}
        name={data.getSpMaterial.name}
        info={data.getSpMaterial.info}
        nameEng={data.getSpMaterial.nameEng}
        materialImage={data.getSpMaterial.materialImage} />}
      Fields={<>
        <OpenDataId
          label={"ID"}
          data={data.getSpMaterial._id}
        />
        <OpenDataId
          label={"Наименование (ENG)"}
          data={data.getSpMaterial.nameEng}
        />
        <OpenDataId
          label={"Информация"}
          data={data.getSpMaterial.info}
        />
        <OpenDataId
          label={"Изображение"}
          data={data.getSpMaterial.materialImage}
        />
        <OpenDataId
          label={"Дата создания"}
          data={data.getSpMaterial.createdAt}
        />
        <OpenDataId
          label={"Дата изменения"}
          data={data.getSpMaterial.updatedAt}
        />
      </>} />
  )
}

export default GetSpMaterial;