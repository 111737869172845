import React from 'react';
import { useQuery } from '@apollo/client';
import { FETCH_SP_BRIDGES_FAMILYS } from './gqlSpBridgeFamily';
import GetSpBridgeFamily from './getSpBridgeFamily';
import { DeleteSpBridgeFamily } from './deleteSpBridgeFamily';
import { UpdateSpBridgeFamily } from './updateSpBridgeFamily';
import Loader from '../../../../components/main/Loader';
import { Box, Container } from '@material-ui/core';

const GetSpBridgesFamilys = () => {

    const { loading, error, data } = useQuery(FETCH_SP_BRIDGES_FAMILYS);

    if (loading) return <Loader/>;
    if (error) return `Error ${error}`;

    return (
        <>
            <Container>
                <Box display="flex" justifyContent="center">
                    <table>
                        <thead>
                            <tr>
                                <th>№</th>
                                <th>Наименование</th>
                                <th>Информация</th>
                                <th>Дата создания</th>
                                <th>Дата изменения</th>
                                <th>Действия</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.getSpBridgesFamilys.map((SpBridgeFamily, index) => {
                                return (
                                    <tr key={SpBridgeFamily._id}>
                                        <td>{index + 1}</td>
                                        <td>{SpBridgeFamily.name}</td>
                                        <td>{SpBridgeFamily.info}</td>
                                        <td>{SpBridgeFamily.createdAt}</td>
                                        <td>{SpBridgeFamily.updatedAt}</td>
                                        <td>
                                            <GetSpBridgeFamily id={SpBridgeFamily._id} />
                                            <UpdateSpBridgeFamily id={SpBridgeFamily._id} name={SpBridgeFamily.name} info={SpBridgeFamily.info}/>
                                            <DeleteSpBridgeFamily id={SpBridgeFamily._id} />
                                        </td>
                                    </tr>)
                            })}
                        </tbody>
                    </table>
                </Box>
            </Container>
        </>
    )
}

export default GetSpBridgesFamilys;