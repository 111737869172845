import React from 'react';
import { Container } from '@material-ui/core';
import AddSpNumberOfFrets from '../../requests/administrator/sp/spNumberOfFrets/addSpNumberOfFrets';


export const Test = () => {
 
  return (
    <Container justifyContent="center">
    {/* <div className={testCss.picture}>
    <img className={testCss.headstock} src={FF}/>
    <img className={testCss.headstock} src={Neck} />
    <img className={testCss.headstock} src={Bridge}/>
    <img className={testCss.headstock} src={HeadStock}/>
    <img className={testCss.headstock} src={PickupB}/>
    <img className={testCss.headstock} src={PickupN}/>
    </div> */}
    <AddSpNumberOfFrets />

    </Container>
  )
}
