import React, { useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { ADD_PT_BRIDGE, FETCH_PT_BRIDGES } from './gqlPtBridge';
import { FETCH_SP_INSTRUMENTS_FOR_SPINSTRUMENTID } from '../../sp/spInstrument/gqlSpInstrument';
import { FETCH_SP_INSTRUMENTS_TYPES_FOR_SPINSTRUMENTTYPEID } from '../../sp/spInstrumentType/gqlSpInstrumentType';
import { FETCH_SP_BRIDGES_CLASSES_FOR_SPBRIDGECLASSID } from '../../sp/spBridgeClass/gqlSpBridgeClass';
import { FETCH_SP_BRIDGES_FAMILYS_FOR_SPBRIDGEFAMILYID } from '../../sp/spBridgeFamily/gqlSpBridgeFamily';
import Loader from '../../../../components/main/Loader';
import Add from '../../../../components/actions/add';
import RequiredField from '../../../../components/InputElements/requiredField';
import OptionalMultiRowsField from '../../../../components/InputElements/optionalMultiRowsField';
import RequiredSelect from '../../../../components/InputElements/requiredSelect';
import UploadIcon from '../../../../components/actions/uploadIcon';
import UploadImage from '../../../../components/actions/uploadImage';
import GetNumbersOfStringsFilter from '../../sp/spNumberOfStrings/getSpNumbersOfStringsFilter';


const AddPtBridge = () => {

    const [values, setValues] = useState({
        name: ""
    });
    const [icon, setIcon] = useState({
        fileName: 'Файл не загружен'
    });

    const [image, setImage] = useState({
        fileName: 'Файл не загружен'
    });

    const [addPtBridge] = useMutation(ADD_PT_BRIDGE, {

        onError(e) {
            console.log(e.graphQLErrors[0].message)
        },
        variables: values,
        refetchQueries: [{ query: FETCH_PT_BRIDGES }]
    });

    const {
        loading: loading_FETCH_SP_INSTRUMENTS_FOR_SPINSTRUMENTID,
        error: error_FETCH_SP_INSTRUMENTS_FOR_SPINSTRUMENTID,
        data: data_FETCH_SP_INSTRUMENTS_FOR_SPINSTRUMENTID } = useQuery(FETCH_SP_INSTRUMENTS_FOR_SPINSTRUMENTID, {
            fetchPolicy: "no-cache"
        });
    const {
        loading: loading_FETCH_SP_INSTRUMENTS_TYPES_FOR_SPINSTRUMENTTYPEID,
        error: error_FETCH_SP_INSTRUMENTS_TYPES_FOR_SPINSTRUMENTTYPEID,
        data: data_FETCH_SP_INSTRUMENTS_TYPES_FOR_SPINSTRUMENTTYPEID } = useQuery(FETCH_SP_INSTRUMENTS_TYPES_FOR_SPINSTRUMENTTYPEID, {
            fetchPolicy: "no-cache"
        });
    const {
        loading: loading_FETCH_SP_BRIDGES_CLASSES_FOR_SPBRIDGECLASSID,
        error: error_FETCH_SP_BRIDGES_CLASSES_FOR_SPBRIDGECLASSID,
        data: data_FETCH_SP_BRIDGES_CLASSES_FOR_SPBRIDGECLASSID } = useQuery(FETCH_SP_BRIDGES_CLASSES_FOR_SPBRIDGECLASSID, {
            fetchPolicy: "no-cache"
        });
    const {
        loading: loading_FETCH_SP_BRIDGES_FAMILYS_FOR_SPBRIDGEFAMILYID,
        error: error_FETCH_SP_BRIDGES_FAMILYS_FOR_SPBRIDGEFAMILYID,
        data: data_FETCH_SP_BRIDGES_FAMILYS_FOR_SPBRIDGEFAMILYID } = useQuery(FETCH_SP_BRIDGES_FAMILYS_FOR_SPBRIDGEFAMILYID, {
            fetchPolicy: "no-cache"
        });

    if (loading_FETCH_SP_INSTRUMENTS_FOR_SPINSTRUMENTID) return <Loader />;
    if (error_FETCH_SP_INSTRUMENTS_FOR_SPINSTRUMENTID) return `Error ${error_FETCH_SP_INSTRUMENTS_FOR_SPINSTRUMENTID}`;

    if (loading_FETCH_SP_INSTRUMENTS_TYPES_FOR_SPINSTRUMENTTYPEID) return <Loader />;
    if (error_FETCH_SP_INSTRUMENTS_TYPES_FOR_SPINSTRUMENTTYPEID) return `Error ${error_FETCH_SP_INSTRUMENTS_TYPES_FOR_SPINSTRUMENTTYPEID}`;

    if (loading_FETCH_SP_BRIDGES_CLASSES_FOR_SPBRIDGECLASSID) return <Loader />;
    if (error_FETCH_SP_BRIDGES_CLASSES_FOR_SPBRIDGECLASSID) return `Error ${error_FETCH_SP_BRIDGES_CLASSES_FOR_SPBRIDGECLASSID}`;

    if (loading_FETCH_SP_BRIDGES_FAMILYS_FOR_SPBRIDGEFAMILYID) return <Loader />;
    if (error_FETCH_SP_BRIDGES_FAMILYS_FOR_SPBRIDGEFAMILYID) return `Error ${error_FETCH_SP_BRIDGES_FAMILYS_FOR_SPBRIDGEFAMILYID}`;

    const handlerChangeName = (e) => {
        setValues({
            ...values,
            [e.target.name]: e.target.value,
        });
    };

    const formSubmit = (e) => {
        e.preventDefault();
        addPtBridge();
        setValues({ name: "" })
    }

    const updateData = (value) => {
        setIcon({ fileName: value })
    }

    const updateDataImage = (value) => {
        setImage({ fileName: value })
    }

    const filterInstrument_id = values.spInstrument_id
    const filterInstrumentType_id = values.spInstrumentType_id

    return (
        <Add
            title={"Добавление Bridge"}
            ButtonText={"Добавить Bridge"}
            dialogTitle={"Добавление нового Bridge"}
            formSubmit={formSubmit}
            Fields={<>
                <UploadIcon
                    label="bridgeIcon"
                    value={values.bridgeIcon}
                    // email={values.email}
                    nameData="pr_gslc_i_bridge"
                    method={"pr/gslc/icons/bridge"}
                    dirIcon={"bridge"}
                    updateData={updateData}
                /><br></br>
                <RequiredField
                    label={"bridgeIcon"}
                    value={values.bridgeIcon = icon.fileName.toString()}
                    onChange={handlerChangeName}
                    name={"bridgeIcon"}
                /><br></br>
                <UploadImage
                    label="bridgeImage"
                    value={values.bridgeImage}
                    // email={values.email}
                    nameData="pr_gslc_img_bridge"
                    method={"pr/gslc/images/bridge"}
                    dirIcon={"bridge"}
                    updateDataImage={updateDataImage}
                /><br></br>
                <RequiredField
                    label={"bridgeImage"}
                    value={values.bridgeImage = image.fileName.toString()}
                    onChange={handlerChangeName}
                    name={"bridgeImage"}
                /><br></br>
                <RequiredField
                    label={"name"}
                    value={values.name}
                    onChange={handlerChangeName}
                    name={"name"}
                />
                <br></br>
                <OptionalMultiRowsField
                    label="Дополнительная информация"
                    name="info"
                    value={values.info}
                    onChange={handlerChangeName}
                    rows={10}
                />
                <br></br>
                <RequiredSelect
                    label={"Инструмент"}
                    name={"spInstrument_id"}
                    value={values.spInstrument_id}
                    onChange={handlerChangeName}
                    fetch={data_FETCH_SP_INSTRUMENTS_FOR_SPINSTRUMENTID.getSpInstruments}
                />
                <br></br>
                <RequiredSelect
                    label={"Инструмент"}
                    name={"spInstrumentType_id"}
                    value={values.spInstrumentType_id}
                    onChange={handlerChangeName}
                    fetch={data_FETCH_SP_INSTRUMENTS_TYPES_FOR_SPINSTRUMENTTYPEID.getSpInstrumentsTypes}
                />
                <br></br>
                <RequiredSelect
                    label={"spBridgeClass_id"}
                    name={"spBridgeClass_id"}
                    value={values.spBridgeClass_id}
                    onChange={handlerChangeName}
                    fetch={data_FETCH_SP_BRIDGES_CLASSES_FOR_SPBRIDGECLASSID.getSpBridgesClasses}
                />
                <br></br>
                <RequiredSelect
                    label={"spBridgeFamily_id"}
                    name={"spBridgeFamily_id"}
                    value={values.spBridgeFamily_id}
                    onChange={handlerChangeName}
                    fetch={data_FETCH_SP_BRIDGES_FAMILYS_FOR_SPBRIDGEFAMILYID.getSpBridgesFamilys}
                />
                <br></br>
                <GetNumbersOfStringsFilter
                    label={"spNumberOfStrings_id"}
                    name={"spNumberOfStrings_id"}
                    value={values.spNumberOfStrings_id}
                    onChange={handlerChangeName}
                    spInstrument_id={filterInstrument_id}
                    spInstrumentType_id={filterInstrumentType_id}
                />
                <br></br>
                <RequiredField
                    label={"sourse"}
                    value={values.sourse}
                    onChange={handlerChangeName}
                    name={"sourse"}
                />
            </>} />
    )

}

export default AddPtBridge;