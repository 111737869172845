import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { AuthContext } from '../../context/AuthContext';
import logo from '../../assets/gsl_logo.svg';
import { AppBar, Box, Button, Container, Toolbar, Typography } from '@material-ui/core';
import {makeStyles} from "@material-ui/core/styles";
import { grey } from '@material-ui/core/colors';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  },
  munuButton: {
    marginRight: theme.spacing(1)
  },
  title: {
    flexGrow:1
  },
  bar: {
    backgroundColor: "#e0e0e0 "
  }
})

)

export const Navbar = ({width}) => {
const classes = useStyles();

  const history = useHistory()
  const auth = useContext(AuthContext)

  const logoutHandler = event => {
    event.preventDefault()
    auth.logout()
    history.push('/')
  }

  return (

      <AppBar position="absolute" style={{backgroundColor: grey[800]}}>
        <Container maxWidth="xl" >
          <Toolbar >
            <Typography className={classes.title}>
            <img src={logo} />
            </Typography>
            <Typography className={classes.title}>{width}</Typography>
            <Box mr={3}>
              <Button color="inherit" variant="outlined" href="/" onClick={logoutHandler}>
                Выйти
              </Button>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
  )
}