import React from 'react';
import { useMutation } from '@apollo/client';
import { DELETE_SP_INSTRUMENT, FETCH_SP_INSTRUMENTS } from './gqlSpInstrument';
import { Delete } from '../../../../components/actions/delete';

export const DeleteSpInstrument = ({ id }) => {
  const [deleteSpBrand] = useMutation(DELETE_SP_INSTRUMENT, {

    onError(e) {
      console.log(e.graphQLErrors[0].message)
    },
    variables: { id },
    refetchQueries: [{ query: FETCH_SP_INSTRUMENTS }]
  });

  const onDelete = () => {
    deleteSpBrand()
  }
  return (
    <Delete onDelete={onDelete} />
  )
}
