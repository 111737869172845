import { gql } from "@apollo/client";

export const FETCH_PT_FORMFACTORS = gql`
query getPtFormFactors($sheet: Int, $limit: Int){
    getPtFormFactors(sheet: $sheet, limit: $limit){
        _id,
        name,
        info,
        spInstrument_id {
            _id
        },
        spInstrumentType_id {
           _id
        },
        spFfClass_id {
           _id
        },
        spFfFamily_id {
           _id
        },
        year,
        sourse,
        formfactorImage,
        formfactorIcon,
        createdAt,
        updatedAt  
    }
}
`;

export const FETCH_PT_FORMFACTOR = gql`
    query getPtFormFactor($id: ID) {
        getPtFormFactor(_id: $id) {
            _id,
            name,
            info,
            spInstrument_id {
                _id,
                name
            },
            spInstrumentType_id {
                _id,
                name
            },
            spFfClass_id {
                _id,
                name
            },
            spFfFamily_id {
                _id,
                name
            },
            year,
            sourse,
            formfactorImage,
            formfactorIcon,
            createdAt,
            updatedAt
        }
    }
`;

export const ADD_PT_FORMFACTOR = gql`
    mutation addPtFormFactor(
        $name: String, 
        $info: String,
        $spInstrument_id: ID,
        $spInstrumentType_id: ID,
        $spFfClass_id: ID,
        $spFfFamily_id: ID,
        $year: String,
        $sourse: String,
        $formfactorImage: String,
        $formfactorIcon: String) {
            addPtFormFactor(
                name: $name, 
                info: $info,
                spInstrument_id: $spInstrument_id,
                spInstrumentType_id: $spInstrumentType_id,
                spFfClass_id: $spFfClass_id,
                spFfFamily_id: $spFfFamily_id,
                year: $year,
                sourse: $sourse,
                formfactorImage: $formfactorImage,
                formfactorIcon: $formfactorIcon) {
                    _id 
                }      
    }
`;

export const DELETE_PT_FORMFACTOR = gql`
    mutation deletePtFormFactor($id: ID) {
        deletePtFormFactor(_id: $id) {
            _id
        }
    }
`;

export const UPDATE_PT_FORMFACTOR = gql`
    mutation updatePtFormFactor(
        $id: ID, 
        $name: String, 
        $info: String,
        $spInstrument_id: ID,
        $spInstrumentType_id: ID,
        $spFfClass_id: ID,
        $spFfFamily_id: ID,
        $year: String,
        $sourse: String,
        $formfactorImage: String,
        $formfactorIcon: String) {
            updatePtFormFactor(
                _id: $id, 
                name: $name, 
                info: $info,
                spInstrument_id: $spInstrument_id,
                spInstrumentType_id: $spInstrumentType_id,
                spFfClass_id: $spFfClass_id,
                spFfFamily_id: $spFfFamily_id,
                year: $year,
                sourse: $sourse,
                formfactorImage: $formfactorImage,
                formfactorIcon: $formfactorIcon) {
                    _id
                }
    }
`;

export const FETCH_PT_FORMFACTORS_FOR_PTFORMFACTORID = gql`
{
    getPtFormFactors{
        _id,
        name 
    }
}
`;

export const FETCH_PT_FORMFACTOR_FILTER = gql`
    query getPtFormFactorFilter(
        $spInstrument_id: String, 
        $spInstrumentType_id: String) {
            getPtFormFactorFilter(
                spInstrument_id: $spInstrument_id,
                spInstrumentType_id: $spInstrumentType_id) {
                    _id,
                    name,
                    formfactorIcon
                }
        }
`;