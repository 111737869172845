
import React from 'react';
import { useQuery } from '@apollo/client';
import { FETCH_USERS_POSTS } from './gqlUserPost';
import Loader from '../../../components/main/Loader';
import Post from '../../../components/post';
import { Container } from '@material-ui/core';



const GetUsersPosts = () => {
  const { loading, error, data } = useQuery(FETCH_USERS_POSTS);

  if (loading) return <Loader />;
  if (error) return `Error ${error}`;

  return data.getUsersPosts.map((userPost) => <UserPost key={userPost._id} userPost={userPost}

  />)
}

const UserPost = (userPost) => {
  return (
    <>
      <Container>
        <Post
          id={userPost.userPost._id}
          postText={userPost.userPost.postText}
          user_id={userPost.userPost.user_id}
          createdAt={userPost.userPost.createdAt}
        />
      </Container>
    </>
  )
}

export default GetUsersPosts;