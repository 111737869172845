import React, { useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { ADD_PT_NECK, FETCH_PT_NECKS } from './gqlPtNeck';
import { FETCH_SP_INSTRUMENTS_FOR_SPINSTRUMENTID } from '../../sp/spInstrument/gqlSpInstrument';
import { FETCH_SP_INSTRUMENTS_TYPES_FOR_SPINSTRUMENTTYPEID } from '../../sp/spInstrumentType/gqlSpInstrumentType';
import Loader from '../../../../components/main/Loader';
import Add from '../../../../components/actions/add';
import RequiredField from '../../../../components/InputElements/requiredField';
import OptionalMultiRowsField from '../../../../components/InputElements/optionalMultiRowsField';
import RequiredSelect from '../../../../components/InputElements/requiredSelect';
import UploadIcon from '../../../../components/actions/uploadIcon';
import UploadImage from '../../../../components/actions/uploadImage';
import GetNumbersOfStringsFilter from '../../sp/spNumberOfStrings/getSpNumbersOfStringsFilter';
import GetNumbersOfFretsFilter from '../../sp/spNumberOfFrets/getSpNumbersOfFretsFilter';


const AddPtNeck = () => {

    const [values, setValues] = useState({
        name: ""
    });

    const [icon, setIcon] = useState({
        fileName: 'Файл не загружен'
    });

    const [image, setImage] = useState({
        fileName: 'Файл не загружен'
    });

    const [addPtNeck] = useMutation(ADD_PT_NECK, {

        onError(e) {
            console.log(e.graphQLErrors[0].message)
        },
        variables: values,
        refetchQueries: [{ query: FETCH_PT_NECKS }]
    });

    const {
        loading: loading_FETCH_SP_INSTRUMENTS_FOR_SPINSTRUMENTID,
        error: error_FETCH_SP_INSTRUMENTS_FOR_SPINSTRUMENTID,
        data: data_FETCH_SP_INSTRUMENTS_FOR_SPINSTRUMENTID } = useQuery(FETCH_SP_INSTRUMENTS_FOR_SPINSTRUMENTID, {
            fetchPolicy: "no-cache"
        });
    const {
        loading: loading_FETCH_SP_INSTRUMENTS_TYPES_FOR_SPINSTRUMENTTYPEID,
        error: error_FETCH_SP_INSTRUMENTS_TYPES_FOR_SPINSTRUMENTTYPEID,
        data: data_FETCH_SP_INSTRUMENTS_TYPES_FOR_SPINSTRUMENTTYPEID } = useQuery(FETCH_SP_INSTRUMENTS_TYPES_FOR_SPINSTRUMENTTYPEID, {
            fetchPolicy: "no-cache"
        });

    if (loading_FETCH_SP_INSTRUMENTS_FOR_SPINSTRUMENTID) return <Loader />;
    if (error_FETCH_SP_INSTRUMENTS_FOR_SPINSTRUMENTID) return `Error ${error_FETCH_SP_INSTRUMENTS_FOR_SPINSTRUMENTID}`;

    if (loading_FETCH_SP_INSTRUMENTS_TYPES_FOR_SPINSTRUMENTTYPEID) return <Loader />;
    if (error_FETCH_SP_INSTRUMENTS_TYPES_FOR_SPINSTRUMENTTYPEID) return `Error ${error_FETCH_SP_INSTRUMENTS_TYPES_FOR_SPINSTRUMENTTYPEID}`;

    const handlerChangeName = (e) => {
        setValues({
            ...values,
            [e.target.name]: e.target.value,
        });
    };

    const formSubmit = (e) => {
        e.preventDefault();
        addPtNeck();
        setValues({ name: "" })
    }

    const updateData = (value) => {
        setIcon({ fileName: value })
    }

    const updateDataImage = (value) => {
        setImage({ fileName: value })
    }
    const filterInstrument_id = values.spInstrument_id
    const filterInstrumentType_id = values.spInstrumentType_id

    return (
        <Add
            title={"Добавление PtNeck"}
            ButtonText={"Добавить PtNeck"}
            dialogTitle={"Добавление нового PtNeck"}
            formSubmit={formSubmit}
            Fields={<>
                <UploadIcon
                    label="neckIcon"
                    value={values.neckIcon}
                    // email={values.email}
                    nameData="pr_gslc_i_neck"
                    method={"pr/gslc/icons/neck"}
                    dirIcon={"neck"}
                    updateData={updateData}
                /><br></br>
                <RequiredField
                    label={"neckIcon"}
                    value={values.neckIcon = icon.fileName.toString()}
                    onChange={handlerChangeName}
                    name={"neckIcon"}
                /><br></br>
                <UploadImage
                    label="neckImage"
                    value={values.neckImage}
                    // email={values.email}
                    nameData="pr_gslc_img_neck"
                    method={"pr/gslc/images/neck"}
                    dirIcon={"neck"}
                    updateDataImage={updateDataImage}
                /><br></br>
                <RequiredField
                    label={"neckImage"}
                    value={values.neckImage = image.fileName.toString()}
                    onChange={handlerChangeName}
                    name={"neckImage"}
                /><br></br>
                <RequiredField
                    label={"name"}
                    value={values.name}
                    onChange={handlerChangeName}
                    name={"name"}
                />
                <br></br>
                <OptionalMultiRowsField
                    label="Дополнительная информация"
                    name="info"
                    value={values.info}
                    onChange={handlerChangeName}
                    rows={10}
                />
                <br></br>
                <RequiredSelect
                    label={"Инструмент"}
                    name={"spInstrument_id"}
                    value={values.spInstrument_id}
                    onChange={handlerChangeName}
                    fetch={data_FETCH_SP_INSTRUMENTS_FOR_SPINSTRUMENTID.getSpInstruments}
                />
                <br></br>
                <RequiredSelect
                    label={"Инструмент"}
                    name={"spInstrumentType_id"}
                    value={values.spInstrumentType_id}
                    onChange={handlerChangeName}
                    fetch={data_FETCH_SP_INSTRUMENTS_TYPES_FOR_SPINSTRUMENTTYPEID.getSpInstrumentsTypes}
                />
                <br></br>
                <GetNumbersOfStringsFilter
                    label={"spNumberOfStrings_id"}
                    name={"spNumberOfStrings_id"}
                    value={values.spNumberOfStrings_id}
                    onChange={handlerChangeName}
                    spInstrument_id={filterInstrument_id}
                    spInstrumentType_id={filterInstrumentType_id}
                />
                <br></br>
                <GetNumbersOfFretsFilter
                   label={"spNumberOfFrets_id"}
                    name={"spNumberOfFrets_id"}
                    value={values.spNumberOfFrets_id}
                    onChange={handlerChangeName}
                    spInstrument_id={filterInstrument_id}
                    spInstrumentType_id={filterInstrumentType_id}
                    spNumberOfStrings_id={values.spNumberOfStrings_id}
                />
            </>} />
    )
}

export default AddPtNeck;