import React from 'react';
import { useQuery } from '@apollo/client';
import { FETCH_SP_MENSURAS } from './gqlSpMensura';
import GetSpMensura from './getSpMensura';
import { DeleteSpMensura } from './deleteSpMensura';
import { UpdateSpMensura } from './updateSpMensura';
import Loader from '../../../../components/main/Loader';
import { Box, Container } from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';

const GetSpMensuras = () => {
    const [page, setPage] = React.useState(1);

    const { loading, error, data } = useQuery(FETCH_SP_MENSURAS, {
        variables: {
            sheet: page,
            limit: 10
        },
    });

    if (loading) return <Loader/>;
    if (error) return `Error ${error}`;

    const handleChange = (event, value) => {
        setPage(value);
    };

    return (
        <>
         <Pagination count={100} page={page} onChange={handleChange} />
            <Container>
                <Box display="flex" justifyContent="center">
                    <table>
                        <thead>
                            <tr>
                                <th>№</th>
                                <th>Наименование</th>
                                <th>nameInch</th>
                                <th>Дата создания</th>
                                <th>Дата изменения</th>
                                <th>Действия</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.getSpMensuras.map((SpMensura, index) => {
                                return (
                                    <tr key={SpMensura._id}>
                                        <td>{index + 1}</td>
                                        <td>{SpMensura.name}</td>
                                        <td>{SpMensura.nameInch}</td>
                                        <td>{SpMensura.createdAt}</td>
                                        <td>{SpMensura.updatedAt}</td>
                                        <td>
                                            <GetSpMensura id={SpMensura._id} />
                                            <UpdateSpMensura
                                                id={SpMensura._id}
                                                name={SpMensura.name}
                                                nameInch={SpMensura.nameInch}
                                                info={SpMensura.info}
                                                spInstrument_id={SpMensura.spInstrument_id._id} 
                                                spInstrumentType_id={SpMensura.spInstrumentType_id._id}
                                                spNumberOfStrings_id={SpMensura.spNumberOfStrings_id._id}
                                                spNumberOfFrets_id={SpMensura.spNumberOfFrets_id._id}
                                                source={SpMensura.source}
                                                />
                                            <DeleteSpMensura id={SpMensura._id} />
                                        </td>
                                    </tr>)
                            })}
                        </tbody>
                    </table>
                </Box>
            </Container>
        </>
    )
}

export default GetSpMensuras;