import { gql } from "@apollo/client";

export const FETCH_SP_FF_CLASSES = gql`
    {
        getSpFFClasses{
            _id
            name
            info    
            createdAt
            updatedAt  
        }
    }
`;

export const FETCH_SP_FF_CLASS = gql`
    query getSpFFClass($id: ID) {
        getSpFFClass(_id: $id) {
            _id
            name
            info
            createdAt
            updatedAt
        }
    }
`;

export const ADD_SP_FF_CLASS = gql`
    mutation addSpFFClass($name: String, $info: String) {
        addSpFFClass(name: $name, info: $info) {
            _id
        }      
    }
`;

export const DELETE_SP_FF_CLASS = gql`
    mutation deleteSpFFClass($id: ID) {
        deleteSpFFClass(_id: $id) {
            _id
        }
    }
`;

export const UPDATE_SP_FF_CLASS = gql`
    mutation updateSpFFClass($id: ID, $name: String, $info: String) {
        updateSpFFClass(_id: $id, name: $name, info: $info) {
            _id
        }
    }
`;

export const FETCH_SP_FF_CLASSES_FOR_SPFFCLASSESID = gql`
    {
        getSpFFClasses{
            _id
            name 
        }
    }
`;