import React from 'react';
import { useQuery } from '@apollo/client';
import { FETCH_SP_PICKUP_TYPE } from './gqlSpPickupType';
import Loader from '../../../../components/main/Loader';
import Open from '../../../../components/actions/open';
import OpenDataId from '../../../../components/view/openDataId';
import { UpdateSpPickupType } from './updateSpPickupType';

const GetSpPickupType = ({ id }) => {

  const { loading, error, data } = useQuery(FETCH_SP_PICKUP_TYPE, {
    variables: { id },
  });

  if (loading) return <Loader />;
  if (error) return `Error ${error}`;

  return (
    <Open
      title={"Открыть"}
      dialogTitle={data.getSpPickupType.name}
      edit={<UpdateSpPickupType
        id={data.getSpPickupType._id}
        name={data.getSpPickupType.name}
        info={data.getSpPickupType.info} />}
      Fields={<>
        <OpenDataId
          label={"ID"}
          data={data.getSpPickupType._id}
        />
        <OpenDataId
          label={"Информация"}
          data={data.getSpPickupType.info}
        />
        <OpenDataId
          label={"Дата создания"}
          data={data.getSpPickupType.createdAt}
        />
        <OpenDataId
          label={"Дата изменения"}
          data={data.getSpPickupType.updatedAt}
        />
      </>} />
  )
}

export default GetSpPickupType;