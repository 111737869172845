import React, { useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { FETCH_SP_PICKUP_COILS, UPDATE_SP_PICKUP_COIL } from './gqlSpPickupCoil';
import Update from '../../../../components/actions/update';
import RequiredField from '../../../../components/InputElements/requiredField';
import OptionalMultiRowsField from '../../../../components/InputElements/optionalMultiRowsField';
import { FETCH_SP_PICKUPS_TYPES_FOR_SPPICKUPTYPEID } from '../spPickupType/gqlSpPickupType';
import Loader from '../../../../components/main/Loader';
import RequiredSelect from '../../../../components/InputElements/requiredSelect';


export const UpdateSpPickupCoil = ({
    id, name, info }) => {

    const [values, setValues] = useState({
        name, info
    });

    const [updateSpPickupCoil] = useMutation(UPDATE_SP_PICKUP_COIL, {

        onError(e) {
            console.log(e.graphQLErrors[0].message)
        },
        variables: {
            id, name: values.name, info: values.info
        },
        refetchQueries: [{ query: FETCH_SP_PICKUP_COILS }]
    })

    const { loading, error, data } = useQuery(FETCH_SP_PICKUPS_TYPES_FOR_SPPICKUPTYPEID, {
        fetchPolicy: "no-cache"
    });

    if (loading) return <Loader />;
    if (error) return `Error ${error}`;

    const handlerChangeName = (e) => {
        setValues({
            ...values,
            [e.target.name]: e.target.value
        })
    }

    const formSubmit = (e) => {
        e.preventDefault();
        updateSpPickupCoil();
    }

    return (
        <Update
            title={"Изменение SpPickupCoil"}
            ButtonText={"Изменить SpPickupCoil"}
            dialogTitle={"Изменение SpPickupCoil"}
            formSubmit={formSubmit}
            Fields={<>
                <RequiredField
                    label={"Наименование"}
                    value={values.name}
                    onChange={handlerChangeName}
                    name={"name"}
                />
                <br></br>
                <OptionalMultiRowsField
                    label={"Информация"}
                    value={values.info}
                    onChange={handlerChangeName}
                    name={"info"}
                />
                <RequiredSelect
                    label={"Тип звукоснимателя"}
                    name={"spPickupType_id"}
                    value={values.spPickupType_id}
                    onChange={handlerChangeName}
                    fetch={data.getSpPickupsTypes}
                />
            </>} />

    )
}