import React from 'react';
import { useQuery } from '@apollo/client';
import Loader from '../../../../components/main/Loader';
import { Box, Container } from '@material-ui/core';
import { FETCH_SP_STATUSES_APPEALS } from './gqlSpStatusAppeal';
import GetSpStatusAppeal from './getSpStatusAppeal';
import { UpdateSpStatusAppeal } from './updateSpStatusAppeal';
import { DeleteSpStatusAppeal } from './deleteSpStatusAppeal';


const GetSpStatusesAppeals = () => {

    const { loading, error, data } = useQuery(FETCH_SP_STATUSES_APPEALS);

    if (loading) return <Loader />;
    if (error) return `Error ${error}`;

    return (
        <>
            <Container>
                <Box display="flex" justifyContent="center">
                    <table>
                        <thead>
                            <tr>
                                <th>№</th>
                                <th>Наименование</th>
                                <th>Информация</th>
                                <th>Дата создания</th>
                                <th>Дата изменения</th>
                                <th>Действия</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.getSpStatusesAppeals.map((SpStatusAppeal, index) => {
                                return (
                                    <tr key={SpStatusAppeal._id}>
                                        <td>{index + 1}</td>
                                        <td>{SpStatusAppeal.name}</td>
                                        <td>{SpStatusAppeal.info}</td>
                                        <td>{SpStatusAppeal.createdAt}</td>
                                        <td>{SpStatusAppeal.updatedAt}</td>
                                        <td>
                                            <GetSpStatusAppeal id={SpStatusAppeal._id} />
                                            <UpdateSpStatusAppeal
                                                id={SpStatusAppeal._id}
                                                name={SpStatusAppeal.name}
                                                info={SpStatusAppeal.info} />
                                            <DeleteSpStatusAppeal id={SpStatusAppeal._id} />
                                        </td>
                                    </tr>)
                            })}
                        </tbody>
                    </table>
                </Box>
            </Container>
        </>
    )
}

export default GetSpStatusesAppeals;