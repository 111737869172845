import { gql } from "@apollo/client";

export const FETCH_SP_NUMBERS_OF_STRINGS = gql`
query getSpNumbersOfStrings($sheet: Int, $limit: Int){
        getSpNumbersOfStrings(sheet: $sheet, limit: $limit){
            _id,
            name,
            info,
            spInstrument_id {
                _id,
                name
            },
            spInstrumentType_id {
                _id,
                name
            },
            code,
            createdAt,
            updatedAt  
        }
    }
`;

export const FETCH_SP_NUMBER_OF_STRINGS = gql`
    query getSpNumberOfStrings($id: ID) {
        getSpNumberOfStrings(_id: $id) {
            _id,
            name,
            info,
            spInstrument_id {
                _id,
                name
            },
            spInstrumentType_id {
                _id,
                name
            },
            code,
            createdAt,
            updatedAt
        }
    }
`;

export const ADD_SP_NUMBER_OF_STRINGS = gql`
    mutation addSpNumberOfStrings(
        $name: String, 
        $info: String,
        $spInstrument_id: ID,
        $spInstrumentType_id: ID,
        $code: String) {
            addSpNumberOfStrings(
                name: $name, 
                info: $info,
                spInstrument_id: $spInstrument_id,
                spInstrumentType_id: $spInstrumentType_id,
                code: $code) {
                    _id  
                }      
        }
`;

export const DELETE_SP_NUMBER_OF_STRINGS = gql`
    mutation deleteSpNumberOfStrings($id: ID) {
        deleteSpNumberOfStrings(_id: $id) {
            _id
        }
    }
`;

export const UPDATE_SP_NUMBER_OF_STRINGS = gql`
    mutation updateSpNumberOfStrings(
        $id: ID, 
        $name: String, 
        $info: String,
        $spInstrument_id: ID,
        $spInstrumentType_id: ID,
        $code: String) {
            updateSpNumberOfStrings(
                _id: $id, 
                name: $name, 
                info: $info,
                spInstrument_id: $spInstrument_id,
                spInstrumentType_id: $spInstrumentType_id,
                code: $code) {
                    _id
                }
        }
`;

export const FETCH_SP_NUMBERS_OF_STRINGS_FOR_SPNUMBEROFSTRINGSCODE = gql`
    {
        getSpNumbersOfStrings{
            _id
            code
        }
    }
`;

export const FETCH_SP_NUMBER_OF_STRINGS_FILTER = gql`
    query getSpNumberOfStringsFilter(
        $spInstrument_id: String, 
        $spInstrumentType_id: String) {
            getSpNumberOfStringsFilter(
                spInstrument_id: $spInstrument_id,
                spInstrumentType_id: $spInstrumentType_id) {
                    _id,
                    name,
                    code
                }
        }
`;