import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { FETCH_SP_INSTRUMENTS, UPDATE_SP_INSTRUMENT } from './gqlSpInstrument';
import Update from '../../../../components/actions/update';
import RequiredField from '../../../../components/InputElements/requiredField';
import UploadIcon from '../../../../components/actions/uploadIcon';

export const UpdateSpInstrument = ({ id, name, info, instrumentIcon }) => {

    const [values, setValues] = useState({
        name, info, instrumentIcon
    });

    const [updateSpInstrument] = useMutation(UPDATE_SP_INSTRUMENT, {

        onError(e) {
            console.log(e.graphQLErrors[0].message)
        },
        variables: { id, name: values.name, info: values.info, instrumentIcon: values.instrumentIcon },
        refetchQueries: [{ query: FETCH_SP_INSTRUMENTS }]
    })

    const handlerChangeName = (e) => {
        setValues({
            ...values,
            [e.target.name]: e.target.value
        })
    }

    const formSubmit = (e) => {
        e.preventDefault();
        updateSpInstrument();
    }

    const [state, setState] = useState({
        fileName: instrumentIcon
    });

    const updateData = (value) => {
        setState({ fileName: value })
    }

    return (
        <Update
            title={"Изменение проблемы"}
            ButtonText={"Изменить проблему"}
            dialogTitle={"Изменение проблемы"}
            formSubmit={formSubmit}
            Fields={<>
                <UploadIcon
                    label="instrumentIcon"
                    value={values.instrumentIcon}
                    email={values.email}
                    nameData="pr_gslc_i_instrument"
                    method={"pr/gslc/icons/instrument"}
                    dirIcon={"instrument"}
                    updateData={updateData}
                /><br></br>
                <RequiredField
                    label={"instrumentIcon"}
                    value={values.instrumentIcon = state.fileName.toString()}
                    onChange={handlerChangeName}
                    name={"instrumentIcon"}
                /><br></br>
                <RequiredField
                    label={"Наименование"}
                    value={values.name}
                    onChange={handlerChangeName}
                    name={"name"}
                /><br></br>
                <RequiredField
                    label={"info"}
                    value={values.info}
                    onChange={handlerChangeName}
                    name={"info"}
                />
            </>}
        />
    )
}