import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { FETCH_SP_BRIDGES_FAMILYS, UPDATE_SP_BRIDGE_FAMILY } from './gqlSpBridgeFamily';
import Update from '../../../../components/actions/update';
import RequiredField from '../../../../components/InputElements/requiredField';
import OptionalMultiRowsField from '../../../../components/InputElements/optionalMultiRowsField';


export const UpdateSpBridgeFamily = ({ id, name, info }) => {
    const [values, setValues] = useState({
        name, info
    });

    const [updateSpBridgeFamily] = useMutation(UPDATE_SP_BRIDGE_FAMILY, {

        onError(e) {
            console.log(e.graphQLErrors[0].message)
        },
        variables: { id, name: values.name, info: values.info },
        refetchQueries: [{ query: FETCH_SP_BRIDGES_FAMILYS }]
    })

    const handlerChangeName = (e) => {
        setValues({
            ...values,
            [e.target.name]: e.target.value
        })
    }

    const formSubmit = (e) => {
        e.preventDefault();
        updateSpBridgeFamily();
    }

    return (
        <Update
            title={"Изменение BridgeFamily"}
            ButtonText={"Изменить BridgeFamily"}
            dialogTitle={"Изменение BridgeFamily"}
            formSubmit={formSubmit}
            Fields={<>
                <RequiredField
                    label={"Наименование"}
                    value={values.name}
                    onChange={handlerChangeName}
                    name={"name"}
                />
                <br></br>
                <OptionalMultiRowsField
                    label={"Информация"}
                    value={values.info}
                    onChange={handlerChangeName}
                    name={"info"}
                />
            </>} />
    )
}