import React from 'react';
import { useQuery } from '@apollo/client';
import { FETCH_PT_FORMFACTORS } from './gqlPtFormFactor';
import GetPtFormFactor from './getPtFormFactor';
import { DeletePtFormFactor } from './deletePtFormFactor';
import { UpdatePtFormFactor } from './updatePtFormFactor';
import Loader from '../../../../components/main/Loader';
import CardDatabaseItem from '../../../../components/view/cards/cardDatabaseItem';
import Pagination from '@material-ui/lab/Pagination';

const GetPtFormFactors = () => {
    const [page, setPage] = React.useState(1);

    const { loading, error, data } = useQuery(FETCH_PT_FORMFACTORS, {
        variables: {
            sheet: page,
            limit: 10
        },
    });

    if (loading) return <Loader />;
    if (error) return `Error ${error}`;

    const handleChange = (event, value) => {
        setPage(value);
    };

    return (
        <>
            <Pagination count={100} page={page} onChange={handleChange} />

            {data.getPtFormFactors.map((PtFormFactor, index) => {
                return (
                    <CardDatabaseItem
                        name={PtFormFactor.name}
                        nameIcon={PtFormFactor.formfactorIcon}
                        info={PtFormFactor.info}
                        dirName={"icons/formFactor"}
                        actions={
                            <>
                                <GetPtFormFactor id={PtFormFactor._id} />
                                <UpdatePtFormFactor
                                    id={PtFormFactor._id}
                                    name={PtFormFactor.name}
                                    info={PtFormFactor.info}
                                    spInstrument_id={PtFormFactor.spInstrument_id._id}
                                    spInstrumentType_id={PtFormFactor.spInstrumentType_id._id}
                                    spFfClass_id={PtFormFactor.spFfClass_id._id}
                                    spFfFamily_id={PtFormFactor.spFfFamily_id._id}
                                    year={PtFormFactor.year}
                                    sourse={PtFormFactor.sourse}
                                    formfactorImage={PtFormFactor.formfactorImage}
                                    formfactorIcon={PtFormFactor.formfactorIcon}
                                />
                                <DeletePtFormFactor id={PtFormFactor._id} />
                            </>} />
                )
            })}
        </>
    )
}

export default GetPtFormFactors;