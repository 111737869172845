import React from 'react';
import { useQuery } from '@apollo/client';
import { FETCH_PT_HEADSTOCK } from './gqlPtHeadstock';
import Loader from '../../../../components/main/Loader';
import OpenDataId from '../../../../components/view/openDataId';
import Open from '../../../../components/actions/open';
import { UpdatePtHeadstock } from './updatePtHeadstock';
import ViewIcon from '../../../../components/view/displayingData/viewIcon';
import ViewImage from '../../../../components/view/displayingData/viewImage';

const GetPtHeadstock = ({ id }) => {

  const { loading, error, data } = useQuery(FETCH_PT_HEADSTOCK, {
    variables: { id },
  });

  if (loading) return <Loader />;
  if (error) return `Error ${error}`;

  return (
    <Open
      title={"Открыть"}
      dialogTitle={data.getPtHeadstock.name}
      edit={<UpdatePtHeadstock
        id={data.getPtHeadstock._id}
        name={data.getPtHeadstock.name}
        info={data.getPtHeadstock.info}
        spInstrument_id={data.getPtHeadstock.spInstrument_id._id}
        spInstrumentType_id={data.getPtHeadstock.spInstrumentType_id._id}
        spNumberOfStrings_id={data.getPtHeadstock.spNumberOfStrings_id._id}
        sourse={data.getPtHeadstock.sourse}
        headstockImage={data.getPtHeadstock.headstockImage}
        headstockIcon={data.getPtHeadstock.headstockIcon}
      />}
      Fields={<>
        <OpenDataId
          label={"ID"}
          data={data.getPtHeadstock._id}
        />
        <OpenDataId
          label={"name"}
          data={data.getPtHeadstock.name}
        />
        <OpenDataId
          label={"info"}
          data={data.getPtHeadstock.info}
        />
        <OpenDataId
          label={"instrument_id.name"}
          data={data.getPtHeadstock.spInstrument_id.name}
        />
        <OpenDataId
          label={"instrumentType_id.name"}
          data={data.getPtHeadstock.spInstrumentType_id.name}
        />
        <OpenDataId
          label={"numberOfStrings_id.code"}
          data={data.getPtHeadstock.spNumberOfStrings_id.code}
        />
        <OpenDataId
          label={"sourse"}
          data={data.getPtHeadstock.sourse}
        />
        <OpenDataId
          label={"headstockImage"}
          data={data.getPtHeadstock.headstockImage}
        />
        <ViewImage
          image={data.getPtHeadstock.headstockImage}
          dir={"images/headstock"}
        />
        <OpenDataId
          label={"headstockIcon"}
          data={data.getPtHeadstock.headstockIcon}
        />
        <ViewIcon
          image={data.getPtHeadstock.headstockIcon}
          dir={"icons/headstock"}
        />

        <OpenDataId
          label={"createdAt"}
          data={data.getPtHeadstock.createdAt}
        />
        <OpenDataId
          label={"updatedAt"}
          data={data.getPtHeadstock.updatedAt}
        />
      </>} />
  )
}

export default GetPtHeadstock;