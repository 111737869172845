import React from 'react';
import { useQuery } from '@apollo/client';
import { FETCH_PT_NECK } from './gqlPtNeck';
import Loader from '../../../../components/main/Loader';
import Open from '../../../../components/actions/open';
import OpenDataId from '../../../../components/view/openDataId';
import { UpdatePtNeck } from './updatePtNeck';
import ViewImage from '../../../../components/view/displayingData/viewImage';
import ViewIcon from '../../../../components/view/displayingData/viewIcon';

const GetPtNeck = ({ id }) => {

  const { loading, error, data } = useQuery(FETCH_PT_NECK, {
    variables: { id },
  });

  if (loading) return <Loader />;
  if (error) return `Error ${error}`;

  return (
    <>
      <Open
        title={"Открыть"}
        dialogTitle={data.getPtNeck.name}
        edit={<UpdatePtNeck
          id={data.getPtNeck._id}
          name={data.getPtNeck.name}
          info={data.getPtNeck.info}
          spInstrument_id={data.getPtNeck.spInstrument_id._id}
          spInstrumentType_id={data.getPtNeck.spInstrumentType_id._id}
          spNumberOfStrings_id={data.getPtNeck.spNumberOfStrings_id._id}
          spNumberOfFrets_id={data.getPtNeck.spNumberOfFrets_id._id}
          neckImage={data.getPtNeck.neckImage}
          neckIcon={data.getPtNeck.neckIcon}
        />}
        Fields={<>
          <OpenDataId
            label={"ID"}
            data={data.getPtNeck._id}
          />
          <OpenDataId
            label={"name"}
            data={data.getPtNeck.name}
          />
          <OpenDataId
            label={"info"}
            data={data.getPtNeck.info}
          />
          <OpenDataId
            label={"spInstrument_id.name"}
            data={data.getPtNeck.spInstrument_id.name}
          />
          <OpenDataId
            label={"spInstrumentType_id.name"}
            data={data.getPtNeck.spInstrumentType_id.name}
          />
          <OpenDataId
            label={"spNumberOfStrings_id.code"}
            data={data.getPtNeck.spNumberOfStrings_id.name}
          />
          <OpenDataId
            label={"spNumberOfFrets_id.code"}
            data={data.getPtNeck.spNumberOfFrets_id.name}
          />
          <OpenDataId
            label={"createdAt"}
            data={data.getPtNeck.createdAt}
          />
          <OpenDataId
            label={"updatedAt"}
            data={data.getPtNeck.updatedAt}
          />
          <OpenDataId
            label={"neckImage"}
            data={data.getPtNeck.neckImage}
          />
          <ViewImage
            image={data.getPtNeck.neckImage}
            dir={"images/neck"}
          />
          <OpenDataId
            label={"neckIcon"}
            data={data.getPtNeck.neckIcon}
          />
          <ViewIcon
            image={data.getPtNeck.neckIcon}
            dir={"icons/neck"}
          />
        </>} />
    </>
  )
}

export default GetPtNeck;