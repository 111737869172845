import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { AuthPage } from './pages/public/AuthPage';
import { Test } from './pages/administrator/Test';
import { FeedPage } from './pages/public/FeedPage';
import { PeoplePage } from './pages/public/PeoplePage';
import { SpPage } from './pages/administrator/dataBase/SpPage';
import { PtPage } from './pages/administrator/dataBase/PtPage';
import { AdministrativePage } from './pages/administrator/dataBase/AdministrativePage';
import { ProductPage } from './pages/administrator/dataBase/ProductPage';
import { AreaInstrumentsPage } from './pages/public/AreaInstrumentsPage';
import { UserSettingsPage } from './pages/public/UserSettingsPage';
import { CurrentUserProfilePage } from './pages/public/CurrentUserProfilePage';
import { SupportPage } from './pages/administrator/dataBase/SupportPage';

export const useRoutes = isAuthenticated => {
  if (isAuthenticated) {
    return (
      <Switch>
        {/* USER NAVIGATION */}
        <Route path="/profile"><CurrentUserProfilePage /></Route>
        <Route path="/feed"><FeedPage /></Route>
        <Route path="/people"><PeoplePage /></Route>
        <Route path="/instruments"><AreaInstrumentsPage /></Route>
        <Route path="/settings"><UserSettingsPage /></Route>

        {/* ADMINISTRATOR NAVIGATION */}
        <Route path="/administrativePage"><AdministrativePage /></Route>
        <Route path="/supportPage"><SupportPage /></Route>
        <Route path="/productPage"><ProductPage /></Route>
        <Route path="/spPage"><SpPage /></Route>
        <Route path="/ptPage"><PtPage /></Route>
        <Route path="/test"><Test /></Route>

        {/* Не в SideBar  */}
        <Redirect to="/profile" />
      </Switch>
    )
  }

  return (
    
    <Switch>
      <Route path="/" exact><AuthPage /></Route>
      <Redirect to="/" />
    </Switch>
  )
}
