import React from 'react';
import { useQuery } from '@apollo/client';
import { FETCH_SP_PICKUP_COIL } from './gqlSpPickupCoil';
import Loader from '../../../../components/main/Loader';
import Open from '../../../../components/actions/open';
import OpenDataId from '../../../../components/view/openDataId';
import { UpdateSpPickupCoil } from './updateSpPickupCoil';
import { FETCH_SP_PICKUPS_TYPES_FOR_SPPICKUPTYPEID } from '../spPickupType/gqlSpPickupType';


const GetSpPickupCoil = ({ id }) => {

  const { loading, error, data } = useQuery(FETCH_SP_PICKUP_COIL, {
    variables: { id },
    refetchQueries: [{ query: FETCH_SP_PICKUPS_TYPES_FOR_SPPICKUPTYPEID }]
  });

  if (loading) return <Loader />;
  if (error) return `Error ${error}`;

  return (
    <>
      <Open
        title={"Открыть"}
        dialogTitle={data.getSpPickupCoil.name}
        edit={<UpdateSpPickupCoil
          id={data.getSpPickupCoil._id}
          name={data.getSpPickupCoil.name}
          info={data.getSpPickupCoil.info}
          spPickupType_id={data.getSpPickupCoil.spPickupType_id._id} />}
        Fields={<>
          <OpenDataId
            label={"Наименование"}
            data={data.getSpPickupCoil.name}
          />
          <OpenDataId
            label={"Информация"}
            data={data.getSpPickupCoil.info}
          />
          <OpenDataId
            label={"spPickupType_id"}
            data={data.getSpPickupCoil.spPickupType_id.name}
          />
          <OpenDataId
            label={"Дата создания"}
            data={data.getSpPickupCoil.createdAt}
          />
          <OpenDataId
            label={"Дата изменения"}
            data={data.getSpPickupCoil.updatedAt}
          />
        </>} />
    </>
  )
}

export default GetSpPickupCoil;