import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { ADD_SP_INSTRUMENT_TYPE, FETCH_SP_INSTRUMENTS_TYPES } from './gqlSpInstrumentType';
import Add from '../../../../components/actions/add';
import UploadIcon from '../../../../components/actions/uploadIcon';
import RequiredField from '../../../../components/InputElements/requiredField';

const AddSpInstrumentType = () => {

    const [values, setValues] = useState({
        name: ""
    });

    const [addSpInstrumentType] = useMutation(ADD_SP_INSTRUMENT_TYPE, {

        onError(e) {
            console.log(e.graphQLErrors[0].message)
        },
        variables: values,
        refetchQueries: [{ query: FETCH_SP_INSTRUMENTS_TYPES }]
    });

    const handlerChangeName = (e) => {
        setValues({
            ...values,
            [e.target.name]: e.target.value,
        });
    };

    const formSubmit = (e) => {
        e.preventDefault();
        addSpInstrumentType();
        setValues({ name: "" })
    }
    const [state, setState] = useState({
        fileName: 'Файл не загружен'
    });

    const updateData = (value) => {
        setState({ fileName: value })
    }

    return (
        <Add
            title={"Add SpInstrumentType"}
            ButtonText={"Add SpInstrumentType"}
            dialogTitle={"Add SpInstrumentType"}
            formSubmit={formSubmit}
            Fields={<>
                <UploadIcon
                    label="instrumentTypeIcon"
                    value={values.instrumentTypeIcon}
                    email={values.email}
                    nameData="pr_gslc_i_instrumentType"
                    method={"pr/gslc/icons/instrumentType"}
                    dirIcon={"instrumentType"}
                    updateData={updateData}
                /><br></br>
                <RequiredField
                    label={"instrumentTypeIcon"}
                    value={values.instrumentTypeIcon = state.fileName.toString()}
                    onChange={handlerChangeName}
                    name={"instrumentTypeIcon"}
                /><br></br>
                <RequiredField
                    label={"Наименование"}
                    value={values.name}
                    onChange={handlerChangeName}
                    name={"name"}
                /><br></br>
                <RequiredField
                    label={"info"}
                    value={values.info}
                    onChange={handlerChangeName}
                    name={"info"}
                />
            </>}
        />
    )
}

export default AddSpInstrumentType;