import React from 'react';
import { useQuery } from '@apollo/client';
import { FETCH_PT_PICKUPS } from './gqlPtPickup';
import GetPtPickup from './getPtPickup';
import { UpdatePtPickup } from './updatePtPickup';
import { DeletePtPickup } from './deletePtPickup';
import Loader from '../../../../components/main/Loader';
import CardDatabaseItem from '../../../../components/view/cards/cardDatabaseItem';
import Pagination from '@material-ui/lab/Pagination';

const GetPtPickups = () => {
    const [page, setPage] = React.useState(1);

    const { loading, error, data } = useQuery(FETCH_PT_PICKUPS, {
        variables: {
            sheet: page,
            limit: 10
        },
    });

    if (loading) return <Loader />;
    if (error) return `Error ${error}`;

    const handleChange = (event, value) => {
        setPage(value);
    };

    return (
        <>
            <Pagination count={100} page={page} onChange={handleChange} />

            {data.getPtPickups.map((PtPickup, index) => {
                return (
                    <CardDatabaseItem
                        name={PtPickup.name}
                        nameIcon={PtPickup.pickupIcon}
                        info={PtPickup.info}
                        dirName={"icons/pickup"}
                        actions={
                            <>
                                <GetPtPickup id={PtPickup._id} />
                                <UpdatePtPickup
                                    id={PtPickup._id}
                                    name={PtPickup.name}
                                    info={PtPickup.info}
                                    spInstrument_id={PtPickup.spInstrument_id._id}
                                    spInstrumentType_id={PtPickup.spInstrumentType_id._id}
                                    spNumberOfStrings_id={PtPickup.spNumberOfStrings_id._id}
                                    spPickupPositions_id={PtPickup.spPickupPositions_id._id}
                                    spPickupType_id={PtPickup.spPickupType_id._id}
                                    spPickupMode_id={PtPickup.spPickupMode_id._id}
                                    sourse={PtPickup.sourse}
                                    pickupImage={PtPickup.pickupImage}
                                    pickupIcon={PtPickup.pickupIcon}
                                />
                                <DeletePtPickup id={PtPickup._id} />
                            </>} />
                )
            })}
        </>
    )
}

export default GetPtPickups;