import React, { useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { ADD_SP_FF_FAMILY, FETCH_SP_FF_FAMILYS } from './gqlSpFFFamily';
import { FETCH_SP_BRANDS_FOR_SPBRANDSID } from '../spBrand/gqlSpBrand';
import Loader from '../../../../components/main/Loader';
import Add from '../../../../components/actions/add';
import RequiredField from '../../../../components/InputElements/requiredField';
import OptionalMultiRowsField from '../../../../components/InputElements/optionalMultiRowsField';
import OptionalField from '../../../../components/InputElements/optionalField';
import RequiredSelect from '../../../../components/InputElements/requiredSelect';


const AddSpFFFamily = () => {

    const [values, setValues] = useState({
        name: ""
    });

    const [addSpFFFamily] = useMutation(ADD_SP_FF_FAMILY, {

        onError(e) {
            console.log(e.graphQLErrors[0].message)
        },
        variables: values,
        refetchQueries: [{ query: FETCH_SP_FF_FAMILYS }]
    });

    const {
        loading: loading_FETCH_SP_BRANDS_FOR_SPBRANDSID,
        error: error_FETCH_SP_BRANDS_FOR_SPBRANDSID,
        data: data_FETCH_SP_BRANDS_FOR_SPBRANDSID } = useQuery(FETCH_SP_BRANDS_FOR_SPBRANDSID, {
            fetchPolicy: "no-cache"
        });

    if (loading_FETCH_SP_BRANDS_FOR_SPBRANDSID) return <Loader />;
    if (error_FETCH_SP_BRANDS_FOR_SPBRANDSID) return `Error ${error_FETCH_SP_BRANDS_FOR_SPBRANDSID}`;

    const handlerChangeName = (e) => {
        setValues({
            ...values,
            [e.target.name]: e.target.value,
        });
    };


    const formSubmit = (e) => {
        e.preventDefault();
        addSpFFFamily();
        setValues({ name: "" })
    }

    return (
        <Add
            title={"Добавление BridgeClass"}
            ButtonText={"Добавить BridgeClass"}
            dialogTitle={"Добавление нового BridgeClass"}
            formSubmit={formSubmit}
            Fields={<>
                <RequiredField
                    label={"Наименование"}
                    value={values.name}
                    onChange={handlerChangeName}
                    name={"name"}
                /><br></br>
                <OptionalMultiRowsField
                    label={"Информация"}
                    value={values.info}
                    onChange={handlerChangeName}
                    name={"info"}
                /><br></br>
                <OptionalField
                    label={"dateOfCreation"}
                    value={values.dateOfCreation}
                    onChange={handlerChangeName}
                    name={"dateOfCreation"}
                /><br></br>
                <RequiredSelect
                    label={"spBrand_id"}
                    name={"spBrand_id"}
                    value={values.spBrand_id}
                    onChange={handlerChangeName}
                    fetch={data_FETCH_SP_BRANDS_FOR_SPBRANDSID.getSpBrands}
                />
            </>} />
    )
}

export default AddSpFFFamily;