import React, { useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { ADD_PT_PICKUP, FETCH_PT_PICKUPS } from './gqlPtPickup';
import { FETCH_SP_INSTRUMENTS_FOR_SPINSTRUMENTID } from '../../sp/spInstrument/gqlSpInstrument';
import { FETCH_SP_INSTRUMENTS_TYPES_FOR_SPINSTRUMENTTYPEID } from '../../sp/spInstrumentType/gqlSpInstrumentType';
import { FETCH_SP_PICKUPS_MODES_FOR_SPPICKUPMODEID } from '../../sp/spPickupMode/gqlSpPickupMode';
import { FETCH_SP_PICKUPS_TYPES_FOR_SPPICKUPTYPEID } from '../../sp/spPickupType/gqlSpPickupType';
import Loader from '../../../../components/main/Loader';
import Add from '../../../../components/actions/add';
import RequiredField from '../../../../components/InputElements/requiredField';
import OptionalMultiRowsField from '../../../../components/InputElements/optionalMultiRowsField';
import RequiredSelect from '../../../../components/InputElements/requiredSelect';
import OptionalField from '../../../../components/InputElements/optionalField';
import UploadIcon from '../../../../components/actions/uploadIcon';
import UploadImage from '../../../../components/actions/uploadImage';
import GetNumbersOfStringsFilter from '../../sp/spNumberOfStrings/getSpNumbersOfStringsFilter';
import GetSpPickupPositionsFilter from '../../sp/spPickupPositions/getSpPickupPositionsFilter';


const AddPtPickup = () => {

    const [values, setValues] = useState({
        name: ""
    });

    const [icon, setIcon] = useState({
        fileName: 'Файл не загружен'
    });

    const [image, setImage] = useState({
        fileName: 'Файл не загружен'
    });

    const [addPtPickup] = useMutation(ADD_PT_PICKUP, {

        onError(e) {
            console.log(e.graphQLErrors[0].message)
        },
        variables: values,
        refetchQueries: [{ query: FETCH_PT_PICKUPS }]
    });

    const {
        loading: loading_FETCH_SP_INSTRUMENTS_FOR_SPINSTRUMENTID,
        error: error_FETCH_SP_INSTRUMENTS_FOR_SPINSTRUMENTID,
        data: data_FETCH_SP_INSTRUMENTS_FOR_SPINSTRUMENTID } = useQuery(FETCH_SP_INSTRUMENTS_FOR_SPINSTRUMENTID, {
            fetchPolicy: "no-cache"
        });
    const {
        loading: loading_FETCH_SP_INSTRUMENTS_TYPES_FOR_SPINSTRUMENTTYPEID,
        error: error_FETCH_SP_INSTRUMENTS_TYPES_FOR_SPINSTRUMENTTYPEID,
        data: data_FETCH_SP_INSTRUMENTS_TYPES_FOR_SPINSTRUMENTTYPEID } = useQuery(FETCH_SP_INSTRUMENTS_TYPES_FOR_SPINSTRUMENTTYPEID, {
            fetchPolicy: "no-cache"
        });
    const {
        loading: loading_FETCH_SP_PICKUPS_MODES_FOR_SPPICKUPMODEID,
        error: error_FETCH_SP_PICKUPS_MODES_FOR_SPPICKUPMODEID,
        data: data_FETCH_SP_PICKUPS_MODES_FOR_SPPICKUPMODEID } = useQuery(FETCH_SP_PICKUPS_MODES_FOR_SPPICKUPMODEID, {
            fetchPolicy: "no-cache"
        });
    const {
        loading: loading_FETCH_SP_PICKUPS_TYPES_FOR_SPPICKUPTYPEID,
        error: error_FETCH_SP_PICKUPS_TYPES_FOR_SPPICKUPTYPEID,
        data: data_FETCH_SP_PICKUPS_TYPES_FOR_SPPICKUPTYPEID } = useQuery(FETCH_SP_PICKUPS_TYPES_FOR_SPPICKUPTYPEID, {
            fetchPolicy: "no-cache"
        });

    if (loading_FETCH_SP_INSTRUMENTS_FOR_SPINSTRUMENTID) return <Loader />;
    if (error_FETCH_SP_INSTRUMENTS_FOR_SPINSTRUMENTID) return `Error ${error_FETCH_SP_INSTRUMENTS_FOR_SPINSTRUMENTID}`;

    if (loading_FETCH_SP_INSTRUMENTS_TYPES_FOR_SPINSTRUMENTTYPEID) return <Loader />;
    if (error_FETCH_SP_INSTRUMENTS_TYPES_FOR_SPINSTRUMENTTYPEID) return `Error ${error_FETCH_SP_INSTRUMENTS_TYPES_FOR_SPINSTRUMENTTYPEID}`;

    if (loading_FETCH_SP_PICKUPS_MODES_FOR_SPPICKUPMODEID) return <Loader />;
    if (error_FETCH_SP_PICKUPS_MODES_FOR_SPPICKUPMODEID) return `Error ${error_FETCH_SP_PICKUPS_MODES_FOR_SPPICKUPMODEID}`;

    if (loading_FETCH_SP_PICKUPS_TYPES_FOR_SPPICKUPTYPEID) return <Loader />;
    if (error_FETCH_SP_PICKUPS_TYPES_FOR_SPPICKUPTYPEID) return `Error ${error_FETCH_SP_PICKUPS_TYPES_FOR_SPPICKUPTYPEID}`;

    const handlerChangeName = (e) => {
        setValues({
            ...values,
            [e.target.name]: e.target.value,
        });
    };

    const formSubmit = (e) => {
        e.preventDefault();
        addPtPickup();
        setValues({ name: "" })
    }

    const updateData = (value) => {
        setIcon({ fileName: value })
    }

    const updateDataImage = (value) => {
        setImage({ fileName: value })
    }

    const filterInstrument_id = values.spInstrument_id
    const filterInstrumentType_id = values.spInstrumentType_id

    return (
        <Add
            title={"Добавление PtPickup"}
            ButtonText={"Добавить PtPickup"}
            dialogTitle={"Добавление нового PtPickup"}
            formSubmit={formSubmit}
            Fields={<>
                <UploadIcon
                    label="pickupIcon"
                    value={values.pickupIcon}
                    // email={values.email}
                    nameData="pr_gslc_i_pickup"
                    method={"pr/gslc/icons/pickup"}
                    dirIcon={"pickup"}
                    updateData={updateData}
                /><br></br>
                <RequiredField
                    label={"pickupIcon"}
                    value={values.pickupIcon = icon.fileName.toString()}
                    onChange={handlerChangeName}
                    name={"pickupIcon"}
                /><br></br>
                <UploadImage
                    label="pickupImage"
                    value={values.pickupImage}
                    // email={values.email}
                    nameData="pr_gslc_img_pickup"
                    method={"pr/gslc/images/pickup"}
                    dirIcon={"pickup"}
                    updateDataImage={updateDataImage}
                /><br></br>
                <RequiredField
                    label={"pickupImage"}
                    value={values.pickupImage = image.fileName.toString()}
                    onChange={handlerChangeName}
                    name={"pickupImage"}
                /><br></br>
                <RequiredField
                    label={"name"}
                    value={values.name}
                    onChange={handlerChangeName}
                    name={"name"}
                /><br></br>
                <OptionalMultiRowsField
                    label="Дополнительная информация"
                    name="info"
                    value={values.info}
                    onChange={handlerChangeName}
                    rows={10}
                /><br></br>
                <RequiredSelect
                    label={"Инструмент"}
                    name={"spInstrument_id"}
                    value={values.spInstrument_id}
                    onChange={handlerChangeName}
                    fetch={data_FETCH_SP_INSTRUMENTS_FOR_SPINSTRUMENTID.getSpInstruments}
                /><br></br>
                <RequiredSelect
                    label={"Инструмент"}
                    name={"spInstrumentType_id"}
                    value={values.spInstrumentType_id}
                    onChange={handlerChangeName}
                    fetch={data_FETCH_SP_INSTRUMENTS_TYPES_FOR_SPINSTRUMENTTYPEID.getSpInstrumentsTypes}
                /><br></br>
                <GetNumbersOfStringsFilter
                    label={"spNumberOfStrings_id"}
                    name={"spNumberOfStrings_id"}
                    value={values.spNumberOfStrings_id}
                    onChange={handlerChangeName}
                    spInstrument_id={filterInstrument_id}
                    spInstrumentType_id={filterInstrumentType_id}
                /><br></br>
                <GetSpPickupPositionsFilter
                    label={"spPickupPositions_id"}
                    name={"spPickupPositions_id"}
                    value={values.spPickupPositions_id}
                    onChange={handlerChangeName}
                    spInstrument_id={filterInstrument_id}
                    spInstrumentType_id={filterInstrumentType_id}
                /> <br></br>
                <RequiredSelect
                    label={"spPickupMode_id"}
                    name={"spPickupMode_id"}
                    value={values.spPickupMode_id}
                    onChange={handlerChangeName}
                    fetch={data_FETCH_SP_PICKUPS_MODES_FOR_SPPICKUPMODEID.getSpPickupsModes}
                /> <br></br>
                <RequiredSelect
                    label={"spPickupType_id"}
                    name={"spPickupType_id"}
                    value={values.spPickupType_id}
                    onChange={handlerChangeName}
                    fetch={data_FETCH_SP_PICKUPS_TYPES_FOR_SPPICKUPTYPEID.getSpPickupsTypes}
                /><br></br>
                <OptionalField
                    label={"sourse"}
                    value={values.sourse}
                    onChange={handlerChangeName}
                    name={"sourse"}
                />
            </>} />

    )

}

export default AddPtPickup;