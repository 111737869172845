import React from 'react';
import { useMutation } from '@apollo/client';
import { DELETE_SP_NUMBER_OF_FRETS, FETCH_SP_NUMBERS_OF_FRETS } from './gqlSpNumberOfFrets';
import { Delete } from '../../../../components/actions/delete';


export const DeleteSpNumberOfFrets = ({ id }) => {
  const [deleteSpNumberOfFrets] = useMutation(DELETE_SP_NUMBER_OF_FRETS, {

    onError(e) {
      console.log(e.graphQLErrors[0].message)
    },
    variables: { id },
    refetchQueries: [{ query: FETCH_SP_NUMBERS_OF_FRETS }]
  });

  const onDelete = () => {
    deleteSpNumberOfFrets()
  }
  return (
    <Delete onDelete={onDelete} />
  )
};