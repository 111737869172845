import { gql } from "@apollo/client";

export const FETCH_PT_PICKGUARDS = gql`
query getPtPickguards($sheet: Int, $limit: Int){
    getPtPickguards(sheet: $sheet, limit: $limit){
        _id,
        name,
        info,
        ptFormFactor_id {
            _id
        },
        pickguardImage,
        pickguardIcon,
        createdAt,
        updatedAt
    }
}
`;

export const FETCH_PT_PICKGUARD = gql`
    query getPtPickguard($id: ID) {
        getPtPickguard(_id: $id) {
            _id,
            name,
            info,
            ptFormFactor_id {
                _id,
                name
            },
            pickguardImage,
            pickguardIcon,
            createdAt,
            updatedAt
        }
    }
`;

export const ADD_PT_PICKGUARD = gql`
    mutation addPtPickguard(
        $name: String, 
        $info: String,
        $ptFormFactor_id: ID,
        $pickguardImage: String,
        $pickguardIcon: String) {
            addPtPickguard(
                name: $name, 
                info: $info,
                ptFormFactor_id: $ptFormFactor_id,
                pickguardImage: $pickguardImage,
                pickguardIcon: $pickguardIcon) {
                    _id
                }      
        }
`;

export const DELETE_PT_PICKGUARD = gql`
    mutation deletePtPickguard($id: ID) {
        deletePtPickguard(_id: $id) {
            _id
    }
}
`;

export const UPDATE_PT_PICKGUARD = gql`
    mutation updatePtPickguard(
        $id: ID, 
        $name: String, 
        $info: String,
        $ptFormFactor_id: ID,
        $pickguardImage: String,
        $pickguardIcon: String) {
            updatePtPickguard(
                _id: $id, 
                name: $name, 
                info: $info,
                ptFormFactor_id: $ptFormFactor_id,
                pickguardImage: $pickguardImage,
                pickguardIcon: $pickguardIcon) {
                    _id
                }
        }
`;

export const FETCH_PT_PICKGUARDS_FOR_PTPICKGUARDSID = gql`
{
    getPtPickguards{
        _id,
        name 
    }
}
`;

export const FETCH_PT_PICKGUARD_FILTER = gql`
    query getPtPickguardFilter(
        $ptFormFactor_id: String) {
            getPtPickguardFilter(
                ptFormFactor_id: $ptFormFactor_id) {
                    _id,
                    name
                }
        }
`;