import { gql } from "@apollo/client";


export const FETCH_USERS_POSTS = gql`
{
    getUsersPosts{
        postText
        _id
        updatedAt
        createdAt
        user_id{
            _id
            
        } 
        }
    }
`;

export const DELETE_USER_POST = gql`
    mutation deleteUserPost($id: ID) 
    {
  deleteUserPost(_id: $id) {
      _id
    }
 }
`;

export const ADD_USER_POST = gql`
    mutation addUserPost($postText: String, $user_id: ID!) 
    {
      addUserPost(postText: $postText, user_id: $user_id) {
          _id
          postText
          user_id{
                 _id
             }
               }
} 
`;