import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { FETCH_SP_COUNTRYS, UPDATE_SP_COUNTRY } from './gqlSpCountry';
import Update from '../../../../components/actions/update';
import RequiredField from '../../../../components/InputElements/requiredField';
import OptionalMultiRowsField from '../../../../components/InputElements/optionalMultiRowsField';


export const UpdateSpCountry = ({ id, name }) => {
    const [values, setValues] = useState({
        name
    });

    const [updateSpCountry] = useMutation(UPDATE_SP_COUNTRY, {

        onError(e) {
            console.log(e.graphQLErrors[0].message)
        },
        variables: { id, name: values.name },
        refetchQueries: [{ query: FETCH_SP_COUNTRYS }]
    })

    const handlerChangeName = (e) => {
        setValues({
            ...values,
            [e.target.name]: e.target.value
        })
    }

    const formSubmit = (e) => {
        e.preventDefault();
        updateSpCountry();
    }

    return (
        <Update
            title={"Изменение Country"}
            ButtonText={"Изменить Country"}
            dialogTitle={"Изменение Country"}
            formSubmit={formSubmit}
            Fields={<>
                <RequiredField
                    label={"Наименование"}
                    value={values.name}
                    onChange={handlerChangeName}
                    name={"name"}
                />
                <br></br>
                <OptionalMultiRowsField
                    label={"Информация"}
                    value={values.info}
                    onChange={handlerChangeName}
                    name={"info"}
                />
            </>} />
    )
}