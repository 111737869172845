import React, { useContext } from 'react';
import { AuthContext } from '../../context/AuthContext';
import { useQuery } from '@apollo/client';
import { FETCH_CURRENT_USER } from './gqlCurrentUser';
import GetUsersPosts from './userPost/getUsersPosts';
import AddUserPost from './userPost/addUserPost';
import Loader from '../../components/main/Loader';
import { CardMedia, Container } from '@material-ui/core';
import userCanvas from '../../assets/images/userCanvas.png';


export const GetCurrentUserProfilePage = () => {

  const { userId } = useContext(AuthContext)

  const { loading, error, data } = useQuery(FETCH_CURRENT_USER, {
    variables: { id: userId },
  });

  if (loading) return <Loader />;
  if (error) return `Error ${error}`;

  const imgOnServer = `http://localhost:5000/public/dataUser/${data.getUser.email}/canvas/${data.getUser.canvas}`;

  return (
    <>
      <Container>
        {data.getUser.canvas !== "Информация не заполнена" || ""
          ? <CardMedia
            component="img"
            image={imgOnServer}>
          </CardMedia>
          : <CardMedia
            component="img"
            image={userCanvas}>
          </CardMedia>
        }
        <AddUserPost userId={userId} />
        <GetUsersPosts userId={userId} />
      </Container>
    </>
  )
}