import React, { useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { FETCH_AREAS, UPDATE_AREA } from './gqlArea';
import { FETCH_USERS_ROLES_FOR_USERROLEID } from '../userRole/gqlUserRole';
import Loader from '../../../components/main/Loader';
import RequiredField from '../../../components/InputElements/requiredField';
import OptionalMultiRowsField from '../../../components/InputElements/optionalMultiRowsField';
import RequiredSelect from '../../../components/InputElements/requiredSelect';
import Update from '../../../components/actions/update';

export const UpdateArea = ({
    id,
    name,
    info,
    userRole_id }) => {

    const [values, setValues] = useState({
        name,
        info,
        userRole_id
    });

    const [updateArea] = useMutation(UPDATE_AREA, {

        onError(e) {
            console.log(e.graphQLErrors[0].message)
        },
        variables: {
            id,
            name: values.name,
            info: values.info,
            userRole_id: values.userRole_id
        },
        refetchQueries: [{ query: FETCH_AREAS }]
    })

    const { loading, error, data } = useQuery(FETCH_USERS_ROLES_FOR_USERROLEID);

    if (loading) return <Loader />;
    if (error) return `Error ${error}`;

    const handlerChangeName = (e) => {
        setValues({
            ...values,
            [e.target.name]: e.target.value
        })
    }

    const formSubmit = (e) => {
        e.preventDefault();
        updateArea();
    }

    return (
        <Update
            title={"Изменение проблемы"}
            ButtonText={"Изменить проблему"}
            dialogTitle={"Изменение проблемы"}
            formSubmit={formSubmit}
            Fields={<>
                <RequiredField
                    label={"name"}
                    value={values.name}
                    onChange={handlerChangeName}
                    name={"name"}
                />
                <br></br>
                <OptionalMultiRowsField
                    label="Дополнительная информация"
                    name="info"
                    value={values.info}
                    onChange={handlerChangeName}
                    rows={10}
                />
                <RequiredSelect
                    label={"Роли"}
                    name={"userRole_id"}
                    value={values.userRole_id}
                    onChange={handlerChangeName}
                    fetch={data.getUsersRoles}
                />
            </>} />
    )
}