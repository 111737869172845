import React from 'react';
import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 300,
    },
}));

const RequiredSelectEmail = ({ label, name, value, onChange, fetch }) => {
    const classes = useStyles();

    const error = value === "";
    return (
        <FormControl>
            <InputLabel htmlFor="age-native-simple">{label}</InputLabel>
            <Select className={classes.formControl}
                id="standard-basic"
                label={label}
                type="text"
                name={name}
                value={value}
                onChange={onChange}
                helperText={error ? "Обязательное поле" : ""}
                error={error}
                defaultValue = ""
            >
                {fetch.map(categories => {

                    return (
                        <MenuItem value={categories._id}>
                            {categories.email}
                        </MenuItem>
                    )
                })}
            </Select>
        </FormControl>
    )

}

export default RequiredSelectEmail;