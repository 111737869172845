import React from 'react';
import { useQuery } from '@apollo/client';
import Loader from '../../../../components/main/Loader';
import Open from '../../../../components/actions/open';
import OpenDataId from '../../../../components/view/openDataId';
import { FETCH_SP_STATUS_APPEAL } from './gqlSpStatusAppeal';
import { UpdateSpStatusAppeal } from './updateSpStatusAppeal';


const GetSpStatusAppeal = ({ id }) => {

  const { loading, error, data } = useQuery(FETCH_SP_STATUS_APPEAL, {
    variables: { id }
  });

  if (loading) return <Loader />;
  if (error) return `Error ${error}`;

  return (
    <>
      <Open
        title={"Открыть"}
        dialogTitle={data.getSpStatusAppeal.name}
        edit={<UpdateSpStatusAppeal
          id={data.getSpStatusAppeal._id}
          name={data.getSpStatusAppeal.name}
          info={data.getSpStatusAppeal.info} />}
        Fields={<>
          <OpenDataId
            label={"Наименование"}
            data={data.getSpStatusAppeal.name}
          />
          <OpenDataId
            label={"Информация"}
            data={data.getSpStatusAppeal.info}
          />
          <OpenDataId
            label={"Дата создания"}
            data={data.getSpStatusAppeal.createdAt}
          />
          <OpenDataId
            label={"Дата изменения"}
            data={data.getSpStatusAppeal.updatedAt}
          />
        </>} />
    </>
  )
}

export default GetSpStatusAppeal;