import React from 'react';
import { GetCurrentUserProfilePage } from '../../requests/users/getCurrentUserProfilePage';


export const CurrentUserProfilePage = () => {
  return (
    <>
      <GetCurrentUserProfilePage />
    </>  
  )
}