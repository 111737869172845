import React, { useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Tooltip } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';

const Update = ({ title, dialogTitle, formSubmit, Fields }) => {

    const [open, setOpen] = useState(false)

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            <Tooltip
                title={title}
                arrow>
                <IconButton onClick={() => setOpen(true)}> <EditIcon/></IconButton>
            </Tooltip>

            <Dialog maxWidth='xl' open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <form onSubmit={formSubmit}>
                    <DialogTitle id="form-dialog-title">{dialogTitle}</DialogTitle>
                    <DialogContent>

                        {Fields}

                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} color="primary">
                            Cancel
          </Button>
                        <Button type="Submit" onClick={handleClose} color="secondary">
                            Save
          </Button>
                    </DialogActions>
                </form>
            </Dialog>
        </>
    )

}

export default Update;