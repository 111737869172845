import React, { useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { FETCH_PR_GSL_CARDS, UPDATE_PR_GSL_CARD } from './gqlPrGslCard';
import Update from '../../../components/actions/update';
import RequiredField from '../../../components/InputElements/requiredField';
import RequiredSelect from '../../../components/InputElements/requiredSelect';
import UniversCheckbox from '../../../components/InputElements/universCheckbox';
import Loader from '../../../components/main/Loader';
import { FETCH_SP_INSTRUMENTS_FOR_SPINSTRUMENTID } from '../sp/spInstrument/gqlSpInstrument';
import { FETCH_SP_INSTRUMENTS_TYPES_FOR_SPINSTRUMENTTYPEID } from '../sp/spInstrumentType/gqlSpInstrumentType';
import { FETCH_SP_BRANDS_FOR_SPBRANDSID } from '../sp/spBrand/gqlSpBrand';
import { FETCH_SP_COUNTRYS_FOR_SPCOUNTRYID } from '../sp/spCountry/gqlSpCountry';
import { FETCH_SP_NECK_TYPES_FOR_SPNECKTYPESID } from '../sp/spNeckType/gqlSpNeckType';
import { FETCH_SP_MATERIALS_FOR_SP_MATERIALSID } from '../sp/spMaterial/gqlSpMaterial';
import { FETCH_USERS_FOR_USERSID } from '../../users/gqlCurrentUser';
import RequiredSelectEmail from '../../../components/InputElements/requiredSelectEmail';
import { Divider } from '@material-ui/core';
import GetNumbersOfStringsFilter from '../sp/spNumberOfStrings/getSpNumbersOfStringsFilter';
import GetNumbersOfFretsFilter from '../sp/spNumberOfFrets/getSpNumbersOfFretsFilter';
import GetPtPickupFilter from '../pt/ptPickup/getPtPickupFilter';
import GetSpPickupPositionsFilter from '../sp/spPickupPositions/getSpPickupPositionsFilter';
import GetPtHeadstockFilter from '../pt/ptHeadstock/getPtHeadstockFilter';
import GetPtPickguardFilter from '../pt/ptPickguard/getPtPickguardFilter';
import GetPtFormFactorFilter from '../pt/ptFormFactor/getPtFormFactorFilter';
import GetPtBridgeFilter from '../pt/ptBridge/getPtBridgeFilter';
import GetSpMensuraFilter from '../sp/spMensura/getSpMensuraFilter';
import GetPtNeckFilter from '../pt/ptNeck/getPtNeckFilter';
import { FETCH_SP_PICKUPS_MODES_FOR_SPPICKUPMODEID } from '../sp/spPickupMode/gqlSpPickupMode';
import { FETCH_SP_PICKUPS_TYPES_FOR_SPPICKUPTYPEID } from '../sp/spPickupType/gqlSpPickupType';

export const UpdateGslCard = ({
    id, name, original, foto, userSeller_id, sellerContact, price, dateOfPurchase,
    // # generalInfo: String
    spInstrument_id, spInstrumentType_id, spBrand_id, model, spCountry_id, spNeckType_id,
    spNumberOfStrings_id, spNumberOfFrets_id, spMensura_id,
    // # bridgeInfo: String
    ptBridge_id, spBrandBridge_id, bridgeModel, spCountryBridge_id,
    // # formFactorInfo: String
    ptFormFactor_id, overlay, spMaterialFormFactor_id, spMaterialFormFactorOverlay_id, pickguard, ptPickguard_id,
    // # headstockInfo: String
    ptHeadstock_id, ptNeck_id, spMaterialNeck_id, spMaterialNeckOverlay_id,
    // # electronicInfo: String
    switchPositions,

    piezo, spBrandPiezo_id, modelPiezo,
    positionBridge, spPickupPositions_id_bridge, spPickupMode_id_bridge, ptPickupBridge_id, spBrandBridgePickup_id, modelBridgePickup,
    positionMiddle, spPickupPositions_id_middle, spPickupMode_id_middle, ptPickupMiddle_id, spBrandMiddlePickup_id, modelMiddlePickup,
    positionNeck, spPickupPositions_id_neck, ptPickupNeck_id, spPickupMode_id_neck, spBrandNeckPickup_id, modelNeckPickup, }) => {

    const [state, setState] = React.useState({
        original: original,
        overlay: overlay,
        pickguard: pickguard,
        piezo: piezo,
        positionBridge: positionBridge,
        positionMiddle: positionMiddle,
        positionNeck: positionNeck,
    });

    const [values, setValues] = useState({
        name, original, foto, userSeller_id, sellerContact, price, dateOfPurchase,
        // # generalInfo: String
        spInstrument_id, spInstrumentType_id, spBrand_id, model, spCountry_id, spNeckType_id,
        spNumberOfStrings_id, spNumberOfFrets_id, spMensura_id,
        // # bridgeInfo: String
        ptBridge_id, spBrandBridge_id, bridgeModel, spCountryBridge_id,
        // # formFactorInfo: String
        ptFormFactor_id, overlay, spMaterialFormFactor_id, spMaterialFormFactorOverlay_id, pickguard, ptPickguard_id,
        // # headstockInfo: String
        ptHeadstock_id, ptNeck_id, spMaterialNeck_id, spMaterialNeckOverlay_id,
        // # electronicInfo: String
        switchPositions,

        piezo, spBrandPiezo_id, modelPiezo,
        positionBridge, spPickupPositions_id_bridge, spPickupMode_id_bridge, ptPickupBridge_id, spBrandBridgePickup_id, modelBridgePickup,
        positionMiddle, spPickupPositions_id_middle, spPickupMode_id_middle, ptPickupMiddle_id, spBrandMiddlePickup_id, modelMiddlePickup,
        positionNeck, spPickupPositions_id_neck, spPickupMode_id_neck, ptPickupNeck_id, spBrandNeckPickup_id, modelNeckPickup,
    });

    const [updatePrGslCard] = useMutation(UPDATE_PR_GSL_CARD, {

        onError(e) {
            console.log(e.graphQLErrors[0].message)
        },
        variables: {
            id, name: values.name, original: state.original, foto: values.foto, userSeller_id: values.userSeller_id, sellerContact: values.sellerContact,
            price: values.price, dateOfPurchase: values.dateOfPurchase,
            // # generalInfo: String
            spInstrument_id: values.spInstrument_id, spInstrumentType_id: values.spInstrumentType_id,
            spBrand_id: values.spBrand_id, model: values.model, spCountry_id: values.spCountry_id, spNeckType_id: values.spNeckType_id,
            spNumberOfStrings_id: values.spNumberOfStrings_id, spNumberOfFrets_id: values.spNumberOfFrets_id, spMensura_id: values.spMensura_id,
            // # bridgeInfo: String
            ptBridge_id: values.ptBridge_id, spBrandBridge_id: values.spBrandBridge_id, bridgeModel: values.bridgeModel, spCountryBridge_id: values.spCountryBridge_id,
            // # formFactorInfo: String
            ptFormFactor_id: values.ptFormFactor_id, overlay: state.overlay, spMaterialFormFactor_id: values.spMaterialFormFactor_id,
            spMaterialFormFactorOverlay_id: values.spMaterialFormFactorOverlay_id, pickguard: state.pickguard, ptPickguard_id: values.ptPickguard_id,
            // # headstockInfo: String
            ptHeadstock_id: values.ptHeadstock_id, ptNeck_id: values.ptNeck_id, spMaterialNeck_id: values.spMaterialNeck_id,
            spMaterialNeckOverlay_id: values.spMaterialNeckOverlay_id,
            // # electronicInfo: String
            switchPositions: values.switchPositions,

            piezo: state.piezo, spBrandPiezo_id: values.spBrandPiezo_id, modelPiezo: values.modelPiezo,
            positionBridge: state.positionBridge, spPickupPositions_id_bridge: values.spPickupPositions_id_bridge,
            spPickupMode_id_bridge: values.spPickupMode_id_bridge, ptPickupBridge_id: values.ptPickupBridge_id, spBrandBridgePickup_id: values.spBrandBridgePickup_id, modelBridgePickup: values.modelBridgePickup,
            positionMiddle: state.positionMiddle, spPickupMode_id_middle: values.spPickupMode_id_middle, ptPickupMiddle_id: values.ptPickupMiddle_id, spBrandMiddlePickup_id: values.spBrandMiddlePickup_id, modelMiddlePickup: values.modelMiddlePickup,
            positionNeck: state.positionNeck, spPickupMode_id_neck: values.spPickupMode_id_neck, ptPickupNeck_id: values.ptPickupNeck_id, spBrandNeckPickup_id: values.spBrandNeckPickup_id, modelNeckPickup: values.modelNeckPickup,

        },
        refetchQueries: [{ query: FETCH_PR_GSL_CARDS }]
    })
    const handleChange = (event) => {
        setState({ ...state, [event.target.name]: event.target.checked });
    };
    const handlerChangeName = (e) => {
        setValues({
            ...values,
            [e.target.name]: e.target.value
        })
    }

    const {
        loading: loading_FETCH_SP_INSTRUMENTS_FOR_SPINSTRUMENTID,
        error: error_FETCH_SP_INSTRUMENTS_FOR_SPINSTRUMENTID,
        data: data_FETCH_SP_INSTRUMENTS_FOR_SPINSTRUMENTID } = useQuery(FETCH_SP_INSTRUMENTS_FOR_SPINSTRUMENTID, {
            fetchPolicy: "no-cache"
        });
    const {
        loading: loading_FETCH_SP_INSTRUMENTS_TYPES_FOR_SPINSTRUMENTTYPEID,
        error: error_FETCH_SP_INSTRUMENTS_TYPES_FOR_SPINSTRUMENTTYPEID,
        data: data_FETCH_SP_INSTRUMENTS_TYPES_FOR_SPINSTRUMENTTYPEID } = useQuery(FETCH_SP_INSTRUMENTS_TYPES_FOR_SPINSTRUMENTTYPEID, {
            fetchPolicy: "no-cache"
        });
    const {
        loading: loading_FETCH_SP_BRANDS_FOR_SPBRANDSID,
        error: error_FETCH_SP_BRANDS_FOR_SPBRANDSID,
        data: data_FETCH_SP_BRANDS_FOR_SPBRANDSID } = useQuery(FETCH_SP_BRANDS_FOR_SPBRANDSID, {
            fetchPolicy: "no-cache"
        });
    const {
        loading: loading_FETCH_SP_COUNTRYS_FOR_SPCOUNTRYID,
        error: error_FETCH_SP_COUNTRYS_FOR_SPCOUNTRYID,
        data: data_FETCH_SP_COUNTRYS_FOR_SPCOUNTRYID } = useQuery(FETCH_SP_COUNTRYS_FOR_SPCOUNTRYID, {
            fetchPolicy: "no-cache"
        });
    const {
        loading: loading_FETCH_SP_NECK_TYPES_FOR_SPNECKTYPESID,
        error: error_FETCH_SP_NECK_TYPES_FOR_SPNECKTYPESID,
        data: data_FETCH_SP_NECK_TYPES_FOR_SPNECKTYPESID } = useQuery(FETCH_SP_NECK_TYPES_FOR_SPNECKTYPESID, {
            fetchPolicy: "no-cache"
        });
    const {
        loading: loading_FETCH_SP_MATERIALS_FOR_SP_MATERIALSID,
        error: error_FETCH_SP_MATERIALS_FOR_SP_MATERIALSID,
        data: data_FETCH_SP_MATERIALS_FOR_SP_MATERIALSID } = useQuery(FETCH_SP_MATERIALS_FOR_SP_MATERIALSID, {
            fetchPolicy: "no-cache"
        });
    const {
        loading: loading_FETCH_USERS_FOR_USERSID,
        error: error_FETCH_USERS_FOR_USERSID,
        data: data_FETCH_USERS_FOR_USERSID } = useQuery(FETCH_USERS_FOR_USERSID, {
            fetchPolicy: "no-cache"
        });

        const {
            loading: loading_FETCH_SP_PICKUPS_MODES_FOR_SPPICKUPMODEID,
            error: error_FETCH_SP_PICKUPS_MODES_FOR_SPPICKUPMODEID,
            data: data_FETCH_SP_PICKUPS_MODES_FOR_SPPICKUPMODEID } = useQuery(FETCH_SP_PICKUPS_MODES_FOR_SPPICKUPMODEID, {
                fetchPolicy: "no-cache"
            });
        const {
            loading: loading_FETCH_SP_PICKUPS_TYPES_FOR_SPPICKUPTYPEID,
            error: error_FETCH_SP_PICKUPS_TYPES_FOR_SPPICKUPTYPEID,
            data: data_FETCH_SP_PICKUPS_TYPES_FOR_SPPICKUPTYPEID } = useQuery(FETCH_SP_PICKUPS_TYPES_FOR_SPPICKUPTYPEID, {
                fetchPolicy: "no-cache"
            });

    if (loading_FETCH_SP_INSTRUMENTS_FOR_SPINSTRUMENTID) return <Loader />;
    if (error_FETCH_SP_INSTRUMENTS_FOR_SPINSTRUMENTID) return `Error ${error_FETCH_SP_INSTRUMENTS_FOR_SPINSTRUMENTID}`;

    if (loading_FETCH_SP_INSTRUMENTS_TYPES_FOR_SPINSTRUMENTTYPEID) return <Loader />;
    if (error_FETCH_SP_INSTRUMENTS_TYPES_FOR_SPINSTRUMENTTYPEID) return `Error ${error_FETCH_SP_INSTRUMENTS_TYPES_FOR_SPINSTRUMENTTYPEID}`;

    if (loading_FETCH_SP_BRANDS_FOR_SPBRANDSID) return <Loader />;
    if (error_FETCH_SP_BRANDS_FOR_SPBRANDSID) return `Error ${error_FETCH_SP_BRANDS_FOR_SPBRANDSID}`;

    if (loading_FETCH_SP_COUNTRYS_FOR_SPCOUNTRYID) return <Loader />;
    if (error_FETCH_SP_COUNTRYS_FOR_SPCOUNTRYID) return `Error ${error_FETCH_SP_COUNTRYS_FOR_SPCOUNTRYID}`;

    if (loading_FETCH_SP_NECK_TYPES_FOR_SPNECKTYPESID) return <Loader />;
    if (error_FETCH_SP_NECK_TYPES_FOR_SPNECKTYPESID) return `Error ${error_FETCH_SP_NECK_TYPES_FOR_SPNECKTYPESID}`;

    if (loading_FETCH_SP_MATERIALS_FOR_SP_MATERIALSID) return <Loader />;
    if (error_FETCH_SP_MATERIALS_FOR_SP_MATERIALSID) return `Error ${error_FETCH_SP_MATERIALS_FOR_SP_MATERIALSID}`;

    if (loading_FETCH_USERS_FOR_USERSID) return <Loader />;
    if (error_FETCH_USERS_FOR_USERSID) return `Error ${error_FETCH_USERS_FOR_USERSID}`;

    if (loading_FETCH_SP_PICKUPS_MODES_FOR_SPPICKUPMODEID) return <Loader />;
    if (error_FETCH_SP_PICKUPS_MODES_FOR_SPPICKUPMODEID) return `Error ${error_FETCH_SP_PICKUPS_MODES_FOR_SPPICKUPMODEID}`;

    if (loading_FETCH_SP_PICKUPS_TYPES_FOR_SPPICKUPTYPEID) return <Loader />;
    if (error_FETCH_SP_PICKUPS_TYPES_FOR_SPPICKUPTYPEID) return `Error ${error_FETCH_SP_PICKUPS_TYPES_FOR_SPPICKUPTYPEID}`;

    const formSubmit = (e) => {
        e.preventDefault();
        updatePrGslCard();
    }

    return (
        <Update
            title={"Изменение GslCard"}
            ButtonText={"Изменить GslCard"}
            dialogTitle={"Изменение GslCard"}
            formSubmit={formSubmit}
            Fields={<>
                 <RequiredField
                    label={"name"}
                    value={values.name}
                    onChange={handlerChangeName}
                    name={"name"}
                />
                <RequiredField
                    label={"foto"}
                    value={values.foto}
                    onChange={handlerChangeName}
                    name={"foto"}
                /> <br></br>
                <RequiredSelectEmail
                    label={"Продавец"}
                    name={"userSeller_id"}
                    value={values.userSeller_id}
                    onChange={handlerChangeName}
                    fetch={data_FETCH_USERS_FOR_USERSID.getUsers}
                />
                <RequiredField
                    label={"userSeller_id"}
                    value={values.userSeller_id}
                    onChange={handlerChangeName}
                    name={"userSeller_id"}
                /> <br></br>
                <RequiredField
                    label={"Контакт продавца"}
                    value={values.sellerContact}
                    onChange={handlerChangeName}
                    name={"sellerContact"}
                />
                <RequiredField
                    label={"Цена"}
                    value={values.price}
                    onChange={handlerChangeName}
                    name={"price"}
                /> <br></br>
                <RequiredField
                    label={"Дата покупки"}
                    value={values.dateOfPurchase}
                    onChange={handlerChangeName}
                    name={"dateOfPurchase"}
                />
                <UniversCheckbox
                    label={"Оригинал?"}
                    name="original"
                    checked={state.original}
                    onChange={handleChange}
                    value={values.original = state.original}
                />

                <br></br> <br></br> <Divider /> <br></br> <br></br>

                <RequiredSelect
                    label={"Инструмент"}
                    name={"spInstrument_id"}
                    value={values.spInstrument_id}
                    onChange={handlerChangeName}
                    fetch={data_FETCH_SP_INSTRUMENTS_FOR_SPINSTRUMENTID.getSpInstruments}
                />
                <RequiredSelect
                    label={"Тип инструмента"}
                    name={"spInstrumentType_id"}
                    value={values.spInstrumentType_id}
                    onChange={handlerChangeName}
                    fetch={data_FETCH_SP_INSTRUMENTS_TYPES_FOR_SPINSTRUMENTTYPEID.getSpInstrumentsTypes}
                /> <br></br>
                <RequiredSelect
                    label={"Бренд"}
                    name={"spBrand_id"}
                    value={values.spBrand_id}
                    onChange={handlerChangeName}
                    fetch={data_FETCH_SP_BRANDS_FOR_SPBRANDSID.getSpBrands}
                />
                <RequiredField
                    label={"Модель"}
                    value={values.model}
                    onChange={handlerChangeName}
                    name={"model"}
                /> <br></br>
                <RequiredSelect
                    label={"Производство"}
                    name={"spCountry_id"}
                    value={values.spCountry_id}
                    onChange={handlerChangeName}
                    fetch={data_FETCH_SP_COUNTRYS_FOR_SPCOUNTRYID.getSpCountrys}
                />
                <RequiredSelect
                    label={"Крепление грифа"}
                    name={"spNeckType_id"}
                    value={values.spNeckType_id}
                    onChange={handlerChangeName}
                    fetch={data_FETCH_SP_NECK_TYPES_FOR_SPNECKTYPESID.getSpNeckTypes}
                /> <br></br>
                <GetNumbersOfStringsFilter
                    label={"Количество струн"}
                    name={"spNumberOfStrings_id"}
                    value={values.spNumberOfStrings_id}
                    onChange={handlerChangeName}
                    spInstrument_id={values.spInstrument_id}
                    spInstrumentType_id={values.spInstrumentType_id}
                /> <br></br>
                <GetNumbersOfFretsFilter
                    label={"Количество ладов"}
                    name={"spNumberOfFrets_id"}
                    value={values.spNumberOfFrets_id}
                    onChange={handlerChangeName}
                    spInstrument_id={values.spInstrument_id}
                    spInstrumentType_id={values.spInstrumentType_id}
                    spNumberOfStrings_id={values.spNumberOfStrings_id}
                /> <br></br>
                <GetSpMensuraFilter
                    label={"Мензура"}
                    name={"spMensura_id"}
                    value={values.spMensura_id}
                    onChange={handlerChangeName}
                    spInstrument_id={values.spInstrument_id}
                    spInstrumentType_id={values.spInstrumentType_id}
                    spNumberOfStrings_id={values.spNumberOfStrings_id}
                    spNumberOfFrets_id={values.spNumberOfFrets_id}
                />
                <GetPtBridgeFilter
                    label={"Бридж"}
                    name={"ptBridge_id"}
                    value={values.ptBridge_id}
                    onChange={handlerChangeName}
                    spInstrument_id={values.spInstrument_id}
                    spInstrumentType_id={values.spInstrumentType_id}
                    spNumberOfStrings_id={values.spNumberOfStrings_id}
                /><br></br>
                <RequiredSelect
                    label={"Бренд"}
                    name={"spBrandBridge_id"}
                    value={values.spBrandBridge_id}
                    onChange={handlerChangeName}
                    fetch={data_FETCH_SP_BRANDS_FOR_SPBRANDSID.getSpBrands}
                />
                <RequiredField
                    label={"Модель"}
                    value={values.bridgeModel}
                    onChange={handlerChangeName}
                    name={"bridgeModel"}
                /> <br></br>
                <RequiredSelect
                    label={"Производство"}
                    name={"spCountryBridge_id"}
                    value={values.spCountryBridge_id}
                    onChange={handlerChangeName}
                    fetch={data_FETCH_SP_COUNTRYS_FOR_SPCOUNTRYID.getSpCountrys}
                />

                <br></br> <br></br> <Divider /> <br></br> <br></br>

                <GetPtFormFactorFilter
                    label={"Формфактор"}
                    name={"ptFormFactor_id"}
                    value={values.ptFormFactor_id}
                    onChange={handlerChangeName}
                    spInstrument_id={values.spInstrument_id}
                    spInstrumentType_id={values.spInstrumentType_id}
                />
                <RequiredSelect
                    label={"Материал"}
                    name={"spMaterialFormFactor_id"}
                    value={values.spMaterialFormFactor_id}
                    onChange={handlerChangeName}
                    fetch={data_FETCH_SP_MATERIALS_FOR_SP_MATERIALSID.getSpMaterials}
                />
                <UniversCheckbox
                    checked={state.overlay}
                    onChange={handleChange}
                    value={values.overlay = state.overlay}
                    label={"Есть накладка?"}
                    name="overlay"
                /> <br></br>
                {!values.overlay ? true :
                    <RequiredSelect
                        label={"Материал накладки"}
                        name={"spMaterialFormFactorOverlay_id"}
                        value={values.spMaterialFormFactorOverlay_id}
                        onChange={handlerChangeName}
                        fetch={data_FETCH_SP_MATERIALS_FOR_SP_MATERIALSID.getSpMaterials}
                    />
                }

                <UniversCheckbox
                    checked={state.pickguard}
                    onChange={handleChange}
                    value={values.pickguard = state.pickguard}
                    label={"pickguard?"}
                    name="pickguard"
                />
                {!values.pickguard ? true :
                    <GetPtPickguardFilter
                        label={"ptPickguard_id"}
                        name={"ptPickguard_id"}
                        value={values.ptPickguard_id}
                        onChange={handlerChangeName}
                        ptFormFactor_id={values.ptFormFactor_id}
                    />}<br></br>
                <GetPtHeadstockFilter
                    label={"Перо грифа"}
                    name={"ptHeadstock_id"}
                    value={values.ptHeadstock_id}
                    onChange={handlerChangeName}
                    spInstrument_id={values.spInstrument_id}
                    spInstrumentType_id={values.spInstrumentType_id}
                    spNumberOfStrings_id={values.spNumberOfStrings_id}
                /><br></br>
                <GetPtNeckFilter
                    label={"Гриф"}
                    name={"ptNeck_id"}
                    value={values.ptNeck_id}
                    onChange={handlerChangeName}
                    spInstrument_id={values.spInstrument_id}
                    spInstrumentType_id={values.spInstrumentType_id}
                    spNumberOfStrings_id={values.spNumberOfStrings_id}
                    spNumberOfFrets_id={values.spNumberOfFrets_id}
                /><br></br>
                <RequiredSelect
                    label={"Материал"}
                    name={"spMaterialNeck_id"}
                    value={values.spMaterialNeck_id}
                    onChange={handlerChangeName}
                    fetch={data_FETCH_SP_MATERIALS_FOR_SP_MATERIALSID.getSpMaterials}
                />
                <RequiredSelect
                    label={"Материал накладки"}
                    name={"spMaterialNeckOverlay_id"}
                    value={values.spMaterialNeckOverlay_id}
                    onChange={handlerChangeName}
                    fetch={data_FETCH_SP_MATERIALS_FOR_SP_MATERIALSID.getSpMaterials}
                />

                <br></br> <br></br> <Divider /> <br></br> <br></br>

                <RequiredField
                    label={"Количество позиций переключателя звукоснимателей"}
                    name={"switchPositions"}
                    value={values.switchPositions}
                    onChange={handlerChangeName}
                /> <br></br>
                {/* piezo: "", spBrandPiezo_id: "", modelPiezo: "", */}
                <UniversCheckbox
                    checked={state.piezo}
                    onChange={handleChange}
                    value={values.piezo = state.piezo}
                    label={"Есть пьезо?"}
                    name="piezo"
                />
                {!values.piezo ? true :
                    <>
                        <RequiredSelect
                            label={"Бренд"}
                            name={"spBrandPiezo_id"}
                            value={values.spBrandPiezo_id}
                            onChange={handlerChangeName}
                            fetch={data_FETCH_SP_BRANDS_FOR_SPBRANDSID.getSpBrands}
                        />
                        <RequiredField
                            label={"Модель"}
                            value={values.modelPiezo}
                            onChange={handlerChangeName}
                            name={"modelPiezo"}
                        />
                    </>}

                <br></br> <br></br>

                <UniversCheckbox
                    checked={state.positionBridge}
                    onChange={handleChange}
                    value={values.positionBridge = state.positionBridge}
                    label={"positionBridge?"}
                    name="positionBridge"
                /><br></br>
                {!values.positionBridge ? true :
                    <>
                        <GetSpPickupPositionsFilter
                            label={"Позиция звукоснимателя"}
                            name={"spPickupPositions_id_bridge"}
                            value={values.spPickupPositions_id_bridge}
                            onChange={handlerChangeName}
                            spInstrument_id={values.spInstrument_id}
                            spInstrumentType_id={values.spInstrumentType_id}
                        /><br></br>
                        <RequiredSelect
                            label={"Режим звукоснимателя"}
                            name={"spPickupMode_id_bridge"}
                            value={values.spPickupMode_id_bridge}
                            onChange={handlerChangeName}
                            fetch={data_FETCH_SP_PICKUPS_MODES_FOR_SPPICKUPMODEID.getSpPickupsModes}
                        />
                        <RequiredSelect
                            label={"Тип звукоснимателя"}
                            name={"spPickupType_id_bridge"}
                            value={values.spPickupType_id_bridge}
                            onChange={handlerChangeName}
                            fetch={data_FETCH_SP_PICKUPS_TYPES_FOR_SPPICKUPTYPEID.getSpPickupsTypes}
                        />
                        <GetPtPickupFilter
                            label={"ptPickupBridge_id"}
                            name={"ptPickupBridge_id"}
                            value={values.ptPickupBridge_id}
                            onChange={handlerChangeName}
                            spInstrument_id={values.spInstrument_id}
                            spInstrumentType_id={values.spInstrumentType_id}
                            spNumberOfStrings_id={values.spNumberOfStrings_id}
                            spPickupPositions_id={values.spPickupPositions_id_bridge}
                            spPickupType_id={values.spPickupType_id_bridge}
                            spPickupMode_id={values.spPickupMode_id_bridge}
                        /><br></br>
                        <RequiredSelect
                            label={"spBrandBridgePickup_id"}
                            name={"spBrandBridgePickup_id"}
                            value={values.spBrandBridgePickup_id}
                            onChange={handlerChangeName}
                            fetch={data_FETCH_SP_BRANDS_FOR_SPBRANDSID.getSpBrands}
                        />
                        <RequiredField
                            label={"modelBridgePickup"}
                            value={values.modelBridgePickup}
                            onChange={handlerChangeName}
                            name={"modelBridgePickup"}
                        />
                    </>}

                <br></br> <br></br>

                <UniversCheckbox
                    checked={state.positionMiddle}
                    onChange={handleChange}
                    value={values.positionMiddle = state.positionMiddle}
                    label={"positionMiddle?"}
                    name="positionMiddle"
                />
                {!values.positionMiddle ? true :
                    <>
                        <GetSpPickupPositionsFilter
                            label={"spPickupPositions_id_middle"}
                            name={"spPickupPositions_id_middle"}
                            value={values.spPickupPositions_id_middle}
                            onChange={handlerChangeName}
                            spInstrument_id={values.spInstrument_id}
                            spInstrumentType_id={values.spInstrumentType_id}
                        /><br></br>
                        <RequiredSelect
                            label={"spPickupMode_id_middle"}
                            name={"spPickupMode_id_middle"}
                            value={values.spPickupMode_id_middle}
                            onChange={handlerChangeName}
                            fetch={data_FETCH_SP_PICKUPS_MODES_FOR_SPPICKUPMODEID.getSpPickupsModes}
                        />
                        <RequiredSelect
                            label={"spPickupType_id_middle"}
                            name={"spPickupType_id_middle"}
                            value={values.spPickupType_id_middle}
                            onChange={handlerChangeName}
                            fetch={data_FETCH_SP_PICKUPS_TYPES_FOR_SPPICKUPTYPEID.getSpPickupsTypes}
                        />
                        <GetPtPickupFilter
                            label={"ptPickupMiddle_id"}
                            name={"ptPickupMiddle_id"}
                            value={values.ptPickupMiddle_id}
                            onChange={handlerChangeName}
                            spInstrument_id={values.spInstrument_id}
                            spInstrumentType_id={values.spInstrumentType_id}
                            spNumberOfStrings_id={values.spNumberOfStrings_id}
                            spPickupPositions_id={values.spPickupPositions_id_middle}
                            spPickupType_id={values.spPickupType_id_middle}
                            spPickupMode_id={values.spPickupMode_id_middle}
                        /><br></br>
                        <RequiredSelect
                            label={"spBrandMiddlePickup_id"}
                            name={"spBrandMiddlePickup_id"}
                            value={values.spBrandMiddlePickup_id}
                            onChange={handlerChangeName}
                            fetch={data_FETCH_SP_BRANDS_FOR_SPBRANDSID.getSpBrands}
                        />
                        <RequiredField
                            label={"modelMiddlePickup"}
                            value={values.modelMiddlePickup}
                            onChange={handlerChangeName}
                            name={"modelMiddlePickup"}
                        />
                    </>}

                <br></br> <br></br>

                <UniversCheckbox
                    checked={state.positionNeck}
                    onChange={handleChange}
                    value={values.positionNeck = state.positionNeck}
                    label={"positionNeck?"}
                    name="positionNeck"
                />
                {!values.positionNeck ? true :
                    <>
                        <GetSpPickupPositionsFilter
                            label={"spPickupPositions_id_neck"}
                            name={"spPickupPositions_id_neck"}
                            value={values.spPickupPositions_id_neck}
                            onChange={handlerChangeName}
                            spInstrument_id={values.spInstrument_id}
                            spInstrumentType_id={values.spInstrumentType_id}
                        /><br></br>
                        <RequiredSelect
                            label={"spPickupMode_id_neck"}
                            name={"spPickupMode_id_neck"}
                            value={values.spPickupMode_id_neck}
                            onChange={handlerChangeName}
                            fetch={data_FETCH_SP_PICKUPS_MODES_FOR_SPPICKUPMODEID.getSpPickupsModes}
                        />
                        <RequiredSelect
                            label={"spPickupType_id_neck"}
                            name={"spPickupType_id_neck"}
                            value={values.spPickupType_id_neck}
                            onChange={handlerChangeName}
                            fetch={data_FETCH_SP_PICKUPS_TYPES_FOR_SPPICKUPTYPEID.getSpPickupsTypes}
                        />
                        <GetPtPickupFilter
                            label={"ptPickupNeck_id"}
                            name={"ptPickupNeck_id"}
                            value={values.ptPickupNeck_id}
                            onChange={handlerChangeName}
                            spInstrument_id={values.spInstrument_id}
                            spInstrumentType_id={values.spInstrumentType_id}
                            spNumberOfStrings_id={values.spNumberOfStrings_id}
                            spPickupPositions_id={values.spPickupPositions_id_neck}
                            spPickupType_id={values.spPickupType_id_neck}
                            spPickupMode_id={values.spPickupMode_id_neck}
                        /><br></br>
                        <RequiredSelect
                            label={"spBrandNeckPickup_id"}
                            name={"spBrandNeckPickup_id"}
                            value={values.spBrandNeckPickup_id}
                            onChange={handlerChangeName}
                            fetch={data_FETCH_SP_BRANDS_FOR_SPBRANDSID.getSpBrands}
                        />
                        <RequiredField
                            label={"modelNeckPickup"}
                            value={values.modelNeckPickup}
                            onChange={handlerChangeName}
                            name={"modelNeckPickup"}
                        />
                    </>}
            </>} />
    )
}