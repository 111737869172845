import React, { useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { ADD_SP_MENSURA, FETCH_SP_MENSURAS } from './gqlSpMensura';
import Loader from '../../../../components/main/Loader';
import Add from '../../../../components/actions/add';
import RequiredField from '../../../../components/InputElements/requiredField';
import OptionalMultiRowsField from '../../../../components/InputElements/optionalMultiRowsField';
import GetNumbersOfStringsFilter from '../spNumberOfStrings/getSpNumbersOfStringsFilter';
import { FETCH_SP_INSTRUMENTS_FOR_SPINSTRUMENTID } from '../spInstrument/gqlSpInstrument';
import { FETCH_SP_INSTRUMENTS_TYPES_FOR_SPINSTRUMENTTYPEID } from '../spInstrumentType/gqlSpInstrumentType';
import RequiredSelect from '../../../../components/InputElements/requiredSelect';
import GetNumbersOfFretsFilter from '../spNumberOfFrets/getSpNumbersOfFretsFilter';


const AddSpMensura = () => {

    const [values, setValues] = useState({
        name: ""
    });

    const [addSpMensura] = useMutation(ADD_SP_MENSURA, {

        onError(e) {
            console.log(e.graphQLErrors[0].message)
        },
        variables: values,
        refetchQueries: [{ query: FETCH_SP_MENSURAS }]
    });

    const {
        loading: loading_FETCH_SP_INSTRUMENTS_FOR_SPINSTRUMENTID,
        error: error_FETCH_SP_INSTRUMENTS_FOR_SPINSTRUMENTID,
        data: data_FETCH_SP_INSTRUMENTS_FOR_SPINSTRUMENTID } = useQuery(FETCH_SP_INSTRUMENTS_FOR_SPINSTRUMENTID, {
            fetchPolicy: "no-cache"
        });
    const {
        loading: loading_FETCH_SP_INSTRUMENTS_TYPES_FOR_SPINSTRUMENTTYPEID,
        error: error_FETCH_SP_INSTRUMENTS_TYPES_FOR_SPINSTRUMENTTYPEID,
        data: data_FETCH_SP_INSTRUMENTS_TYPES_FOR_SPINSTRUMENTTYPEID } = useQuery(FETCH_SP_INSTRUMENTS_TYPES_FOR_SPINSTRUMENTTYPEID, {
            fetchPolicy: "no-cache"
        });

    if (loading_FETCH_SP_INSTRUMENTS_FOR_SPINSTRUMENTID) return <Loader />;
    if (error_FETCH_SP_INSTRUMENTS_FOR_SPINSTRUMENTID) return `Error ${error_FETCH_SP_INSTRUMENTS_FOR_SPINSTRUMENTID}`;

    if (loading_FETCH_SP_INSTRUMENTS_TYPES_FOR_SPINSTRUMENTTYPEID) return <Loader />;
    if (error_FETCH_SP_INSTRUMENTS_TYPES_FOR_SPINSTRUMENTTYPEID) return `Error ${error_FETCH_SP_INSTRUMENTS_TYPES_FOR_SPINSTRUMENTTYPEID}`;

    const handlerChangeName = (e) => {
        setValues({
            ...values,
            [e.target.name]: e.target.value,
        });
    };

    const formSubmit = (e) => {
        e.preventDefault();
        addSpMensura();
        setValues({ name: "" })
    }

    return (
        <Add
            title={"Добавление Mensura"}
            ButtonText={"Добавить Mensura"}
            dialogTitle={"Добавление нового Mensura"}
            formSubmit={formSubmit}
            Fields={<>
                <RequiredField
                    label={"name"}
                    value={values.name}
                    onChange={handlerChangeName}
                    name={"name"}
                /> <br></br>
                 <RequiredField
                    label={"nameInch"}
                    value={values.nameInch}
                    onChange={handlerChangeName}
                    name={"nameInch"}
                /> <br></br>
                <OptionalMultiRowsField
                    label={"info"}
                    value={values.info}
                    onChange={handlerChangeName}
                    name={"info"}
                /> <br></br>
                <RequiredSelect
                    label={"Инструмент"}
                    name={"spInstrument_id"}
                    value={values.spInstrument_id}
                    onChange={handlerChangeName}
                    fetch={data_FETCH_SP_INSTRUMENTS_FOR_SPINSTRUMENTID.getSpInstruments}
                /> <br></br>
                <RequiredSelect
                    label={"Тип инструмента"}
                    name={"spInstrumentType_id"}
                    value={values.spInstrumentType_id}
                    onChange={handlerChangeName}
                    fetch={data_FETCH_SP_INSTRUMENTS_TYPES_FOR_SPINSTRUMENTTYPEID.getSpInstrumentsTypes}
                /> <br></br>
                <GetNumbersOfStringsFilter
                    label={"spNumberOfStrings_id"}
                    name={"spNumberOfStrings_id"}
                    value={values.spNumberOfStrings_id}
                    onChange={handlerChangeName}
                    spInstrument_id={values.spInstrument_id}
                    spInstrumentType_id={values.spInstrumentType_id}
                /> <br></br>
                <GetNumbersOfFretsFilter
                    label={"spNumberOfFrets_id"}
                    name={"spNumberOfFrets_id"}
                    value={values.spNumberOfFrets_id}
                    onChange={handlerChangeName}
                    spInstrument_id={values.spInstrument_id}
                    spInstrumentType_id={values.spInstrumentType_id}
                    spNumberOfStrings_id={values.spNumberOfStrings_id}
                /><br></br>
                <OptionalMultiRowsField
                    label={"source"}
                    value={values.source}
                    onChange={handlerChangeName}
                    name={"source"}
                />
            </>} />
    )
}

export default AddSpMensura;