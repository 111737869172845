import React from 'react';
import { Box, Card, CardContent, CardMedia, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
        backgroundColor: theme.palette.background.paper,
    },
    card: {
        width: "100%",
        position: 'relative',
        height: 200
    },
    image: {
        width: "100%",
        position: 'absolute'
    },
}));


const CardGslMini = ({ name,
    ptFormFactor_id, dirName_ptFormFactor_id,
    ptNeck_id, dirName_ptNeck_id,
    ptHeadstock_id, dirName_ptHeadstock_id,
    ptBridge_id, dirName_ptBridge_id,
    ptPickguard_Id, dirName_ptPickguard_Id,
    ptPickupBridge_id, ptPickupMiddle_id, ptPickupNeck_id, dirName_ptPickup,

    info, actions }) => {
    const classes = useStyles();

    const imgOnServer_ptFormFactor_id = `http://localhost:5000/public/dataSystem/product/gslCard/${dirName_ptFormFactor_id}/`;
    const imgOnServer_ptNeck_id = `http://localhost:5000/public/dataSystem/product/gslCard/${dirName_ptNeck_id}/`;
    const imgOnServer_ptHeadstock_id = `http://localhost:5000/public/dataSystem/product/gslCard/${dirName_ptHeadstock_id}/`;
    const imgOnServer_ptBridge_id = `http://localhost:5000/public/dataSystem/product/gslCard/${dirName_ptBridge_id}/`;
    const imgOnServer_ptPickguard_Id = `http://localhost:5000/public/dataSystem/product/gslCard/${dirName_ptPickguard_Id}/`;
    const imgOnServer_ptPickup = `http://localhost:5000/public/dataSystem/product/gslCard/${dirName_ptPickup}/`;

    return (
        <>
            <Card className={classes.card} >
                <CardContent >

                    <Box display="flex" justifyContent="center">
                        <Typography>{name} {info} {actions}</Typography>
                    </Box>

                    <Box display="flex" justifyContent="center" >
                        <CardMedia className={classes.image}
                            component="img"
                            image={`${imgOnServer_ptFormFactor_id}${ptFormFactor_id}`} />
                        <CardMedia className={classes.image}
                            component="img"
                            image={`${imgOnServer_ptNeck_id}${ptNeck_id}`} />
                        <CardMedia className={classes.image}
                            component="img"
                            image={`${imgOnServer_ptHeadstock_id}${ptHeadstock_id}`} />
                        <CardMedia className={classes.image}
                            component="img"
                            image={`${imgOnServer_ptPickguard_Id}${ptPickguard_Id}`} />
                        <CardMedia className={classes.image}
                            component="img"
                            image={`${imgOnServer_ptBridge_id}${ptBridge_id}`} />
                        <CardMedia className={classes.image}
                            component="img"
                            image={`${imgOnServer_ptPickup}${ptPickupBridge_id}`} />
                        <CardMedia className={classes.image}
                            component="img"
                            image={`${imgOnServer_ptPickup}${ptPickupMiddle_id}`} />
                        <CardMedia className={classes.image}
                            component="img"
                            image={`${imgOnServer_ptPickup}${ptPickupNeck_id}`} />
                    </Box>

                </CardContent>
            </Card>
            <br></br>
        </>
    )
}

export default CardGslMini;