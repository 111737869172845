import React, { useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { FETCH_PT_NECKS, UPDATE_PT_NECK } from './gqlPtNeck';
import { FETCH_SP_INSTRUMENTS_FOR_SPINSTRUMENTID } from '../../sp/spInstrument/gqlSpInstrument';
import { FETCH_SP_INSTRUMENTS_TYPES_FOR_SPINSTRUMENTTYPEID } from '../../sp/spInstrumentType/gqlSpInstrumentType';
import Loader from '../../../../components/main/Loader';
import RequiredField from '../../../../components/InputElements/requiredField';
import Update from '../../../../components/actions/update';
import OptionalMultiRowsField from '../../../../components/InputElements/optionalMultiRowsField';
import RequiredSelect from '../../../../components/InputElements/requiredSelect';
import UploadIcon from '../../../../components/actions/uploadIcon';
import UploadImage from '../../../../components/actions/uploadImage';
import GetNumbersOfStringsFilter from '../../sp/spNumberOfStrings/getSpNumbersOfStringsFilter';


export const UpdatePtNeck = ({
    id, name, info, spInstrument_id, spInstrumentType_id, spNumberOfStrings_id, spNumberOfFrets_id,
    neckImage, neckIcon }) => {

    const [values, setValues] = useState({
        name, info, spInstrument_id, spInstrumentType_id, spNumberOfStrings_id, spNumberOfFrets_id,
        neckImage, neckIcon
    });

    const [icon, setIcon] = useState({
        fileName: neckIcon
    });

    const [image, setImage] = useState({
        fileName: neckImage
    });

    const [updatePtNeck] = useMutation(UPDATE_PT_NECK, {

        onError(e) {
            console.log(e.graphQLErrors[0].message)
        },
        variables: {
            id, name: values.name, info: values.info, spInstrument_id: values.spInstrument_id, spInstrumentType_id: values.spInstrumentType_id,
            spNumberOfStrings_id: values.spNumberOfStrings_id, spNumberOfFrets_id: values.spNumberOfFrets_id,
            neckImage: values.neckImage, neckIcon: values.neckIcon
        },
        refetchQueries: [{ query: FETCH_PT_NECKS }]
    })

    const {
        loading: loading_FETCH_SP_INSTRUMENTS_FOR_SPINSTRUMENTID,
        error: error_FETCH_SP_INSTRUMENTS_FOR_SPINSTRUMENTID,
        data: data_FETCH_SP_INSTRUMENTS_FOR_SPINSTRUMENTID } = useQuery(FETCH_SP_INSTRUMENTS_FOR_SPINSTRUMENTID, {
            fetchPolicy: "no-cache"
        });
    const {
        loading: loading_FETCH_SP_INSTRUMENTS_TYPES_FOR_SPINSTRUMENTTYPEID,
        error: error_FETCH_SP_INSTRUMENTS_TYPES_FOR_SPINSTRUMENTTYPEID,
        data: data_FETCH_SP_INSTRUMENTS_TYPES_FOR_SPINSTRUMENTTYPEID } = useQuery(FETCH_SP_INSTRUMENTS_TYPES_FOR_SPINSTRUMENTTYPEID, {
            fetchPolicy: "no-cache"
        });

    if (loading_FETCH_SP_INSTRUMENTS_FOR_SPINSTRUMENTID) return <Loader />;
    if (error_FETCH_SP_INSTRUMENTS_FOR_SPINSTRUMENTID) return `Error ${error_FETCH_SP_INSTRUMENTS_FOR_SPINSTRUMENTID}`;

    if (loading_FETCH_SP_INSTRUMENTS_TYPES_FOR_SPINSTRUMENTTYPEID) return <Loader />;
    if (error_FETCH_SP_INSTRUMENTS_TYPES_FOR_SPINSTRUMENTTYPEID) return `Error ${error_FETCH_SP_INSTRUMENTS_TYPES_FOR_SPINSTRUMENTTYPEID}`;

    const handlerChangeName = (e) => {
        setValues({
            ...values,
            [e.target.name]: e.target.value
        })
    }

    const formSubmit = (e) => {
        e.preventDefault();
        updatePtNeck();
    }

    const updateData = (value) => {
        setIcon({ fileName: value })
    }

    const updateDataImage = (value) => {
        setImage({ fileName: value })
    }

    const filterInstrument_id = values.spInstrument_id
    const filterInstrumentType_id = values.spInstrumentType_id

    return (
        <Update
            title={"Изменение PtNeck"}
            ButtonText={"Изменить PtNeck"}
            dialogTitle={"Изменение PtNeck"}
            formSubmit={formSubmit}
            Fields={<>
                <UploadIcon
                    label="neckIcon"
                    value={values.neckIcon}
                    // email={values.email}
                    nameData="pr_gslc_i_neck"
                    method={"pr/gslc/icons/neck"}
                    dirIcon={"neck"}
                    updateData={updateData}
                /><br></br>
                <RequiredField
                    label={"neckIcon"}
                    value={values.neckIcon = icon.fileName.toString()}
                    onChange={handlerChangeName}
                    name={"neckIcon"}
                /><br></br>
                <UploadImage
                    label="neckImage"
                    value={values.neckImage}
                    // email={values.email}
                    nameData="pr_gslc_img_neck"
                    method={"pr/gslc/images/neck"}
                    dirIcon={"neck"}
                    updateDataImage={updateDataImage}
                /><br></br>
                <RequiredField
                    label={"neckImage"}
                    value={values.neckImage = image.fileName.toString()}
                    onChange={handlerChangeName}
                    name={"neckImage"}
                /><br></br>
                <RequiredField
                    label={"name"}
                    value={values.name}
                    onChange={handlerChangeName}
                    name={"name"}
                />
                <br></br>
                <OptionalMultiRowsField
                    label="Дополнительная информация"
                    name="info"
                    value={values.info}
                    onChange={handlerChangeName}
                    rows={10}
                />
                <br></br>
                <RequiredSelect
                    label={"Инструмент"}
                    name={"spInstrument_id"}
                    value={values.spInstrument_id}
                    onChange={handlerChangeName}
                    fetch={data_FETCH_SP_INSTRUMENTS_FOR_SPINSTRUMENTID.getSpInstruments}
                />
                <br></br>
                <RequiredSelect
                    label={"Инструмент"}
                    name={"spInstrumentType_id"}
                    value={values.spInstrumentType_id}
                    onChange={handlerChangeName}
                    fetch={data_FETCH_SP_INSTRUMENTS_TYPES_FOR_SPINSTRUMENTTYPEID.getSpInstrumentsTypes}
                />
                <br></br>
                <GetNumbersOfStringsFilter
                    label={"spNumberOfStrings_id"}
                    name={"spNumberOfStrings_id"}
                    value={values.spNumberOfStrings_id}
                    onChange={handlerChangeName}
                    spInstrument_id={filterInstrument_id}
                    spInstrumentType_id={filterInstrumentType_id}
                />
            </>} />

    )
}