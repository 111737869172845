import React from 'react';
import GslCard from '../../requests/administrator/gslCard/GslCard';
import GslCardMini from '../../requests/administrator/gslCard/GslCardMini';
import AddPrGslCardForUser from '../../requests/public/addPrGslCardForUser';


export const AreaInstrumentsPage = () => {
  return (
    <>
    <GslCard/>
    <AddPrGslCardForUser/>
    <GslCardMini /> 
    </>
  )
}
