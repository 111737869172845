import React from 'react';
import { useQuery } from '@apollo/client';
import { FETCH_PT_PICKGUARD } from './gqlPtPickguard';
import Loader from '../../../../components/main/Loader';
import Open from '../../../../components/actions/open';
import OpenDataId from '../../../../components/view/openDataId';
import { UpdatePtPickguard } from './updatePtPickguard';
import ViewImage from '../../../../components/view/displayingData/viewImage';
import ViewIcon from '../../../../components/view/displayingData/viewIcon';

const GetPtPickguard = ({ id }) => {

  const { loading, error, data } = useQuery(FETCH_PT_PICKGUARD, {
    variables: { id },
  });

  if (loading) return <Loader />;
  if (error) return `Error ${error}`;

  return (
    <Open
      title={"Открыть"}
      dialogTitle={data.getPtPickguard.name}
      edit={<UpdatePtPickguard
        id={data.getPtPickguard._id}
        name={data.getPtPickguard.name}
        info={data.getPtPickguard.info}
        ptFormFactor_id={data.getPtPickguard.ptFormFactor_id._id}
        pickguardImage={data.getPtPickguard.pickguardImage}
        pickguardIcon={data.getPtPickguard.pickguardIcon}
      />}
      Fields={<>
        <OpenDataId
          label={"ID"}
          data={data.getPtPickguard._id}
        />
        <OpenDataId
          label={"name"}
          data={data.getPtPickguard.name}
        />
        <OpenDataId
          label={"info"}
          data={data.getPtPickguard.info}
        />
        <OpenDataId
          label={"createdAt"}
          data={data.getPtPickguard.createdAt}
        />
        <OpenDataId
          label={"updatedAt"}
          data={data.getPtPickguard.updatedAt}
        />
        <OpenDataId
          label={"ptFormFactor_id.name"}
          data={data.getPtPickguard.ptFormFactor_id.name}
        />
        <OpenDataId
          label={"pickguardImage"}
          data={data.getPtPickguard.pickguardImage}
        />
        <OpenDataId
          label={"pickguardImage"}
          data={data.getPtPickguard.pickguardImage}
        />
        <ViewImage
          image={data.getPtPickguard.pickguardImage}
          dir={"images/pickguard"}
        />
        <OpenDataId
          label={"pickguardIcon"}
          data={data.getPtPickguard.pickguardIcon}
        />
        <ViewIcon
          image={data.getPtPickguard.pickguardIcon}
          dir={"icons/pickguard"}
        />
      </>} />
  )
}

export default GetPtPickguard;