import { gql } from "@apollo/client";

export const FETCH_SP_MATERIALS = gql`
query getSpMaterials($sheet: Int, $limit: Int){
        getSpMaterials(sheet: $sheet, limit: $limit){
            _id,
            name,
            nameEng,
            info,
            materialImage,
            createdAt,
            updatedAt  
        }
    }
`;

export const FETCH_SP_MATERIAL = gql`
    query getSpMaterial($id: ID) {
        getSpMaterial(_id: $id) {
            _id,
            name,
            nameEng,
            info,
            materialImage,
            createdAt,
            updatedAt
        }
    }
`;

export const ADD_SP_MATERIAL = gql`
    mutation addSpMaterial(
        $name: String, 
        $info: String,
        $nameEng: String,
        $materialImage: String) {
        addSpMaterial(
            name: $name, 
            info: $info,
            nameEng: $nameEng,
            materialImage: $materialImage) {
                _id
        }      
    }
`;

export const DELETE_SP_MATERIAL = gql`
    mutation deleteSpMaterial($id: ID) {
        deleteSpMaterial(_id: $id) {
            _id
        }
    }
`;

export const UPDATE_SP_MATERIAL = gql`
    mutation updateSpMaterial(
        $id: ID, 
        $name: String, 
        $info: String,
        $nameEng: String,
        $materialImage: String) {
        updateSpMaterial(
            _id: $id, 
            name: $name, 
            info: $info,
            nameEng: $nameEng,
            materialImage: $materialImage) {
            _id
        }
    }
`;

export const FETCH_SP_MATERIALS_FOR_SP_MATERIALSID = gql`
    {
        getSpMaterials{
            _id
            name  
        }
    }
`;