import React from 'react';
import { useQuery } from '@apollo/client';
import { FETCH_AREAS } from './gqlArea';
import GetArea from './getArea';
import { DeleteArea } from './deleteArea';
import { UpdateArea } from './updateArea';
import Loader from '../../../components/main/Loader';
import { Box, Container } from '@material-ui/core';

const GetAreas = () => {

    const { loading, error, data } = useQuery(FETCH_AREAS);

    if (loading) return <Loader />;
    if (error) return `Error ${error}`;

    return (

        <Container>
            <Box display="flex" justifyContent="center">
                <table>
                    <thead>
                        <tr>
                            <th>№</th>
                            <th>Наименование</th>
                            <th>Информация</th>
                            <th>Дата создания</th>
                            <th>Дата изменения</th>
                            <th>Действия</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.getAreas.map((area, index) => {
                            return (
                                <tr key={area._id}>
                                    <td>{index + 1}</td>
                                    <td>{area.name}</td>
                                    <td>{area.info}</td>
                                    <td>{area.createdAt}</td>
                                    <td>{area.updatedAt}</td>
                                    <td>
                                        <GetArea id={area._id} />
                                        <UpdateArea
                                            id={area._id}
                                            name={area.name}
                                            info={area.info}
                                            userRole_id={area.userRole_id._id}
                                        />
                                        <DeleteArea id={area._id} />
                                    </td>
                                </tr>)
                        })}
                    </tbody>
                </table>
            </Box>
        </Container>

    )
}

export default GetAreas;