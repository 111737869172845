import React, { useState } from 'react';
import { Box, Button, Container, Dialog, DialogActions, DialogContent, DialogTitle, Tooltip } from '@material-ui/core';

const Add = ({ title, ButtonText, dialogTitle, formSubmit, Fields, error }) => {

    const [open, setOpen] = useState(false)

    const handleClose = () => {
        setOpen(false)
    };

    return (

        <Container>
            <Tooltip
                title={title}
                arrow>
                <Button onClick={() => setOpen(true)}>{ButtonText}</Button>
            </Tooltip>

            <Dialog fullScreen open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <form onSubmit={formSubmit}>
                    <DialogActions>
                        <Button onClick={handleClose} color="primary">
                            Отмена
                        </Button>
                        <Button type="Submit" onClick={handleClose} color="secondary">
                            Сохранить
                        </Button>
                    </DialogActions>
                    <DialogTitle id="form-dialog-title">{dialogTitle}</DialogTitle>
                    <DialogContent>
                        <Box display="flex" justifyContent="center" m={1} p={1} >
                            <Box p={1}>
                                {Fields}
                            </Box>
                        </Box>

                    </DialogContent>

                </form>
            </Dialog>
        </Container>

    )

}

export default Add;