import { gql } from "@apollo/client";

export const FETCH_PT_HEADSTOCKS = gql`
query getPtHeadstocks($sheet: Int, $limit: Int){
        getPtHeadstocks(sheet: $sheet, limit: $limit){
            _id,
            name,
            info  ,
            spInstrument_id {
                _id
            },
            spInstrumentType_id {
                _id
            },
            spNumberOfStrings_id {
                _id
            },
            sourse,
            headstockImage,
            headstockIcon,
            createdAt,
            updatedAt  
        }
    }
`;

export const FETCH_PT_HEADSTOCK = gql`
    query getPtHeadstock($id: ID) {
        getPtHeadstock(_id: $id) {
        _id,
        name,
        info,
        spInstrument_id {
            _id,
            name
        },
        spInstrumentType_id {
            _id,
            name
        },
        spNumberOfStrings_id {
            _id,
            code
        },
        sourse,
        headstockImage,
        headstockIcon,
        createdAt,
        updatedAt
    }
}
`;

export const ADD_PT_HEADSTOCK = gql`
    mutation addPtHeadstock(
        $name: String, 
        $info: String,
        $spInstrument_id: ID,
        $spInstrumentType_id: ID,
        $spNumberOfStrings_id: ID,
        $sourse: String,
        $headstockImage: String,
        $headstockIcon: String) {
            addPtHeadstock(
                name: $name, 
                info: $info,
                spInstrument_id: $spInstrument_id,
                spInstrumentType_id: $spInstrumentType_id,
                spNumberOfStrings_id: $spNumberOfStrings_id,
                sourse: $sourse,
                headstockImage: $headstockImage,
                headstockIcon: $headstockIcon) {
                    _id
                }      
    }
`;

export const DELETE_PT_HEADSTOCK = gql`
    mutation deletePtHeadstock($id: ID) {
        deletePtHeadstock(_id: $id) {
            _id
        }
    }
`;

export const UPDATE_PT_HEADSTOCK = gql`
    mutation updatePtHeadstock(
        $id: ID, 
        $name: String, 
        $info: String,
        $spInstrument_id: ID,
        $spInstrumentType_id: ID,
        $spNumberOfStrings_id: ID,
        $sourse: String,
        $headstockImage: String,
        $headstockIcon: String) {
            updatePtHeadstock(
                _id: $id, 
                name: $name, 
                info: $info,
                spInstrument_id: $spInstrument_id,
                spInstrumentType_id: $spInstrumentType_id,
                spNumberOfStrings_id: $spNumberOfStrings_id,
                sourse: $sourse,
                headstockImage: $headstockImage,
                headstockIcon: $headstockIcon) {
                    _id
                }
    }
`;

export const FETCH_PT_HEADSTOCKS_FOR_PTHEADSTOCKSID = gql`
    {
        getPtHeadstocks{
            _id,
            name
        }
    }
`;

export const FETCH_PT_HEADSTOCK_FILTER = gql`
    query getPtHeadstockFilter(
        $spInstrument_id: String, 
        $spInstrumentType_id: String,
        $spNumberOfStrings_id: String) {
            getPtHeadstockFilter(
                spInstrument_id: $spInstrument_id,
                spInstrumentType_id: $spInstrumentType_id,
                spNumberOfStrings_id: $spNumberOfStrings_id) {
                    _id,
                    name,
                    headstockIcon
                }
        }
`;