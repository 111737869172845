import React from 'react';
import { useMutation } from '@apollo/client';
import { DELETE_PR_GSL_CARD, FETCH_PR_GSL_CARDS } from './gqlPrGslCard';
import { Delete } from '../../../components/actions/delete';


export const DeleteGslCard = ({ id }) => {
  const [deleteSpBrand] = useMutation(DELETE_PR_GSL_CARD, {

    onError(e) {
      console.log(e.graphQLErrors[0].message)
    },
    variables: { id },
    refetchQueries: [{ query: FETCH_PR_GSL_CARDS }]
  });

  const onDelete = () => {
    deleteSpBrand()
  }
  return (
    <Delete
      onDelete={onDelete} />
  )
}