import React, { useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { FETCH_PT_BRIDGES, UPDATE_PT_BRIDGE } from './gqlPtBridge';
import { FETCH_SP_INSTRUMENTS_FOR_SPINSTRUMENTID } from '../../sp/spInstrument/gqlSpInstrument';
import { FETCH_SP_INSTRUMENTS_TYPES_FOR_SPINSTRUMENTTYPEID } from '../../sp/spInstrumentType/gqlSpInstrumentType';
import { FETCH_SP_BRIDGES_CLASSES_FOR_SPBRIDGECLASSID } from '../../sp/spBridgeClass/gqlSpBridgeClass';
import { FETCH_SP_BRIDGES_FAMILYS_FOR_SPBRIDGEFAMILYID } from '../../sp/spBridgeFamily/gqlSpBridgeFamily';
import Loader from '../../../../components/main/Loader';
import Update from '../../../../components/actions/update';
import RequiredField from '../../../../components/InputElements/requiredField';
import OptionalMultiRowsField from '../../../../components/InputElements/optionalMultiRowsField';
import RequiredSelect from '../../../../components/InputElements/requiredSelect';
import UploadIcon from '../../../../components/actions/uploadIcon';
import UploadImage from '../../../../components/actions/uploadImage';
import GetNumbersOfStringsFilter from '../../sp/spNumberOfStrings/getSpNumbersOfStringsFilter';


export const UpdatePtBridge = ({
    id,
    name,
    info,
    spInstrument_id,
    spInstrumentType_id,
    spBridgeClass_id,
    spBridgeFamily_id,
    spNumberOfStrings_id,
    sourse,
    bridgeIcon,
    bridgeImage }) => {

    const [values, setValues] = useState({
        name,
        info,
        spInstrument_id,
        spInstrumentType_id,
        spBridgeClass_id,
        spBridgeFamily_id,
        spNumberOfStrings_id,
        sourse,
        bridgeIcon,
        bridgeImage
    });

    const [state, setState] = useState({
        fileName: bridgeIcon
    });

    const [image, setImage] = useState({
        fileName: bridgeImage
    });

    const [updatePtBridge] = useMutation(UPDATE_PT_BRIDGE, {

        onError(e) {
            console.log(e.graphQLErrors[0].message)
        },
        variables: {
            id,
            name: values.name,
            info: values.info,
            spInstrument_id: values.spInstrument_id,
            spInstrumentType_id: values.spInstrumentType_id,
            spBridgeClass_id: values.spBridgeClass_id,
            spBridgeFamily_id: values.spBridgeFamily_id,
            spNumberOfStrings_id: values.spNumberOfStrings_id,
            sourse: values.sourse,
            bridgeIcon: values.bridgeIcon,
            bridgeImage: values.bridgeImage
        },
        refetchQueries: [{ query: FETCH_PT_BRIDGES }]
    })

    const handlerChangeName = (e) => {
        setValues({
            ...values,
            [e.target.name]: e.target.value
        })
    }

    const formSubmit = (e) => {
        e.preventDefault();
        updatePtBridge();
    }

    const {
        loading: loading_FETCH_SP_INSTRUMENTS_FOR_SPINSTRUMENTID,
        error: error_FETCH_SP_INSTRUMENTS_FOR_SPINSTRUMENTID,
        data: data_FETCH_SP_INSTRUMENTS_FOR_SPINSTRUMENTID } = useQuery(FETCH_SP_INSTRUMENTS_FOR_SPINSTRUMENTID, {
            fetchPolicy: "no-cache"
        });
    const {
        loading: loading_FETCH_SP_INSTRUMENTS_TYPES_FOR_SPINSTRUMENTTYPEID,
        error: error_FETCH_SP_INSTRUMENTS_TYPES_FOR_SPINSTRUMENTTYPEID,
        data: data_FETCH_SP_INSTRUMENTS_TYPES_FOR_SPINSTRUMENTTYPEID } = useQuery(FETCH_SP_INSTRUMENTS_TYPES_FOR_SPINSTRUMENTTYPEID, {
            fetchPolicy: "no-cache"
        });
    const {
        loading: loading_FETCH_SP_BRIDGES_CLASSES_FOR_SPBRIDGECLASSID,
        error: error_FETCH_SP_BRIDGES_CLASSES_FOR_SPBRIDGECLASSID,
        data: data_FETCH_SP_BRIDGES_CLASSES_FOR_SPBRIDGECLASSID } = useQuery(FETCH_SP_BRIDGES_CLASSES_FOR_SPBRIDGECLASSID, {
            fetchPolicy: "no-cache"
        });
    const {
        loading: loading_FETCH_SP_BRIDGES_FAMILYS_FOR_SPBRIDGEFAMILYID,
        error: error_FETCH_SP_BRIDGES_FAMILYS_FOR_SPBRIDGEFAMILYID,
        data: data_FETCH_SP_BRIDGES_FAMILYS_FOR_SPBRIDGEFAMILYID } = useQuery(FETCH_SP_BRIDGES_FAMILYS_FOR_SPBRIDGEFAMILYID, {
            fetchPolicy: "no-cache"
        });

    if (loading_FETCH_SP_INSTRUMENTS_FOR_SPINSTRUMENTID) return <Loader />;
    if (error_FETCH_SP_INSTRUMENTS_FOR_SPINSTRUMENTID) return `Error ${error_FETCH_SP_INSTRUMENTS_FOR_SPINSTRUMENTID}`;

    if (loading_FETCH_SP_INSTRUMENTS_TYPES_FOR_SPINSTRUMENTTYPEID) return <Loader />;
    if (error_FETCH_SP_INSTRUMENTS_TYPES_FOR_SPINSTRUMENTTYPEID) return `Error ${error_FETCH_SP_INSTRUMENTS_TYPES_FOR_SPINSTRUMENTTYPEID}`;

    if (loading_FETCH_SP_BRIDGES_CLASSES_FOR_SPBRIDGECLASSID) return <Loader />;
    if (error_FETCH_SP_BRIDGES_CLASSES_FOR_SPBRIDGECLASSID) return `Error ${error_FETCH_SP_BRIDGES_CLASSES_FOR_SPBRIDGECLASSID}`;

    if (loading_FETCH_SP_BRIDGES_FAMILYS_FOR_SPBRIDGEFAMILYID) return <Loader />;
    if (error_FETCH_SP_BRIDGES_FAMILYS_FOR_SPBRIDGEFAMILYID) return `Error ${error_FETCH_SP_BRIDGES_FAMILYS_FOR_SPBRIDGEFAMILYID}`;

    const updateData = (value) => {
        setState({ fileName: value })
    }

    const updateDataImage = (value) => {
        setImage({ fileName: value })
    }

    const filterInstrument_id = values.spInstrument_id
    const filterInstrumentType_id = values.spInstrumentType_id

    return (
        <Update
            title={"Изменение проблемы"}
            ButtonText={"Изменить проблему"}
            dialogTitle={"Изменение проблемы"}
            formSubmit={formSubmit}
            Fields={<>
                <UploadIcon
                    label="bridgeIcon"
                    value={values.bridgeIcon}
                    // email={values.email}
                    nameData="pr_gslc_i_bridge"
                    method={"pr/gslc/icons/bridge"}
                    dirIcon={"bridge"}
                    updateData={updateData}
                /><br></br>
                <RequiredField
                    label={"bridgeIcon"}
                    value={values.bridgeIcon = state.fileName.toString()}
                    onChange={handlerChangeName}
                    name={"bridgeIcon"}
                /><br></br>
                <UploadImage
                    label="bridgeImage"
                    value={values.bridgeImage}
                    // email={values.email}
                    nameData="pr_gslc_img_bridge"
                    method={"pr/gslc/images/bridge"}
                    dirIcon={"bridge"}
                    updateDataImage={updateDataImage}
                /><br></br>
                <RequiredField
                    label={"bridgeImage"}
                    value={values.bridgeImage = image.fileName.toString()}
                    onChange={handlerChangeName}
                    name={"bridgeImage"}
                /><br></br>
                <RequiredField
                    label={"name"}
                    value={values.name}
                    onChange={handlerChangeName}
                    name={"name"}
                />
                <br></br>
                <OptionalMultiRowsField
                    label="Дополнительная информация"
                    name="info"
                    value={values.info}
                    onChange={handlerChangeName}
                    rows={10}
                />
                <br></br>
                <RequiredSelect
                    label={"Инструмент"}
                    name={"spInstrument_id"}
                    value={values.spInstrumentType_id}
                    onChange={handlerChangeName}
                    fetch={data_FETCH_SP_INSTRUMENTS_FOR_SPINSTRUMENTID.getSpInstruments}
                />
                <br></br>
                <RequiredSelect
                    label={"Инструмент"}
                    name={"spInstrumentType_id"}
                    value={values.spInstrumentType_id}
                    onChange={handlerChangeName}
                    fetch={data_FETCH_SP_INSTRUMENTS_TYPES_FOR_SPINSTRUMENTTYPEID.getSpInstrumentsTypes}
                />
                <br></br>
                <RequiredSelect
                    label={"spBridgeClass_id"}
                    name={"spBridgeClass_id"}
                    value={values.spBridgeClass_id}
                    onChange={handlerChangeName}
                    fetch={data_FETCH_SP_BRIDGES_CLASSES_FOR_SPBRIDGECLASSID.getSpBridgesClasses}
                />
                <br></br>
                <RequiredSelect
                    label={"spBridgeFamily_id"}
                    name={"spBridgeFamily_id"}
                    value={values.spBridgeFamily_id}
                    onChange={handlerChangeName}
                    fetch={data_FETCH_SP_BRIDGES_FAMILYS_FOR_SPBRIDGEFAMILYID.getSpBridgesFamilys}
                />
                <br></br>
                <GetNumbersOfStringsFilter
                    label={"spNumberOfStrings_id"}
                    name={"spNumberOfStrings_id"}
                    value={values.spNumberOfStrings_id}
                    onChange={handlerChangeName}
                    spInstrument_id={filterInstrument_id}
                    spInstrumentType_id={filterInstrumentType_id}
                />
                <br></br>
                <br></br>
                <RequiredField
                    label={"sourse"}
                    value={values.sourse}
                    onChange={handlerChangeName}
                    name={"sourse"}
                />
            </>} />

    )
}