import React from 'react';
import PropTypes from 'prop-types';
import AddSpBrand from '../../../requests/administrator/sp/spBrand/addSpBrand';
import GetSpBrands from '../../../requests/administrator/sp/spBrand/getSpBrands';
import AddSpBridgeClass from '../../../requests/administrator/sp/spBridgeClass/addSpBridgeClass';
import GetSpBridgesClasses from '../../../requests/administrator/sp/spBridgeClass/getSpBridgesClasses';
import AddSpBridgeFamily from '../../../requests/administrator/sp/spBridgeFamily/addSpBridgeFamily';
import GetSpBridgesFamilys from '../../../requests/administrator/sp/spBridgeFamily/getSpBridgesFamilys';
import AddSpCountry from '../../../requests/administrator/sp/spCountry/addSpCountry';
import GetSpCountrys from '../../../requests/administrator/sp/spCountry/getSpCountrys';
import AddSpFFClass from '../../../requests/administrator/sp/spFFClass/addSpFFClass';
import GetSpFFClasses from '../../../requests/administrator/sp/spFFClass/getSpFFClasses';
import AddSpFFFamily from '../../../requests/administrator/sp/spFFFamily/addSpFFFamily';
import GetSpFFFamilys from '../../../requests/administrator/sp/spFFFamily/getSpFFFamilys';
import AddSpInstrument from '../../../requests/administrator/sp/spInstrument/addSpInstrument';
import GetSpInstruments from '../../../requests/administrator/sp/spInstrument/getSpInstruments';
import AddSpInstrumentType from '../../../requests/administrator/sp/spInstrumentType/addSpInstrumentType';
import GetSpInstrumentsTypes from '../../../requests/administrator/sp/spInstrumentType/getSpInstrumentsTypes';
import AddSpMaterial from '../../../requests/administrator/sp/spMaterial/addSpMaterials';
import GetSpMaterials from '../../../requests/administrator/sp/spMaterial/getSpMaterials';
import AddSpMensura from '../../../requests/administrator/sp/spMensura/addSpMensura';
import GetSpMensuras from '../../../requests/administrator/sp/spMensura/getSpMensuras';
import AddSpNumberOfFrets from '../../../requests/administrator/sp/spNumberOfFrets/addSpNumberOfFrets';
import GetSpNumbersOfFrets from '../../../requests/administrator/sp/spNumberOfFrets/getSpNumbersOfFrets';
import AddSpNumberOfStrings from '../../../requests/administrator/sp/spNumberOfStrings/AddSpNumberOfStrings';
import GetSpNumbersOfStrings from '../../../requests/administrator/sp/spNumberOfStrings/getSpNumbersOfStrings';
import AddSpPickupMode from '../../../requests/administrator/sp/spPickupMode/addSpPickupMode';
import GetSpPickupsModes from '../../../requests/administrator/sp/spPickupMode/getSpPickupsModes';
import AddSpPickupPositions from '../../../requests/administrator/sp/spPickupPositions/addSpPickupPositions';
import GetSpPickupsPositions from '../../../requests/administrator/sp/spPickupPositions/getSpPickupsPositions';
import AddSpPickupType from '../../../requests/administrator/sp/spPickupType/addSpPickupType';
import GetSpPickupsTypes from '../../../requests/administrator/sp/spPickupType/getSpPickupsTypes';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AddSpNeckType from '../../../requests/administrator/sp/spNeckType/addSpNeckType';
import GetSpNeckTypes from '../../../requests/administrator/sp/spNeckType/getSpNeckTypes';
import AddSpPickupCoil from '../../../requests/administrator/sp/spPickupCoil/addSpPickupCoil';
import GetSpPickupCoils from '../../../requests/administrator/sp/spPickupCoil/getSpPickupCoils';
import AddSpStatusAppeal from '../../../requests/administrator/sp/spStatusAppeal/addSpStatusAppeal';
import GetSpStatusesAppeals from '../../../requests/administrator/sp/spStatusAppeal/getSpStatusesAppeals';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    height: 850,
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
}));

export const SpPage = () => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Container className={classes.root}>
      <Tabs
        orientation="vertical"
        variant="scrollable"
        value={value}
        onChange={handleChange}
        aria-label="Vertical tabs example"
        className={classes.tabs}
      >
        <Tab label="SpBrand" {...a11yProps(0)} />
        <Tab label="SpBridgeClass" {...a11yProps(1)} />
        <Tab label="SpBridgeFamily" {...a11yProps(2)} />
        <Tab label="SpCountry" {...a11yProps(3)} />
        <Tab label="SpFFClass" {...a11yProps(4)} />
        <Tab label="SpFFFamily" {...a11yProps(5)} />
        <Tab label="SpInstrument" {...a11yProps(6)} />
        <Tab label="SpInstrumentType" {...a11yProps(7)} />
        <Tab label="SpMaterial" {...a11yProps(8)} />
        <Tab label="SpMensura" {...a11yProps(9)} />
        <Tab label="SpNumberOfFrets" {...a11yProps(10)} />
        <Tab label="SpNumberOfStrings" {...a11yProps(11)} />
        <Tab label="SpPickupMode" {...a11yProps(12)} />
        <Tab label="SpPickupPositions" {...a11yProps(13)} />
        <Tab label="SpPickupType" {...a11yProps(14)} />
        <Tab label="SpNeckType" {...a11yProps(15)} />
        <Tab label="SpPickupCoil" {...a11yProps(16)} />
        <Tab label="SpStatusesAppeals" {...a11yProps(17)} />
       
      </Tabs>

      <TabPanel value={value} index={0}>
        <AddSpBrand />
        <GetSpBrands />
      </TabPanel>

      <TabPanel value={value} index={1}>
        <AddSpBridgeClass />
        <GetSpBridgesClasses />
      </TabPanel>

      <TabPanel value={value} index={2}>
        <AddSpBridgeFamily />
        <GetSpBridgesFamilys />
      </TabPanel>

      <TabPanel value={value} index={3}>
        <AddSpCountry />
        <GetSpCountrys />
      </TabPanel>

      <TabPanel value={value} index={4}>
        <AddSpFFClass />
        <GetSpFFClasses />
      </TabPanel>

      <TabPanel value={value} index={5}>
        <AddSpFFFamily />
        <GetSpFFFamilys />
      </TabPanel>

      <TabPanel value={value} index={6}>
        <AddSpInstrument />
        <GetSpInstruments />
      </TabPanel>

      <TabPanel value={value} index={7}>
        <AddSpInstrumentType />
        <GetSpInstrumentsTypes />
      </TabPanel>

      <TabPanel value={value} index={8}>
        <AddSpMaterial />
        <GetSpMaterials />
      </TabPanel>

      <TabPanel value={value} index={9}>
        <AddSpMensura />
        <GetSpMensuras />
      </TabPanel>

      <TabPanel value={value} index={10}>
        <AddSpNumberOfFrets />
        <GetSpNumbersOfFrets />
      </TabPanel>

      <TabPanel value={value} index={11}>
        <AddSpNumberOfStrings />
        <GetSpNumbersOfStrings />
      </TabPanel>

      <TabPanel value={value} index={12}>
        <AddSpPickupMode />
        <GetSpPickupsModes />
      </TabPanel>

      <TabPanel value={value} index={13}>
        <AddSpPickupPositions />
        <GetSpPickupsPositions />
      </TabPanel>

      <TabPanel value={value} index={14}>
        <AddSpPickupType />
        <GetSpPickupsTypes />
      </TabPanel>

      <TabPanel value={value} index={15}>
        <AddSpNeckType />
        <GetSpNeckTypes />
      </TabPanel>

      <TabPanel value={value} index={16}>
        <AddSpPickupCoil />
        <GetSpPickupCoils />
      </TabPanel>

      <TabPanel value={value} index={17}>
        <AddSpStatusAppeal />
        <GetSpStatusesAppeals />
      </TabPanel>

    </Container>
  )
}
