import React from 'react';
import { useQuery } from '@apollo/client';
import Loader from '../../../../components/main/Loader';
import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { FETCH_PT_FORMFACTOR_FILTER } from './gqlPtFormFactor';
import CardDatabaseItemFilter from '../../../../components/view/cards/cardDatabaseItemFIlter';

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 300,
    },
}));

const GetPtFormFactorFilter = ({ label, name, value, onChange, spInstrument_id, spInstrumentType_id }) => {

    const classes = useStyles();
    const { loading, error, data } = useQuery(FETCH_PT_FORMFACTOR_FILTER, {
        variables: { spInstrument_id, spInstrumentType_id }
    });

    if (loading) return <Loader />;
    if (error) return `Error ${error}`;

    return (
        <>
            <FormControl>
                <InputLabel htmlFor="age-native-simple">{label}</InputLabel>
                <Select className={classes.formControl}
                    id="standard-basic"
                    label={label}
                    type="text"
                    name={name}
                    value={value}
                    onChange={onChange}
                    helperText={error ? "Обязательное поле" : ""}
                    error={error}
                    defaultValue = ""
                >
                    {data.getPtFormFactorFilter.map((formFactor, index) => {
                        return (
                            <MenuItem value={formFactor._id}>
                                {formFactor.name}
                                <CardDatabaseItemFilter
                                    nameIcon={formFactor.formfactorIcon}
                                    dirName={"icons/formFactor"}
                                />
                            </MenuItem>
                        )
                    })}

                </Select>
            </FormControl>
        </>
    )
}

export default GetPtFormFactorFilter;