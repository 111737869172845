import React from 'react';
import { useQuery } from '@apollo/client';
import { FETCH_PR_GSL_CARD } from './gqlPrGslCard';
import Loader from '../../../components/main/Loader';
import { Divider } from '@material-ui/core';
import Open from '../../../components/actions/open'
import { UpdateGslCard } from './updatePrGslCard';
import OpenDataId from '../../../components/view/openDataId';

const GetGslCard = ({ id }) => {

  const { loading, error, data } = useQuery(FETCH_PR_GSL_CARD, {
    variables: { id },
    // refetchQueries: [{ query: FETCH_USERS_ROLES_FOR_USERROLEID }]
  });

  if (loading) return <Loader />;
  if (error) return `Error ${error}`;

  return (
    <Open
      title={"Открыть"}
      dialogTitle={data.getPrGslCard.name}
      edit={<UpdateGslCard
        id={data.getPrGslCard._id}
        name={data.getPrGslCard.name}
        original={data.getPrGslCard.original}
        foto={data.getPrGslCard.foto}
        userSeller_id={!data.getPrGslCard.userSeller_id ? null : data.getPrGslCard.userSeller_id._id}
        sellerContact={data.getPrGslCard.sellerContact}
        price={data.getPrGslCard.price}
        dateOfPurchase={data.getPrGslCard.dateOfPurchase}
        // # generalInfo: String
        spInstrument_id={data.getPrGslCard.spInstrument_id._id}
        spIinstrumentType_id={data.getPrGslCard.spInstrumentType_id._id}
        spBrand_id={data.getPrGslCard.spBrand_id._id}
        model={data.getPrGslCard.model}
        spCountry_id={data.getPrGslCard.spCountry_id._id}
        spNeckType_id={data.getPrGslCard.spNeckType_id._id}
        spNumberOfStrings_id={data.getPrGslCard.spNumberOfStrings_id._id}
        spNumberOfFrets_id={data.getPrGslCard.spNumberOfFrets_id._id}
        spMensura_id={data.getPrGslCard.spMensura_id._id}
        // # bridgeInfo: String
        ptBridge_id={data.getPrGslCard.ptBridge_id._id}
        spBrandBridge_id={data.getPrGslCard.spBrandBridge_id._id}
        bridgeModel={data.getPrGslCard.bridgeModel}
        spCountryBridge_id={data.getPrGslCard.spCountryBridge_id._id}
        // # formFactorInfo: String
        ptFormFactor_id={data.getPrGslCard.ptFormFactor_id._id}
        spMaterialFormFactor_id={data.getPrGslCard.spMaterialFormFactor_id._id}
        overlay={data.getPrGslCard.overlay}
        spMaterialFormFactorOverlay_id={!data.getPrGslCard.spMaterialFormFactorOverlay_id ? null : data.getPrGslCard.spMaterialFormFactorOverlay_id._id}
        pickguard={data.getPrGslCard.pickguard}
        ptPickguard_Id={!data.getPrGslCard.ptPickguard_id ? null : data.getPrGslCard.ptPickguard_id._id}
        // # headstockInfo: String
        ptHeadstock_id={data.getPrGslCard.ptHeadstock_id._id}
        ptNeck_id={data.getPrGslCard.ptNeck_id._id}
        spMaterialNeck_id={data.getPrGslCard.spMaterialNeck_id._id}
        spMaterialNeckOverlay_id={data.getPrGslCard.spMaterialNeckOverlay_id._id}
        // # electronicInfo: String
        switchPositions={data.getPrGslCard.switchPositions}

        piezo={data.getPrGslCard.piezo}
        spBrandPiezo_id={!data.getPrGslCard.spBrandPiezo_id ? null : data.getPrGslCard.spBrandPiezo_id._id}
        modelPiezo={data.getPrGslCard.modelPiezo}

        positionBridge={data.getPrGslCard.positionBridge}
        ptPickupBridge_id={data.getPrGslCard.ptPickupBridge_id._id}
        spBrandBridgePickup_id={data.getPrGslCard.spBrandBridgePickup_id._id}
        modelBridgePickup={data.getPrGslCard.modelBridgePickup}

        positionMiddle={data.getPrGslCard.positionMiddle}
        ptPickupMiddle_id={!data.getPrGslCard.ptPickupMiddle_id ? null : data.getPrGslCard.ptPickupMiddle_id._id}
        spBrandMiddlePickup_id={!data.getPrGslCard.spBrandMiddlePickup_id ? null : data.getPrGslCard.spBrandMiddlePickup_id._id}
        modelMiddlePickup={data.getPrGslCard.modelMiddlePickup}

        positionNeck={data.getPrGslCard.positionNeck}
        ptPickupNeck_id={!data.getPrGslCard.ptPickupNeck_id ? null : data.getPrGslCard.ptPickupNeck_id._id}
        spBrandNeckPickup_id={!data.getPrGslCard.spBrandNeckPickup_id ? null : data.getPrGslCard.spBrandNeckPickup_id._id}
        modelNeckPickup={data.getPrGslCard.modelNeckPickup}
      />}
      Fields={<>

        <OpenDataId
          label={"Пользователь"}
          data={!data.getPrGslCard.user_id ? null :data.getPrGslCard.user_id.email}
        />
        <OpenDataId
          label={"Оригинал"}
          data={data.getPrGslCard.original.toString()}
        />
        <OpenDataId
          label={"Фото инструмента"}
          data={data.getPrGslCard.foto}
        />
        <OpenDataId
          label={"Продавец"}
          data={!data.getPrGslCard.userSeller_id ? null : data.getPrGslCard.userSeller_id.email}
        />
        <OpenDataId
          label={"Контакты продавца"}
          data={data.getPrGslCard.sellerContact}
        />
        <OpenDataId
          label={"Цена"}
          data={data.getPrGslCard.price}
        />
        <OpenDataId
          label={"Дата покупки"}
          data={data.getPrGslCard.dateOfPurchase}
        />
        <Divider />
        <OpenDataId
          label={"Инструмент"}
          data={data.getPrGslCard.spInstrument_id.name}
        />
        <OpenDataId
          label={"Тип иструмента"}
          data={data.getPrGslCard.spInstrumentType_id.name}
        />
        {/* generalInfo */}
        <OpenDataId
          label={"Бренд"}
          data={data.getPrGslCard.spBrand_id.name}
        />
        <OpenDataId
          label={"Модель"}
          data={data.getPrGslCard.model}
        />
        <OpenDataId
          label={"Страна"}
          data={data.getPrGslCard.spCountry_id.name}
        />
        <OpenDataId
          label={"Тип крепление грифа"}
          data={data.getPrGslCard.spNeckType_id.name}
        />
        <OpenDataId
          label={"Количество струн"}
          data={data.getPrGslCard.spNumberOfStrings_id.name}
        />
        <OpenDataId
          label={"Количество ладов"}
          data={data.getPrGslCard.spNumberOfFrets_id.name}
        />
        <OpenDataId
          label={"Мензура"}
          data={data.getPrGslCard.spMensura_id.name}
        />
        <Divider />
        {/* bridgeInfo */}
        <OpenDataId
          label={"Бридж"}
          data={data.getPrGslCard.ptBridge_id.name}
        />
        <OpenDataId
          label={"Бренд Бридж"}
          data={data.getPrGslCard.spBrandBridge_id.name}
        />
        <OpenDataId
          label={"Модель Бридж"}
          data={data.getPrGslCard.bridgeModel}
        />
        <OpenDataId
          label={"Производство Бридж"}
          data={data.getPrGslCard.spCountryBridge_id.name}
        />
        <Divider />
        {/* formFactorInfo */}
        <OpenDataId
          label={"ФормФактор"}
          data={data.getPrGslCard.ptFormFactor_id.name}
        />
        <OpenDataId
          label={"Материал деки"}
          data={data.getPrGslCard.spMaterialFormFactor_id.name}
        />

        {!data.getPrGslCard.overlay ? true :
          <>
            <OpenDataId
              label={"Есть накладка?"}
              data={data.getPrGslCard.overlay.toString()}
            />
            <OpenDataId
              label={"Материал накладки"}
              data={data.getPrGslCard.spMaterialFormFactorOverlay_id.name}
            />
          </>}

        {!data.getPrGslCard.pickguard ? true :
          <>
            <OpenDataId
              label={"Есть Pickguard?"}
              data={data.getPrGslCard.pickguard.toString()}
            />
            <OpenDataId
              label={"Pickguard"}
              data={!data.getPrGslCard.ptPickguard_id ? null : data.getPrGslCard.ptPickguard_id.name}
            />
          </>}

        <Divider />
        {/* headstockInfo */}
        <OpenDataId
          label={"Голова грифа"}
          data={data.getPrGslCard.ptHeadstock_id.name}
        />
        <OpenDataId
          label={"Гриф"}
          data={data.getPrGslCard.ptNeck_id.name}
        />
        <OpenDataId
          label={"Материал грифа"}
          data={data.getPrGslCard.spMaterialNeck_id.name}
        />
        <OpenDataId
          label={"Материал накладки грифа"}
          data={data.getPrGslCard.spMaterialNeckOverlay_id.name}
        />
        <Divider />
        {/*  electronicInfo */}
        <OpenDataId
          label={"Количество позиций переключателя звукоснимателей"}
          data={data.getPrGslCard.switchPositions}
        />

        {!data.getPrGslCard.piezo ? true :
          <>
            <OpenDataId
              label={"Есть piezo?"}
              data={data.getPrGslCard.piezo.toString()}
            />
            <OpenDataId
              label={"Бренд piezo?"}
              data={data.getPrGslCard.spBrandPiezo_id.name}
            />
            <OpenDataId
              label={"Модель piezo?"}
              data={data.getPrGslCard.modelPiezo}
            />
          </>}

        {!data.getPrGslCard.positionBridge ? true :
          <>
            <OpenDataId
              label={"Звукосниматель Bridge?"}
              data={data.getPrGslCard.positionBridge.toString()}
            />
            <OpenDataId
              label={"Звукосниматель Bridge"}
              data={data.getPrGslCard.ptPickupBridge_id.name}
            />
            <OpenDataId
              label={"Бренд Bridge"}
              data={data.getPrGslCard.spBrandBridgePickup_id.name}
            />
            <OpenDataId
              label={"Модель Bridge"}
              data={data.getPrGslCard.modelBridgePickup.name}
            />
          </>}

        {!data.getPrGslCard.positionMiddle ? true :
          <>
            <OpenDataId
              label={"Звукосниматель Middle?"}
              data={data.getPrGslCard.positionMiddle.toString()}
            />
            <OpenDataId
              label={"Звукосниматель Middle"}
              data={data.getPrGslCard.ptPickupMiddle_id.name}
            />
            <OpenDataId
              label={"Бренд Middle"}
              data={data.getPrGslCard.spBrandMiddlePickup_id.name}
            />
            <OpenDataId
              label={"Модель Middle"}
              data={data.getPrGslCard.modelMiddlePickup.name}
            />
          </>
        }

        {!data.getPrGslCard.positionNeck ? true :
          <>
        <OpenDataId
          label={"Звукосниматель Neck?"}
          data={data.getPrGslCard.positionNeck.toString()}
        />
        <OpenDataId
          label={"Звукосниматель Neck"}
          data={data.getPrGslCard.ptPickupNeck_id.name}
        />
        <OpenDataId
          label={"Бренд Neck"}
          data={data.getPrGslCard.spBrandNeckPickup_id.name}
        />
        <OpenDataId
          label={"Модель Neck"}
          data={data.getPrGslCard.modelNeckPickup.name}
        />
        </>}
      </>} />
  )
}

export default GetGslCard;