import React from 'react';
import { TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    mail: {
        width: 500,
    },
}));

const OptionalMultiRowsField = ({ label, name, value, onChange, rows }) => {
    const classes = useStyles();

    return (
        <>
            <TextField className={classes.mail}
                id="outlined-multiline-flexible"
                label={label}
                multiline
                rowsMax={rows}
                type="text"
                name={name}
                value={value}
                onChange={onChange}
                variant="outlined"
            >
            </TextField>
        </>
    )

}

export default OptionalMultiRowsField;