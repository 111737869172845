import React from 'react';
import { useQuery } from '@apollo/client';
import { FETCH_SP_INSTRUMENTS } from './gqlSpInstrument';
import GetSpInstrument from './getSpInstrument';
import { DeleteSpInstrument } from './deleteSpInstrument';
import { UpdateSpInstrument } from './updateSpInstrument';
import Loader from '../../../../components/main/Loader';
import CardDatabaseItem from '../../../../components/view/cards/cardDatabaseItem';


const GetSpInstruments = () => {

    const { loading, error, data } = useQuery(FETCH_SP_INSTRUMENTS);

    if (loading) return <Loader />;
    if (error) return `Error ${error}`;

    return (
        <>
            {data.getSpInstruments.map((spInstrument, index) => {
                return (
                    <CardDatabaseItem
                        name={spInstrument.name}
                        nameIcon={spInstrument.instrumentIcon}
                        info={spInstrument.info}
                        dirName={"icons/instrument"}
                        actions={
                            <>
                                <GetSpInstrument id={spInstrument._id} />
                                <UpdateSpInstrument
                                    id={spInstrument._id}
                                    name={spInstrument.name}
                                    info={spInstrument.info}
                                    instrumentIcon={spInstrument.instrumentIcon} />
                                <DeleteSpInstrument id={spInstrument._id} />
                            </>
                        } />
                )
            })}
        </>
    )
}

export default GetSpInstruments;