import React from 'react';
import { useMutation } from '@apollo/client';
import { DELETE_PT_HEADSTOCK, FETCH_PT_HEADSTOCKS } from './gqlPtHeadstock';
import { Delete } from '../../../../components/actions/delete';


export const DeletePtHeadstock = ({ id }) => {
  const [deletePtHeadstock] = useMutation(DELETE_PT_HEADSTOCK, {

    onError(e) {
      console.log(e.graphQLErrors[0].message)
    },
    variables: { id },
    refetchQueries: [{ query: FETCH_PT_HEADSTOCKS }]
  });

  const onDelete = () => {
    deletePtHeadstock()
  }
  return (
    <Delete
      onDelete={onDelete} />
  )
}