import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Card, CardContent, CardMedia, Container } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  card: {
    width: "50%",
    position: 'relative',
    height: 250
  },
  image: {
    width: "100%",
    position: 'absolute'
  },
}));


const ViewIcon = ({ dir, image }) => {
  const classes = useStyles();

  const imgOnServer_dir = `http://localhost:5000/public/dataSystem/product/gslCard/${dir}/`;

  return (
    <>
      <Container >
        <Box display="flex" justifyContent="center" >
          <Card className={classes.card}>
            <CardContent >

              <Box display="flex" justifyContent="center" >
                <CardMedia className={classes.image}
                  component="img"
                  image={`${imgOnServer_dir}${image}`} />
              </Box>
            </CardContent>
          </Card>
        </Box>
      </Container>
      <br></br>
    </>
  )
}

export default ViewIcon;