import React, { useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { ADD_SP_NUMBER_OF_STRINGS, FETCH_SP_NUMBERS_OF_STRINGS } from './gqlSpNumberOfStrings';
import { FETCH_SP_INSTRUMENTS_FOR_SPINSTRUMENTID } from '../spInstrument/gqlSpInstrument';
import { FETCH_SP_INSTRUMENTS_TYPES_FOR_SPINSTRUMENTTYPEID } from '../spInstrumentType/gqlSpInstrumentType';
import Loader from '../../../../components/main/Loader';
import RequiredField from '../../../../components/InputElements/requiredField';
import OptionalMultiRowsField from '../../../../components/InputElements/optionalMultiRowsField';
import RequiredSelect from '../../../../components/InputElements/requiredSelect';
import Add from '../../../../components/actions/add';


const AddSpNumberOfStrings = () => {
    const [values, setValues] = useState({
        name: ""
    });

    const [addSpNumberOfStrings] = useMutation(ADD_SP_NUMBER_OF_STRINGS, {

        onError(e) {
            console.log(e.graphQLErrors[0].message)
        },
        variables: values,
        refetchQueries: [{ query: FETCH_SP_NUMBERS_OF_STRINGS }]
    });

    const { loading: loading_FETCH_SP_INSTRUMENTS_FOR_SPINSTRUMENTID,
        error: error_FETCH_SP_INSTRUMENTS_FOR_SPINSTRUMENTID,
        data: data_FETCH_SP_INSTRUMENTS_FOR_SPINSTRUMENTID } = useQuery(FETCH_SP_INSTRUMENTS_FOR_SPINSTRUMENTID, {
            fetchPolicy: "no-cache"
        });
    const { loading: loading_FETCH_SP_INSTRUMENTS_TYPES_FOR_SPINSTRUMENTTYPEID,
        error: error_FETCH_SP_INSTRUMENTS_TYPES_FOR_SPINSTRUMENTTYPEID,
        data: data_FETCH_SP_INSTRUMENTS_TYPES_FOR_SPINSTRUMENTTYPEID } = useQuery(FETCH_SP_INSTRUMENTS_TYPES_FOR_SPINSTRUMENTTYPEID, {
            fetchPolicy: "no-cache"
        });

    if (loading_FETCH_SP_INSTRUMENTS_FOR_SPINSTRUMENTID) return <Loader />;
    if (error_FETCH_SP_INSTRUMENTS_FOR_SPINSTRUMENTID) return `Error ${error_FETCH_SP_INSTRUMENTS_FOR_SPINSTRUMENTID}`;

    if (loading_FETCH_SP_INSTRUMENTS_TYPES_FOR_SPINSTRUMENTTYPEID) return <Loader />;
    if (error_FETCH_SP_INSTRUMENTS_TYPES_FOR_SPINSTRUMENTTYPEID) return `Error ${error_FETCH_SP_INSTRUMENTS_TYPES_FOR_SPINSTRUMENTTYPEID}`;

    const handlerChangeName = (e) => {
        setValues({
            ...values,
            [e.target.name]: e.target.value,
        });
    };

    const formSubmit = (e) => {
        e.preventDefault();
        addSpNumberOfStrings();
        setValues({ name: "" })
    }

    return (
        <Add
            title={"Добавление NumberOfStrings"}
            ButtonText={"Добавить NumberOfStrings"}
            dialogTitle={"Добавление нового NumberOfStrings"}
            formSubmit={formSubmit}
            Fields={<>
                <RequiredField
                    label={"Наименование"}
                    value={values.name}
                    onChange={handlerChangeName}
                    name={"name"}
                />
                <br></br>
                <OptionalMultiRowsField
                    label={"Информация"}
                    value={values.info}
                    onChange={handlerChangeName}
                    name={"info"}
                />
                <br></br>
                <RequiredSelect
                    label={"Инструмент"}
                    name={"spInstrument_id"}
                    value={values.spInstrument_id}
                    onChange={handlerChangeName}
                    fetch={data_FETCH_SP_INSTRUMENTS_FOR_SPINSTRUMENTID.getSpInstruments}
                />
                <br></br>
                <RequiredSelect
                    label={"Тип инструмента"}
                    name={"spInstrumentType_id"}
                    value={values.spInstrumentType_id}
                    onChange={handlerChangeName}
                    fetch={data_FETCH_SP_INSTRUMENTS_TYPES_FOR_SPINSTRUMENTTYPEID.getSpInstrumentsTypes}
                />
            </>} />
    )
}

export default AddSpNumberOfStrings;