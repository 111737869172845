import React from 'react';
import { useMutation } from '@apollo/client';
import { DELETE_SP_FF_FAMILY, FETCH_SP_FF_FAMILYS } from './gqlSpFFFamily';
import { Delete } from '../../../../components/actions/delete';


export const DeleteSpFFFamily = ({id}) => {
  const [deleteSpFFFamily] = useMutation(DELETE_SP_FF_FAMILY, {
    
    onError(e) {
        console.log(e.graphQLErrors[0].message)
    },
     variables: {id},
     refetchQueries: [{ query: FETCH_SP_FF_FAMILYS }]
});

const onDelete= () => {
  deleteSpFFFamily()
}
  return (
    <Delete onDelete={onDelete} />
  )
}