import { gql } from "@apollo/client";

export const FETCH_SP_COUNTRYS = gql`
query getSpCountrys($sheet: Int, $limit: Int){
        getSpCountrys(sheet: $sheet, limit: $limit){
            _id
            name
            info
            createdAt
            updatedAt  
        }
    }
`;

export const FETCH_SP_COUNTRY = gql`
    query getSpCountry($id: ID) {
        getSpCountry(_id: $id) {
            _id
            name
            info
            createdAt
            updatedAt
        }
    }
`;

export const ADD_SP_COUNTRY = gql`
    mutation addSpCountry($name: String, $info: String) {
        addSpCountry(name: $name, info: $info) {
            _id  
        }      
    }
`;

export const DELETE_SP_COUNTRY = gql`
    mutation deleteSpCountry($id: ID) {
        deleteSpCountry(_id: $id) {
            _id
        }
    }
`;

export const UPDATE_SP_COUNTRY = gql`
    mutation updateSpCountry($id: ID, $name: String, $info: String) {
        updateSpCountry(_id: $id, name: $name, info: $info) {
            _id 
        }
    }
`;

export const FETCH_SP_COUNTRYS_FOR_SPCOUNTRYID = gql`
    {
        getSpCountrys{
            _id
            name 
        }
    }
`;