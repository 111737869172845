import React from 'react';
import { useQuery } from '@apollo/client';
import { FETCH_SP_BRIDGE_CLASS } from './gqlSpBridgeClass';
import Loader from '../../../../components/main/Loader';
import OpenDataId from '../../../../components/view/openDataId';
import Open from '../../../../components/actions/open';
import { UpdateSpBridgeClass } from './updateSpBridgeClass';

const GetSpBridgeClass = ({ id }) => {

  const { loading, error, data } = useQuery(FETCH_SP_BRIDGE_CLASS, {
    variables: { id },
  });

  if (loading) return <Loader />;
  if (error) return `Error ${error}`;

  return (
    <Open
      title={"Открыть"}
      dialogTitle={data.getSpBridgeClass.name}
      edit={<UpdateSpBridgeClass
        id={data.getSpBridgeClass._id}
        name={data.getSpBridgeClass.name}
        info={data.getSpBridgeClass.info} />}
      Fields={<>
        <OpenDataId
          label={"Наименование"}
          data={data.getSpBridgeClass._id}
        />
        <OpenDataId
          label={"Информация"}
          data={data.getSpBridgeClass.info}
        />
        <OpenDataId
          label={"Дата создания"}
          data={data.getSpBridgeClass.createdAt}
        />
        <OpenDataId
          label={"Дата изменения"}
          data={data.getSpBridgeClass.updatedAt}
        />
      </>} />
  )
}

export default GetSpBridgeClass;