import React, { useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { ADD_SP_BRAND, FETCH_SP_BRANDS } from './gqlSpBrand';
import { FETCH_SP_COUNTRYS_FOR_SPCOUNTRYID } from '../spCountry/gqlSpCountry';
import Loader from '../../../../components/main/Loader';
import Add from '../../../../components/actions/add';
import RequiredField from '../../../../components/InputElements/requiredField';
import OptionalMultiRowsField from '../../../../components/InputElements/optionalMultiRowsField';
import RequiredSelect from '../../../../components/InputElements/requiredSelect';

const AddSpBrand = () => {

    const [values, setValues] = useState({
        name: ""
    });

    const [addSpBrand] = useMutation(ADD_SP_BRAND, {

        onError(e) {
        alert(e)
        },
        variables: values,
        refetchQueries: [{ query: FETCH_SP_BRANDS }],
    });

    const { loading, error, data: data_FETCH_SP_COUNTRYS_FOR_SPCOUNTRYID } = useQuery(FETCH_SP_COUNTRYS_FOR_SPCOUNTRYID, {
        fetchPolicy: "no-cache"
    });

    if (loading) return <Loader />;
    if (error) return `Error ${error}`;

    const handlerChangeName = (e) => {
        setValues({
            ...values,
            [e.target.name]: e.target.value,
        });
    };

    const formSubmit = (e) => {
        e.preventDefault();
        addSpBrand();
        setValues({ name: "" })
    }

    return (
        <Add
            title={"Добавление Brand"}
            ButtonText={"Добавить Brand"}
            dialogTitle={"Добавление нового Brand"}
            formSubmit={formSubmit}
            error={error}
            Fields={<>
                <RequiredField
                    label={"Наименование"}
                    value={values.name}
                    onChange={handlerChangeName}
                    name={"name"}
                />
                <br></br>
                <OptionalMultiRowsField
                    label={"Информация"}
                    value={values.info}
                    onChange={handlerChangeName}
                    name={"info"}
                />
                <br></br>
                <RequiredField
                    label={"Дата создания компании"}
                    value={values.dateOfFoundation}
                    onChange={handlerChangeName}
                    name={"dateOfFoundation"}
                />
                <br></br>
                <RequiredField
                    label={"web"}
                    value={values.web}
                    onChange={handlerChangeName}
                    name={"web"}
                />
                <br></br>
                <RequiredSelect
                    label={"Страна производителя"}
                    name={"spCountry_id"}
                    value={values.spCountry_id}
                    onChange={handlerChangeName}
                    fetch={data_FETCH_SP_COUNTRYS_FOR_SPCOUNTRYID.getSpCountrys}
                />
                <br></br>
                <RequiredSelect
                    label={"Страна производства"}
                    name={"spCountryProduction_id"}
                    value={values.spCountryProduction_id}
                    onChange={handlerChangeName}
                    fetch={data_FETCH_SP_COUNTRYS_FOR_SPCOUNTRYID.getSpCountrys}
                />
                <br></br>
            </>} />
    )
}

export default AddSpBrand;