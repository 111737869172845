import React, { useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { ADD_USER_ROLE_FOR_USER, FETCH_CURRENT_USER } from './gqlCurrentUser';
import { FETCH_USERS_ROLES_FOR_USERROLEID } from '../administrator/userRole/gqlUserRole';
import Loader from '../../components/main/Loader';
import Add from '../../components/actions/add';
import RequiredSelect from '../../components/InputElements/requiredSelect';
import RequiredSelectCode from '../../components/InputElements/requiredSelectCode';

export const AddUserRoleForUser = ({ id, userRole_id }) => {
    
    const [values, setValues] = useState({ userRole_id });

    const [addUserRoleForUser] = useMutation(ADD_USER_ROLE_FOR_USER, {

        onError(e) {
            console.log(e.graphQLErrors[0].message)
        },
        variables: { id, userRole_id: values.userRole_id },
        refetchQueries: [{ query: FETCH_CURRENT_USER }]
    })

    const { loading: loading_FETCH_USERS_ROLES_FOR_USERROLEID,
        error: error_FETCH_USERS_ROLES_FOR_USERROLEID,
        data: data_FETCH_USERS_ROLES_FOR_USERROLEID } = useQuery(FETCH_USERS_ROLES_FOR_USERROLEID, {
            fetchPolicy: "no-cache"
        });

    if (loading_FETCH_USERS_ROLES_FOR_USERROLEID) return <Loader />;
    if (error_FETCH_USERS_ROLES_FOR_USERROLEID) return `Error ${error_FETCH_USERS_ROLES_FOR_USERROLEID}`;

    const handlerChangeName = (e) => {
        setValues({
            ...values,
            [e.target.name]: e.target.value
        })
    }

    const formSubmit = (e) => {
        e.preventDefault();
        addUserRoleForUser();
    }

    return (
        <Add
            title={"Добавить роль"}
            ButtonText={"Добавить роль"}
            dialogTitle={"Добавить роль"}
            formSubmit={formSubmit}
            Fields={<>
                <RequiredSelectCode
                    label={"userRole_id"}
                    name={"userRole_id"}
                    value={values.userRole_id}
                    onChange={handlerChangeName}
                    fetch={data_FETCH_USERS_ROLES_FOR_USERROLEID.getUsersRoles}
                />
            </>}
        />
    )
}