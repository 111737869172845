import React from 'react';
import axios from 'axios';
import FormData from 'form-data';

export default function UploadIcon({ email, value, method, nameData, updateData }) {

    const [img, setImg] = React.useState([`${value}`])
    const [icon, setIcon] = React.useState(value)

    const sendFile = React.useCallback(async () => {
        try {
            const data = new FormData()
            data.append(`${nameData}`, img)

            await axios.post(`/api/upload/${method}`, data, {
                headers: {
                    'content-type': 'mulpipart/form-data'
                },
                params: { email }
            })
                .then(res => setIcon(res.data.path))
        } catch (error) { }
    }, [img])

    const file = [img].map((file) => (
        file.name
    ))
    return (
        <>
                <input type="file" onChange={e => setImg(e.target.files[0])}></input>
                <br></br>
                <button onClick={ () => {sendFile(); updateData(file)}}>Загрузить</button>
        </>
    )
}