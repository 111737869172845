import React, { useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { ADD_SP_PICKUP_COIL, FETCH_SP_PICKUP_COILS } from './gqlSpPickupCoil';
import Loader from '../../../../components/main/Loader';
import Add from '../../../../components/actions/add';
import RequiredField from '../../../../components/InputElements/requiredField';
import OptionalMultiRowsField from '../../../../components/InputElements/optionalMultiRowsField';
import RequiredSelect from '../../../../components/InputElements/requiredSelect';
import { FETCH_SP_PICKUPS_TYPES_FOR_SPPICKUPTYPEID } from '../spPickupType/gqlSpPickupType';

const AddSpPickupCoil = () => {

    const [values, setValues] = useState({
        name: ""
    });

    const [addSpPickupCoil] = useMutation(ADD_SP_PICKUP_COIL, {

        onError(e) {
            alert(e)
        },
        variables: values,
        refetchQueries: [{ query: FETCH_SP_PICKUP_COILS }],
    });

    const { loading, error, data } = useQuery(FETCH_SP_PICKUPS_TYPES_FOR_SPPICKUPTYPEID, {
        fetchPolicy: "no-cache"
    });

    if (loading) return <Loader />;
    if (error) return `Error ${error}`;

    const handlerChangeName = (e) => {
        setValues({
            ...values,
            [e.target.name]: e.target.value,
        });
    };

    const formSubmit = (e) => {
        e.preventDefault();
        addSpPickupCoil();
        setValues({ name: "" })
    }

    return (
        <Add
            title={"Добавление SpPickupCoil"}
            ButtonText={"Добавить SpPickupCoil"}
            dialogTitle={"Добавление нового SpPickupCoil"}
            formSubmit={formSubmit}
            error={error}
            Fields={<>
                <RequiredField
                    label={"Наименование"}
                    value={values.name}
                    onChange={handlerChangeName}
                    name={"name"}
                />
                <br></br>
                <OptionalMultiRowsField
                    label={"Информация"}
                    value={values.info}
                    onChange={handlerChangeName}
                    name={"info"}
                />
                <RequiredSelect
                    label={"Тип звукоснимателя"}
                    name={"spPickupType_id"}
                    value={values.spPickupType_id}
                    onChange={handlerChangeName}
                    fetch={data.getSpPickupsTypes}
                />
            </>} />
    )
}

export default AddSpPickupCoil;