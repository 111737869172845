import React from 'react';
import { useQuery } from '@apollo/client';
import { FETCH_PT_NECKS } from './gqlPtNeck';
import GetPtNeck from './getPtNeck';
import { DeletePtNeck } from './deletePtNeck';
import { UpdatePtNeck } from './updatePtNeck';
import Loader from '../../../../components/main/Loader';
import CardDatabaseItem from '../../../../components/view/cards/cardDatabaseItem';
import Pagination from '@material-ui/lab/Pagination';

const GetPtNecks = () => {
    const [page, setPage] = React.useState(1);

    const { loading, error, data } = useQuery(FETCH_PT_NECKS, {
        variables: {
            sheet: page,
            limit: 10
        },
    });

    if (loading) return <Loader />;
    if (error) return `Error ${error}`;

    const handleChange = (event, value) => {
        setPage(value);
    };

    return (
        <>
            <Pagination count={100} page={page} onChange={handleChange} />

            {data.getPtNecks.map((PtNeck, index) => {
                return (
                    <CardDatabaseItem
                        name={PtNeck.name}
                        nameIcon={PtNeck.neckIcon}
                        info={PtNeck.info}
                        dirName={"icons/neck"}
                        actions={
                            <>
                                <GetPtNeck id={PtNeck._id} />
                                <UpdatePtNeck
                                    id={PtNeck._id}
                                    name={PtNeck.name}
                                    info={PtNeck.info}
                                    spInstrument_id={PtNeck.spInstrument_id._id}
                                    spInstrumentType_id={PtNeck.spInstrumentType_id._id}
                                    spNumberOfStrings_id={PtNeck.spNumberOfStrings_id._id}
                                    spNumberOfFrets_id={PtNeck.spNumberOfFrets_id._id}
                                    neckImage={PtNeck.neckImage}
                                    neckIcon={PtNeck.neckIcon}
                                />
                                <DeletePtNeck id={PtNeck._id} />
                            </>} />
                )
            })}
        </>
    )
}

export default GetPtNecks;