import React from 'react';
import { useQuery } from '@apollo/client';
import { FETCH_SP_BRANDS } from './gqlSpBrand';
import GetSpBrand from './getSpBrand';
import { DeleteSpBrand } from './deleteSpBrand';
import { UpdateSpBrand } from './updateSpBrand';
import Loader from '../../../../components/main/Loader';
import { Box, Container } from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';


const GetSpBrands = () => {

    const [page, setPage] = React.useState(1);

    const { loading, error, data } = useQuery(FETCH_SP_BRANDS, {
        variables: {
            sheet: page,
            limit: 10
        },
    });

    if (loading) return <Loader />;
    if (error) return `Error ${error}`;

    const handleChange = (event, value) => {
        setPage(value);
    };

    return (
        <>
            <Pagination count={100} page={page} onChange={handleChange} />
            <Container>
                <Box display="flex" justifyContent="center">
                    <table>
                        <thead>
                            <tr>
                                <th>№</th>
                                <th>Наименование</th>
                                <th>Информация</th>
                                <th>Дата создания</th>
                                <th>Дата изменения</th>
                                <th>Действия</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.getSpBrands.map((SpBrand, index) => {
                                return (
                                    <tr key={SpBrand._id}>
                                        <td>{index + 1}</td>
                                        <td>{SpBrand.name}</td>
                                        <td>{SpBrand.info}</td>
                                        <td>{SpBrand.createdAt}</td>
                                        <td>{SpBrand.updatedAt}</td>
                                        <td>
                                            <GetSpBrand id={SpBrand._id} />
                                            <UpdateSpBrand
                                                id={SpBrand._id}
                                                name={SpBrand.name}
                                                info={SpBrand.info}
                                                dateOfFoundation={SpBrand.dateOfFoundation}
                                                spCountry_id={SpBrand.spCountry_id._id}
                                                spCountryProduction_id={SpBrand.spCountryProduction_id._id}
                                                web={SpBrand.web} />
                                            <DeleteSpBrand id={SpBrand._id} />
                                        </td>
                                    </tr>)
                            })}
                        </tbody>
                    </table>
                </Box>
            </Container>
        </>
    )
}

export default GetSpBrands;