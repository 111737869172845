import React from 'react';
import { useQuery } from '@apollo/client';
import { FETCH_SP_MATERIALS } from './gqlSpMaterial';
import GetSpMaterial from './getSpMaterial';
import { DeleteSpMaterial } from './deleteSpMaterials';
import { UpdateSpMaterial } from './updateSpMaterial';
import Loader from '../../../../components/main/Loader';
import { Box, Container } from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';

const GetSpMaterials = () => {
    const [page, setPage] = React.useState(1);

    const { loading, error, data } = useQuery(FETCH_SP_MATERIALS, {
        variables: {
            sheet: page,
            limit: 10
        },
    });

    if (loading) return <Loader />;
    if (error) return `Error ${error}`;

    const handleChange = (event, value) => {
        setPage(value);
    };

    return (
        <>
        <Pagination count={100} page={page} onChange={handleChange} />
            <Container>
                <Box display="flex" justifyContent="center">
                    <table>
                        <thead>
                            <tr>
                                <th>№</th>
                                <th>Наименование</th>
                                <th>Информация</th>
                                <th>Дата создания</th>
                                <th>Дата изменения</th>
                                <th>Действия</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.getSpMaterials.map((spMaterial, index) => {
                                return (
                                    <tr key={spMaterial._id}>
                                        <td>{index + 1}</td>
                                        <td>{spMaterial.name}</td>
                                        <td>{spMaterial.info}</td>
                                        <td>{spMaterial.createdAt}</td>
                                        <td>{spMaterial.updatedAt}</td>
                                        <td>
                                            <GetSpMaterial id={spMaterial._id} />
                                            <UpdateSpMaterial
                                                id={spMaterial._id}
                                                name={spMaterial.name}
                                                info={spMaterial.info}
                                                nameEng={spMaterial.nameEng}
                                                materialImage={spMaterial.materialImage}
                                            />
                                            <DeleteSpMaterial id={spMaterial._id} />
                                        </td>
                                    </tr>)
                            })}
                        </tbody>
                    </table>
                </Box>
            </Container>
        </>
    )
}

export default GetSpMaterials;