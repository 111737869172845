import { gql } from "@apollo/client";

export const FETCH_PR_GSL_CARDS = gql`
{
    getPrGslCards{
        _id,
        user_id {
            _id email
        },
        name,
        original
        foto
        userSeller_id { _id }
        sellerContact
        price
        dateOfPurchase
            spInstrument_id { _id name }
            spInstrumentType_id { _id name}
            spBrand_id { _id name }
            model,
            spCountry_id { _id }
            spNeckType_id { _id }
            spNumberOfStrings_id { _id name code}
            spNumberOfFrets_id { _id name code}
            spMensura_id { _id } 
                # bridgeInfo: 
                ptBridge_id { _id, bridgeImage }
                spBrandBridge_id { _id }
                bridgeModel,
                spCountryBridge_id { _id } 
                    # formFactorInfo: 
                    ptFormFactor_id { _id formfactorImage}
                    spMaterialFormFactor_id { _id }
                    overlay,
                    spMaterialFormFactorOverlay_id { _id }
                    pickguard,
                    ptPickguard_id { _id pickguardImage} 
                        # headstockInfo: 
                        ptHeadstock_id { _id headstockImage}
                        ptNeck_id { _id neckImage}
                        spMaterialNeck_id { _id }
                        spMaterialNeckOverlay_id { _id } 
                            # electronicInfo: String
                            switchPositions, 
                            piezo,
                            spBrandPiezo_id { _id }
                            modelPiezo, 
                            positionBridge,
                            spPickupPositions_id_bridge {_id},
                            ptPickupBridge_id { _id pickupImage}
                            spBrandBridgePickup_id { _id }
                            modelBridgePickup, 
                            positionMiddle,
                            spPickupPositions_id_middle {_id},
                            ptPickupMiddle_id { _id pickupImage}
                            spBrandMiddlePickup_id { _id }
                            modelMiddlePickup, 
                            positionNeck,
                            spPickupPositions_id_neck {_id},
                            ptPickupNeck_id { _id pickupImage}
                            spBrandNeckPickup_id { _id }
                            modelNeckPickup,
        createdAt,
        updatedAt  
        
    }
}
`;

export const FETCH_PR_GSL_CARD = gql`
query getPrGslCard($id: ID) {
    getPrGslCard(_id: $id) {
        _id,
        user_id { _id email },
        name,
        original,
        foto,
        userSeller_id { _id email },
        sellerContact
        price
        dateOfPurchase
            spInstrument_id { _id name }
            spInstrumentType_id { _id name}
            spBrand_id { _id name }
            model,
            spCountry_id { _id name }
            spNeckType_id { _id name }
            spNumberOfStrings_id { _id name code}
            spNumberOfFrets_id { _id name code}
            spMensura_id { _id name }
                # bridgeInfo: 
                ptBridge_id { _id name bridgeImage }
                spBrandBridge_id { _id name }
                bridgeModel,
                spCountryBridge_id { _id name } 
                    # formFactorInfo: 
                    ptFormFactor_id { _id name formfactorImage}
                    spMaterialFormFactor_id { _id name }
                    overlay,
                    spMaterialFormFactorOverlay_id { _id name }
                    pickguard,
                    ptPickguard_id { _id name } 
                        # headstockInfo: 
                        ptHeadstock_id { _id name headstockImage}
                        ptNeck_id { _id name neckImage}
                        spMaterialNeck_id { _id name }
                        spMaterialNeckOverlay_id { _id name } 
                            # electronicInfo: String
                            switchPositions, 
                            piezo,
                            spBrandPiezo_id { _id name },
                            modelPiezo,
                            positionBridge,
                            ptPickupBridge_id { _id name pickupImage},
                            spBrandBridgePickup_id { _id name },
                            modelBridgePickup,
                            positionMiddle,
                            ptPickupMiddle_id { _id name pickupImage},
                            spBrandMiddlePickup_id { _id name },
                            modelMiddlePickup,
                            positionNeck,
                            ptPickupNeck_id { _id name pickupImage},
                            spBrandNeckPickup_id { _id name },
                            modelNeckPickup,
    createdAt,
    updatedAt
  }
}
`;

export const ADD_PR_GSL_CARD = gql`
mutation addPrGslCard(
    $name: String
    $user_id: ID
    $original: Boolean
    $foto: String
    $userSeller_id: ID
    $sellerContact: String
    $price: String
    $dateOfPurchase: String
        $spInstrument_id: ID
        $spInstrumentType_id: ID
        $spBrand_id: ID
        $model: String
        $spCountry_id: ID
        $spNeckType_id: ID
        $spNumberOfStrings_id: ID
        $spNumberOfFrets_id: ID
        $spMensura_id: ID
            $ptBridge_id: ID
            $spBrandBridge_id: ID
            $bridgeModel: String
            $spCountryBridge_id: ID
                $ptFormFactor_id: ID
                $spMaterialFormFactor_id: ID
                $overlay: Boolean
                $spMaterialFormFactorOverlay_id: ID
                $pickguard: Boolean
                $ptPickguard_id: ID
                    $ptHeadstock_id: ID
                    $ptNeck_id: ID
                    $spMaterialNeck_id: ID
                    $spMaterialNeckOverlay_id: ID
                        $switchPositions: String
                        $piezo: Boolean
                        $spBrandPiezo_id: ID
                        $modelPiezo: String

                        $positionBridge: Boolean
                        $spPickupPositions_id_bridge: ID
                        $spPickupMode_id_bridge: ID
                        $spPickupType_id_bridge: ID
                        $ptPickupBridge_id: ID
                        $spBrandBridgePickup_id: ID
                        $modelBridgePickup: String

                        $positionMiddle: Boolean
                        $spPickupPositions_id_middle: ID
                        $spPickupMode_id_middle: ID
                        $spPickupType_id_middle: ID
                        $ptPickupMiddle_id: ID
                        $spBrandMiddlePickup_id: ID
                        $modelMiddlePickup: String

                        $positionNeck: Boolean
                        $spPickupPositions_id_neck: ID
                        $spPickupMode_id_neck: ID
                        $spPickupType_id_neck: ID
                        $ptPickupNeck_id: ID
                        $spBrandNeckPickup_id: ID
                        $modelNeckPickup: String)
{
    addPrGslCard(
        name: $name
        user_id: $user_id
        original: $original
        foto: $foto
        userSeller_id: $userSeller_id
        sellerContact: $sellerContact
        price: $price
        dateOfPurchase: $dateOfPurchase
            spInstrument_id: $spInstrument_id
            spInstrumentType_id: $spInstrumentType_id
            spBrand_id: $spBrand_id
            model: $model
            spCountry_id: $spCountry_id
            spNeckType_id: $spNeckType_id
            spNumberOfStrings_id: $spNumberOfStrings_id
            spNumberOfFrets_id: $spNumberOfFrets_id
            spMensura_id: $spMensura_id
                ptBridge_id: $ptBridge_id
                spBrandBridge_id: $spBrandBridge_id
                bridgeModel: $bridgeModel
                spCountryBridge_id: $spCountryBridge_id
                    ptFormFactor_id: $ptFormFactor_id
                    spMaterialFormFactor_id: $spMaterialFormFactor_id
                    overlay: $overlay
                    spMaterialFormFactorOverlay_id: $spMaterialFormFactorOverlay_id
                    pickguard: $pickguard
                    ptPickguard_id: $ptPickguard_id
                        ptHeadstock_id: $ptHeadstock_id
                        ptNeck_id: $ptNeck_id
                        spMaterialNeck_id: $spMaterialNeck_id
                        spMaterialNeckOverlay_id: $spMaterialNeckOverlay_id
                            switchPositions: $switchPositions
                            piezo: $piezo
                            spBrandPiezo_id: $spBrandPiezo_id
                            modelPiezo: $modelPiezo

                            positionBridge: $positionBridge
                            spPickupPositions_id_bridge: $spPickupPositions_id_bridge
                            spPickupMode_id_bridge: $spPickupMode_id_bridge
                            spPickupType_id_bridge: $spPickupType_id_bridge
                            ptPickupBridge_id: $ptPickupBridge_id
                            spBrandBridgePickup_id: $spBrandBridgePickup_id
                            modelBridgePickup: $modelBridgePickup

                            positionMiddle: $positionMiddle
                            spPickupPositions_id_middle: $spPickupPositions_id_middle
                            spPickupMode_id_middle: $spPickupMode_id_middle
                            spPickupType_id_middle: $spPickupType_id_middle
                            ptPickupMiddle_id: $ptPickupMiddle_id
                            spBrandMiddlePickup_id: $spBrandMiddlePickup_id
                            modelMiddlePickup: $modelMiddlePickup

                            positionNeck: $positionNeck
                            spPickupPositions_id_neck: $spPickupPositions_id_neck
                            spPickupMode_id_neck: $spPickupMode_id_neck
                            spPickupType_id_neck: $spPickupType_id_neck
                            ptPickupNeck_id: $ptPickupNeck_id
                            spBrandNeckPickup_id: $spBrandNeckPickup_id
                            modelNeckPickup: $modelNeckPickup) {
            _id
        
    }      
}
`;

export const DELETE_PR_GSL_CARD = gql`
 mutation deletePrGslCard($id: ID) 
 {
    deletePrGslCard(_id: $id) {
      _id
    }
 }
`;

export const UPDATE_PR_GSL_CARD = gql`
 mutation updatePrGslCard(
    $id: ID, 
    $name: String
    $original: Boolean
    $foto: String
    $userSeller_id: ID
    $sellerContact: String
    $price: String
    $dateOfPurchase: String
    # generalInfo: String
        $spInstrument_id: ID
        $spInstrumentType_id: ID
        $spBrand_id: ID
        $model: String
        $spCountry_id: ID
        $spNeckType_id: ID
        $spNumberOfStrings_id: ID
        $spNumberOfFrets_id: ID
        $spMensura_id: ID
            # bridgeInfo: String
            $ptBridge_id: ID
            $spBrandBridge_id: ID
            $bridgeModel: String
            $spCountryBridge_id: ID
                # formFactorInfo: String
                $ptFormFactor_id: ID
                $spMaterialFormFactor_id: ID
                $overlay: Boolean
                $spMaterialFormFactorOverlay_id: ID
                $pickguard: Boolean
                $ptPickguard_id: ID
                    # headstockInfo: String
                    $ptHeadstock_id: ID
                    $ptNeck_id: ID
                    $spMaterialNeck_id: ID
                    $spMaterialNeckOverlay_id: ID
                        # electronicInfo: String
                        $switchPositions: String
                        $piezo: Boolean
                        $spBrandPiezo_id: ID
                        $modelPiezo: String

                        $positionBridge: Boolean
                        $spPickupPositions_id_bridge: ID
                        $spPickupMode_id_bridge: ID
                        $spPickupType_id_bridge: ID
                        $ptPickupBridge_id: ID
                        $spBrandBridgePickup_id: ID
                        $modelBridgePickup: String

                        $positionMiddle: Boolean
                        $spPickupPositions_id_middle: ID
                        $spPickupMode_id_middle: ID
                        $spPickupType_id_middle: ID
                        $ptPickupMiddle_id: ID
                        $spBrandMiddlePickup_id: ID
                        $modelMiddlePickup: String

                        $positionNeck: Boolean
                        $spPickupPositions_id_neck: ID
                        $spPickupMode_id_neck: ID
                        $spPickupType_id_neck: ID
                        $ptPickupNeck_id: ID
                        $spBrandNeckPickup_id: ID
                        $modelNeckPickup: String) 
    {
        updatePrGslCard(
            _id: $id, 
            name: $name
            original: $original
            foto: $foto
            userSeller_id: $userSeller_id
            sellerContact: $sellerContact
            price: $price
            dateOfPurchase: $dateOfPurchase       
                # generalInfo: String
                spInstrument_id: $spInstrument_id
                spInstrumentType_id: $spInstrumentType_id
                spBrand_id: $spBrand_id
                model: $model
                spCountry_id: $spCountry_id
                spNeckType_id: $spNeckType_id
                spNumberOfStrings_id: $spNumberOfStrings_id
                spNumberOfFrets_id: $spNumberOfFrets_id
                spMensura_id: $spMensura_id
                    # bridgeInfo: String
                    ptBridge_id: $ptBridge_id
                    spBrandBridge_id: $spBrandBridge_id
                    bridgeModel: $bridgeModel
                    spCountryBridge_id: $spCountryBridge_id
                        # formFactorInfo: String
                        ptFormFactor_id: $ptFormFactor_id
                        spMaterialFormFactor_id: $spMaterialFormFactor_id
                        overlay: $overlay
                        spMaterialFormFactorOverlay_id: $spMaterialFormFactorOverlay_id
                        pickguard: $pickguard
                        ptPickguard_id: $ptPickguard_id
                            # headstockInfo: String
                            ptHeadstock_id: $ptHeadstock_id
                            ptNeck_id: $ptNeck_id
                            spMaterialNeck_id: $spMaterialNeck_id
                            spMaterialNeckOverlay_id: $spMaterialNeckOverlay_id
                                # electronicInfo: String
                                switchPositions: $switchPositions
                                piezo: $piezo
                                spBrandPiezo_id: $spBrandPiezo_id
                                modelPiezo: $modelPiezo

                                positionBridge: $positionBridge
                                spPickupPositions_id_bridge: $spPickupPositions_id_bridge
                                spPickupMode_id_bridge: $spPickupMode_id_bridge
                                spPickupType_id_bridge: $spPickupType_id_bridge
                                ptPickupBridge_id: $ptPickupBridge_id
                                spBrandBridgePickup_id: $spBrandBridgePickup_id
                                modelBridgePickup: $modelBridgePickup

                                positionMiddle: $positionMiddle
                                spPickupPositions_id_middle: $spPickupPositions_id_middle
                                spPickupMode_id_middle: $spPickupMode_id_middle
                                spPickupType_id_middle: $spPickupType_id_middle
                                ptPickupMiddle_id: $ptPickupMiddle_id
                                spBrandMiddlePickup_id: $spBrandMiddlePickup_id
                                modelMiddlePickup: $modelMiddlePickup

                                positionNeck: $positionNeck
                                spPickupPositions_id_neck: $spPickupPositions_id_neck
                                spPickupMode_id_neck: $spPickupMode_id_neck
                                spPickupType_id_neck: $spPickupType_id_neck
                                ptPickupNeck_id: $ptPickupNeck_id
                                spBrandNeckPickup_id: $spBrandNeckPickup_id
                                modelNeckPickup: $modelNeckPickup) {
                _id
       
    }
}
`;