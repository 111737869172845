import React from 'react';
import { UpdateUser } from '../../requests/users/updateUser';

export const UserSettingsPage = () => {
  
  return (
    <>
      <UpdateUser/>
    </>
  )
}
