import React from 'react';
import { useQuery } from '@apollo/client';
import { FETCH_SP_FF_CLASSES } from './gqlSpFFClass';
import GetSpFFClass from './getSpFFClass';
import { DeleteSpFFClass } from './deleteSpFFClass';
import { UpdateSpFFClass } from './updateSpFFClass';
import Loader from '../../../../components/main/Loader';
import { Box, Container } from '@material-ui/core';

const GetSpFFClasses = () => {

    const { loading, error, data } = useQuery(FETCH_SP_FF_CLASSES);

    if (loading) return <Loader/>;
    if (error) return `Error ${error}`;
   

    return (
        <>
            <Container>
                <Box display="flex" justifyContent="center">
                    <table>
                        <thead>
                            <tr>
                                <th>№</th>
                                <th>Наименование</th>
                                <th>Информация</th>
                                <th>Дата создания</th>
                                <th>Дата изменения</th>
                                <th>Действия</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.getSpFFClasses.map((SpFFClass, index) => {
                                return (
                                    <tr key={SpFFClass._id}>
                                        <td>{index + 1}</td>
                                        <td>{SpFFClass.name}</td>
                                        <td>{SpFFClass.info}</td>
                                        <td>{SpFFClass.createdAt}</td>
                                        <td>{SpFFClass.updatedAt}</td>
                                        <td>
                                            <GetSpFFClass id={SpFFClass._id} />
                                            <UpdateSpFFClass id={SpFFClass._id} name={SpFFClass.name} info={SpFFClass.info}/>
                                            <DeleteSpFFClass id={SpFFClass._id} />
                                        </td>
                                    </tr>)
                            })}
                        </tbody>
                    </table>
                </Box>
            </Container>
        </>
    )
}

export default GetSpFFClasses;