import React from 'react';
import { useQuery } from '@apollo/client';
import { FETCH_PT_PICKUP } from './gqlPtPickup';
import Loader from '../../../../components/main/Loader';
import { UpdatePtPickup } from './updatePtPickup';
import OpenDataId from '../../../../components/view/openDataId';
import Open from '../../../../components/actions/open';
import ViewIcon from '../../../../components/view/displayingData/viewIcon';
import ViewImage from '../../../../components/view/displayingData/viewImage';

const GetPtPickup = ({ id }) => {

  const { loading, error, data } = useQuery(FETCH_PT_PICKUP, {
    fetchPolicy: "no-cache",
    variables: { id },

  });

  if (loading) return <Loader />;
  if (error) return `Error ${error}`;

  return (  
    <Open
      title={"Открыть"}
      dialogTitle={data.getPtPickup.name}
      edit={<UpdatePtPickup
        id={data.getPtPickup._id}
        name={data.getPtPickup.name}
        info={data.getPtPickup.info}
        spInstrument_id={data.getPtPickup.spInstrument_id._id}
        spInstrumentType_id={data.getPtPickup.spInstrumentType_id._id}
        spNumberOfStrings_id={data.getPtPickup.spNumberOfStrings_id._id}
        spPickupPositions_id={data.getPtPickup.spPickupPositions_id._id}
        spPickupType_id={data.getPtPickup.spPickupType_id._id}
        spPickupMode_id={data.getPtPickup.spPickupMode_id._id}
        sourse={data.getPtPickup.sourse}
        pickupImage={data.getPtPickup.pickupImage}
        pickupIcon={data.getPtPickup.pickupIcon}
      />}
      Fields={<>
        <OpenDataId
          label={"ID"}
          data={data.getPtPickup._id}
        />
        <OpenDataId
          label={"Наименование"}
          data={data.getPtPickup.name}
        />
        <OpenDataId
          label={"Информация"}
          data={data.getPtPickup.info}
        />
        <OpenDataId
          label={"Инструмент"}
          data={data.getPtPickup.spInstrument_id.name}
        />
        <OpenDataId
          label={"Тип инструмента"}
          data={data.getPtPickup.spInstrumentType_id.name}
        />
        <OpenDataId
          label={"Количество струн"}
          data={data.getPtPickup.spNumberOfStrings_id.name}
        /><br></br>
        <OpenDataId
          label={"Позиция звукоснимателя"}
          data={data.getPtPickup.spPickupPositions_id.name}
        />
        <OpenDataId
          label={"Тип звукоснимателя"}
          data={data.getPtPickup.spPickupType_id.name}
        />
        <OpenDataId
          label={"Режим звукоснимателя"}
          data={data.getPtPickup.spPickupMode_id.name}
        /><br></br>
        <OpenDataId
          label={"Источник"}
          data={data.getPtPickup.sourse}
        /><br></br>
        <OpenDataId
          label={"Изображение"}
          data={data.getPtPickup.pickupImage}
        />
        <ViewImage
            image={data.getPtPickup.pickupImage}
            dir={"images/pickup"}
          />
        <OpenDataId
          label={"Иконка"}
          data={data.getPtPickup.pickupIcon}
        />
         <ViewIcon
            image={data.getPtPickup.pickupIcon}
            dir={"icons/pickup"}
          /><br></br>
        <OpenDataId
          label={"Создан"}
          data={data.getPtPickup.createdAt}
        />
        <OpenDataId
          label={"Изменён"}
          data={data.getPtPickup.updatedAt}
        />
      </>} />
  )
}

export default GetPtPickup;