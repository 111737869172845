import { gql } from "@apollo/client";

export const FETCH_SP_BRIDGES_FAMILYS = gql`
    {
        getSpBridgesFamilys{
            _id,
            name,
            info,    
            createdAt,
            updatedAt  
        }
    }
`;

export const FETCH_SP_BRIDGE_FAMILY = gql`
    query getSpBridgeFamily($id: ID) {
        getSpBridgeFamily(_id: $id) {
            _id,
            name,
            info,
            createdAt,
            updatedAt
        }
    }
`;

export const ADD_SP_BRIDGE_FAMILY = gql`
    mutation addSpBridgeFamily($name: String, $info: String) {
        addSpBridgeFamily(name: $name, info: $info) {
            _id  
        }      
    }
`;

export const DELETE_SP_BRIDGE_FAMILY = gql`
    mutation deleteSpBridgeFamily($id: ID) {
        deleteSpBridgeFamily(_id: $id) {
            _id
        }
    }
`;

export const UPDATE_SP_BRIDGE_FAMILY = gql`
    mutation updateSpBridgeFamily($id: ID, $name: String, $info: String) {
        updateSpBridgeFamily(_id: $id, name: $name, info: $info) {
            _id
        }
    }
`;

export const FETCH_SP_BRIDGES_FAMILYS_FOR_SPBRIDGEFAMILYID = gql`
    {
        getSpBridgesFamilys{
            _id,
            name  
        }
    }
`;