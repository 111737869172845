import { gql } from "@apollo/client";

export const FETCH_SP_PICKUPS_TYPES = gql`
    {
        getSpPickupsTypes{
            _id,
            name,
            info,    
            createdAt,
            updatedAt  
        }
    }
`;

export const FETCH_SP_PICKUP_TYPE = gql`
    query getSpPickupType($id: ID) {
        getSpPickupType(_id: $id) {
            _id,
            name,
            info,
            createdAt,
            updatedAt
      }
    }
`;

export const ADD_SP_PICKUP_TYPE = gql`
    mutation addSpPickupType($name: String, $info: String) {
        addSpPickupType(name: $name, info: $info) {
                _id
            }      
        }
`;

export const DELETE_SP_PICKUP_TYPE = gql`
    mutation deleteSpPickupType($id: ID) {
        deleteSpPickupType(_id: $id) {
            _id
        }
    }
`;

export const UPDATE_SP_PICKUP_TYPE = gql`
    mutation updateSpPickupType($id: ID, $name: String, $info: String) {
        updateSpPickupType(_id: $id, name: $name, info: $info) {
           _id
        }
    }
`;

export const FETCH_SP_PICKUPS_TYPES_FOR_SPPICKUPTYPEID = gql`
    {
        getSpPickupsTypes{
            _id,
            name  
        }
    }
`;