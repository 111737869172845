import React from 'react';
import { TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 300,
    },
}));

const OptionalField = ({ value, onChange, name, label }) => {
    const classes = useStyles();

    return (

        <TextField className={classes.formControl}
            id="standard-basic"
            label={label}
            type="text"
            name={name}
            value={value}
            multiline
            rowsMax={4}
            onChange={onChange}
        >
        </TextField>
    )

}

export default OptionalField;