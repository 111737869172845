import React from 'react';
import { useQuery } from '@apollo/client';
import { FETCH_SP_FF_CLASS } from './gqlSpFFClass';
import Loader from '../../../../components/main/Loader';
import Open from '../../../../components/actions/open';
import OpenDataId from '../../../../components/view/openDataId';
import { UpdateSpFFClass } from './updateSpFFClass';

const GetSpFFClass = ({ id }) => {

  const { loading, error, data } = useQuery(FETCH_SP_FF_CLASS, {
    variables: { id },
  });

  if (loading) return <Loader />;
  if (error) return `Error ${error}`;

  return (
    <Open
      title={"Открыть"}
      dialogTitle={data.getSpFFClass.name}
      edit={<UpdateSpFFClass id={data.getSpFFClass._id} name={data.getSpFFClass.name} info={data.getSpFFClass.info} />}
      Fields={<>
        <OpenDataId
          label={"Наименование"}
          data={data.getSpFFClass._id}
        />
        <OpenDataId
          label={"Информация"}
          data={data.getSpFFClass.info}
        />
        <OpenDataId
          label={"Дата создания"}
          data={data.getSpFFClass.createdAt}
        />
        <OpenDataId
          label={"Дата изменения"}
          data={data.getSpFFClass.updatedAt}
        />
      </>} />
  )
}

export default GetSpFFClass;