import React from 'react';
import { useQuery } from '@apollo/client';
import Loader from '../../../../components/main/Loader';
import Open from '../../../../components/actions/open';
import OpenDataId from '../../../../components/view/openDataId';
import { FETCH_ST_APPEAL } from './gqlStAppeal';
import { UpdateStAppeal } from './updateStAppeal';


const GetStAppeal = ({ id }) => {

  const { loading, error, data } = useQuery(FETCH_ST_APPEAL, {
    variables: { id }
  });

  if (loading) return <Loader />;
  if (error) return `Error ${error}`;

  return (
    <>
      <Open
        title={"Открыть"}
        dialogTitle={`Номер обращения: ${data.getStAppeal.numberAppeal} Статус: ${data.getStAppeal.spStatusAppeal_id.name}`}
        edit={<UpdateStAppeal
          id={data.getStAppeal._id}
          textAppeal={data.getStAppeal.textAppeal}
          location={data.getStAppeal.location}
          spStatusAppeal_id={data.getStAppeal.spStatusAppeal_id._id} />}
        Fields={<>
        <OpenDataId
            label={"Текст обращения"}
            data={data.getStAppeal.user_id.email}
          />
          <OpenDataId
            label={"Текст обращения"}
            data={data.getStAppeal.textAppeal}
          />
          <OpenDataId
            label={"Расположение"}
            data={data.getStAppeal.location}
          />
          <OpenDataId
            label={"Переменные"}
            data={data.getStAppeal.valueVariables}
          />
          <OpenDataId
            label={"Дата создания"}
            data={data.getStAppeal.createdAt}
          />
          <OpenDataId
            label={"Дата изменения"}
            data={data.getStAppeal.updatedAt}
          />
        </>} />
    </>
  )
}

export default GetStAppeal;