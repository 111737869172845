import React, { useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import Update from '../../../../components/actions/update';
import RequiredField from '../../../../components/InputElements/requiredField';
import OptionalMultiRowsField from '../../../../components/InputElements/optionalMultiRowsField';
import { FETCH_ST_APPEALS, UPDATE_ST_APPEAL } from './gqlStAppeal';
import RequiredSelect from '../../../../components/InputElements/requiredSelect';
import { FETCH_SP_STATUSES_APPEALS_FOR_SPSTATUSAPPEALSID } from '../../sp/spStatusAppeal/gqlSpStatusAppeal';
import Loader from '../../../../components/main/Loader';


export const UpdateStAppeal = ({ id, textAppeal, location, spStatusAppeal_id }) => {

    const [values, setValues] = useState({ textAppeal, location, spStatusAppeal_id });

    const [updateStAppeal] = useMutation(UPDATE_ST_APPEAL, {

        onError(e) {
            console.log(e.graphQLErrors[0].message)
        },
        variables: { id, input: values },
        refetchQueries: [{ query: FETCH_ST_APPEALS }]
    })

    const {
        loading: loading_FETCH_SP_STATUSES_APPEALS_FOR_SPSTATUSAPPEALSID,
        error: error_FETCH_SP_STATUSES_APPEALS_FOR_SPSTATUSAPPEALSID,
        data: data_FETCH_SP_STATUSES_APPEALS_FOR_SPSTATUSAPPEALSID } = useQuery(FETCH_SP_STATUSES_APPEALS_FOR_SPSTATUSAPPEALSID, {
            fetchPolicy: "no-cache"
        });

    if (loading_FETCH_SP_STATUSES_APPEALS_FOR_SPSTATUSAPPEALSID) return <Loader />;
    if (error_FETCH_SP_STATUSES_APPEALS_FOR_SPSTATUSAPPEALSID) return `Error ${error_FETCH_SP_STATUSES_APPEALS_FOR_SPSTATUSAPPEALSID}`;

    const handlerChangeName = (e) => {
        setValues({
            ...values,
            [e.target.name]: e.target.value
        })
    }

    const formSubmit = (e) => {
        e.preventDefault();
        updateStAppeal();
    }

    return (
        <Update
            title={"Изменение обращения"}
            ButtonText={"Изменить обращение"}
            dialogTitle={"Изменение обращения"}
            formSubmit={formSubmit}
            Fields={<>
                <RequiredField
                    label={"Наименование"}
                    value={values.textAppeal}
                    onChange={handlerChangeName}
                    name={"textAppeal"}
                />
                <br></br>
                <OptionalMultiRowsField
                    label={"Информация"}
                    value={values.location}
                    onChange={handlerChangeName}
                    name={"location"}
                />
                 <RequiredSelect
                    label={"Бренд"}
                    name={"spStatusAppeal_id"}
                    value={values.spStatusAppeal_id}
                    onChange={handlerChangeName}
                    fetch={data_FETCH_SP_STATUSES_APPEALS_FOR_SPSTATUSAPPEALSID.getSpStatusesAppeals}
                />
            </>} />
    )
}