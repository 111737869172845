import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { ADD_SP_INSTRUMENT, FETCH_SP_INSTRUMENTS } from './gqlSpInstrument';
import Add from '../../../../components/actions/add';
import RequiredField from '../../../../components/InputElements/requiredField';
import UploadIcon from '../../../../components/actions/uploadIcon';

const AddSpInstrument = () => {

    const [values, setValues] = useState({
        name: ""
    });

    const [addSpInstrument] = useMutation(ADD_SP_INSTRUMENT, {

        onError(e) {
            console.log(e.graphQLErrors[0].message)
        },
        variables: values,
        refetchQueries: [{ query: FETCH_SP_INSTRUMENTS }]
    });

    const handlerChangeName = (e) => {
        setValues({
            ...values,
            [e.target.name]: e.target.value,
        });
    };

    const formSubmit = (e) => {
        e.preventDefault();
        addSpInstrument();
        setValues({ name: "" })
    }

    const [state, setState] = useState({
        fileName: 'Файл не загружен'
    });

    const updateData = (value) => {
        setState({ fileName: value })
    }
    return (
        <Add
            title={"Add SpInstrument"}
            ButtonText={"Add SpInstrument"}
            dialogTitle={"Add SpInstrument"}
            formSubmit={formSubmit}
            Fields={<>
                <UploadIcon
                    label="instrumentIcon"
                    value={values.instrumentIcon}
                    email={values.email}
                    nameData="pr_gslc_i_instrument"
                    method={"pr/gslc/icons/instrument"}
                    dirIcon={"instrument"}
                    updateData={updateData}
                /><br></br>
                <RequiredField
                    label={"instrumentIcon"}
                    value={values.instrumentIcon = state.fileName.toString()}
                    onChange={handlerChangeName}
                    name={"instrumentIcon"}
                /><br></br>
                <RequiredField
                    label={"Наименование"}
                    value={values.name}
                    onChange={handlerChangeName}
                    name={"name"}
                /><br></br>
                <RequiredField
                    label={"info"}
                    value={values.info}
                    onChange={handlerChangeName}
                    name={"info"}
                />
            </>}
        />
    )
}

export default AddSpInstrument;