import React from 'react';
import { Checkbox, Typography, Box } from '@material-ui/core';

const UniversCheckbox = ({ checked, onChange, value, label, name }) => {

    return (
        <Box display="flex" alignItems="center">
          <Box p={0}>
            <Checkbox
              checked={checked}
              onChange={onChange}
              inputProps={{ 'aria-label': 'primary checkbox' }}
              value={value}
              name={name}
            />
          </Box>
          <Box p={0}>
            <Typography color="textSecondary" gutterBottom>{label}</Typography>
          </Box>
        </Box>
    )

}

export default UniversCheckbox;