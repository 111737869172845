import React from 'react';
import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 300,
    },
}));

const RequiredSelectCode = ({ label, name, value, onChange, fetch }) => {
    const classes = useStyles();

    const error = value === "";
    return (
        <FormControl>
            <InputLabel htmlFor="age-native-simple">{label}</InputLabel>
            <Select className={classes.formControl}
                id="standard-basic"
                label={label}
                type="text"
                name={name}
                value={value}
                onChange={onChange}
                helperText={error ? "Обязательное поле" : ""}
                error={error}
                defaultValue=""
            >
                {fetch.map((element, index ) => {

                    return (
                        <MenuItem
                            key={element._id}
                            value={element._id}>
                            {element.name} {index}
                        </MenuItem>
                    )
                })}
            </Select>
        </FormControl>
    )

}

export default RequiredSelectCode;