import React from 'react';
import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 300,
    },
}));

const RequiredSelect = ({ label, name, value, onChange, fetch }) => {
    const classes = useStyles();

    const error = value === "";
    return (
        <>
        <FormControl>
            {/* <InputLabel htmlFor="age-native-simple">{label}</InputLabel> */}
            <InputLabel id="demo-simple-select-label">{label}</InputLabel>
            <Select className={classes.formControl}
                labelId="demo-simple-select-label"
                // label={label}
                type="text"
                name={name}
                value={value}
                onChange={onChange}
                helperText={error ? "Обязательное поле" : ""}
                error={error}
                defaultValue = ""
            >
                {fetch.map(element => {

                    return (
                        <MenuItem
                            key={element._id}
                            value={element._id}>
                            {element.name}
                        </MenuItem>
                    )
                })}
            </Select>
            </FormControl>
        </>
    )

}

export default RequiredSelect;