import React from 'react';
import { useMutation } from '@apollo/client';
import { DELETE_SP_PICKUP_MODE, FETCH_SP_PICKUPS_MODES } from './gqlSpPickupMode';
import { Delete } from '../../../../components/actions/delete';


export const DeleteSpPickupMode = ({ id }) => {
  const [deleteSpPickupMode] = useMutation(DELETE_SP_PICKUP_MODE, {

    onError(e) {
      console.log(e.graphQLErrors[0].message)
    },
    variables: { id },
    refetchQueries: [{ query: FETCH_SP_PICKUPS_MODES }]
  });

  const onDelete = () => {
    deleteSpPickupMode()
  }
  return (
    <Delete onDelete={onDelete} />
  )
}