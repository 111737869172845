import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { ADD_SP_PICKUP_TYPE, FETCH_SP_PICKUPS_TYPES } from './gqlSpPickupType';
import Add from '../../../../components/actions/add';
import RequiredField from '../../../../components/InputElements/requiredField';
import OptionalMultiRowsField from '../../../../components/InputElements/optionalMultiRowsField';

const AddSpPickupType = () => {
    const [values, setValues] = useState({
        name: ""
    });

    const [addSpPickupType] = useMutation(ADD_SP_PICKUP_TYPE, {

        onError(e) {
            console.log(e.graphQLErrors[0].message)
        },
        variables: values,
        refetchQueries: [{ query: FETCH_SP_PICKUPS_TYPES }]
    });

    const handlerChangeName = (e) => {
        setValues({
            ...values,
            [e.target.name]: e.target.value,
        });
    };

    const formSubmit = (e) => {
        e.preventDefault();
        addSpPickupType();
        setValues({ name: "" })
    }

    return (
        <Add
            title={"Добавление PickupType"}
            ButtonText={"Добавить PickupMode"}
            dialogTitle={"Добавление нового PickupType"}
            formSubmit={formSubmit}
            Fields={<>
                <RequiredField
                    label={"Наименование"}
                    value={values.name}
                    onChange={handlerChangeName}
                    name={"name"}
                />
                <br></br>
                <OptionalMultiRowsField
                    label={"Информация"}
                    value={values.info}
                    onChange={handlerChangeName}
                    name={"info"}
                />
            </>} />
    )

}

export default AddSpPickupType;