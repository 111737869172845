
import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import { FETCH_CURRENT_USER } from './gqlCurrentUser';
import Loader from '../../components/main/Loader';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Tooltip } from '@material-ui/core';

const GetUser = ({ id }) => {
  const [open, setOpen] = useState(false)
  
  const { loading, error, data } = useQuery(FETCH_CURRENT_USER, {
    variables: { id },
  });

  if (loading) return <Loader />;
  if (error) return `Error ${error}`;

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Tooltip
        title="Open"
        arrow>
        <Button onClick={() => setOpen(true)}>Open</Button>
      </Tooltip>

      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">

        <DialogTitle id="form-dialog-title">Просмотр</DialogTitle>
        <DialogContent>
          <p>ID: {data.getUser._id}</p>
          <p>Наименование: {data.getUser.email}</p>
          <p>Информация: {data.getUser.password}</p>
          <p>firstName: {data.getUser.firstName}</p>
          <p>lastName: {data.getUser.lastName}</p>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
        </DialogActions>

      </Dialog>
    </>
  )
}

export default GetUser;