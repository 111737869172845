import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { FETCH_SP_STATUSES_APPEALS, UPDATE_SP_STATUS_APPEAL } from './gqlSpStatusAppeal';
import Update from '../../../../components/actions/update';
import RequiredField from '../../../../components/InputElements/requiredField';
import OptionalMultiRowsField from '../../../../components/InputElements/optionalMultiRowsField';


export const UpdateSpStatusAppeal = ({ id, name, info }) => {

    const [values, setValues] = useState({ name, info });

    const [updateSpStatusAppeal] = useMutation(UPDATE_SP_STATUS_APPEAL, {

        onError(e) {
            console.log(e.graphQLErrors[0].message)
        },
        variables: { id, input: values },
        refetchQueries: [{ query: FETCH_SP_STATUSES_APPEALS }]
    })

    const handlerChangeName = (e) => {
        setValues({
            ...values,
            [e.target.name]: e.target.value
        })
    }

    const formSubmit = (e) => {
        e.preventDefault();
        updateSpStatusAppeal();
    }

    return (
        <Update
            title={"Изменение статуса обращения"}
            ButtonText={"Изменить статус обращения"}
            dialogTitle={"Изменение статуса обращения"}
            formSubmit={formSubmit}
            Fields={<>
                <RequiredField
                    label={"Наименование"}
                    value={values.name}
                    onChange={handlerChangeName}
                    name={"name"}
                />
                <br></br>
                <OptionalMultiRowsField
                    label={"Информация"}
                    value={values.info}
                    onChange={handlerChangeName}
                    name={"info"}
                />
            </>} />
    )
}