import { gql } from "@apollo/client";

export const FETCH_SP_PICKUP_COILS = gql`
    {
        getSpPickupCoils{
            _id,
            name,
            info,
            spPickupType_id {
                _id
            },
            createdAt,
            updatedAt
        }
    }
`;

export const FETCH_SP_PICKUP_COIL = gql`
    query getSpPickupCoil($id: ID) {
        getSpPickupCoil(_id: $id) {
            _id,
            name,
            info,
            spPickupType_id {
                _id,
                name
            },
            createdAt,
            updatedAt
    }
}
`;

export const ADD_SP_PICKUP_COIL = gql`
    mutation addSpPickupCoil(
        $name: String, 
        $info: String,
        $spPickupType_id: ID) {
            addSpPickupCoil(
                name: $name,
                info: $info,
                spPickupType_id: $spPickupType_id) {
                    _id
                }      
        }
`;

export const DELETE_SP_PICKUP_COIL = gql`
    mutation deleteSpPickupCoils($id: ID) {
        deleteSpPickupCoils(_id: $id) {
            _id
        }
    }
`;

export const UPDATE_SP_PICKUP_COIL = gql`
    mutation updateSpPickupCoil(
        $id: ID, 
        $name: String, 
        $info: String,
        $spPickupType_id: ID) {
            updateSpPickupCoil(
                _id: $id, 
                name: $name,
                info: $info,
                spPickupType_id: $spPickupType_id) {
                    _id
                }
    }
`;