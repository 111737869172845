
import React from 'react';
import { useMutation } from '@apollo/client';
import { DELETE_USER_POST, FETCH_USERS_POSTS } from './gqlUserPost';




export const DeleteUserPost = ({ id }) => {
  const [deleteUserPost] = useMutation(DELETE_USER_POST, {
    
    onError(e) {
      console.log(e.graphQLErrors[0].message)
    },
    variables: { id },
    refetchQueries: [{ query: FETCH_USERS_POSTS}]
  });

  const onDelete = () => {
    deleteUserPost()
  }
  return (
    <div >
      <button type="submit" onClick={onDelete}>Удалить</button>
    </div>
  )
}