import React from 'react';
import { useQuery } from '@apollo/client';
import { FETCH_SP_INSTRUMENTS_TYPES } from './gqlSpInstrumentType';
import GetSpInstrumentType from './getSpInstrumentType';
import { DeleteSpInstrumentType } from './deleteSpInstrumentType';
import { UpdateSpInstrumentType } from './updateSpInstrumentType';
import Loader from '../../../../components/main/Loader';
import CardDatabaseItem from '../../../../components/view/cards/cardDatabaseItem';


const GetSpInstrumentsTypes = () => {

    const { loading, error, data } = useQuery(FETCH_SP_INSTRUMENTS_TYPES);

    if (loading) return <Loader />;
    if (error) return `Error ${error}`;

    return (
        <>
            {data.getSpInstrumentsTypes.map((spInstrumentType, index) => {
                return (
                    <CardDatabaseItem
                        name={spInstrumentType.name}
                        nameIcon={spInstrumentType.instrumentTypeIcon}
                        info={spInstrumentType.info}
                        dirName={"icons/instrumentType"}
                        actions={
                            <>
                                <GetSpInstrumentType id={spInstrumentType._id} />
                                <UpdateSpInstrumentType
                                    id={spInstrumentType._id}
                                    name={spInstrumentType.name}
                                    info={spInstrumentType.info}
                                    instrumentTypeIcon={spInstrumentType.instrumentTypeIcon} />
                                <DeleteSpInstrumentType id={spInstrumentType._id} />
                            </>
                        } />)
            })}
        </>
    )
}

export default GetSpInstrumentsTypes;