import { gql } from "@apollo/client";

export const FETCH_PT_BRIDGES = gql`
query getPtBridges($sheet: Int, $limit: Int){
        getPtBridges(sheet: $sheet, limit: $limit){
            _id,
            name,
            info,
            spInstrument_id{
                _id
            },
            spInstrumentType_id{
                _id
            },
            spBridgeClass_id{
                _id
            },
            spBridgeFamily_id{
                _id
            },
            spNumberOfStrings_id {
                _id
            },
            sourse,
            bridgeIcon,
            bridgeImage,
            createdAt,
            updatedAt  
        }
    }
`;

export const FETCH_PT_BRIDGE = gql`
    query getPtBridge($id: ID) {
        getPtBridge(_id: $id) {
            _id,
            name,
            info,
            spInstrument_id{
                _id,
                name
            },
            spInstrumentType_id{
                _id,
                name
            },
            spBridgeClass_id{
                _id,
                name
            },
            spBridgeFamily_id{
                _id,
                name
            },
            spNumberOfStrings_id {
                _id,
                name,
                code
            },
            sourse,
            bridgeIcon,
            bridgeImage,
            createdAt,
            updatedAt
        }
    }
`;

export const ADD_PT_BRIDGE = gql`
    mutation addPtBridge(
        $name: String,
        $info: String,
        $spInstrument_id: ID,
        $spInstrumentType_id: ID,
        $spBridgeClass_id: ID,
        $spBridgeFamily_id: ID,
        $spNumberOfStrings_id: ID,
        $sourse: String,
        $bridgeIcon: String,
        $bridgeImage: String){
            addPtBridge(
                name: $name, 
                info: $info,
                spInstrument_id: $spInstrument_id,
                spInstrumentType_id: $spInstrumentType_id,
                spBridgeClass_id: $spBridgeClass_id,
                spBridgeFamily_id: $spBridgeFamily_id,
                spNumberOfStrings_id: $spNumberOfStrings_id,
                sourse: $sourse,
                bridgeIcon: $bridgeIcon,
                bridgeImage: $bridgeImage) {
                    _id
                }      
    }
`;

export const DELETE_PT_BRIDGE = gql`
    mutation deletePtBridge($id: ID) {
        deletePtBridge(_id: $id) {
            _id
        }
    }
`;

export const UPDATE_PT_BRIDGE = gql`
    mutation updatePtBridge(
        $id: ID, 
        $name: String, 
        $info: String,
        $spInstrument_id: ID,
        $spInstrumentType_id: ID,
        $spBridgeClass_id: ID,
        $spBridgeFamily_id: ID,
        $spNumberOfStrings_id: ID,
        $sourse: String,
        $bridgeIcon: String,
        $bridgeImage: String) {
            updatePtBridge(
                _id: $id, 
                name: $name, 
                info: $info,
                spInstrument_id: $spInstrument_id,
                spInstrumentType_id: $spInstrumentType_id,
                spBridgeClass_id: $spBridgeClass_id,
                spBridgeFamily_id: $spBridgeFamily_id,
                spNumberOfStrings_id: $spNumberOfStrings_id,
                sourse: $sourse,
                bridgeIcon: $bridgeIcon,
                bridgeImage: $bridgeImage) {
                    _id
                }
    }
`;

export const FETCH_PT_BRIDGES_FOR_PTBRIDGESID = gql`
{
    getPtBridges{
        _id,
        name 
    }
}
`;

export const FETCH_PT_BRIDGE_FILTER = gql`
    query getPtBridgeFilter(
        $spInstrument_id: String, 
        $spInstrumentType_id: String,
        $spNumberOfStrings_id: String) {
            getPtBridgeFilter(
                spInstrument_id: $spInstrument_id,
                spInstrumentType_id: $spInstrumentType_id,
                spNumberOfStrings_id: $spNumberOfStrings_id) {
                    _id,
                    name,
                    bridgeIcon
                }
        }
`;