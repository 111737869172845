import React, { useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { ADD_PT_FORMFACTOR, FETCH_PT_FORMFACTORS } from './gqlPtFormFactor';
import { FETCH_SP_INSTRUMENTS_FOR_SPINSTRUMENTID } from '../../sp/spInstrument/gqlSpInstrument';
import { FETCH_SP_INSTRUMENTS_TYPES_FOR_SPINSTRUMENTTYPEID } from '../../sp/spInstrumentType/gqlSpInstrumentType';
import { FETCH_SP_FF_CLASSES_FOR_SPFFCLASSESID } from '../../sp/spFFClass/gqlSpFFClass';
import { FETCH_SP_FF_FAMILYS_FOR_SPFFFAMILYSID } from '../../sp/spFFFamily/gqlSpFFFamily';
import Loader from '../../../../components/main/Loader';
import Add from '../../../../components/actions/add';
import RequiredField from '../../../../components/InputElements/requiredField';
import RequiredSelect from '../../../../components/InputElements/requiredSelect';
import OptionalMultiRowsField from '../../../../components/InputElements/optionalMultiRowsField';
import OptionalField from '../../../../components/InputElements/optionalField';
import UploadIcon from '../../../../components/actions/uploadIcon';
import UploadImage from '../../../../components/actions/uploadImage';

const AddPtFormFactor = () => {

    const [values, setValues] = useState({
        name: ""
    });

    const [icon, setIcon] = useState({
        fileName: 'Файл не загружен'
    });

    const [image, setImage] = useState({
        fileName: 'Файл не загружен'
    });

    const [addPtFormFactor] = useMutation(ADD_PT_FORMFACTOR, {

        onError(e) {
            console.log(e.graphQLErrors[0].message)
        },
        variables: values,
        refetchQueries: [{ query: FETCH_PT_FORMFACTORS }]
    });

    const {
        loading: loading_FETCH_SP_INSTRUMENTS_FOR_SPINSTRUMENTID,
        error: error_FETCH_SP_INSTRUMENTS_FOR_SPINSTRUMENTID,
        data: data_FETCH_SP_INSTRUMENTS_FOR_SPINSTRUMENTID } = useQuery(FETCH_SP_INSTRUMENTS_FOR_SPINSTRUMENTID, {
            fetchPolicy: "no-cache"
        });
    const {
        loading: loading_FETCH_SP_INSTRUMENTS_TYPES_FOR_SPINSTRUMENTTYPEID,
        error: error_FETCH_SP_INSTRUMENTS_TYPES_FOR_SPINSTRUMENTTYPEID,
        data: data_FETCH_SP_INSTRUMENTS_TYPES_FOR_SPINSTRUMENTTYPEID } = useQuery(FETCH_SP_INSTRUMENTS_TYPES_FOR_SPINSTRUMENTTYPEID, {
            fetchPolicy: "no-cache"
        });
    const {
        loading: loading_FETCH_SP_FF_CLASSES_FOR_SPFFCLASSESID,
        error: error_FETCH_SP_FF_CLASSES_FOR_SPFFCLASSESID,
        data: data_FETCH_SP_FF_CLASSES_FOR_SPFFCLASSESID } = useQuery(FETCH_SP_FF_CLASSES_FOR_SPFFCLASSESID, {
            fetchPolicy: "no-cache"
        });

    const {
        loading: loading_FETCH_SP_FF_FAMILYS_FOR_SPFFFAMILYSID,
        error: error_FETCH_SP_FF_FAMILYS_FOR_SPFFFAMILYSID,
        data: data_FETCH_SP_FF_FAMILYS_FOR_SPFFFAMILYSID } = useQuery(FETCH_SP_FF_FAMILYS_FOR_SPFFFAMILYSID, {
            fetchPolicy: "no-cache"
        });

    if (loading_FETCH_SP_INSTRUMENTS_FOR_SPINSTRUMENTID) return <Loader />;
    if (error_FETCH_SP_INSTRUMENTS_FOR_SPINSTRUMENTID) return `Error ${error_FETCH_SP_INSTRUMENTS_FOR_SPINSTRUMENTID}`;

    if (loading_FETCH_SP_INSTRUMENTS_TYPES_FOR_SPINSTRUMENTTYPEID) return <Loader />;
    if (error_FETCH_SP_INSTRUMENTS_TYPES_FOR_SPINSTRUMENTTYPEID) return `Error ${error_FETCH_SP_INSTRUMENTS_TYPES_FOR_SPINSTRUMENTTYPEID}`;

    if (loading_FETCH_SP_FF_CLASSES_FOR_SPFFCLASSESID) return <Loader />;
    if (error_FETCH_SP_FF_CLASSES_FOR_SPFFCLASSESID) return `Error ${error_FETCH_SP_FF_CLASSES_FOR_SPFFCLASSESID}`;

    if (loading_FETCH_SP_FF_FAMILYS_FOR_SPFFFAMILYSID) return <Loader />;
    if (error_FETCH_SP_FF_FAMILYS_FOR_SPFFFAMILYSID) return `Error ${error_FETCH_SP_FF_FAMILYS_FOR_SPFFFAMILYSID}`;

    const handlerChangeName = (e) => {
        setValues({
            ...values,
            [e.target.name]: e.target.value,
        });
    };

    const formSubmit = (e) => {
        e.preventDefault();
        addPtFormFactor();
        setValues({ name: "" })
    }

    const updateData = (value) => {
        setIcon({ fileName: value })
    }

    const updateDataImage = (value) => {
        setImage({ fileName: value })
    }

    return (
        <Add
            title={"Добавление formfactor"}
            ButtonText={"Добавить formfactor"}
            dialogTitle={"Добавление нового formfactor"}
            formSubmit={formSubmit}
            Fields={<>
             <UploadIcon
                    label="formfactorIcon"
                    value={values.formfactorIcon}
                    // email={values.email}
                    nameData="pr_gslc_i_formfactor"
                    method={"pr/gslc/icons/formfactor"}
                    dirIcon={"formfactor"}
                    updateData={updateData}
                /><br></br>
                <RequiredField
                    label={"formfactorIcon"}
                    value={values.formfactorIcon = icon.fileName.toString()}
                    onChange={handlerChangeName}
                    name={"formfactorIcon"}
                /><br></br>
                <UploadImage
                    label="formfactorImage"
                    value={values.formfactorImage}
                    // email={values.email}
                    nameData="pr_gslc_img_formfactor"
                    method={"pr/gslc/images/formfactor"}
                    dirIcon={"formfactor"}
                    updateDataImage={updateDataImage}
                /><br></br>
                <RequiredField
                    label={"formfactorImage"}
                    value={values.formfactorImage = image.fileName.toString()}
                    onChange={handlerChangeName}
                    name={"formfactorImage"}
                /><br></br>
                <RequiredField
                    label={"name"}
                    value={values.name}
                    onChange={handlerChangeName}
                    name={"name"}
                />
                <br></br>
                <OptionalMultiRowsField
                    label="Дополнительная информация"
                    name="info"
                    value={values.info}
                    onChange={handlerChangeName}
                    rows={10}
                />
                <br></br>
                <RequiredSelect
                    label={"Инструмент"}
                    name={"spInstrument_id"}
                    value={values.spInstrument_id}
                    onChange={handlerChangeName}
                    fetch={data_FETCH_SP_INSTRUMENTS_FOR_SPINSTRUMENTID.getSpInstruments}
                />
                <br></br>
                <RequiredSelect
                    label={"Инструмент"}
                    name={"spInstrumentType_id"}
                    value={values.spInstrumentType_id}
                    onChange={handlerChangeName}
                    fetch={data_FETCH_SP_INSTRUMENTS_TYPES_FOR_SPINSTRUMENTTYPEID.getSpInstrumentsTypes}
                />
                <br></br>
                <RequiredSelect
                    label={"spFfClass_id"}
                    name={"spFfClass_id"}
                    value={values.spFfClass_id}
                    onChange={handlerChangeName}
                    fetch={data_FETCH_SP_FF_CLASSES_FOR_SPFFCLASSESID.getSpFFClasses}
                />
                <br></br>
                <RequiredSelect
                    label={"spFfFamily_id"}
                    name={"spFfFamily_id"}
                    value={values.spFfFamily_id}
                    onChange={handlerChangeName}
                    fetch={data_FETCH_SP_FF_FAMILYS_FOR_SPFFFAMILYSID.getSpFFFamilys}
                />
                <br></br>
                <OptionalField
                    label={"year"}
                    value={values.year}
                    onChange={handlerChangeName}
                    name={"year"}
                />
                <br></br>
                <OptionalField
                    label={"sourse"}
                    value={values.sourse}
                    onChange={handlerChangeName}
                    name={"sourse"}
                />
                <br></br>
            </>} />
    )

}

export default AddPtFormFactor;