import React from 'react';
import { useQuery } from '@apollo/client';
import { FETCH_PT_PICKGUARDS } from './gqlPtPickguard';
import GetPtPickguard from './getPtPickguard';
import { UpdatePtPickguard } from './updatePtPickguard';
import { DeletePtPickguard } from './deletePtPickguard';
import Loader from '../../../../components/main/Loader';
import CardDatabaseItem from '../../../../components/view/cards/cardDatabaseItem';
import Pagination from '@material-ui/lab/Pagination';

const GetPtPickguards = () => {
    const [page, setPage] = React.useState(1);

    const { loading, error, data } = useQuery(FETCH_PT_PICKGUARDS, {
        variables: {
            sheet: page,
            limit: 10
        },
    });

    if (loading) return <Loader />;
    if (error) return `Error ${error}`;

    const handleChange = (event, value) => {
        setPage(value);
    };

    return (
        <>
            <Pagination count={100} page={page} onChange={handleChange} />

            {data.getPtPickguards.map((PtPickguard, index) => {
                return (
                    <CardDatabaseItem
                        name={PtPickguard.name}
                        nameIcon={PtPickguard.pickguardIcon}
                        info={PtPickguard.info}
                        dirName={"icons/pickguard"}
                        actions={
                            <>
                                <GetPtPickguard id={PtPickguard._id} />
                                <UpdatePtPickguard
                                    id={PtPickguard._id}
                                    name={PtPickguard.name}
                                    info={PtPickguard.info}
                                    ptFormFactor_id={PtPickguard.ptFormFactor_id._id}
                                    pickguardImage={PtPickguard.pickguardImage}
                                    pickguardIcon={PtPickguard.pickguardIcon}
                                />
                                <DeletePtPickguard id={PtPickguard._id} />
                            </>} />
                )
            })}
        </>
    )
}

export default GetPtPickguards;