import React from 'react';
import { TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 300,
    },
}));

const RequiredField = ({ value, onChange, name, label }) => {
    const classes = useStyles();

    const error = value === "";
    return (

        <TextField className={classes.formControl} 
            id="standard-basic"
            label={label}
            type="text"
            name={name}
            value={value}
            onChange={onChange}
            helperText={error ? "Обязательное поле" : ""}
            error={error}
            variant="outlined"
        >
        </TextField>
    )

}

export default RequiredField;