import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Card, CardContent, CardMedia, Container } from '@material-ui/core';
import { red } from '@material-ui/core/colors';

const useStyles = makeStyles((theme) => ({
    icon: {
        width: 100
    },
    root: {
        width: 500
    },
    avatar: {
        backgroundColor: red[500],
    },
    large: {
        width: "100%",
    },
}));

const CardDatabaseItemFilter = ({ nameIcon, dirName}) => {
    const classes = useStyles();

    const imgOnServer = `http://localhost:5000/public/dataSystem/product/gslCard/${dirName}/`;
    return (
        <>
            <Container >
                <Box display="flex" justifyContent="center">
                    <Card className={classes.icon}>
                        <CardContent>
                            <Box display="flex">
                                <Box flexGrow={0}>
                                    <CardMedia className={classes.large}
                                        component="img"
                                    image={`${imgOnServer}${nameIcon}`} />
                                </Box> 
                            </Box>
                        </CardContent>
                    </Card>
                </Box>
            </Container>
            <br></br>
        </>
    )
}

export default CardDatabaseItemFilter;