import React from 'react';
import { useMutation } from '@apollo/client';
import { Delete } from '../../../../components/actions/delete';
import { DELETE_SP_STATUS_APPEAL, FETCH_SP_STATUSES_APPEALS } from './gqlSpStatusAppeal';


export const DeleteSpStatusAppeal = ({ id }) => {
  const [deleteSpStatusAppeal] = useMutation(DELETE_SP_STATUS_APPEAL, {

    onError(e) {
      console.log(e.graphQLErrors[0].message)
    },
    variables: { id },
    refetchQueries: [{ query: FETCH_SP_STATUSES_APPEALS }]
  });

  const onDelete = () => {
    deleteSpStatusAppeal()
  }

  return (
    <Delete onDelete={onDelete} />
  )
}