import React from 'react';
import { useQuery } from '@apollo/client';
import { FETCH_SP_PICKUPS_POSITIONS } from './gqlSpPickupPositions';
import GetSpPickupPositions from './getSpPickupPositions';
import { DeleteSpPickupPositions } from './deleteSpPickupPositions';
import { UpdateSpPickupPositions } from './updateSpPickupPositions';
import Loader from '../../../../components/main/Loader';
import { Box, Container } from '@material-ui/core';

const GetSpPickupsPositions = () => {

    const { loading, error, data } = useQuery(FETCH_SP_PICKUPS_POSITIONS);

    if (loading) return <Loader />;
    if (error) return `Error ${error}`;

    return (
        <>
            <Container>
                <Box display="flex" justifyContent="center">
                    <table>
                        <thead>
                            <tr>
                                <th>№</th>
                                <th>Наименование</th>
                                <th>CODE</th>
                                <th>Дата создания</th>
                                <th>Дата изменения</th>
                                <th>Действия</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.getSpPickupsPositions.map((SpPickupPositions, index) => {
                                return (
                                    <tr key={SpPickupPositions._id}>
                                        <td>{index + 1}</td>
                                        <td>{SpPickupPositions.name}</td>
                                        <td>{SpPickupPositions.code}</td>
                                        <td>{SpPickupPositions.createdAt}</td>
                                        <td>{SpPickupPositions.updatedAt}</td>
                                        <td>
                                            <GetSpPickupPositions id={SpPickupPositions._id} />
                                            <UpdateSpPickupPositions
                                                id={SpPickupPositions._id}
                                                name={SpPickupPositions.name}
                                                info={SpPickupPositions.info}
                                                spInstrument_id={SpPickupPositions.spInstrument_id._id}
                                                spInstrumentType_id={SpPickupPositions.spInstrumentType_id._id}
                                                code={SpPickupPositions.code} />
                                            <DeleteSpPickupPositions id={SpPickupPositions._id} />
                                        </td>
                                    </tr>)
                            })}
                        </tbody>
                    </table>
                </Box>
            </Container>
        </>
    )
}

export default GetSpPickupsPositions;