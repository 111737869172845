import React from 'react';
import { useMutation } from '@apollo/client';
import { FETCH_USER, DELETE_USER_ROLE_IN_USER } from './gqlCurrentUser';
import { Delete } from '../../components/actions/delete';

export const DeleteUserRoleInUser = ({ id, userRole_id }) => {

    const [deleteUserRoleInUser] = useMutation(DELETE_USER_ROLE_IN_USER, {

        onError(e) {
            alert(e.graphQLErrors[0].message)
        },
        variables: { id, userRole_id },
        refetchQueries: [{ query: FETCH_USER, variables: { id } }]
    })

    const onDelete = () => {
        deleteUserRoleInUser()
    }

    return (
            <Delete
                onDelete={onDelete} />
    )
}