import { gql } from "@apollo/client";

export const FETCH_USERS_ROLES = gql`
{
    getUsersRoles{
        _id
        name
        info
        createdAt
        updatedAt
    }
}
`;

export const FETCH_USER_ROLE = gql`
query getUserRole($id: ID) {
  getUserRole(_id: $id) {
    _id
    name
    info
    createdAt
    updatedAt
  }
}
`;

export const ADD_USER_ROLE = gql`

   mutation addUserRole($name: String, $info: String){
        addUserRole(name: $name, info: $info) {
            _id   
        }      
    }
`;

export const DELETE_USER_ROLE = gql`
 mutation deleteUserRole($id: ID) {
    deleteUserRole(_id: $id) {
       _id
    }
 }
`;

export const UPDATE_USER_ROLE = gql`
 mutation updateUserRole($id: ID, $name: String, $info: String) {
  updateUserRole(_id: $id, name: $name, info: $info) {
    _id
    }
 }
`;

export const FETCH_USERS_ROLES_FOR_USERROLEID = gql`
{
    getUsersRoles{
        _id
        name
    }
}
`;