import React from 'react';
import { useQuery } from '@apollo/client';
import { FETCH_SP_NUMBERS_OF_STRINGS } from './gqlSpNumberOfStrings';
import GetSpNumberOfStrings from './getSpNumberOfStrings';
import { DeleteSpNumberOfStrings } from './deleteSpNumberOfStrings';
import { UpdateSpNumberOfStrings } from './updateSpNumberOfStrings';
import Loader from '../../../../components/main/Loader';
import { Box, Container } from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';

const GetSpNumbersOfStrings = () => {
    const [page, setPage] = React.useState(1);

    const { loading, error, data } = useQuery(FETCH_SP_NUMBERS_OF_STRINGS, {
        variables: {
            sheet: page,
            limit: 10
        },
    });

    if (loading) return <Loader/>;
    if (error) return `Error ${error}`;

    const handleChange = (event, value) => {
        setPage(value);
    };

    return (
        <>
        <Pagination count={100} page={page} onChange={handleChange} />
            <Container>
                <Box display="flex" justifyContent="center">
                    <table>
                        <thead>
                            <tr>
                                <th>№</th>
                                <th>Наименование</th>
                                <th>CODE</th>
                                <th>Дата создания</th>
                                <th>Дата изменения</th>
                                <th>Действия</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.getSpNumbersOfStrings.map((spNumberOfStrings, index) => {
                                return (
                                    <tr key={spNumberOfStrings._id}>
                                        <td>{index + 1}</td>
                                        <td>{spNumberOfStrings.name}</td>
                                        <td>{spNumberOfStrings.code}</td>
                                        <td>{spNumberOfStrings.createdAt}</td>
                                        <td>{spNumberOfStrings.updatedAt}</td>
                                        <td>
                                            <GetSpNumberOfStrings id={spNumberOfStrings._id} />
                                            <UpdateSpNumberOfStrings 
                                            id={spNumberOfStrings._id} 
                                            name={spNumberOfStrings.name} 
                                            info={spNumberOfStrings.info}
                                            spInstrument_id={spNumberOfStrings.spInstrument_id._id}
                                            spInstrumentType_id={spNumberOfStrings.spInstrumentType_id._id}
                                            code={spNumberOfStrings.code}
                                            spInstrumentName={spNumberOfStrings.spInstrument_id._id}/>
                                            <DeleteSpNumberOfStrings id={spNumberOfStrings._id} />
                                        </td>
                                    </tr>)
                            })}
                        </tbody>
                    </table>
                </Box>
            </Container>
        </>
    )
}

export default GetSpNumbersOfStrings;