import { gql } from "@apollo/client";

export const FETCH_SP_STATUSES_APPEALS = gql`
    {
        getSpStatusesAppeals{
            _id,
            name,
            info,
            createdAt,
            updatedAt
        }
    }
`;

export const FETCH_SP_STATUS_APPEAL = gql`
    query getSpStatusAppeal($id: ID) {
        getSpStatusAppeal(_id: $id) {
            _id,
            name,
            info,
            createdAt,
            updatedAt
    }
}
`;

export const ADD_SP_STATUS_APPEAL = gql`
    mutation addSpStatusAppeal( $input: inputSpStatusAppeal ) {
            addSpStatusAppeal( input: $input ) {
                    _id
            }      
    }
`;

export const DELETE_SP_STATUS_APPEAL = gql`
    mutation deleteSpStatusAppeal($id: ID) {
        deleteSpStatusAppeal(_id: $id) {
            _id
        }
    }
`;

export const UPDATE_SP_STATUS_APPEAL = gql`
    mutation updateSpStatusAppeal( $id: ID, $input: inputSpStatusAppeal ) {
            updateSpStatusAppeal( _id: $id, input: $input ) {
                    _id
            }
    }
`;

export const FETCH_SP_STATUSES_APPEALS_FOR_SPSTATUSAPPEALSID = gql`
    {
        getSpStatusesAppeals{
            _id,
            name
        }
    }
`;