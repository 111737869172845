import React from 'react';
import { useQuery } from '@apollo/client';
import { FETCH_SP_PICKUPS_MODES } from './gqlSpPickupMode';
import GetSpPickupMode from './getSpPickupMode';
import { DeleteSpPickupMode } from './deleteSpPickupMode';
import { UpdateSpPickupMode } from './updateSpPickupMode';
import Loader from '../../../../components/main/Loader';
import { Box, Container } from '@material-ui/core';

const GetSpPickupsModes = () => {

    const { loading, error, data } = useQuery(FETCH_SP_PICKUPS_MODES);

    if (loading) return <Loader/>;
    if (error) return `Error ${error}`;

    return (
        <>
            <Container>
                <Box display="flex" justifyContent="center">
                    <table>
                        <thead>
                            <tr>
                                <th>№</th>
                                <th>Наименование</th>
                                <th>Информация</th>
                                <th>Дата создания</th>
                                <th>Дата изменения</th>
                                <th>Действия</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.getSpPickupsModes.map((SpPickupMode, index) => {
                                return (
                                    <tr key={SpPickupMode._id}>
                                        <td>{index + 1}</td>
                                        <td>{SpPickupMode.name}</td>
                                        <td>{SpPickupMode.info}</td>
                                        <td>{SpPickupMode.createdAt}</td>
                                        <td>{SpPickupMode.updatedAt}</td>
                                        <td>
                                            <GetSpPickupMode id={SpPickupMode._id} />
                                            <UpdateSpPickupMode id={SpPickupMode._id} name={SpPickupMode.name} info={SpPickupMode.info}/>
                                            <DeleteSpPickupMode id={SpPickupMode._id} />
                                        </td>
                                    </tr>)
                            })}
                        </tbody>
                    </table>
                </Box>
            </Container>
        </>
    )
}

export default GetSpPickupsModes;