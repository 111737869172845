import React from 'react';
import { useQuery } from '@apollo/client';
import { FETCH_USERS } from './gqlCurrentUser';
import GetUser from './getUser';
import { UpdateUser } from './updateUser';
import Loader from '../../components/main/Loader';
import { makeStyles } from '@material-ui/core/styles';
import { Avatar, Box, Button, Card, CardContent, Container, Typography } from '@material-ui/core';
import { red } from '@material-ui/core/colors';

const useStyles = makeStyles((theme) => ({
    root: {
        width: 615
    },
    avatar: {
        backgroundColor: red[500],
    },
}));

const GetUsers = () => {
    const classes = useStyles();
    const { loading, error, data } = useQuery(FETCH_USERS);

    if (loading) return <Loader />;
    if (error) return `Error ${error}`;

    return (data.getUsers.map(user =>
        <div key={user._id}> 
            <Container >
                <Box display="flex" justifyContent="center">
                    <Card className={classes.root}>
                        <CardContent>
                            <Box display="flex">
                                <Box flexGrow={0}>
                                    <Avatar aria-label="recipe" className={classes.avatar} >
                                        {user.email.charAt(0).toUpperCase()}
                                    </Avatar>
                                </Box>
                                <Typography>{user.email}</Typography>
                                <Button>Follow</Button>
                            </Box>
                        </CardContent>
                    </Card>
                    <GetUser id={user._id} />
                    <UpdateUser
                        id={user._id}
                        email={user.email}
                        password={user.password}
                        firstName={user.firstName}
                        userRole_id={user.userRole_id._id}
                        avatar={user.avatar}
                        canvas={user.canvas}
                    />
                </Box>
            </Container>
            <br></br>
        </div>)
    )
}

export default GetUsers;