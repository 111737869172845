
import React from 'react';
import { useQuery } from '@apollo/client';
import { FETCH_SP_BRIDGES_CLASSES } from './gqlSpBridgeClass';
import GetSpBridgeClass from './getSpBridgeClass';
import { DeleteSpBridgeClass } from './deleteSpBridgeClass';
import { UpdateSpBridgeClass } from './updateSpBridgeClass';
import Loader from '../../../../components/main/Loader';
import { Box, Container } from '@material-ui/core';

const GetSpBridgesClasses = () => {

    const { loading, error, data } = useQuery(FETCH_SP_BRIDGES_CLASSES);

    if (loading) return <Loader/>;
    if (error) return `Error ${error}`;

    return (
        <>
            <Container>
                <Box display="flex" justifyContent="center">
                    <table>
                        <thead>
                            <tr>
                                <th>№</th>
                                <th>Наименование</th>
                                <th>Информация</th>
                                <th>Дата создания</th>
                                <th>Дата изменения</th>
                                <th>Действия</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.getSpBridgesClasses.map((SpBridgeClass, index) => {
                                return (
                                    <tr key={SpBridgeClass._id}>
                                        <td>{index + 1}</td>
                                        <td>{SpBridgeClass.name}</td>
                                        <td>{SpBridgeClass.info}</td>
                                        <td>{SpBridgeClass.createdAt}</td>
                                        <td>{SpBridgeClass.updatedAt}</td>
                                        <td>
                                            <GetSpBridgeClass id={SpBridgeClass._id} />
                                            <UpdateSpBridgeClass id={SpBridgeClass._id} name={SpBridgeClass.name} info={SpBridgeClass.info}/>
                                            <DeleteSpBridgeClass id={SpBridgeClass._id} />
                                        </td>
                                    </tr>)
                            })}
                        </tbody>
                    </table>
                </Box>
            </Container>
        </>
    )
}

export default GetSpBridgesClasses;