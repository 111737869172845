import React, { useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { FETCH_SP_PICKUPS_POSITIONS, UPDATE_SP_PICKUP_POSITION } from './gqlSpPickupPositions';
import { FETCH_SP_INSTRUMENTS_FOR_SPINSTRUMENTID } from '../spInstrument/gqlSpInstrument';
import { FETCH_SP_INSTRUMENTS_TYPES_FOR_SPINSTRUMENTTYPEID } from '../spInstrumentType/gqlSpInstrumentType';
import Loader from '../../../../components/main/Loader';
import Update from '../../../../components/actions/update';
import RequiredField from '../../../../components/InputElements/requiredField';
import OptionalMultiRowsField from '../../../../components/InputElements/optionalMultiRowsField';
import RequiredSelect from '../../../../components/InputElements/requiredSelect';


export const UpdateSpPickupPositions = ({ id, name, info, code, spInstrument_id, spInstrumentType_id }) => {

    const [values, setValues] = useState({
        name, info, code, spInstrument_id, spInstrumentType_id
    });

    const [updateSpPickupPositions] = useMutation(UPDATE_SP_PICKUP_POSITION, {

        onError(e) {
            console.log(e.graphQLErrors[0].message)
        },
        variables: { id, name: values.name, info: values.info, code: values.code, 
            spInstrument_id: values.spInstrument_id, spInstrumentType_id: values.spInstrumentType_id },
        refetchQueries: [{ query: FETCH_SP_PICKUPS_POSITIONS }]
    })

    const { loading: loading_FETCH_SP_INSTRUMENTS_FOR_SPINSTRUMENTID,
        error: error_FETCH_SP_INSTRUMENTS_FOR_SPINSTRUMENTID,
        data: data_FETCH_SP_INSTRUMENTS_FOR_SPINSTRUMENTID } = useQuery(FETCH_SP_INSTRUMENTS_FOR_SPINSTRUMENTID, {
            fetchPolicy: "no-cache"
        });
    const { loading: loading_FETCH_SP_INSTRUMENTS_TYPES_FOR_SPINSTRUMENTTYPEID,
        error: error_FETCH_SP_INSTRUMENTS_TYPES_FOR_SPINSTRUMENTTYPEID,
        data: data_FETCH_SP_INSTRUMENTS_TYPES_FOR_SPINSTRUMENTTYPEID } = useQuery(FETCH_SP_INSTRUMENTS_TYPES_FOR_SPINSTRUMENTTYPEID, {
            fetchPolicy: "no-cache"
        });

    if (loading_FETCH_SP_INSTRUMENTS_FOR_SPINSTRUMENTID) return <Loader />;
    if (error_FETCH_SP_INSTRUMENTS_FOR_SPINSTRUMENTID) return `Error ${error_FETCH_SP_INSTRUMENTS_FOR_SPINSTRUMENTID}`;

    if (loading_FETCH_SP_INSTRUMENTS_TYPES_FOR_SPINSTRUMENTTYPEID) return <Loader />;
    if (error_FETCH_SP_INSTRUMENTS_TYPES_FOR_SPINSTRUMENTTYPEID) return `Error ${error_FETCH_SP_INSTRUMENTS_TYPES_FOR_SPINSTRUMENTTYPEID}`;

    const handlerChangeName = (e) => {
        setValues({
            ...values,
            [e.target.name]: e.target.value
        })
    }

    const formSubmit = (e) => {
        e.preventDefault();
        updateSpPickupPositions();
    }

    return (
        <Update
            title={"Изменение проблемы"}
            ButtonText={"Изменить проблему"}
            dialogTitle={"Изменение проблемы"}
            formSubmit={formSubmit}
            Fields={<>
                <RequiredField
                    label={"Наименование"}
                    value={values.name}
                    onChange={handlerChangeName}
                    name={"name"}
                />
                <br></br>
                <OptionalMultiRowsField
                    label={"Информация"}
                    value={values.info}
                    onChange={handlerChangeName}
                    name={"info"}
                />
                <br></br>
                <RequiredSelect
                    label={"Инструмент"}
                    name={"spInstrument_id"}
                    value={values.spInstrument_id}
                    onChange={handlerChangeName}
                    fetch={data_FETCH_SP_INSTRUMENTS_FOR_SPINSTRUMENTID.getSpInstruments}
                />
                <br></br>
                <RequiredSelect
                    label={"Тип инструмента"}
                    name={"spInstrumentType_id"}
                    value={values.spInstrumentType_id}
                    onChange={handlerChangeName}
                    fetch={data_FETCH_SP_INSTRUMENTS_TYPES_FOR_SPINSTRUMENTTYPEID.getSpInstrumentsTypes}
                />
            </>} />
    )
};