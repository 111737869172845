import React, { useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { ADD_AREA, FETCH_AREAS } from './gqlArea';
import { FETCH_USERS_ROLES_FOR_USERROLEID } from '../userRole/gqlUserRole';
import Loader from '../../../components/main/Loader';
import OptionalMultiRowsField from '../../../components/InputElements/optionalMultiRowsField';
import RequiredSelect from '../../../components/InputElements/requiredSelect';
import Add from '../../../components/actions/add';
import RequiredField from '../../../components/InputElements/requiredField';

const AddArea = () => {

    const [values, setValues] = useState({
        name: ""
    });

    const [addArea] = useMutation(ADD_AREA, {

        onError(e) {
            alert(e)
        },
        variables: values,
        refetchQueries: [{ query: FETCH_AREAS }, { query: FETCH_USERS_ROLES_FOR_USERROLEID }]
    });

    const { loading, error, data } = useQuery(FETCH_USERS_ROLES_FOR_USERROLEID);

    if (loading) return <Loader />;
    if (error) return `Error ${error}`;

    const handlerChangeName = (e) => {
        setValues({
            ...values,
            [e.target.name]: e.target.value,
        });
    };

    const formSubmit = (e) => {
        e.preventDefault();
        addArea();
        setValues({ name: "" })
    }

    return (
        <Add
            title={"Добавление пространства"}
            ButtonText={"Добавить пространство"}
            dialogTitle={"Добавление нового пространства"}
            formSubmit={formSubmit}
            Fields={<>
                <RequiredField
                    label={"name"}
                    value={values.name}
                    onChange={handlerChangeName}
                    name={"name"}
                /> <br></br>
                <OptionalMultiRowsField
                    label="Дополнительная информация"
                    name="info"
                    value={values.info}
                    onChange={handlerChangeName}
                    rows={10}
                /> <br></br>
                <RequiredSelect
                    label={"Роли"}
                    name={"userRole_id"}
                    value={values.userRole_id}
                    onChange={handlerChangeName}
                    fetch={data.getUsersRoles}
                />
            </>} />

    )

}

export default AddArea;