
import React from 'react';
import { useQuery } from '@apollo/client';
import { FETCH_USERS_ROLES } from './gqlUserRole';
import GetUserRole from './getUserRole';
import { DeleteUserRole } from './deleteUserRole';
import { UpdateUserRole } from './UpdateUserRole';
import Loader from '../../../components/main/Loader';
import { Box, Container } from '@material-ui/core';

const GetUsersRoles = () => {

    const { loading, error, data } = useQuery(FETCH_USERS_ROLES);

    if (loading) return <Loader />;
    if (error) return `Error ${error}`;

    return (
        <>
            <Container>
                <Box display="flex" justifyContent="center">
                    <table>
                        <thead>
                            <tr>
                                <th>№</th>
                                <th>Наименование</th>
                                <th>Информация</th>
                                <th>Дата создания</th>
                                <th>Дата изменения</th>
                                <th>Действия</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.getUsersRoles.map((userRole, index) => {
                                return (
                                    <tr key={userRole._id}>
                                        <td>{index + 1}</td>
                                        <td>{userRole.name}</td>
                                        <td>{userRole.info}</td>
                                        <td>{userRole.createdAt}</td>
                                        <td>{userRole.updatedAt}</td>
                                        <td>
                                            <GetUserRole id={userRole._id} />
                                        </td>
                                        <td>
                                            <UpdateUserRole id={userRole._id} name={userRole.name} info={userRole.info}>Edit</UpdateUserRole>
                                        </td>
                                        <td>
                                            <DeleteUserRole id={userRole._id} />
                                        </td>
                                    </tr>)
                            })}
                        </tbody>
                    </table>
                </Box>
            </Container>
        </>
    )
}

export default GetUsersRoles;