import { gql } from "@apollo/client";

export const FETCH_SP_INSTRUMENTS_TYPES = gql`
    {
        getSpInstrumentsTypes{
            _id,
            name,
            info,
            instrumentTypeIcon,
            createdAt,
            updatedAt
        }
    }
`;

export const FETCH_SP_INSTRUMENT_TYPE = gql`
    query getSpInstrumentType($id: ID) {
        getSpInstrumentType(_id: $id) {
            _id,
            name,
            info,
            instrumentTypeIcon,
            createdAt,
            updatedAt
        }
    }
`;

export const ADD_SP_INSTRUMENT_TYPE = gql`
    mutation addSpInstrumentType(
       $name: String, 
       $info: String,
       $instrumentTypeIcon: String){
            addSpInstrumentType(
                name: $name, 
                info: $info,
                instrumentTypeIcon: $instrumentTypeIcon) {
                    _id
                }      
        }  
`;

export const DELETE_SP_INSTRUMENT_TYPE = gql`
    mutation deleteSpInstrumentType($id: ID) {
        deleteSpInstrumentType(_id: $id) {
            _id
        }
    }
`;

export const UPDATE_SP_INSTRUMENT_TYPE = gql`
    mutation updateSpInstrumentType(
        $id: ID, 
        $name: String, 
        $info: String,
        $instrumentTypeIcon: String) {
            updateSpInstrumentType(
                _id: $id, 
                name: $name, 
                info: $info,
                instrumentTypeIcon: $instrumentTypeIcon) {
                    _id
                }
            }
`;

export const FETCH_SP_INSTRUMENTS_TYPES_FOR_SPINSTRUMENTTYPEID = gql`
    {
        getSpInstrumentsTypes{
            _id
            name
        }
    }
`;