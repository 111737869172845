import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { FETCH_SP_INSTRUMENTS_TYPES, UPDATE_SP_INSTRUMENT_TYPE } from './gqlSpInstrumentType';
import Update from '../../../../components/actions/update';
import UploadIcon from '../../../../components/actions/uploadIcon';
import RequiredField from '../../../../components/InputElements/requiredField';


export const UpdateSpInstrumentType = ({ id, name, info, instrumentTypeIcon }) => {

    const [values, setValues] = useState({
        name, info, instrumentTypeIcon
    });

    const [updateSpInstrumentType] = useMutation(UPDATE_SP_INSTRUMENT_TYPE, {

        onError(e) {
            console.log(e.graphQLErrors[0].message)
        },
        variables: { id, name: values.name, info: values.info, instrumentTypeIcon: values.instrumentTypeIcon },
        refetchQueries: [{ query: FETCH_SP_INSTRUMENTS_TYPES }]
    })

    const handlerChangeName = (e) => {
        setValues({
            ...values,
            [e.target.name]: e.target.value
        })
    }

    const formSubmit = (e) => {
        e.preventDefault();
        updateSpInstrumentType();
    }

    const [state, setState] = useState({
        fileName: instrumentTypeIcon
    });

    const updateData = (value) => {
        setState({ fileName: value })
    }

    return (
        <Update
            title={"Изменение instrumentTypeIcon"}
            ButtonText={"Изменить instrumentTypeIcon"}
            dialogTitle={"Изменение instrumentTypeIcon"}
            formSubmit={formSubmit}
            Fields={<>
                <UploadIcon
                    label="instrumentTypeIcon"
                    value={values.instrumentTypeIcon}
                    email={values.email}
                    nameData="pr_gslc_i_instrumentType"
                    method={"pr/gslc/icons/instrumentType"}
                    dirIcon={"instrumentType"}
                    updateData={updateData}
                /><br></br>
                <RequiredField
                    label={"instrumentTypeIcon"}
                    value={values.instrumentTypeIcon = state.fileName.toString()}
                    onChange={handlerChangeName}
                    name={"instrumentTypeIcon"}
                /><br></br>
                <RequiredField
                    label={"Наименование"}
                    value={values.name}
                    onChange={handlerChangeName}
                    name={"name"}
                /><br></br>
                <RequiredField
                    label={"info"}
                    value={values.info}
                    onChange={handlerChangeName}
                    name={"info"}
                />
            </>}
        />
    )
}