import React from 'react';
import { useMutation } from '@apollo/client';
import { DELETE_SP_BRIDGE_FAMILY, FETCH_SP_BRIDGES_FAMILYS } from './gqlSpBridgeFamily';
import { Delete } from '../../../../components/actions/delete';


export const DeleteSpBridgeFamily = ({ id }) => {
  const [deleteSpBridgeFamily] = useMutation(DELETE_SP_BRIDGE_FAMILY, {

    onError(e) {
      console.log(e.graphQLErrors[0].message)
    },
    variables: { id },
    refetchQueries: [{ query: FETCH_SP_BRIDGES_FAMILYS }]
  });

  const onDelete = () => {
    deleteSpBridgeFamily()
  }
  return (
    <Delete onDelete={onDelete} />
  )
}