import React from 'react';
import { useMutation } from '@apollo/client';
import { DELETE_SP_COUNTRY, FETCH_SP_COUNTRYS } from './gqlSpCountry';
import { Delete } from '../../../../components/actions/delete';


export const DeleteSpCountry = ({ id }) => {
  const [deleteSpCountry] = useMutation(DELETE_SP_COUNTRY, {

    onError(e) {
      console.log(e.graphQLErrors[0].message)
    },
    variables: { id },
    refetchQueries: [{ query: FETCH_SP_COUNTRYS }]
  });

  const onDelete = () => {
    deleteSpCountry()
  }
  return (
    <Delete onDelete={onDelete} />
  )
}