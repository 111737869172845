import React, { useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { FETCH_CURRENT_USER, UPDATE_USER } from './gqlCurrentUser';
import { FETCH_USERS_ROLES_FOR_USERROLEID } from '../administrator/userRole/gqlUserRole';
import Loader from '../../components/main/Loader';
import { Box, Card, Typography } from '@material-ui/core';
import UploadForm from '../../components/actions/uploadForm';
import Add from '../../components/actions/add';
import RequiredField from '../../components/InputElements/requiredField';
import { AddUserRoleForUser } from './addUserRoleForUser';
import { DeleteUserRoleInUser } from './deleteUserRoleInUser';

export const UpdateUser = ({ id, email, password, firstName, lastName, userRole_id, avatar, canvas }) => {

    const [values, setValues] = useState({
        email, password, firstName, lastName, userRole_id, avatar, canvas
    });

    const [updateUser] = useMutation(UPDATE_USER, {

        onError(e) {
            console.log(e.graphQLErrors[0].message)
        },
        variables: {
            id, email: values.email, password: values.password,
            firstName: values.firstName, lastName: values.lastName, userRole_id: values.userRole_id, avatar: values.avatar,
            canvas: values.canvas
        },
        refetchQueries: [{
            query: FETCH_CURRENT_USER, variables: {
                id
            }
        }]
    })

    const { loading, error, data } = useQuery(FETCH_CURRENT_USER, {
        variables: { id: id },
    });

    if (loading) return <Loader />;
    if (error) return `Error ${error}`;

    const handlerChangeName = (e) => {
        setValues({
            ...values,
            [e.target.name]: e.target.value
        })
    }

    const formSubmit = (e) => {
        e.preventDefault();
        updateUser();
    }

    return (
        <Add
            title={"Настройки"}
            ButtonText={"Настройки"}
            dialogTitle={"Настройки"}
            formSubmit={formSubmit}
            Fields={<>
                <Typography> Безопасность </Typography>
                <br></br>
                <br></br>
                <RequiredField
                    label={"email"}
                    value={values.email}
                    onChange={handlerChangeName}
                    name={"email"}
                />
                <RequiredField
                    label={"password"}
                    value={values.password}
                    onChange={handlerChangeName}
                    name={"password"}
                />
                <br></br>
                <br></br>
                <Typography> Основное </Typography>
                <br></br>
                <br></br>
                <RequiredField
                    label={"firstName"}
                    value={values.firstName}
                    onChange={handlerChangeName}
                    name={"firstName"}
                />
                <RequiredField
                    label={"lastName"}
                    value={values.lastName}
                    onChange={handlerChangeName}
                    name={"lastName"}
                />
                <br></br>
                <br></br>

                <Typography component={'span'}> Роли
                    {data.getUser.userRole_id.map((userRole_id, index) => {

                        return (
                            <Card key={userRole_id._id}>
                                {[userRole_id.name]}
                                <DeleteUserRoleInUser
                                    id={id}
                                    userRole_id={userRole_id._id}
                                />
                            </Card>
                        )
                    })}
                </Typography>
                <AddUserRoleForUser
                    id={id}
                    userRole_id={userRole_id}
                />

                <br></br>
                <br></br>

                <br></br>
                <br></br>
                <Typography> Контактная информация </Typography>
                <br></br>
                <br></br>
                <Typography> E-mail: {values.email} </Typography>
                <br></br>
                <br></br>
                <Typography> Персонализация </Typography>
                <br></br>
                <br></br>
                <UploadForm
                    label="avatar"
                    name="avatar"
                    value={values.avatar}
                    onChange={handlerChangeName}
                    email={values.email}
                    method={"userAvatar"}
                />
                <br></br>
                <UploadForm
                    label="canvas"
                    name="canvas"
                    value={values.canvas}
                    onChange={handlerChangeName}
                    email={values.email}
                    method={"userCanvas"}
                />
            </>}
        />
    )
}