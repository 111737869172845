import React from 'react';
import { useQuery } from '@apollo/client';
import { FETCH_PT_FORMFACTOR } from './gqlPtFormFactor';
import Loader from '../../../../components/main/Loader';
import Open from '../../../../components/actions/open';
import OpenDataId from '../../../../components/view/openDataId';
import { UpdatePtFormFactor } from './updatePtFormFactor';
import ViewImage from '../../../../components/view/displayingData/viewImage';
import ViewIcon from '../../../../components/view/displayingData/viewIcon';

const GetPtFormFactor = ({ id }) => {

  const { loading, error, data } = useQuery(FETCH_PT_FORMFACTOR, {
    variables: { id },
  });

  if (loading) return <Loader />;
  if (error) return `Error ${error}`;

  return (
    <Open
      title={"Открыть"}
      dialogTitle={data.getPtFormFactor.name}
      edit={<UpdatePtFormFactor
        id={data.getPtFormFactor._id}
        name={data.getPtFormFactor.name}
        info={data.getPtFormFactor.info}
        spInstrument_id={data.getPtFormFactor.spInstrument_id._id}
        spInstrumentType_id={data.getPtFormFactor.spInstrumentType_id._id}
        spFfClass_id={data.getPtFormFactor.spFfClass_id._id}
        spFfFamily_id={data.getPtFormFactor.spFfFamily_id._id}
        year={data.getPtFormFactor.year}
        sourse={data.getPtFormFactor.sourse}
        formfactorImage={data.getPtFormFactor.formfactorImage}
        formfactorIcon={data.getPtFormFactor.formfactorIcon}
      />}
      Fields={<>
        <OpenDataId
          label={"ID"}
          data={data.getPtFormFactor._id}
        />
        <OpenDataId
          label={"info"}
          data={data.getPtFormFactor.info}
        />
        <OpenDataId
          label={"instrument_id.name"}
          data={data.getPtFormFactor.spInstrument_id.name}
        />
        <OpenDataId
          label={"instrumentType_id.name"}
          data={data.getPtFormFactor.spInstrumentType_id.name}
        />
        <OpenDataId
          label={"ffClass_id.name"}
          data={data.getPtFormFactor.spFfClass_id.name}
        />
        <OpenDataId
          label={"ffFamily_id.name"}
          data={data.getPtFormFactor.spFfFamily_id.name}
        />
        <OpenDataId
          label={"year"}
          data={data.getPtFormFactor.year}
        />
        <OpenDataId
          label={"sourse"}
          data={data.getPtFormFactor.sourse}
        />

        <OpenDataId
          label={"formfactorImage"}
          data={data.getPtFormFactor.formfactorImage}
        />
        <ViewImage
          image={data.getPtFormFactor.formfactorImage}
          dir={"images/formfactor"}
        />

        <OpenDataId
          label={"formfactorIcon"}
          data={data.getPtFormFactor.formfactorIcon}
        />
        <ViewIcon
          image={data.getPtFormFactor.formfactorIcon}
          dir={"icons/formfactor"}
        />
        
        <OpenDataId
          label={"createdAt"}
          data={data.getPtFormFactor.createdAt}
        />
        <OpenDataId
          label={"updatedAt"}
          data={data.getPtFormFactor.updatedAt}
        />
      </>} />
  )
}

export default GetPtFormFactor;