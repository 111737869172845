import { gql } from "@apollo/client";

export const FETCH_SP_NUMBERS_OF_FRETS = gql`
query getSpNumbersOfFrets($sheet: Int, $limit: Int){
        getSpNumbersOfFrets(sheet: $sheet, limit: $limit){
            _id,
            name,
            info,
            spInstrument_id {
                _id
            },    
            spInstrumentType_id {
                _id
            },
            spNumberOfStrings_id {
                _id
            },
            code,
            createdAt,
            updatedAt  
        }
    }
`;

export const FETCH_SP_NUMBER_OF_FRETS = gql`
    query getSpNumberOfFrets($id: ID) {
        getSpNumberOfFrets(_id: $id) {
            _id,
            name,
            info,
            spInstrument_id {
                _id
                name
            },    
            spInstrumentType_id {
                _id
                name
            },
            spNumberOfStrings_id {
                _id
                name
                code
            },
            code,
            createdAt,
            updatedAt
        }
    }
`;

export const ADD_SP_NUMBER_OF_FRETS = gql`
    mutation addSpNumberOfFrets(
        $name: String, 
        $info: String,
        $spInstrument_id: ID,
        $spInstrumentType_id: ID,
        $spNumberOfStrings_id: ID,
        $code: String) {
            addSpNumberOfFrets(
                name: $name, 
                info: $info,
                spInstrument_id: $spInstrument_id,
                spInstrumentType_id: $spInstrumentType_id,
                spNumberOfStrings_id: $spNumberOfStrings_id,
                code: $code) {
                    _id
                    spNumberOfStrings_id {
                        code
                    }
                }      
            }
`;

export const DELETE_SP_NUMBER_OF_FRETS = gql`
    mutation deleteSpNumberOfFrets($id: ID) {
        deleteSpNumberOfFrets(_id: $id) {
            _id
        }   
    }
`;

export const UPDATE_SP_NUMBER_OF_FRETS = gql`
    mutation updateSpNumberOfFrets(
        $id: ID, 
        $name: String, 
        $info: String,
        $spInstrument_id: ID,
        $spInstrumentType_id: ID,
        $spNumberOfStrings_id: ID,
        $code: String) {
            updateSpNumberOfFrets(
                _id: $id, 
                name: $name, 
                info: $info,
                spInstrument_id: $spInstrument_id,
                spInstrumentType_id: $spInstrumentType_id,
                spNumberOfStrings_id: $spNumberOfStrings_id,
                code: $code) {
                 _id
            }
        }
`;

export const FETCH_SP_NUMBERS_OF_FRETS_FOR_SPNUMBERSOFFRETSCODE = gql`
    {
        getSpNumbersOfFrets{
            _id,
            code  
        }
    }
`;

export const FETCH_SP_NUMBER_OF_FRETS_FILTER = gql`
    query getSpNumberOfFretsFilter(
        $spInstrument_id: String, 
        $spInstrumentType_id: String,
        $spNumberOfStrings_id: String) {
            getSpNumberOfFretsFilter(
                spInstrument_id: $spInstrument_id,
                spInstrumentType_id: $spInstrumentType_id,
                spNumberOfStrings_id: $spNumberOfStrings_id) {
                    _id,
                    name,
                    code
                }
        }
`;