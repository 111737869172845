import React, { useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { UPDATE_PT_PICKGUARD, FETCH_PT_PICKGUARDS } from './gqlPtPickguard';
import { FETCH_PT_FORMFACTORS_FOR_PTFORMFACTORID } from '../ptFormFactor/gqlPtFormFactor';
import Loader from '../../../../components/main/Loader';
import Update from '../../../../components/actions/update';
import RequiredField from '../../../../components/InputElements/requiredField';
import OptionalMultiRowsField from '../../../../components/InputElements/optionalMultiRowsField';
import RequiredSelect from '../../../../components/InputElements/requiredSelect';
import UploadIcon from '../../../../components/actions/uploadIcon';
import UploadImage from '../../../../components/actions/uploadImage';


export const UpdatePtPickguard = ({
    id, name, info, ptFormFactor_id, pickguardImage, pickguardIcon }) => {

    const [values, setValues] = useState({
        name, info, ptFormFactor_id, pickguardImage, pickguardIcon
    });

    const [icon, setIcon] = useState({
        fileName: pickguardIcon
    });

    const [image, setImage] = useState({
        fileName: pickguardImage
    });

    const [updatePtPickup] = useMutation(UPDATE_PT_PICKGUARD, {

        onError(e) {
            console.log(e.graphQLErrors[0].message)
        },
        variables: {
            id, name: values.name, info: values.info, ptFormFactor_id: values.ptFormFactor_id,
            pickguardImage: values.pickguardImage, pickguardIcon: values.pickguardIcon
        },
        refetchQueries: [{ query: FETCH_PT_PICKGUARDS }]
    })

    const {
        loading: loading_FETCH_PT_FORMFACTORS_FOR_PTFORMFACTORID,
        error: error_FETCH_PT_FORMFACTORS_FOR_PTFORMFACTORID,
        data: data_FETCH_PT_FORMFACTORS_FOR_PTFORMFACTORID } = useQuery(FETCH_PT_FORMFACTORS_FOR_PTFORMFACTORID, {
            fetchPolicy: "no-cache"
        });

    if (loading_FETCH_PT_FORMFACTORS_FOR_PTFORMFACTORID) return <Loader />;
    if (error_FETCH_PT_FORMFACTORS_FOR_PTFORMFACTORID) return `Error ${error_FETCH_PT_FORMFACTORS_FOR_PTFORMFACTORID}`;

    const handlerChangeName = (e) => {
        setValues({
            ...values,
            [e.target.name]: e.target.value
        })
    }

    const formSubmit = (e) => {
        e.preventDefault();
        updatePtPickup();
    }

    const updateData = (value) => {
        setIcon({ fileName: value })
    }

    const updateDataImage = (value) => {
        setImage({ fileName: value })
    }

    return (
        <Update
            title={"Изменение Pickguard"}
            ButtonText={"Изменить Pickguard"}
            dialogTitle={"Изменение Pickguard"}
            formSubmit={formSubmit}
            Fields={<>
            <UploadIcon
                    label="pickguardIcon"
                    value={values.pickguardIcon}
                    // email={values.email}
                    nameData="pr_gslc_i_pickguard"
                    method={"pr/gslc/icons/pickguard"}
                    dirIcon={"pickguard"}
                    updateData={updateData}
                /><br></br>
                <RequiredField
                    label={"pickguardIcon"}
                    value={values.pickguardIcon = icon.fileName.toString()}
                    onChange={handlerChangeName}
                    name={"pickguardIcon"}
                /><br></br>
                <UploadImage
                    label="pickguardImage"
                    value={values.pickguardImage}
                    // email={values.email}
                    nameData="pr_gslc_img_pickguard"
                    method={"pr/gslc/images/pickguard"}
                    dirIcon={"pickguard"}
                    updateDataImage={updateDataImage}
                /><br></br>
                <RequiredField
                    label={"pickguardImage"}
                    value={values.pickguardImage = image.fileName.toString()}
                    onChange={handlerChangeName}
                    name={"pickguardImage"}
                /><br></br>
                <RequiredField
                    label={"name"}
                    value={values.name}
                    onChange={handlerChangeName}
                    name={"name"}
                />
                <br></br>
                <OptionalMultiRowsField
                    label="Дополнительная информация"
                    name="info"
                    value={values.info}
                    onChange={handlerChangeName}
                    rows={10}
                />
                <br></br>
                <RequiredSelect
                    label={"ptFormFactor_id"}
                    name={"ptFormFactor_id"}
                    value={values.ptFormFactor_id}
                    onChange={handlerChangeName}
                    fetch={data_FETCH_PT_FORMFACTORS_FOR_PTFORMFACTORID.getPtFormFactors}
                />
            </>} />

    )
}