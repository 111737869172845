import React from 'react';
import { useMutation } from '@apollo/client';
import { DELETE_SP_FF_CLASS, FETCH_SP_FF_CLASSES } from './gqlSpFFClass';
import { Delete } from '../../../../components/actions/delete';


export const DeleteSpFFClass = ({id}) => {
  const [deleteSpFFClass] = useMutation(DELETE_SP_FF_CLASS, {
    
    onError(e) {
        console.log(e.graphQLErrors[0].message)
    },
     variables: {id},
     refetchQueries: [{ query: FETCH_SP_FF_CLASSES }]
});

const onDelete= () => {
  deleteSpFFClass()
}
  return (
    <Delete onDelete={onDelete} />
  )
}