import React from 'react';
import { useMutation } from '@apollo/client';
import { DELETE_SP_PICKUP_TYPE, FETCH_SP_PICKUPS_TYPES } from './gqlSpPickupType';
import { Delete } from '../../../../components/actions/delete';


export const DeleteSpPickupType = ({id}) => {
  const [deleteSpPickupType] = useMutation(DELETE_SP_PICKUP_TYPE, {
    
    onError(e) {
        console.log(e.graphQLErrors[0].message)
    },
     variables: {id},
     refetchQueries: [{ query: FETCH_SP_PICKUPS_TYPES }]
});

const onDelete= () => {
  deleteSpPickupType()
}
  return (
    <Delete onDelete={onDelete} />
  )
};