import React from 'react';
import { useMutation } from '@apollo/client';
import { DELETE_SP_NUMBER_OF_STRINGS, FETCH_SP_NUMBERS_OF_STRINGS } from './gqlSpNumberOfStrings';
import { Delete } from '../../../../components/actions/delete';


export const DeleteSpNumberOfStrings = ({id}) => {
  const [deleteSpNumberOfStrings] = useMutation(DELETE_SP_NUMBER_OF_STRINGS, {
    
    onError(e) {
        console.log(e.graphQLErrors[0].message)
    },
     variables: {id},
     refetchQueries: [{ query: FETCH_SP_NUMBERS_OF_STRINGS }]
});

const onDelete= () => {
  deleteSpNumberOfStrings()
}
  return (
    <Delete onDelete={onDelete} />
  )
}