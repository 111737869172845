import React from 'react';
import { useQuery } from '@apollo/client';
import { FETCH_SP_NUMBERS_OF_FRETS } from './gqlSpNumberOfFrets';
import GetSpNumberOfFrets from './getSpNumberOfFrets';
import { DeleteSpNumberOfFrets } from './deleteSpNumberOfFrets';
import { UpdateSpNumberOfFrets } from './updateSpNumberOfFrets';
import Loader from '../../../../components/main/Loader';
import { Box, Container } from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';

const GetSpNumbersOfFrets = () => {
    const [page, setPage] = React.useState(1);

    const { loading, error, data } = useQuery(FETCH_SP_NUMBERS_OF_FRETS, {
        variables: {
            sheet: page,
            limit: 10
        },
    });

    if (loading) return <Loader/>;
    if (error) return  `Error ${error}`;
    
    const handleChange = (event, value) => {
        setPage(value);
    };

    return (
        <>
        <Pagination count={100} page={page} onChange={handleChange} />
            <Container>
                <Box display="flex" justifyContent="center">
                    <table>
                        <thead>
                            <tr>
                                <th>№</th>
                                <th>Наименование</th>
                                <th>info</th>
                                <th>Дата создания</th>
                                <th>Дата изменения</th>
                                <th>Действия</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.getSpNumbersOfFrets.map((SpNumberOfFrets, index) => {
                                return (
                                    <tr key={SpNumberOfFrets._id}>
                                        <td>{index + 1}</td>
                                        <td>{SpNumberOfFrets.name}</td>
                                        <td>{SpNumberOfFrets.info}</td>
                                        <td>{SpNumberOfFrets.createdAt}</td>
                                        <td>{SpNumberOfFrets.updatedAt}</td>
                                        <td>
                                            <GetSpNumberOfFrets id={SpNumberOfFrets._id} />
                                            <UpdateSpNumberOfFrets
                                                id={SpNumberOfFrets._id}
                                                name={SpNumberOfFrets.name}
                                                info={SpNumberOfFrets.info}
                                                spInstrument_id={SpNumberOfFrets.spInstrument_id._id}
                                                spInstrumentType_id={SpNumberOfFrets.spInstrumentType_id._id}
                                                spNumberOfStrings_id={SpNumberOfFrets.spNumberOfStrings_id._id}
                                                code={SpNumberOfFrets.code}/>
                                            <DeleteSpNumberOfFrets id={SpNumberOfFrets._id} />
                                        </td>
                                    </tr>)
                            })}
                        </tbody>
                    </table>
                </Box>
            </Container>
        </>
    )
}

export default GetSpNumbersOfFrets;