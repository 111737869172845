import { gql } from "@apollo/client";

export const FETCH_SP_NECK_TYPES = gql`
    {
        getSpNeckTypes{
            _id,
            name,
            info,    
            createdAt,
            updatedAt  
        }
    }
`;

export const FETCH_SP_NECK_TYPE = gql`
    query getSpNeckType($id: ID) {
        getSpNeckType(_id: $id) {
            _id,
            name,
            info,
            createdAt
        }
    }
`;

export const ADD_SP_NECK_TYPE = gql`
    mutation addSpNeckType( $name: String, $info: String) {
        addSpNeckType( name: $name, info: $info) {
            _id
        }      
    }
`;

export const DELETE_SP_NECK_TYPE = gql`
    mutation deleteSpNeckType($id: ID) {
        deleteSpNeckType(_id: $id) {
          _id
        }
    }
`;

export const UPDATE_SP_NECK_TYPE = gql`
    mutation updateSpNeckType( $id: ID, $name: String, $info: String) {
        updateSpNeckType( _id: $id, name: $name, info: $info) {
            _id
        }
    }
`;

export const FETCH_SP_NECK_TYPES_FOR_SPNECKTYPESID = gql`
    {
        getSpNeckTypes{
            _id
            name 
        }
    }
`;