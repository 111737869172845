import React from 'react';
import { useQuery } from '@apollo/client';
import { FETCH_PT_BRIDGES } from './gqlPtBridge';
import GetPtBridge from './getPtBridge';
import { DeletePtBridge } from './deletePtBridge';
import { UpdatePtBridge } from './updatePtBridge';
import Loader from '../../../../components/main/Loader';
import CardDatabaseItem from '../../../../components/view/cards/cardDatabaseItem';
import Pagination from '@material-ui/lab/Pagination';

const GetPtBridges = () => {
    const [page, setPage] = React.useState(1);

    const { loading, error, data } = useQuery(FETCH_PT_BRIDGES, {
        variables: {
            sheet: page,
            limit: 10
        },
    });

    if (loading) return <Loader />;
    if (error) return `Error ${error}`;

    const handleChange = (event, value) => {
        setPage(value);
    };

    return (
        <>
            <Pagination count={100} page={page} onChange={handleChange} />

            {data.getPtBridges.map((PtBridge, index) => {
                return (
                    <CardDatabaseItem
                        name={PtBridge.name}
                        nameIcon={PtBridge.bridgeIcon}
                        info={PtBridge.info}
                        dirName={"icons/bridge"}
                        actions={
                            <>
                                <GetPtBridge id={PtBridge._id} />
                                <UpdatePtBridge
                                    id={PtBridge._id}
                                    name={PtBridge.name}
                                    info={PtBridge.info}
                                    spInstrument_id={PtBridge.spInstrument_id._id}
                                    spInstrumentType_id={PtBridge.spInstrumentType_id._id}
                                    spBridgeClass_id={PtBridge.spBridgeClass_id._id}
                                    spBridgeFamily_id={PtBridge.spBridgeFamily_id._id}
                                    spNumberOfStrings_id={PtBridge.spNumberOfStrings_id._id}
                                    sourse={PtBridge.sourse}
                                    bridgeIcon={PtBridge.bridgeIcon}
                                    bridgeImage={PtBridge.bridgeImage}
                                />
                                <DeletePtBridge id={PtBridge._id} />
                            </>
                        } />
                )
            })}
        </>
    )
}

export default GetPtBridges;