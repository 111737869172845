import React from 'react';
import { useMutation } from '@apollo/client';
import { DELETE_SP_BRIDGE_CLASS, FETCH_SP_BRIDGES_CLASSES } from './gqlSpBridgeClass';
import { Delete } from '../../../../components/actions/delete';


export const DeleteSpBridgeClass = ({ id }) => {
  const [deleteSpBridgeClass] = useMutation(DELETE_SP_BRIDGE_CLASS, {

    onError(e) {
      console.log(e.graphQLErrors[0].message)
    },
    variables: { id },
    refetchQueries: [{ query: FETCH_SP_BRIDGES_CLASSES }]
  });

  const onDelete = () => {
    deleteSpBridgeClass()
  }

  return (
    <Delete onDelete={onDelete} />
  )
}