
import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import { FETCH_USER_ROLE } from './gqlUserRole';
import Loader from '../../../components/main/Loader';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Tooltip, Typography } from '@material-ui/core';

const GetUserRole = ({ id }) => {
  const [open, setOpen] = useState(false)

  const { loading, error, data } = useQuery(FETCH_USER_ROLE, {
    variables: { id },
  });

  if (loading) return <Loader />;
  if (error) return `Error ${error}`;

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Tooltip
        title="Open"
        arrow>
        <Button onClick={() => setOpen(true)}>Open</Button>
      </Tooltip>

      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">

        <DialogTitle id="form-dialog-title">Просмотр</DialogTitle>
        <DialogContent>
          <Typography>ID: {data.getUserRole._id}</Typography>
          <Typography>Наименование: {data.getUserRole.name}</Typography>
          <Typography>Информация: {data.getUserRole.info}</Typography>
          <Typography>Дата создания: {data.getUserRole.createdAt}</Typography>
          <Typography>Дата изменения: {data.getUserRole.updatedAt}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
        </DialogActions>

      </Dialog>
    </>
  )
}

export default GetUserRole;