import React from 'react';
import { useMutation } from '@apollo/client';
import { DELETE_SP_BRAND, FETCH_SP_BRANDS } from './gqlSpBrand';
import { Delete } from '../../../../components/actions/delete';


export const DeleteSpBrand = ({id}) => {
  const [deleteSpBrand] = useMutation(DELETE_SP_BRAND, {
    
    onError(e) {
        console.log(e.graphQLErrors[0].message)
    },
     variables: {id},
     refetchQueries: [{ query: FETCH_SP_BRANDS }]
});

const onDelete= () => {
  deleteSpBrand()
}
  return (
    <Delete onDelete={onDelete} />
  )
}


