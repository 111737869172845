import React, { useState } from 'react';
import { Box, Dialog, DialogContent, DialogTitle, IconButton, Tooltip } from '@material-ui/core';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import CloseIcon from '@material-ui/icons/Close';

const Open = ({ title, dialogTitle, Fields, edit }) => {

    const [open, setOpen] = useState(false)

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            <Tooltip
                title={title}
                arrow>
                <IconButton onClick={() => setOpen(true)}> <OpenInNewIcon /></IconButton>
            </Tooltip>

            <Dialog maxWidth='lg' open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">
                    <Box display="flex" >
                        <Box p={1} flexGrow={1}> {dialogTitle}</Box>
                        <Box p={1}>  {edit}</Box>
                        <Box p={1}>
                            <Tooltip
                                title={"Закрыть"}
                                arrow>
                                <IconButton onClick={handleClose}>  <CloseIcon /></IconButton>
                            </Tooltip>
                        </Box>
                    </Box>



                </DialogTitle>
                <DialogContent>

                    {Fields}

                </DialogContent>
            </Dialog>
        </>
    )

}

export default Open;