import React from 'react';
import { useQuery } from '@apollo/client';
import { FETCH_USER } from '../requests/users/gqlCurrentUser';
import { DeleteUserPost } from '../requests/users/userPost/deleteUserPost';
import Loader from './main/Loader';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { red } from '@material-ui/core/colors';
import FavoriteIcon from '@material-ui/icons/Favorite';
import ShareIcon from '@material-ui/icons/Share';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { Box, Container } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: 615,
        width: 615

    },

    avatar: {
        backgroundColor: red[500],
    },
}));

const MenuItems = (props) => {
    const classes = useStyles();
    const { loading, error, data } = useQuery(FETCH_USER, {
        variables: { id: props.user_id._id },  
    });
    if (loading) return <Loader/>;
    if (error) return `Error ${error}`

    return (
        <>
            <Container >
                <Box display="flex" justifyContent="center">
                    <Card className={classes.root} >
                        <CardHeader
                            avatar={
                                <Avatar aria-label="recipe" className={classes.avatar}>
                                    {data.getUser.email.charAt(0).toUpperCase()}
                                </Avatar>
                            }
                            action={
                                <IconButton aria-label="settings">
                                    <MoreVertIcon />
                                </IconButton>
                            }
                            title={data.getUser.firstName}
                            subheader={props.createdAt}
                        />

                        <CardContent>
                            <Typography variant="body2" color="textSecondary" component="p">
                                {props.postText}
                            </Typography>
                        </CardContent>
                        <CardActions disableSpacing>
                            <IconButton aria-label="add to favorites">
                                <FavoriteIcon />
                            </IconButton>
                            <IconButton aria-label="share">
                                <ShareIcon />
                            </IconButton>
                            <DeleteUserPost id={props.id}/>
                        </CardActions>
                    </Card>
                </Box>
            </Container>
            <br></br>
        </>
    )
}

// const FETCH_USER = gql`
// query getUser($id: ID) {
//   getUser(_id: $id) {
//     _id
//     email
//     firstName
//     lastName
//   }
// }
// `;

export default MenuItems;