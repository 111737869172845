import React from 'react';
import { useQuery } from '@apollo/client';
import { FETCH_SP_PICKUP_MODE } from './gqlSpPickupMode';
import Loader from '../../../../components/main/Loader';
import Open from '../../../../components/actions/open';
import OpenDataId from '../../../../components/view/openDataId';
import { UpdateSpPickupMode } from './updateSpPickupMode';

const GetSpPickupMode = ({ id }) => {

  const { loading, error, data } = useQuery(FETCH_SP_PICKUP_MODE, {
    variables: { id },
  });

  if (loading) return <Loader />;
  if (error) return `Error ${error}`;

  return (
    <Open
      title={"Открыть"}
      dialogTitle={data.getSpPickupMode.name}
      edit={<UpdateSpPickupMode
        id={data.getSpPickupMode._id}
        name={data.getSpPickupMode.name}
        info={data.getSpPickupMode.info} />}
      Fields={<>
        <OpenDataId
          label={"ID"}
          data={data.getSpPickupMode._id}
        />
        <OpenDataId
          label={"Информация"}
          data={data.getSpPickupMode.info}
        />
        <OpenDataId
          label={"Дата создания"}
          data={data.getSpPickupMode.createdAt}
        />
        <OpenDataId
          label={"Дата изменения"}
          data={data.getSpPickupMode.updatedAt}
        />
      </>} />
  )
}

export default GetSpPickupMode;