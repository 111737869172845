import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { ADD_SP_MATERIAL, FETCH_SP_MATERIALS } from './gqlSpMaterial';
import Add from '../../../../components/actions/add';
import RequiredField from '../../../../components/InputElements/requiredField';
import OptionalMultiRowsField from '../../../../components/InputElements/optionalMultiRowsField';
import UploadImage from '../../../../components/actions/uploadImage';

const AddSpMaterial = () => {
    const [values, setValues] = useState({
        name: ""
    });

    const [image, setImage] = useState({
        fileName: 'Файл не загружен'
    });

    const [addSpMaterial] = useMutation(ADD_SP_MATERIAL, {

        onError(e) {
            console.log(e.graphQLErrors[0].message)
        },
        variables: values,
        refetchQueries: [{ query: FETCH_SP_MATERIALS }]
    });

    const handlerChangeName = (e) => {
        setValues({
            ...values,
            [e.target.name]: e.target.value,
        });
    };


    const formSubmit = (e) => {
        e.preventDefault();
        addSpMaterial();
        setValues({ name: "" })
    }

    const updateDataImage = (value) => {
        setImage({ fileName: value })
    }

    return (
        <Add
            title={"Добавление Material"}
            ButtonText={"Добавить Material"}
            dialogTitle={"Добавление нового Material"}
            formSubmit={formSubmit}
            Fields={<>
                <RequiredField
                    label={"Наименование"}
                    value={values.name}
                    onChange={handlerChangeName}
                    name={"name"}
                /><br></br>
                <RequiredField
                    label={"Наименование (ENG)"}
                    value={values.nameEng}
                    onChange={handlerChangeName}
                    name={"nameEng"}
                /><br></br>
                <OptionalMultiRowsField
                    label={"Информация"}
                    value={values.info}
                    onChange={handlerChangeName}
                    name={"info"}
                /><br></br>
                <UploadImage
                    label="Изображение"
                    value={values.materialImage}
                    nameData="sp_material_img"
                    method={"sp/material"}
                    updateDataImage={updateDataImage}
                /><br></br>
                <RequiredField
                    label={"Изображение"}
                    value={values.materialImage = image.fileName.toString()}
                    onChange={handlerChangeName}
                    name={"materialImage"}
                />
            </>} />
    )
}

export default AddSpMaterial;