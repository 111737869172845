import React from 'react';
import { useMutation } from '@apollo/client';
import { DELETE_SP_MENSURA, FETCH_SP_MENSURAS } from './gqlSpMensura';
import { Delete } from '../../../../components/actions/delete';


export const DeleteSpMensura = ({ id }) => {
  const [deleteSpMensura] = useMutation(DELETE_SP_MENSURA, {

    onError(e) {
      console.log(e.graphQLErrors[0].message)
    },
    variables: { id },
    refetchQueries: [{ query: FETCH_SP_MENSURAS }]
  });

  const onDelete = () => {
    deleteSpMensura()
  }

  return (
    <Delete onDelete={onDelete} />
  )
}