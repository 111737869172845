
import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { FETCH_USERS_ROLES, UPDATE_USER_ROLE } from './gqlUserRole';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Tooltip } from '@material-ui/core';


export const UpdateUserRole = ({ id, name, info }) => {
    const [open, setOpen] = useState(false)
    const [values, setValues] = useState({
        name, info
    });

    const [updateUserRole] = useMutation(UPDATE_USER_ROLE, {

        onError(e) {
            console.log(e.graphQLErrors[0].message)
        },
        variables: { id, name: values.name, info: values.info },
        refetchQueries: [{ query: FETCH_USERS_ROLES }]
    })

    const handlerChangeName = (e) => {
        setValues({
            ...values,
            [e.target.name]: e.target.value
        })
    }

    const formSubmit = (e) => {
        e.preventDefault();
        updateUserRole();
    }

    const handleClose = () => {
        setOpen(false);
    };
    
    return (
        <>
            <Tooltip
                title="Edit"
                arrow>
                <Button onClick={() => setOpen(true)}>Edit</Button>
            </Tooltip>

            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <form onSubmit={formSubmit}>
                    <DialogTitle id="form-dialog-title">Update</DialogTitle>
                    <DialogContent>

                        <TextField
                            id="standard-basic"
                            label="Наименование"
                            type="text"
                            name="name"
                            value={values.name}
                            onChange={handlerChangeName}
                        >
                        </TextField>
                        <br></br>
                        <TextField
                            id="standard-basic"
                            label="info"
                            type="text"
                            name="info"
                            value={values.info}
                            onChange={handlerChangeName}
                        >
                        </TextField>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} color="primary">
                            Cancel
          </Button>
                        <Button type="Submit" onClick={handleClose} color="secondary">
                            Save
          </Button>
                    </DialogActions>
                </form>
            </Dialog>
        </>
    )
}

