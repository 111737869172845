import React from 'react';
import { useQuery } from '@apollo/client';
import { FETCH_SP_NUMBER_OF_FRETS } from './gqlSpNumberOfFrets';
import Loader from '../../../../components/main/Loader';
import Open from '../../../../components/actions/open';
import OpenDataId from '../../../../components/view/openDataId';
import { UpdateSpNumberOfFrets } from './updateSpNumberOfFrets';

const GetSpNumberOfFrets = ({ id }) => {

  const { loading, error, data } = useQuery(FETCH_SP_NUMBER_OF_FRETS, {
    variables: { id }

  });

  if (loading) return <Loader />;
  if (error) return `Error ${error}`;

  return (
    <Open
      title={"Открыть"}
      dialogTitle={data.getSpNumberOfFrets.name}
      edit={<UpdateSpNumberOfFrets
        id={data.getSpNumberOfFrets._id}
        name={data.getSpNumberOfFrets.name}
        info={data.getSpNumberOfFrets.info}
        spInstrument_id={data.getSpNumberOfFrets.spInstrument_id._id}
        spInstrumentType_id={data.getSpNumberOfFrets.spInstrumentType_id._id}
        spNumberOfStrings_id={data.getSpNumberOfFrets.spNumberOfStrings_id._id}
        code={data.getSpNumberOfFrets.code} />}
      Fields={<>
        <OpenDataId
          label={"Наименование"}
          data={data.getSpNumberOfFrets._id}
        />
        <OpenDataId
          label={"Информация"}
          data={data.getSpNumberOfFrets.info}
        />
        <OpenDataId
          label={"spInstrument_id"}
          data={data.getSpNumberOfFrets.spInstrument_id.name}
        />
        <OpenDataId
          label={"spInstrumentType_id"}
          data={data.getSpNumberOfFrets.spInstrumentType_id.name}
        />
        <OpenDataId
          label={"spNumberOfStrings_id"}
          data={data.getSpNumberOfFrets.spNumberOfStrings_id.name}
        />
        <OpenDataId
          label={"spNumberOfStrings_id"}
          data={data.getSpNumberOfFrets.spNumberOfStrings_id.code}
        />
        <OpenDataId
          label={"Дата создания"}
          data={data.getSpNumberOfFrets.createdAt}
        />
        <OpenDataId
          label={"Дата изменения"}
          data={data.getSpNumberOfFrets.updatedAt}
        />
      </>} />
  )
}

export default GetSpNumberOfFrets;